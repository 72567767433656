import React from 'react';
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'

import ActionProvider from './ActionProvider';
import MessageParser from './MessageParser';
import config from './config';
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	ChatbotContainer: {
    '& .react-chatbot-kit-chat-header': {
      width: '280px',
    },
		'& .react-chatbot-kit-chat-message-container': {
      borderLeft: 'outset',
      borderRight: 'inset',
      width: '264px',
    },
    '& .react-chatbot-kit-chat-input-container': {
      width:'305px',
    },
    '& .react-chatbot-kit-chat-input': {
      borderLeft: 'outset',
      borderBottom: '1.5px solid #d8d8d8',
    },
	},
}))


const ChatUi = () => {

  const classes = useStyles()

  return (
    <div className={classes.ChatbotContainer}>
        <Chatbot config={config} actionProvider={ActionProvider} messageParser={MessageParser} />
    </div>
  )
}

export default ChatUi