import { ACTION_TYPES } from '../../../../constants'
import _ from 'lodash'
import update from 'immutability-helper'

const stickyGroup = (state, action) => {
	const stickyGroupIdx = _.findIndex(state, { sticky_group_id: action?.payload?.stygpId })
	const stickyGroupId = _.findIndex(state, { sticky_group_id: action?.payload?.data?.sticky_group_id })
	switch (action.type) {
		case ACTION_TYPES.ADD_GLASS_STICKY_GROUP:
			return update(state, { $push: [_.get(action, 'payload')] })
		case ACTION_TYPES.UPDATE_GLASS_STICKY_GROUP:
			const stickyGroupToUpdate = _.get(action, 'payload.data')
			return update(state, { [stickyGroupId]: { $set: { ...stickyGroupToUpdate } } })
		case ACTION_TYPES.REMOVE_GLASS_STICKY_GROUP:
			return update(state, { $splice: [[stickyGroupIdx, 1]] })
		default:
			return state
	}
}

export default stickyGroup
