import { ACTION_TYPES, FILTER_CONSTANTS, JOB_PRIORITY } from '../../../constants'
import _ from 'lodash'

const initialState = {
	[FILTER_CONSTANTS.CODE_QUALITY]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [0, 5],
	},
	[FILTER_CONSTANTS.PERFORMANCE_SCORE]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [0, 5],
	},
	[FILTER_CONSTANTS.CREATED_DATE]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [],
	},
	[FILTER_CONSTANTS.REVISIONS]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: ['No revisions', '1-5', '5-10', 'More than 10'],
	},
	[FILTER_CONSTANTS.STATUS]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: ['Open', 'In Progress', 'Stopped', 'Success', 'Failed'],
	},
	[FILTER_CONSTANTS.RATING]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [0, 5],
	},
	[FILTER_CONSTANTS.SATISFACTION_SCORE]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [0, 5],
	},
	[FILTER_CONSTANTS.PROJECT_NAME]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [],
	},
	[FILTER_CONSTANTS.GLASSX_NAME]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [],
	},
	[FILTER_CONSTANTS.MODIFIED_BY]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [],
	},
	[FILTER_CONSTANTS.CREATED_BY]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [],
	},
	[FILTER_CONSTANTS.PERFORMANCE_TIME]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: ['0-20', '20-40', '40-60', 'More than 60'],
	},
}

const filterSuggestions = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.GET_MODELX_SUGGESTIONS_PENDING: {
			return {
				...state,
				[action.filterKey]: {
					isInitialState: false,
					isLoading: true,
					suggestions: [...state[action.filterKey].suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_MODELX_SUGGESTIONS_FULFILLED: {
			const productFilters = [
				FILTER_CONSTANTS.CODE_QUALITY,
				FILTER_CONSTANTS.PERFORMANCE_SCORE,
				FILTER_CONSTANTS.CREATED_DATE,
				FILTER_CONSTANTS.REVISIONS,
				FILTER_CONSTANTS.STATUS,
				FILTER_CONSTANTS.PROJECT_NAME,
				FILTER_CONSTANTS.GLASSX_NAME,
				FILTER_CONSTANTS.MODIFIED_BY,
				FILTER_CONSTANTS.CREATED_BY,
				FILTER_CONSTANTS.PERFORMANCE_TIME,
			]
			const newSuggestions = [...(action.isNewData ? [] : state[action.filterKey].suggestions), ...action.data.data.items]

			if (_.includes(productFilters, action.filterKey)) {
				return {
					...state,
					[action.filterKey]: {
						isInitialState: false,
						isLoading: false,
						suggestions: newSuggestions,
						isAllLoaded: newSuggestions.length === action.data.data.total_count || action.data.data.items.length === action.data.data.total_count,
					},
				}
			} else {
				return {
					...state,
					[action.filterKey]: {
						isInitialState: false,
						isLoading: false,
						suggestions: newSuggestions,
						isAllLoaded: newSuggestions.length === action.data.data.total_size || action.data.data.items.length === action.data.data.total_size,
					},
				}
			}
		}

		case ACTION_TYPES.GET_MODELX_SUGGESTIONS_REJECTED: {
			return {
				...state,
				[action.filterKey]: {
					isLoading: false,
					isInitialState: false,
					suggestions: [...state[action.filterKey].suggestions],
					// isAllLoaded: state[action.filterKey].suggestions.length === action.payload.total_size || action.payload.items === action.payload.total_size
				},
			}
		}

		case ACTION_TYPES.UPDATE_JOB_FILTER_DATA: {
			return {
				...state,
				[FILTER_CONSTANTS.JOB_STATUS]: {
					isLoading: false,
					isInitialState: false,
					suggestions: action.data,
				},
			}
		}

		case ACTION_TYPES.GET_STYLE_CODE_SUGGESTIONS_PENDING: {
			return {
				...state,
				styleCode: {
					isLoading: true,
					isInitialState: false,
					suggestions: [...state.styleCode.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_STYLE_CODE_SUGGESTIONS_FULFILLED: {
			return {
				...state,
				styleCode: {
					isLoading: false,
					isInitialState: false,
					suggestions: _.uniqBy([...state.styleCode.suggestions, ...action.payload.search_result], _.get(action, 'key', 'objectID')),
					isAllLoaded:
						state.styleCode.suggestions.length === action.payload.total_count || action.payload.search_result.length === action.payload.total_count,
				},
			}
		}

		case ACTION_TYPES.GET_STYLE_CODE_SUGGESTIONS_REJECTED: {
			return {
				...state,
				styleCode: {
					isLoading: false,
					isInitialState: false,
					suggestions: [...state.styleCode.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_RETAILER_SUGGESTIONS_PENDING: {
			return {
				...state,
				retailer: {
					isLoading: true,
					isInitialState: false,
					suggestions: [...state.retailer.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_RETAILER_SUGGESTIONS_FULFILLED: {
			return {
				...state,
				retailer: {
					isLoading: false,
					isInitialState: false,
					isAllLoaded: state.retailer.suggestions.length === action.payload.total_count || action.payload.length === action.payload.total_count,
					suggestions: [...state.retailer.suggestions, ...action.payload.items],
				},
			}
		}

		case ACTION_TYPES.GET_RETAILER_SUGGESTIONS_REJECTED: {
			return {
				...state,
				retailer: {
					isLoading: false,
					isInitialState: false,
					suggestions: [...state.retailer.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_PRODUCT_SUGGESSIONS_PENDING: {
			return {
				...state,
				product: {
					isLoading: true,
					isInitialState: false,
					suggestions: [...state.product.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_PRODUCT_SUGGESSIONS_FULFILLED: {
			return {
				...state,
				product: {
					isLoading: false,
					isInitialState: false,
					isAllLoaded: state.retailer.suggestions.length === action.payload.total_count || action.payload.length === action.payload.total_count,
					suggestions: [...state.product.suggestions, ...action.payload.items],
				},
			}
		}

		case ACTION_TYPES.GET_PRODUCT_SUGGESSIONS_REJECTED: {
			return {
				...state,
				product: {
					isLoading: false,
					isInitialState: false,
					suggestions: [...state.product.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_RESELLER_SUGGESSIONS_PENDING: {
			return {
				...state,
				reseller: {
					isLoading: true,
					isInitialState: false,
					suggestions: [...state.product.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_RESELLER_SUGGESSIONS_FULFILLED: {
			return {
				...state,
				reseller: {
					isLoading: false,
					isInitialState: false,
					isAllLoaded: state.retailer.suggestions.length === action.payload.total_count || action.payload.length === action.payload.total_count,
					suggestions: [...state.reseller.suggestions, ...action.payload.items],
				},
			}
		}

		case ACTION_TYPES.GET_RESELLER_SUGGESSIONS_REJECTED: {
			return {
				...state,
				reseller: {
					isLoading: false,
					isInitialState: false,
					suggestions: [...state.reseller.suggestions],
				},
			}
		}

		case ACTION_TYPES.CLEAR_SUGGESTIONS: {
			return { ...initialState }
		}

		default: {
			return state
		}
	}
}

export default filterSuggestions
