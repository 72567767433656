import React, { useRef, useState, useEffect } from 'react'
import { 
    makeStyles, 
    TextField, 
    Avatar, 
    Typography, 
    Icon, 
    Grid, 
    FormHelperText, 
    InputAdornment, 
    IconButton, 
    Slide, 
    Button, 
    Dialog, 
    DialogContent, 
    DialogActions, 
    DialogTitle, 
    LinearProgress,
	Divider,
} from '@material-ui/core'
import { KeyboardTimePicker, KeyboardDateTimePicker, DateTimePicker, TimePicker } from '@material-ui/pickers'
import _ from 'lodash'
import { Autocomplete } from '@material-ui/lab'
import { REPEAT_TYPE, MONTHS, DATE, DAYS, MINUTE_WISE, HOUR_WISE } from '../../../../../../constants'
import classnames from 'classnames'
import moment from 'moment'
import { DateRange } from 'react-date-range'
import { convertLocalTimeToGmtStr, msToDurationTxt } from 'utils'

const useStyles = makeStyles((theme) => ({
    detailsContent: {
		padding: theme.spacing(1, 0),
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: theme.spacing(0.5, 0),
		},
	},
	pr2: {
		// paddingRight: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingRight: theme.spacing(0),
			width: '100%',
		},
	},
    datePicker: {
		// paddingRight: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingRight: theme.spacing(0),
			width: '100%',
		},
        '& .MuiFormControl-root': {
            minWidth: '100%',
        },
	},
}))

const RepeatForm = ({ formik }) => {

    const classes = useStyles()
    const lastValidDateRange = useRef()

    const [minuteOpen, setMinuteOpen] = useState(false)
    const [hourOpen, setHourOpen] = useState(false)
    const [monthOpen, setMonthOpen] = useState(false)
    const [dayOpen, setDayOpen] = useState(false)
    const [dateOpen, setDateOpen] = useState(false)

    useEffect (() => {
        setMinuteOpen(false)
        setHourOpen(false)
        setMonthOpen(false)
        setDayOpen(false)
        setDateOpen(false)
    }, [formik?.values])

    const onChangeTimeField = (fieldName, value) => {
		var valueToUpdate = moment(value)
		valueToUpdate = moment(_.get(lastValidDateRange.current, fieldName)).set({ hours: valueToUpdate.get('hours'), minutes: valueToUpdate.get('minutes') })
		formik?.setFieldValue(fieldName, valueToUpdate)
        // console.log('time', value, moment(value))
	}

    return (
        <div>
            <form>
                <Grid container>
                    <Grid item xs={12}  className={classnames(classes.detailsContent, classes.pr2)}>
                        <Autocomplete
                            title='RepeatType'
                            name='RepeatType'
                            options={REPEAT_TYPE}
                            size={'small'}
                            // onBlur={toggleAssigneeList}
                            disableClearable
                            // popupIcon={null}
                            fullWidth
                            openOnFocus
                            getOptionLabel={(option) => option ? option : ''}
                            getOptionSelected={(option, value) => option === value}
                            renderOption={(option, index) => {
                                return (
                                    <Grid key={index} container alignItems='center'>
                                        <Grid item xs>
                                            <Typography variant='body2'>{option}</Typography>
                                        </Grid>
                                    </Grid>
                                )
                            }}
                            filterSelectedOptions
                            onChange={(e, v) => {
                            	// onChange(e, v)
                            	// toggleAssigneeList()
                                formik.setFieldValue('type', v)
                            }}
                            value={formik?.values?.type}
                            renderInput={(params) => <TextField autoFocus {...params} variant='outlined' label='Repeat Type' />}
                        />
                    </Grid>
                    {_.isEqual(formik?.values?.type, 'Custom') &&
                    <Grid item xs={12}  className={classnames(classes.detailsContent, classes.datePicker)}>
                        <DateTimePicker
                            fullWidth
                            minDateMessage={<></>}
                            inputVariant='outlined'
                            value={formik?.values?.custom_date}
                            size={'small'}
                            name={'Custom date & time'}
                            // error={error}
                            margin='dense'
                            clearable
                            autoFocus
                            // disabled={disabled}
                            disablePast={true}
                            format={'DD MMM YYYY, h:mm:ss A'}
                            onChange={(date) => {
                                // onChange('', date)
                                // toggleAssigneeList()
                                formik.setFieldValue('custom_date', date)
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton size='small'>
                                            <Icon>date_range</Icon>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            className={classes.inputText}
                        />
                    </Grid>}
                    {_.isEqual(formik?.values?.type, 'Hourly') &&
                    <Grid item xs={12}  className={classnames(classes.detailsContent, classes.pr2)}>
                        <Autocomplete
                            open={hourOpen}
                            onOpen={() => {
                                setHourOpen(true);
                            }}
                            onClose={() => {
                                setHourOpen(false);
                            }}
                            title='Hours'
                            name={'hours'}
                            options={HOUR_WISE}
                            size={'small'}
                            // onBlur={toggleAssigneeList}
                            clearable
                            // popupIcon={null}
                            fullWidth
                            openOnFocus
                            getOptionLabel={(option) => option ? option : ''}
                            getOptionSelected={(option, value) => option === value}
                            renderOption={(option, index) => {
                                return (
                                    <Grid key={index} container alignItems='center'>
                                        <Grid item xs>
                                            <Typography variant='body2'>{option} Hours</Typography>
                                        </Grid>
                                    </Grid>
                                )
                            }}
                            filterSelectedOptions
                            onChange={(e, v) => {
                            	// onChange(e, v)
                            	// toggleAssigneeList()
                                formik.setFieldValue('hours', v)
                            }}
                            value={formik?.values?.hours}
                            renderInput={(params) => <TextField autoFocus {...params} variant='outlined' label='Hours' 
                            error={formik?.errors?.hours && formik?.touched?.hours}
							helperText={formik?.errors?.hours && formik?.touched?.hours && formik?.errors?.hours}
                            />}
                        />
                    </Grid>}
                    {(_.isEqual(formik?.values?.type, 'Hourly') || _.isEqual(formik?.values?.type, 'Minute wise')) &&
                    <Grid item xs={12}  className={classnames(classes.detailsContent, classes.pr2)}>
                        <Autocomplete
                            open={minuteOpen}
                            onOpen={() => {
                                setMinuteOpen(true);
                            }}
                            onClose={() => {
                                setMinuteOpen(false);
                            }}
                            title='Minute'
                            name={'minute'}
                            options={MINUTE_WISE}
                            size={'small'}
                            // onBlur={toggleAssigneeList}
                            clearable
                            // popupIcon={null}
                            fullWidth
                            openOnFocus
                            getOptionLabel={(option) => option ? option : ''}
                            getOptionSelected={(option, value) => option === value}
                            renderOption={(option, index) => {
                                return (
                                    <Grid key={index} container alignItems='center'>
                                        <Grid item xs>
                                            <Typography variant='body2'>{option} Minutes</Typography>
                                        </Grid>
                                    </Grid>
                                )
                            }}
                            filterSelectedOptions
                            onChange={(e, v) => {
                            	// onChange(e, v)
                            	// toggleAssigneeList()
                                formik.setFieldValue('minutes', v)
                            }}
                            value={formik?.values?.minutes}
                            renderInput={(params) => <TextField autoFocus {...params} variant='outlined' label='Minutes' 
                            error={formik?.errors?.minutes && formik?.touched?.minutes}
							helperText={formik?.errors?.minutes && formik?.touched?.minutes && formik?.errors?.minutes}
                            />}
                        />
                    </Grid>}
                    {_.isEqual(formik?.values?.type, 'Yearly') &&
                    <Grid item xs={12}  className={classnames(classes.detailsContent, classes.pr2)}>
                        <Autocomplete
                            open={monthOpen}
                            onOpen={() => {
                                setMonthOpen(true);
                            }}
                            onClose={() => {
                                setMonthOpen(false);
                            }}
                            title='Month'
                            name={'months'}
                            options={MONTHS}
                            size={'small'}
                            // onBlur={toggleAssigneeList}
                            clearable
                            multiple
                            // popupIcon={null}
                            fullWidth
                            openOnFocus
                            getOptionLabel={(option) => option ? _.toString(option) : ''}
                            getOptionSelected={(option, value) => option === value}
                            renderOption={(option, index) => {
                                return (
                                    <Grid key={index} container alignItems='center'>
                                        <Grid item xs>
                                            <Typography variant='body2'>{option}</Typography>
                                        </Grid>
                                    </Grid>
                                )
                            }}
                            filterSelectedOptions
                            onChange={(e, v) => {
                            	// onChange(e, v)
                            	// toggleAssigneeList()
                                formik.setFieldValue('month', v)
                            }}
                            value={formik?.values?.month}
                            renderInput={(params) => <TextField autoFocus {...params} variant='outlined' label='Month' 
                            error={formik?.errors?.month && formik?.touched?.month}
							helperText={formik?.errors?.month && formik?.touched?.month && formik?.errors?.month}
                            />}
                        />
                    </Grid>}
                    {_.isEqual(formik?.values?.type, 'Weekly') &&
                    <Grid item xs={12}  className={classnames(classes.detailsContent, classes.pr2)}>
                        <Autocomplete
                            open={dayOpen}
                            onOpen={() => {
                                setDayOpen(true);
                            }}
                            onClose={() => {
                                setDayOpen(false);
                            }}
                            title='Day'
                            name={'day'}
                            options={DAYS}
                            size={'small'}
                            // onBlur={toggleAssigneeList}
                            clearable
                            multiple
                            // popupIcon={null}
                            fullWidth
                            openOnFocus
                            getOptionLabel={(option) => option ? _.toString(option) : ''}
                            getOptionSelected={(option, value) => option === value}
                            renderOption={(option, index) => {
                                return (
                                    <Grid key={index} container alignItems='center'>
                                        <Grid item xs>
                                            <Typography variant='body2'>{option}</Typography>
                                        </Grid>
                                    </Grid>
                                )
                            }}
                            filterSelectedOptions
                            onChange={(e, v) => {
                            	// onChange(e, v)
                            	// toggleAssigneeList()
                                formik.setFieldValue('day', v)
                            }}
                            value={formik?.values?.day}
                            renderInput={(params) => <TextField autoFocus {...params} variant='outlined' label='Day' 
                            error={formik?.errors?.day && formik?.touched?.day}
							helperText={formik?.errors?.day && formik?.touched?.day && formik?.errors?.day}
                            />}
                        />
                    </Grid>}
                    {(_.isEqual(formik?.values?.type, 'Yearly') || _.isEqual(formik?.values?.type, 'Monthly')) &&
                    <Grid item xs={12}  className={classnames(classes.detailsContent, classes.pr2)}>
                        <Autocomplete
                            open={dateOpen}
                            onOpen={() => {
                                setDateOpen(true);
                            }}
                            onClose={() => {
                                setDateOpen(false);
                            }}
                            title='Date'
                            name={'date'}
                            options={DATE}
                            size={'small'}
                            // onBlur={toggleAssigneeList}
                            clearable
                            multiple
                            // popupIcon={null}
                            fullWidth
                            openOnFocus
                            getOptionLabel={(option) => option ? _.toString(option) : ''}
                            getOptionSelected={(option, value) => option === value}
                            renderOption={(option, index) => {
                                return (
                                    <Grid key={index} container alignItems='center'>
                                        <Grid item xs>
                                            <Typography variant='body2'>{option}</Typography>
                                        </Grid>
                                    </Grid>
                                )
                            }}
                            filterSelectedOptions
                            onChange={(e, v) => {
                            	// onChange(e, v)
                            	// toggleAssigneeList()
                                formik.setFieldValue('date', v)
                            }}
                            value={formik?.values?.date}
                            renderInput={(params) => <TextField autoFocus {...params} variant='outlined' label='Date' 
                            error={formik?.errors?.date && formik?.touched?.date}
							helperText={formik?.errors?.date && formik?.touched?.date && formik?.errors?.date}
                            />}
                        />
                    </Grid>}
                    {(_.isEqual(formik?.values?.type, 'Yearly') || _.isEqual(formik?.values?.type, 'Monthly') || _.isEqual(formik?.values?.type, 'Weekly') || _.isEqual(formik?.values?.type, 'Daily')) &&
                    <Grid item xs={12}  className={classnames(classes.detailsContent, classes.datePicker)}>
                        <KeyboardTimePicker
                            mask='__:__ _M'
                            format={'DD MMM YYYY, h:mm A'}
                            name='time'
                            value={formik?.values?.time}
                            onChange={(e, value) => {
                                onChangeTimeField('time', value)
                                // formik.setFieldValue('time', value)
                                // onChangeTimeField('startDate', value)
                            }}
                            // error={Boolean(formik?.errors?.startDate)}
                            // helperText={Boolean(formik?.errors?.startDate) && formik?.errors?.startDate}
                            size='small'
                            label='Time'
                            inputVariant='outlined'
                        />
                    </Grid>}
                </Grid>
            </form>
        </div>
    )
}

export default RepeatForm