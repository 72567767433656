import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'
const CancelToken = Axios.CancelToken

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	createProject: (data, successCallback, errorCallback) => {
		Axios.post(`${API_ENDPOINTS.CREATE_PROJECT}`, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchAllProjects: (search, offset, limit, searchFields, cancelExecutor, successCallback, errorCallback) => {
		if (cancelExecutor.current) cancelExecutor.current()
		Axios.get(`${API_ENDPOINTS.GET_PROJECT}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { search, offset, limit, searchFields },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchSingleProject: (projectId, successCallback, errorCallback) => {
		Axios.get(`${API_ENDPOINTS.SINGLE_PROJECT}`, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { projectId },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	editProject: (data, projectId, successCallback, errorCallback) => {
		Axios.put(
			`${API_ENDPOINTS.SINGLE_PROJECT}`,
			{ ...data },
			{
				headers: {
					'Content-Type': 'application/json',
					isAuthRequired: true,
					path: { projectId },
				},
			}
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	deleteProject: (projectId, successCallback, errorCallback) => {
		Axios.delete(`${API_ENDPOINTS.SINGLE_PROJECT}`, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { projectId },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchTeamUnit: (successCallback, errorCallback) => {
		Axios.get(`${API_ENDPOINTS.GET_TEAM_UNIT}`, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
}
