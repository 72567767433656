import { TextField, makeStyles, Button, CircularProgress, Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import BoardContext from '../BoardContext'

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.glassX.laneColor,
		padding: theme.spacing(1),
	},
	btnSec: {
		textAlign: 'right',
		'& button': {
			margin: theme.spacing(0.5),
		},
		'& button:last-child': {
			marginRight: 0,
		},
	},
	loaderRoot: {
		backgroundColor: theme.palette.glassX.laneColor,
		marginTop: theme.spacing(0.5),
		marginLeft: theme.spacing(0.5),
		padding: theme.spacing(1),
		textAlign: 'center',
		width: 250,
		minHeight: 98,
		borderRadius: 4,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		'& .MuiTypography-body1': {
			fontStyle: 'italic',
			marginTop: theme.spacing(1),
		},
	},
}))

const LaneSchema = Yup.object().shape({
	title: Yup.string().trim().max(60, 'Pane name should not be more than 60 characters').required('Please enter the Pane name'),
})

const NewLaneForm = (props) => {
	const { onCancel } = props
	const classes = useStyles()
	const { onClickAddLane, addPaneLoading } = useContext(BoardContext)

	const callBack = () => {
		onCancel()
	}

	const onSubmit = ({ title }) => {
		onClickAddLane(title, () => {
			callBack()
		})
	}

	const formik = useFormik({
		initialValues: {
			title: '',
		},
		onSubmit: onSubmit,
		validationSchema: LaneSchema,
	})
	return (
		<div>
			{addPaneLoading ? (
				<div className={classes.loaderRoot}>
					<CircularProgress size={24} />
					<Typography>Creating pane. Please wait...</Typography>
				</div>
			) : (
				<div className={classes.root}>
					<form onSubmit={formik.handleSubmit}>
						<TextField
							fullWidth
							variant='outlined'
							name='title'
							autoFocus
							value={formik.values.title}
							error={Boolean(formik.errors.title)}
							helperText={formik.errors?.title}
							onChange={formik.handleChange}
							label='Enter pane name'
							margin='dense'
						/>
						<div className={classes.btnSec}>
							<Button variant='contained' size='small' color='primary' type='submit' disableElevation>
								Add Pane
							</Button>
							<Button variant='outlined' color='primary' size='small' onClick={onCancel} disableElevation>
								Cancel
							</Button>
						</div>
					</form>
				</div>
			)}
		</div>
	)
}

export default NewLaneForm
