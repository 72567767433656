import { put, call, takeLatest, takeEvery } from 'redux-saga/effects'
import { ACTION_TYPES, SAGA_ACTIONS } from '../../../constants'
import searchSuggestionsApi from '../api'
import _ from 'lodash'

function* getPeoplexSuggestions(action) {
	yield put({
		type: ACTION_TYPES.GET_PEOPLEX_SUGGESTIONS_PENDING,
		filterKey: action.filterKey,
	})
	try {
		const api =
			!_.isNil(action.isProduct) && action.isProduct === true
				? searchSuggestionsApi.searchSuggestionsInPeoplexSearchApi
				: searchSuggestionsApi.suggestions
		const data = yield call(api, action.query)
		yield put({
			type: ACTION_TYPES.GET_PEOPLEX_SUGGESTIONS_FULFILLED,
			filterKey: action.filterKey,
			data: data.data,
			isNewData: _.isEqual(_.get(action.query, 'offset', 0), 0),
		})
	} catch (err) {
		yield put({
			type: ACTION_TYPES.GET_PEOPLEX_SUGGESTIONS_REJECTED,
			filterKey: action.filterKey,
		})
	}
}

function* getStyleCodeSuggestions(action) {
	yield put({
		type: ACTION_TYPES.GET_PEOPLEX_SUGGESTIONS_PENDING,
		filterKey: action.filterKey,
	})
	try {
		const styleCodes =
			action.payload.product_type === 'new'
				? yield call(searchSuggestionsApi.searchSuggestionsInPeoplexSearchApi, action.payload)
				: yield call(searchSuggestionsApi.searchSuggestionsInPeoplexSearchApi, action.payload)
		yield put({
			type: ACTION_TYPES.GET_STYLE_CODE_SUGGESTIONS_FULFILLED,
			payload: styleCodes.data.data,
			key: action.payload.product_type === 'new' ? 'tss_style_code' : 'objectID',
		})
		console.log('styleCodes', styleCodes)
	} catch (err) {
		yield put({ type: ACTION_TYPES.GET_STYLE_CODE_SUGGESTIONS_REJECTED })
	}
}

function* getRetailerSuggestions(action) {
	yield put({ type: ACTION_TYPES.GET_RETAILER_SUGGESTIONS_PENDING })
	try {
		const retailers = yield call(searchSuggestionsApi.searchSuggestionsInPeoplexSearchApi, action.payload)
		yield put({
			type: ACTION_TYPES.GET_RETAILER_SUGGESTIONS_FULFILLED,
			payload: retailers.data.data,
		})
		console.log(retailers.data.data)
	} catch (err) {
		yield put({ type: ACTION_TYPES.GET_RETAILER_SUGGESTIONS_REJECTED })
		console.log('errerr', err)
	}
}

function* getProductSuggessions(action) {
	yield put({ type: ACTION_TYPES.GET_PRODUCT_SUGGESSIONS_PENDING })
	try {
		const products = yield call(searchSuggestionsApi.searchSuggestionsInPeoplexSearchApi, action.payload)
		yield put({
			type: ACTION_TYPES.GET_PRODUCT_SUGGESSIONS_FULFILLED,
			payload: products.data.data,
		})
		console.log('products', products)
	} catch (err) {
		yield put({ type: ACTION_TYPES.GET_PRODUCT_SUGGESSIONS_REJECTED })
		console.log('errerr', err)
	}
}

function* getResellerSuggessions(action) {
	yield put({ type: ACTION_TYPES.GET_RESELLER_SUGGESSIONS_PENDING })
	try {
		const products = yield call(searchSuggestionsApi.searchSuggestionsInPeoplexSearchApi, action.payload)
		yield put({
			type: ACTION_TYPES.GET_RESELLER_SUGGESSIONS_FULFILLED,
			payload: products.data.data,
		})
		console.log('products', products)
	} catch (err) {
		yield put({ type: ACTION_TYPES.GET_RESELLER_SUGGESSIONS_REJECTED })
		console.log('errerr', err)
	}
}

export function* watchGetPeoplexSuggestions() {
	yield takeEvery(SAGA_ACTIONS.GET_PEOPLEX_SUGGESTIONS, getPeoplexSuggestions)
}

export function* watchGetStyleCodeSuggestions() {
	yield takeLatest(SAGA_ACTIONS.GET_STYLE_CODE_SUGGESTIONS, getStyleCodeSuggestions)
}

export function* watchGetRetailerSuggestions() {
	yield takeLatest(SAGA_ACTIONS.GET_RETAILER_SUGGESTIONS, getRetailerSuggestions)
}

export function* watchGetProductSuggessions() {
	yield takeLatest(SAGA_ACTIONS.GET_PRODUCT_SUGGESSIONS, getProductSuggessions)
}

export function* watchGetResellerSuggessions() {
	yield takeLatest(SAGA_ACTIONS.GET_RESELLER_SUGGESSIONS, getResellerSuggessions)
}
