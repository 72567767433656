import { put, call, takeLatest, cancelled, select, takeEvery, all } from 'redux-saga/effects'
import Axios from 'axios'
import { ACTION_TYPES, SAGA_ACTIONS } from '../../../constants'
import myTaskApi from '../api'
import _ from 'lodash'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { getErrMsg } from 'utils'
// import { updateStickList } from '../actions'

// get my_task stickies
function* watchGetMyTaskStickies() {
	yield takeLatest(SAGA_ACTIONS.SAGA_GET_MY_TASK_STICKIES, getStickiesWorker)
}
function* getStickiesWorker(action) {
	const { sessionId, successCallback, errCallback, payload } = action
	try {
		const resp = yield call(myTaskApi.fetchMytaskStickies, sessionId, payload)
		yield put({
			type: ACTION_TYPES.GET_MY_TASK_STICKIES_SUCCESS,
			payload: _.get(resp, 'data.data'),
		})

		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

// get my_task active filters
function* watchGetMyTaskFilter() {
	yield takeLatest(SAGA_ACTIONS.SAGA_GET_MY_TASK_FILTER, getFilterWorker)
}
function* getFilterWorker(action) {
	const { sessionId, successCallback, errCallback } = action
	try {
		const resp = yield call(myTaskApi.getMytaskFilters, sessionId)
		yield put({
			type: ACTION_TYPES.GET_MY_TASK_FILTERS_SUCCESS,
			payload: _.get(resp, 'data.data'),
		})

		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

// set my_task active filters
function* watchSetMytaskFilter() {
	yield takeLatest(SAGA_ACTIONS.SAGA_SET_MY_TASK_FILTER, setFilterWorker)
}
function* setFilterWorker(action) {
	const cancelSource = Axios.CancelToken.source()
	const { sessionId, payload, succCallback, errCallback } = action
	try {
		const resp = yield call(myTaskApi.setMytaskFilters, sessionId, payload, cancelSource)
		yield put({
			type: ACTION_TYPES.SET_MY_TASK_FILTERS_SUCCESS,
			payload: _.get(resp, 'data.data'),
		})
		succCallback && (yield call(succCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

// set my_task layout
function* watchSetMytaskLayout() {
	yield takeLatest(SAGA_ACTIONS.SAGA_UPDATE_MYTASK_LAYOUT, setLayoutWorker)
}
function* setLayoutWorker(action) {
	const cancelSource = Axios.CancelToken.source()
	const { layout, succCallback, errCallback } = action
	try {
		const resp = yield call(myTaskApi.updateMytaskLayout, layout, cancelSource)
		yield put({
			type: ACTION_TYPES.UPDATE_MYTASK_LAYOUT,
			payload: _.get(resp, 'data.data'),
		})
		succCallback && (yield call(succCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

export default[
	watchGetMyTaskFilter(),
    watchSetMytaskFilter(),
	watchGetMyTaskStickies(),
	watchSetMytaskLayout(),
]
