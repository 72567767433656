import { ACTION_TYPES } from '../../../constants'

const initialState = {
	objectID: [],
	website: [],
	stock: [],
	price: [0, 500000],
	scrapper: 'all',
	manual_update: [true, false],
	retailer: [],
	product: [],
	reseller: [],

	// product_type: 'new'
}

const filters = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_STYLE_CODE_FILTER: {
			return {
				...state,
				objectID: [...action.payload],
			}
		}
		case ACTION_TYPES.SET_RETAILER_FILTER: {
			return {
				...state,
				retailer: [...action.payload],
			}
		}
		case ACTION_TYPES.SET_PRODUCT_FILTER: {
			return {
				...state,
				product: [...action.payload],
			}
		}
		case ACTION_TYPES.SET_RESELLER_FILTER: {
			return {
				...state,
				reseller: [...action.payload],
			}
		}
		case ACTION_TYPES.SET_STOCK_FILTER: {
			return {
				...state,
				stock: [...action.payload],
			}
		}
		case ACTION_TYPES.SET_PRICE_FILTER: {
			return {
				...state,
				price: [...action.payload],
			}
		}
		case ACTION_TYPES.SET_SCRAPPER_FILTER: {
			return {
				...state,
				scrapper: action.payload,
				manual_update:
					action.payload === 'all' ? [true, false] : action.payload === 'on' ? [false] : action.payload === 'off' ? [true] : [true, false],
			}
		}
		case ACTION_TYPES.CLEAR_ALL_FILTERS: {
			return Object.assign({}, initialState)
		}
		case ACTION_TYPES.CLEAR_THIS_FILTER: {
			if (action.payload === 'scrapper') {
				return {
					...state,
					[action.payload]: initialState[action.payload],
					manual_update: initialState['manual_update'],
				}
			} else {
				return {
					...state,
					[action.payload]: initialState[action.payload],
				}
			}
		}
		default: {
			return state
		}
	}
}

export default filters
