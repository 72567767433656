import _ from 'lodash'

export const initialState = {
	openPopover: false,
	searchText: '',
	openLblForm: false,
	formLabelName: '',
	formLabelColor: '',
	anchorEl: null,
	formLabelId: '',
}

export function reducer(state = initialState, action) {
	switch (action.type) {
		case 'openLabelsPopover':
			return {
				...state,
				openPopover: true,
				openLblForm: false,
				anchorEl: action.element,
			}
		case 'closeLabelsPopover':
			return { ...state, openPopover: false, anchorEl: null, searchText: '' }
		case 'searchLabels':
			return { ...state, searchText: action.query }
		case 'openLabelForm':
			return { ...state, openLblForm: true, ..._.get(action, 'formData', {}) }
		case 'closeLabelForm':
			return {
				...state,
				openLblForm: false,
				formLabelId: '',
				formLabelName: '',
				formLabelColor: '',
				searchText: '',
			}
		default:
			throw new Error()
	}
}
