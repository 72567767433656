import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Grid, IconButton, Icon, makeStyles, TextField, Typography, Link, Breadcrumbs, Box, Fab, CircularProgress } from '@material-ui/core'
import PopoverMenus from 'components/PopoverMenus'
import { PERMISSION_TYPE } from 'constants/modules'
import { GLASS_ROLES } from 'constants/index'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { getErrMsg, getSuccessMsg } from 'utils'
import { hideLoader, showConfirmMessage, showErrorMessage, showLoader } from 'services/loader/actions'
import { modelxJobs } from 'services/modelx/actions'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
	headerRoot: {
		'& .MuiFormControl-fullWidth': {
			marginTop: 0,
		},
		'& .MuiOutlinedInput-inputMarginDense': {
			padding: theme.spacing(1),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1.2, 2, 0, 2),
		},
		padding: theme.spacing(1, '20px'),
	},
	closeIcon: {
		textAlign: 'center',
	},
	titleSec: {
		overflow: 'hidden',
		cursor: 'text',
		'& h5': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
	subTitleSec: {
		overflow: 'hidden',
		'& .MuiTypography-caption': {
			marginTop: theme.spacing(0.5),
			marginBottom: theme.spacing(1),
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(0),
				marginTop: theme.spacing(0),
			},
		},
	},
	headerIconSec: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& button': {
			margin: theme.spacing(0, 1),
		},
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'flex-end',
		},
	},
	parentSticky: {
		fontSize: '13px',
		cursor: 'pointer',
	},
	timer: {
		marginTop: '8px',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	timerBtnWrapper: {
		padding: '6px',
		position: 'relative',
	},
	timerBtn: {
		boxShadow: 'none',
		// marginRight: theme.spacing(1),
		width: 36,
		height: 36,
	},
	fabProgress: {
		position: 'absolute',
		top: 5,
		left: 5,
		zIndex: 1,
	},
	loader: {
		marginTop: '-4px',
	},
}))

const Header = ({
	onClose,
	formik,
	// isEditEnabled,
	// isOwnerOrCreatedBy,
	// isOwnerOrCreatedByOrReporter,
	stickyDetails,
	onHeaderClick,
	sessionId,
	glassCode,
	stickyCode,
	glassMembers,
	lanesData,
	history,
	slider,
	hideDeleteBtn,
	editAllowed,
	basicDetails,
	onClickDeleteMenu,
}) => {
	const classes = useStyles()
	const [anchorEl, setAnchorEl] = useState(null)
	const dispatch = useDispatch()
	const jobsApiCancelExec = useRef()
	// const { status, persistence, glass_members } = basicDetails

	const jobsLoading = useSelector((state) => state.modelxTable?.isFetchingJobs)

	// const currentUserId = useSelector((state) => _.get(state, 'session.user.userDetails.user_id'))
	// const currentEmail = useSelector((state) => _.get(state, 'session.user.userDetails.email'))
	// const createdBy = useMemo(() => _.isEqual(_.get(basicDetails, 'sticky_created_by.email'), currentEmail), [currentEmail, basicDetails])
	// const reportedTo = useMemo(() => _.isEqual(_.get(basicDetails, 'sticky_reporting_to.email'), currentEmail), [currentEmail, basicDetails])

	// const isOwner = useMemo(() => _.findIndex(glass_members, { user_id: currentUserId, role: GLASS_ROLES.OWNER }) > -1, [glass_members, currentUserId])
	// const isGuest = useMemo(() => _.findIndex(glass_members, { user_id: currentUserId, role: GLASS_ROLES.GUEST }) > -1, [glass_members, currentUserId])

	// const isEditEnabled = (createdBy || reportedTo || isOwner) && !isGuest && editAllowed
	// const isDeleteAllowed = (isOwner || createdBy) && !isGuest && !hideDeleteBtn

	const actionMenus = [
		// _.isEqual(hideDeleteBtn, false) ?
		{
			label: 'Delete',
			icon: 'delete',
			actionName: PERMISSION_TYPE.DELETE,
		}
		//  : null,
	]

	const fomatedActionItems = _.compact(actionMenus)

	const handleClickMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	const performAction = (actionName) => {
		switch (actionName) {
			case PERMISSION_TYPE.DELETE:
				handleCloseMenu()
				onClickDeleteMenu(slider?.id)
				break
			default:
				break
		}
		handleCloseMenu()
	}

	// const onTimer = () => {
	// 	const jobType = (_.isEqual(status, 'open') || _.isEqual(status, 'stopped')) ? 'start' : _.isEqual(status, 'inprogress') ? 'stop' : null
	// 	let modelxId = slider?.id
	// 	let data = {type: jobType}
	// 	dispatch(modelxJobs(modelxId, data, jobsApiCancelExec, 
	// 		(resp) => {
	// 			// dispatch({
	// 			// 	type: 'setSharedList',
	// 			// 	payload: _.get(resp, 'data.data'),
	// 			// })
	// 			dispatch(showSnackbarWithTimeout(getSuccessMsg(resp, 'Updated Successfully'), 1500))
	// 		},
	// 		(err) => {
	// 			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
	// 		}
	// 	))
	// }

	return (
		<Grid container justifyContent='space-between' alignItems='flex-start' className={classes.headerRoot}>
			<Grid item lg={10} sm={10} xs={10}>
				<React.Fragment>
					<Breadcrumbs aria-label='breadcrumb'>
						<Typography className={classes.subTitle} variant='caption' component='div' color='textSecondary'>
							{slider?.name}
						</Typography>
					</Breadcrumbs>
					<div className={classes.titleSec} role='button' >
						<Typography variant='h6' component='h6'>
							{slider?.talent_type}
						</Typography>
					</div>
				</React.Fragment>
			</Grid>
			<Grid item lg={2} sma={2} xs={2} className={classes.closeIcon}>
				<div className={classes.headerIconSec}>
					{/* {!_.isEqual(persistence, 'bound') && !_.isEqual(status, 'success') && !_.isEqual(status, 'failed') && isDeleteAllowed ?
					<IconButton size='small' edge='end' onClick={handleClickMenu}>
						<Icon>more_horiz</Icon>
					</IconButton>
					: null} */}
					<IconButton size='small' edge='end' onClick={onClose}>
						<Icon>close</Icon>
					</IconButton>
				</div>
				{/* {!_.isEqual(persistence, 'bound') && !_.isEqual(status, 'success') && !_.isEqual(status, 'failed') ?
				<div style={{ marginTop: '8px' }} className={classes.timer} >
					<Box className={classes.timerBtnWrapper}>
						<Fab
							// onClick={isTimerRun ? onStopTimer : onStartTimer}
							onClick={onTimer}
							size='small'
							disabled={jobsLoading || !isEditEnabled}
							aria-label='save'
							color='primary'
							className={classes.timerBtn}
						>
							<Icon fontSize='small'>
							{_.isEqual(status, 'inprogress') ? 'pause' : 'play_arrow'}
							</Icon>
						</Fab>
						{jobsLoading && <CircularProgress size={38} className={classnames(classes.fabProgress)} />}
					</Box>
				</div>
				: null} */}
			</Grid>
			<PopoverMenus
				open={Boolean(anchorEl)}
				id={'1'}
				menus={fomatedActionItems}
				anchorEl={anchorEl}
				onClickAction={performAction}
				onClose={handleCloseMenu}
			/>
		</Grid>
	)
}

export default Header
