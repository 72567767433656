import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Icon, Typography, Hidden, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import Pluralize from 'pluralize'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
		[theme.breakpoints.down('sm')]: {
			flexBasis: '100%',
		},
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
	panelDetails: {
		borderTop: '1px solid #f3ebeb',
		padding: theme.spacing(1, 2),
		// flexDirection: 'column'
	},
}))

const FilterExpansionPanel = ({ panelTitle = '', expanded, onChange, panelIcon = 'dns', filterLen = 0, children }) => {
	const classes = useStyles()
	return (
		<Accordion /* expanded={expanded} onChange={onChange} */>
			<AccordionSummary expandIcon={<Icon>keyboard_arrow_down</Icon>} aria-controls={`${panelTitle} panel`}>
				<Typography className={classes.heading}>
					<Icon className={classnames('v-middle', 'filter-list-ico')}>{panelIcon}</Icon>
					<span className={classnames('filter-list-title', 'v-middle')}>{panelTitle}</span>
				</Typography>
				<Hidden smDown>
					<Typography className={classes.secondaryHeading}>{`${Pluralize('filter', filterLen, true)}`}</Typography>
				</Hidden>
			</AccordionSummary>
			<AccordionDetails className={classes.panelDetails}>{children}</AccordionDetails>
		</Accordion>
	)
}

FilterExpansionPanel.propTypes = {
	panelTitle: PropTypes.string.isRequired,
	panelIcon: PropTypes.string.isRequired,
	filterLen: PropTypes.number.isRequired,
	expanded: PropTypes.bool,
	children: PropTypes.node.isRequired,
	onChange: PropTypes.func,
}

export default FilterExpansionPanel
