import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab, Icon, Box, Grid, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import classnames from 'classnames'
import BasicDetails from './BasicDetails'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { showLoader, hideLoader } from 'services/loader/actions'
import { usePermission } from 'hooks/usePermission'

const MODULES = {
	LABEL: 'label',
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		display: 'flex',
	},
	tabs: {
		marginTop: theme.spacing(2),
	},
	tabsIndicator: {
		display: 'none',
	},
	tabRoot: {
		minHeight: '42px',
		letterSpacing: '2px',
		textTransform: 'none',
		width: '220px',
		[theme.breakpoints.down('sm')]: {
			width: '100px',
			fontSize: '12px',
			lineHeight: '14px',
			borderBottom: `1px solid ${theme.palette.grey[300]}`,
		},
		'&:hover': {
			color: theme.palette.primary.dark,
		},
	},
	tabSelected: {
		background: `${theme.palette.primary.light}38`,
		borderRadius: '0 50px 50px 0',
		color: theme.palette.primary.dark,
		[theme.breakpoints.down('sm')]: {
			borderRadius: 'unset',
		},
	},
	tabPanel: {
		width: `calc(100% - 220px)`,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	pageTitle: {
		marginLeft: '252px', // [tabRootWidth(220) + tabPanelHeadingPadding(24) + typographyPadding(8)]
		color: '#666',
		letterSpacing: '2px',
		padding: theme.spacing(2, 0, 0),
		[theme.breakpoints.down('sm')]: {
			marginLeft: '16px',
			padding: theme.spacing(1, 0, 0),
		},
		'& span': {
			verticalAlign: 'middle',
			fontSize: theme.typography.pxToRem(22),
			[theme.breakpoints.down('sm')]: {
				fontSize: theme.typography.pxToRem(16),
			},
		},
	},
	titleIcon: {
		marginRight: theme.spacing(1.5),
	},
}))

const getModuleIdx = (moduleName) => {
	switch (moduleName) {
		case MODULES.LABEL:
			return 0
		default:
			return -1
	}
}

const getModuleNameByIdx = (moduleIdx) => {
	switch (moduleIdx) {
		case 0:
			return MODULES.LABEL
		default:
			return undefined
	}
}

const AppSettings = ({ history, match, moduleName, openIframeDialog }) => {
	const module = _.get(match, 'params.module', MODULES.LABEL)

	const activeTabIdx = getModuleIdx(module)

	const theme = useTheme()
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))

	const labelData = useSelector((state) => _.get(state, 'appSettings.data'))
	const isLoading = useSelector((state) => _.get(state, 'appSettings.isLoading'))
	// const homeDashboardId = useSelector((state) => _.get(state, 'user.userDetails.home_dashboard_id'))
	const appSettingsPerms = usePermission(moduleName)

	const classes = useStyles()
	const dispatch = useDispatch()
	const tabClasses = { root: classes.tabRoot, selected: classes.tabSelected }

	const onChangeTab = (e, tabIdx) => {
		history.push(`/appsettings/${getModuleNameByIdx(tabIdx)}`)
	}

	useEffect(() => {
		if (isLoading) {
			dispatch(showLoader('Please wait...'))
		} else {
			dispatch(hideLoader())
		}
	}, [isLoading, dispatch])

	return (
		<div className={classnames(classes.root, 'page-content')}>
			<Grid container>
				<Grid item container xs={12}>
					<Typography variant='h5' className={classes.pageTitle}>
						<span className={classnames('material-icons', classes.titleIcon)}>miscellaneous_services</span>
						<span>App Settings</span>
					</Typography>
				</Grid>
				<Grid item container xs={12}>
					<Tabs
						orientation={isSmallDevice ? 'horizontal' : 'vertical'}
						variant='scrollable'
						value={activeTabIdx}
						classes={{ indicator: classes.tabsIndicator }}
						className={classes.tabs}
						onChange={onChangeTab}
						aria-label='App Settings Tabs'
					>
						<Tab
							icon={<Icon fontSize={isSmallDevice ? 'small' : 'default'}>business</Icon>}
							classes={tabClasses}
							label='App Info'
							disableRipple
						></Tab>
					</Tabs>
					<TabPanel value={activeTabIdx} index={0} className={classes.tabPanel} isSmallDevice={isSmallDevice}>
						<BasicDetails perms={appSettingsPerms} data={labelData} openIframeDialog={openIframeDialog} />
					</TabPanel>
				</Grid>
			</Grid>
		</div>
	)
}

function TabPanel(props) {
	const { children, isSmallDevice, value, index, ...other } = props
	return (
		<div role='tabpanel' hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
			{value === index && <Box p={isSmallDevice ? 2 : 3}> {children}</Box>}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

export default AppSettings
