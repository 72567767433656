import React from 'react'
import { makeStyles, Grid, TextField, Icon, IconButton } from '@material-ui/core'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	textField: {
		'& textarea': {
			fontSize: theme.typography.pxToRem(14),
			fontWeight: 500,
		},
		'& input': {
			// textAlign: 'center',
			// fontSize: theme.typography.pxToRem(14),
			// fontWeight: 500,
		},
		'& .MuiIcon-root': {
			fontSize: theme.typography.pxToRem(16),
		},
		'& .MuiFormLabel-root.Mui-disabled': {
			color: '#92929d',
		},
		'& .MuiInputBase-root.Mui-disabled': {
			color: '#171725',
			'&::before': {
				borderBottomStyle: 'solid',
			},
		},
		'& .MuiInput-underline:before': {
			borderBottom: `1px solid rgba(0,0,0,0.16)`,
		},
	},
	customizeBtns: {
		'& .MuiIconButton-root': {
			padding: '8px',
			[theme.breakpoints.only('md')]: {
				padding: '3px',
			},
		},
	},
}))

const filterComponents = {
	dropdown: {
		name: 'Dropdown',
		type: 'check',
		isMulti: true,
		isSearchable: true,
	},
	autocomplete: {
		name: 'Autocomplete',
		type: 'text',
		isMulti: true,
		isSearchable: true,
	},
	daterange: {
		name: 'Daterange',
		type: 'date',
		isMulti: false,
		isSearchable: false,
		defaultEmptyValue: {},
		options: [{ label: 'Custom', key: 'custom_dates' }],
	},
	numberrange: {
		name: 'Range Input',
		type: 'range',
		isMulti: false,
		isSearchable: false,
		defaultEmptyValue: {},
	},
}

const FilterCreator = (props) => {
	const { data } = props
	const classes = useStyles()

	const conditionOption = [{ id: 'must', name: 'Must' }, 
	                         { id: 'must_not', name: 'Must not' }, 
							 { id: 'should', name: 'Should' }, 
							 { id: 'like_must', name: 'Like must' }, 
							 { id: 'like_should', name: 'Like Should' }, 
							 { id: 'number_range', name: 'Number range' }, 
							 { id: 'range', name: 'Range' }]

	return data.map((o) => (
		<Grid container spacing={1} justifyContent='center' className='filter-row-container'>
			<Grid item xs={12} md={12}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={3}>
						<TextField
							label='Filter Name'
							variant='outlined'
							margin='dense'
							size='small'
							fullWidth
							value={o?.name || ''}
							disabled
							className={classes.textField}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<TextField
							label='Field'
							variant='outlined'
							margin='dense'
							size='small'
							fullWidth
							value={o?.column || ''}
							disabled
							className={classes.textField}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={2}>
						<TextField
							label='Condition'
							variant='outlined'
							margin='dense'
							size='small'
							fullWidth
							value={_.get( _.find(conditionOption, option => { return option.id === o?.condition }), 'name' ) || ''}
							disabled
							className={classes.textField}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<TextField
							label='Component'
							variant='outlined'
							margin='dense'
							size='small'
							fullWidth
							value={_.get(filterComponents, `${o?.component}.name`) || ''}
							disabled
							className={classes.textField}
						/>
					</Grid>
					<Grid item xs={12} md={1} alignItems='center' container className={classes.customizeBtns}>
						<IconButton onClick={() => props?.onClickEdit(o)}>
							<Icon>edit</Icon>
						</IconButton>
						<IconButton onClick={() => props?.onClickDelete(o?.id)}>
							<Icon>delete</Icon>
						</IconButton>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	))
}

export default FilterCreator
