import React from 'react'
import { Grid, Icon, IconButton, makeStyles, Typography } from '@material-ui/core'
import SearchBox from 'components/SearchBox'

const useStyles = makeStyles((theme) => ({
	title: {
		// fontWeight: 'bold',
	},
	searchSection: {
		textAlign: 'right',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1, 0),
		},
		'& .searchbar-container': {
			padding: 0,
		},
	},
}))

function Header({ onBackBtnClick, onChangeSearchBox, searchPlaceholder }) {
	const classes = useStyles()

	return (
		<Grid container>
			<Grid container item lg={12}>
				<Grid container item lg={8} sm={6} xs={12} alignItems='center'>
					<IconButton size='small' onClick={onBackBtnClick} style={{ alignItems: 'center', justifyContent: 'center' }}>
						<Icon fontSize='small'>arrow_back_ios</Icon>
					</IconButton>
					<Typography variant='h6' className={classes.title}>
						My Saved Search
					</Typography>
				</Grid>
				<Grid item lg={4} sm={6} xs={12} className={classes.searchSection}>
					<SearchBox onChange={onChangeSearchBox} searchBarClassName={classes.searchBar} size='small' placeholder={searchPlaceholder}></SearchBox>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default Header
