import { Grid, Icon, IconButton, makeStyles } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import update from 'immutability-helper'

const useStyles = makeStyles((theme) => ({
	title: {
		display: 'flex',
		alignItems: 'center',
		flex: 1,
	},
}))

const CustomHeader = (props) => {
	const [ascSort, setAscSort] = useState(false)
	const [descSort, setDescSort] = useState(false)
	const [noSort, setNoSort] = useState(false)
	const colMenuBtn = useRef(null)
	const colEditBtn = useRef(null)

	const classes = useStyles()

	const onMenuClicked = () => {
		props.column?.colDef?.showColumnMenu(colMenuBtn.current)
		// props.showColumnMenu(colMenuBtn.current)
	}

	const onEditMenuClicked = (e) => {
		// showColRenameMenu
		const colDef = props.column?.colDef
		colDef?.showColRenameMenu(colEditBtn.current, colDef?.field, colDef?.headerName)
	}

	const onSortChanged = () => {
		setAscSort(props.column.isSortAscending() ? true : false)
		setDescSort(props.column.isSortDescending() ? true : false)
		setNoSort(!props.column.isSortAscending() && !props.column.isSortDescending() ? true : false)
	}

	const onSortRequested = (order, event) => {
		const exstColState = props?.columnApi?.getColumnState()
		const colIdx = _.findIndex(exstColState, { colId: props?.column?.colId })
		props?.columnApi?.applyColumnState({
			state: update(exstColState, {
				[colIdx]: {
					$set: {
						...exstColState[colIdx],
						sort: order,
						sortIndex: 0,
					},
				},
			}),
		})
		props?.column?.colDef?.onSortChanged(props)
		//props.setSort(order, event.shiftKey)
	}

	useEffect(() => {
		props.column.addEventListener('sortChanged', onSortChanged)
		onSortChanged()
	}, [])

	let sort = null
	if (props.enableSorting) {
		const nextSortBy = ascSort ? 'desc' : descSort ? '' : noSort && 'asc'
		sort = (
			<IconButton size='small' onClick={(event) => onSortRequested(nextSortBy, event)}>
				<Icon fontSize='small'>{ascSort ? 'arrow_downward' : descSort ? 'arrow_upward' : 'sort_by_alpha'}</Icon>
			</IconButton>
		)
	}

	return (
		<Grid container spacing={1} wrap={'nowrap'}>
			<Grid item className={classes.title}>
				<div>{props.displayName}</div>
			</Grid>
			{props?.column?.colDef?.enableEditing !== false && (
				<Grid item>
					<IconButton ref={colEditBtn} size='small' onClick={onEditMenuClicked}>
						<Icon fontSize='small'>edit</Icon>
					</IconButton>
				</Grid>
			)}
			{/* <Grid item>{sort}</Grid> */}
			<Grid item>
				<IconButton ref={colMenuBtn} size='small' onClick={() => onMenuClicked()}>
					<Icon fontSize='small'>menu</Icon>
				</IconButton>
			</Grid>
		</Grid>
	)
}

export default CustomHeader
