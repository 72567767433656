import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { updateNotificationCount } from 'services/notifications/actions'

const notificationsWatcher = (socketIORef, dispatch) => {
	socketIORef.current.on('join_notification_status', (resp) => {
		if (resp?.status === 'failed') {
			dispatch(showSnackbarWithTimeout(`Socket Error : ${resp?.message}`, 3000))
		} else {
			dispatch(updateNotificationCount(resp?.data?.unshown || 0))
		}
	})

	socketIORef.current.on('notification_received', (resp) => {
		dispatch(updateNotificationCount(resp?.data?.unshown))
	})

	socketIORef.current.on('notification_shown', (resp) => {
		dispatch(updateNotificationCount(resp?.data?.unshown))
	})
}

export default notificationsWatcher
