import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	fetchJobs: (conditionsQuery) => {
		return Axios.post(API_ENDPOINTS.FETCH_JOBS, conditionsQuery, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
}
