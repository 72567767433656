import { withStyles, Tooltip } from '@material-ui/core'
import React from 'react'

const LightTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
}))(Tooltip)

const CustomTooltip = ({ children, title, placement }) => {
	return (
		<LightTooltip title={title} placement={placement ? placement : 'bottom'} enterTouchDelay={0}>
			{children}
		</LightTooltip>
	)
}

export default CustomTooltip
