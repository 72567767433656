import { API_ENDPOINTS } from 'constants/index'
import Axios from 'axios'
const CancelToken = Axios.CancelToken

const NotificationApi = {
	fetchNotifications: (limit, offset, sort_by, cancelExecutor) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		return Axios.get(API_ENDPOINTS.NOTIFICATION_LIST, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			params: { limit, offset, sort_by },
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
	updateNotification: (notificationId, isRead) => {
		return Axios.put(
			API_ENDPOINTS.NOTIFICATION,
			{ is_read: isRead },
			{
				headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { notificationId } },
			}
		)
	},
}

export default NotificationApi
