import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import AgGridCustom from 'components/AgGridCustom'
import _ from 'lodash'
import CustomHeader from './CustomHeader'
import ShowColumnsMenu from './ShowColumnsMenu'
import update from 'immutability-helper'
import HeaderRenameMenu from './HeaderRenameMenu'
import { makeStyles } from '@material-ui/core'
import DiscoverxApi from 'services/discoverx/api'
import Axios from 'axios'

RecordsTable.propTypes = {
	datasourceInfo: PropTypes.object.isRequired,
	isSelectedAll: PropTypes.bool.isRequired,
	data: PropTypes.object.isRequired,
	searchParams: PropTypes.object.isRequired,
	updateDatasourceInfo: PropTypes.func.isRequired,
}

// function sortGrid(event, sortCols) {
// 	const columnState = {
// 		// https://www.ag-grid.com/javascript-grid-column-state/#column-state-interface
// 		state: sortCols,
// 	}
// 	event?.columnApi?.applyColumnState(columnState)
// }

function getElemPosition(event) {
	var viewportOffset = event.getBoundingClientRect()
	// these are relative to the viewport, i.e. the window
	var top = viewportOffset.top
	var left = viewportOffset.left
	return { top, left }
}

const useStyles = makeStyles((theme) => ({
	root: {
		height: 'calc(100vh - 107px)',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
	},
	table: {
		flex: '1',
		'& .ag-header': {
			textTransform: 'none',
		},
		'&.ag-theme-material .ag-cell': {
			fontSize: theme.typography.pxToRem(13),
			display: 'flex',
			alignItems: 'center',
			// border: `0.5px solid ${theme.palette.grey[300]}`,
		},
		'&.ag-theme-material .ag-row': {
			// borderWidth: 0,
			borderColor: '#e8e8e8',
		},
	},
}))

const reizeAllColumns = (gridApi) => {
	setTimeout(() => {
		if (gridApi?.columnApi) {
			var allColumnIds = []
			gridApi?.columnApi?.getAllColumns()?.forEach(function (column) {
				allColumnIds.push(column.colId)
			})
			gridApi?.columnApi?.autoSizeColumns(allColumnIds)
		}
	}, 200)
}

const getTableRecords = (apiResp) => {
	return _.map(_.get(apiResp, 'discoverx_results'), (record) => ({
		[`${record?.ds_id}_datasource`]: record.data_source,
		[`${record?.ds_id}_title`]: record.title,
		[`${record?.ds_id}_desc`]: record.description,
		..._.get(record, 'row_data'),
	}))
}

function RecordsTable({ datasourceInfo, updateDatasourceInfo, isSelectedAll, data, searchParams, isDsUpdating, selectedCount }) {
	const [state, setState] = useState({ loading: true, rowData: [] })
	const [{ openColumnMenu, columnMenuPos }, setOpenColumnMenu] = useState({ openColumnMenu: false, columnMenuPos: { left: 0, top: 0 } })
	const [{ openColRenameMenu, colRenameMenuPos, activeColumn }, setOpenColRenameMenu] = useState({
		openColRenameMenu: false,
		colRenameMenuPos: { left: 0, top: 0 },
		activeColumn: {},
	})
	const classes = useStyles()
	const gridRef = useRef()
	const fetchRecordsApiExec = useRef()

	const showColumnMenu = (e) => {
		const { top, left } = getElemPosition(e)
		setOpenColumnMenu({ openColumnMenu: true, columnMenuPos: { left, top } })
	}

	const closeColumnMenu = (e) => {
		setOpenColumnMenu({ openColumnMenu: false, columnMenuPos: { left: 0, top: 0 } })
	}

	const showColRenameMenu = (e, field, headerName) => {
		const { top, left } = getElemPosition(e)
		setOpenColRenameMenu({ openColRenameMenu: true, activeColumn: { field, headerName }, colRenameMenuPos: { left, top } })
	}

	const closeColRenameMenu = (e) => {
		setOpenColRenameMenu({ openColRenameMenu: false, activeColumn: {}, colRenameMenuPos: { left: 0, top: 0 } })
	}

	const gridHeaders = useMemo(
		() => [
			{
				field: `${datasourceInfo?.ds_id}_datasource`,
				lockPosition: true,
				enableEditing: false,
				lockPinned: true,
				suppressMovable: true,
				headerName: 'Datasource Name',
				// pinned: 'left',
			},
			{
				field: `${datasourceInfo?.ds_id}_title`,
				lockPosition: true,
				enableEditing: false,
				lockPinned: true,
				suppressMovable: true,
				headerName: 'Title',
			},
			{
				field: `${datasourceInfo?.ds_id}_desc`,
				lockPosition: true,
				enableEditing: false,
				lockPinned: true,
				suppressMovable: true,
				headerName: 'Description',
			},
			..._.map(_.sortBy(datasourceInfo?.columns, ['columnMenuPos']), (column) => ({
				field: column?.column_name,
				headerName: column?.column_label || column?.column_name,
				sortable: true,
				enableMenu: true,
				hide: !column?.is_visible,
				showColRenameMenu: showColRenameMenu,
			})),
		],
		[datasourceInfo]
	)

	const delayedFetchRecords = useCallback(
		_.debounce((callback) => {
			callback()
		}, 500),
		[]
	)

	const fetchTableRecords = useCallback(() => {
		const fetchRecordsParams = {
			...searchParams,
			limit: isSelectedAll || !_.isEmpty(data) ? 50 : 0,
			...(isSelectedAll ? {} : { ids: data }),
		}
		setState({ loading: true, rowData: [] })
		const onSuccess = (resp) => {
			setState({
				loading: false,
				rowData: getTableRecords(_.get(resp, 'data.data')),
			})
			reizeAllColumns(gridRef.current)
		}
		const onError = (err) => {
			if (!Axios.isCancel(err)) {
				setState({ loading: false, rowData: [] })
			}
		}

		DiscoverxApi.fetchDsRecords(datasourceInfo?.ds_id, fetchRecordsParams, fetchRecordsApiExec).then(onSuccess, onError)
	}, [datasourceInfo, isSelectedAll, data, searchParams])

	useEffect(() => {
		if (!isDsUpdating) {
			delayedFetchRecords(fetchTableRecords)
		} else {
			//Ds updating inprogress
			setState({ loading: true, rowData: [] })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDsUpdating])

	// const updateColSorting = useCallback(
	// 	(gridApi) => {
	// 		const sortColDetails = _.map(datasourceInfo?.sorting, (col) => ({
	// 			colId: col?.column_name,
	// 			sort: col?.sort_by,
	// 		}))
	// 		//sortGrid(gridApi, sortColDetails)
	// 	},
	// 	[datasourceInfo]
	// )

	const onReady = (param) => {
		gridRef.current = param
		// updateColSorting(param)
	}

	useEffect(() => {
		if (gridRef?.current) reizeAllColumns(gridRef.current)
	}, [openColumnMenu, openColRenameMenu])

	const onChangeColVisibility = (field, isVisible) => {
		const colIdx = _.findIndex(datasourceInfo?.columns, { column_name: field })
		updateDatasourceInfo(
			datasourceInfo?.ds_id,
			update(datasourceInfo, { columns: { [colIdx]: { $set: { ...datasourceInfo.columns[colIdx], is_visible: isVisible } } } })
		)
	}

	const onSortChanged = (params) => {
		const sortModel = params.columnApi.getColumnState()
		const colsToSort = _.reduce(
			sortModel,
			(result, col) => {
				if (col.sort) result.push({ column_name: col.colId, sort_by: col.sort })
				return result
			},
			[]
		)
		if (!_.isEqual(colsToSort, datasourceInfo?.sorting)) {
			updateDatasourceInfo(datasourceInfo?.ds_id, update(datasourceInfo, { sorting: { $set: colsToSort } }))
		}
	}

	const onColumnMoved = (params) => {
		const columnState = params.columnApi.getColumnState()
		const colsOrderArry = _.map(columnState, (col) => col.colId)
		const columnsToUpdate = _.map(datasourceInfo?.columns, (column) => ({ ...column, position: _.indexOf(colsOrderArry, column.column_name) }))
		updateDatasourceInfo(datasourceInfo?.ds_id, update(datasourceInfo, { columns: { $set: columnsToUpdate } }))
		reizeAllColumns(gridRef.current)
	}

	const onRenameHeader = (field, columnLabel) => {
		const colIdx = _.findIndex(datasourceInfo?.columns, { column_name: field })
		updateDatasourceInfo(
			datasourceInfo?.ds_id,
			update(datasourceInfo, {
				columns: {
					[colIdx]: {
						$set: {
							...datasourceInfo.columns[colIdx],
							column_label: !_.isEmpty(_.trim(columnLabel)) ? columnLabel : datasourceInfo.columns[colIdx].column_name,
						},
					},
				},
			})
		)
	}

	const dsColDef = useMemo(
		() =>
			_.filter(gridHeaders, (col) => {
				return !col?.lockPinned
			}),
		[gridHeaders]
	)

	return (
		<div className={classes.root}>
			<AgGridCustom
				className={classes.table}
				columnDefs={gridHeaders}
				frameworkComponents={{ agColumnHeader: CustomHeader }}
				onReady={onReady}
				sizeColumnsToFit={false}
				multiSortKey='ctrl'
				defaultColDef={{
					suppressToolPanel: true,
					resizable: true,
					onSortChanged: onSortChanged,
					showColumnMenu: showColumnMenu,
				}}
				rowHeight={30}
				rowData={state?.rowData}
				loading={state?.loading}
				noDataTxt={'No records found'}
				onColumnMoved={onColumnMoved}
				domLayout={''}
				animateRows={false}
			></AgGridCustom>
			<ShowColumnsMenu
				open={openColumnMenu}
				position={columnMenuPos}
				handleClose={closeColumnMenu}
				columnDefs={dsColDef}
				onChangeVisible={onChangeColVisibility}
			/>
			<HeaderRenameMenu
				open={openColRenameMenu}
				position={colRenameMenuPos}
				handleClose={closeColRenameMenu}
				column={activeColumn}
				onRenameHeader={onRenameHeader}
			/>
		</div>
	)
}

export default RecordsTable
