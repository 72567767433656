import { Button, makeStyles, Grid, TextField, Typography, IconButton, Icon, Avatar, CircularProgress } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import InputLabels from 'components/InputLabels'
import RichTextEditor from 'components/RichTextEditor'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hideAdvaceSnackbar, showAdvanceSnackbar } from 'services/snackbarAdvance/actions'
import _ from 'lodash'
import Attachments from 'components/Attachments'
import update from 'immutability-helper'
import { formTheName, getImgUrl, stringToHslColor } from 'utils'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	activityEditerRoot: {
		border: 'none',
	},
	formRoot: {
		border: `1px solid ${theme.palette.divider}`,
	},
	bottomSection: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: theme.spacing(0.5),
		gap: '5px',
		[theme.breakpoints.down('sm')]: {
			alignItems: 'flex-start',
		},
	},
	rightOptions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		padding: theme.spacing(0.5),
		gap: '5px',
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap',
			alignItems: 'flex-start',
			justifyContent: 'flex-end',
		},
	},
	activityType: {
		'& .MuiAutocomplete-inputRoot': {
			marginTop: 0,
			marginBottom: 0,
		},
	},
	inputText: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': { borderColor: 'transparent' },
			'&:hover fieldset': { borderColor: '#ececec' },
			'&.Mui-focused fieldset': { borderColor: '#ececec' },
			'&:hover': { backgroundColor: '#ececec' },
			'&.Mui-focused': { backgroundColor: '#ececec' },
			'&.MuiAutocomplete-clearIndicator': { display: 'none' },
			'& .MuiAutocomplete-clearIndicator': { visibility: 'hidden' },
			'&.MuiIconButton-root': { padding: 0 },
			'&.MuiAutocomplete-input': { padding: 0 },
			'& input': {
				padding: '0 !important',
				fontSize: '14px',
			},
		},
		'&.MuiAutocomplete-input': { padding: 0 },
		'& .MuiFormControl-marginDense': { margin: '4px 8px 4px 0px', width: '150px' },
		'& .MuiAutocomplete-hasPopupIcon': {
			paddingRight: 25,
		},
	},
	textField: {
		width: '100%',
		border: 'none',
		'&:focus': {
			border: 'none',
		},
	},
	attachmentList: {
		width: '100%',
		// padding: theme.spacing(0, 1),
	},
	avatar: {
		width: 32,
		height: 32,
		fontSize: 17,
		[theme.breakpoints.down('xs')]: {
			width: 20,
			height: 20,
			fontSize: 12,
		},
	},
}))

const CommentBox = ({
	loading,
	glassCode,
	sessionId,
	isActivity,
	suggestions,
	glassLabels,
	activityTypes,
	onSubmit,
	handleCustomLabel,
	newCustomLabel,
}) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const userDetails = useSelector((state) => _.get(state, 'session.user.userDetails', {}))
	const token = useSelector((state) => _.get(state, 'session.authToken'))

	const onClickPost = (e) => {
		const isDescEmpty =
			_.isEmpty(formik?.values?.desc) || _.isEmpty(ContentState.createFromBlockArray(htmlToDraft(formik?.values?.desc)).getPlainText())
		if (isDescEmpty && _.isEmpty(formik?.values?.attachments)) {
			dispatch(showSnackbarWithTimeout('Please enter Description or add an attachment to post', 1000))
		} else {
			onSubmit({ ...e, desc: formik?.values?.desc }, formik)
		}
	}

	const formik = useFormik({
		initialValues: {
			desc: '',
			activity_type_id: null,
			labels: [],
			attachments: [],
		},
		enableReinitialize: true,
		onSubmit: onClickPost,
	})

	const onAddAttachments = (e) => {
		let imgArray = Array.from(e.target.files)
		let result = _.find(imgArray, function (file) {
			if (_.get(file, 'size', 0) >= 1073741824) return true
		})
		let isFileExceeded = result ? true : false
		if (isFileExceeded) {
			dispatch(
				showAdvanceSnackbar({
					msg: 'File size is too large. maximum file size is 1 gb.',
					severity: 'error',
				})
			)
			setTimeout(() => {
				dispatch(hideAdvaceSnackbar())
			}, 2000)
			e.target.value = ''
		} else {
			formik?.setFieldValue('attachments', update(formik?.values?.attachments || [], { $push: [...e.target.files] }))
			e.target.value = ''
		}
	}

	const onDeleteAttachment = (fileObjToDelete) => {
		formik?.setFieldValue(
			'attachments',
			update(formik?.values?.attachments, { $splice: [[_.indexOf(formik?.values?.attachments, fileObjToDelete), 1]] })
		)
	}
	return (
		<form onSubmit={formik?.handleSubmit}>
			<Grid container spacing={1}>
				<Grid item lg={1} xs={1}>
					<Avatar
						size='small'
						className={classes.avatar}
						src={getImgUrl(token, userDetails?.profile_pic_url) || null}
						style={{ backgroundColor: stringToHslColor(formTheName(userDetails?.first_name, userDetails?.last_name)) }}
					>
						{_.slice(_.words(formTheName(userDetails?.first_name, userDetails?.last_name)), 0, 1).reduce((a, t) => (a += t[0]), '')}
					</Avatar>
				</Grid>
				<Grid item lg={11} xs={11}>
					<Grid container className={classes.formRoot}>
						<Grid item xs={12}>
							<RichTextEditor
								renderKey={loading}
								rootClass={classes.activityEditerRoot}
								name={'desc'}
								placeholder='Ask a question or post an update'
								value={formik?.values?.desc}
								onChange={formik?.handleChange}
								hideToolbarOnFocus={false}
								readOnly={loading}
								suggestions={suggestions}
							/>
						</Grid>
						<Grid xs={12} item className={classes.attachmentList}>
							<Attachments
								attachmentList={formik?.values?.attachments}
								showDivider={false}
								isActivity={true}
								onDeleteAttachment={onDeleteAttachment}
								disableDelete={false}
								enableAddBtn={false}
							/>
						</Grid>
						<Grid item xs={12}>
							<div className={classes.bottomSection}>
								<div>
									<IconButton component='label' title='Add Attachment' disabled={loading}>
										<Icon fontSize='small'>attach_file</Icon>
										<input multiple onChange={onAddAttachments} type='file' hidden />
									</IconButton>
								</div>
								<div className={classes.rightOptions}>
									<Autocomplete
										id='activity'
										options={activityTypes}
										getOptionLabel={(option) => option.activity_name}
										className={classes.inputText}
										disabled={loading}
										name='activity_type_id'
										value={formik?.values?.activity_type_id}
										onChange={(e, value) => formik.setFieldValue('activity_type_id', value)}
										renderInput={(params) => (
											<TextField
												margin='dense'
												className={classes.activityType}
												fullWidth
												{...params}
												variant='outlined'
												placeholder='Activity Type'
											/>
										)}
										renderOption={(option) => {
											return (
												<Grid container alignItems='center'>
													<Grid item xs>
														<Typography variant='body2'>{option.activity_name}</Typography>
													</Grid>
												</Grid>
											)
										}}
									/>
									<InputLabels
										onChange={formik?.handleChange}
										name='labels'
										value={formik?.values?.labels}
										labels={glassLabels}
										glassCode={glassCode}
										sessionId={sessionId}
										isActivity={isActivity}
										showElpises={true}
										elipsesFrom={1}
										disabled={loading}
										handleCustomLabel={handleCustomLabel}
										newCustomLabel={newCustomLabel}
									/>
									<Button
										color='primary'
										type='submit'
										disabled={loading}
										onClick={formik?.handleSubmit}
										variant='contained'
										size='small'
										disableElevation
									>
										{loading ? <CircularProgress size={20} /> : 'Post'}
									</Button>
								</div>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}

CommentBox.propTypes = {
	loading: PropTypes.bool,
	glassCode: PropTypes.string,
	sessionId: PropTypes.string,
	isActivity: PropTypes.bool,
	suggestions: PropTypes.array,
	glassLabels: PropTypes.array,
	activityTypes: PropTypes.array,
	onSubmit: PropTypes.func,
}

export default CommentBox
