import { Drawer, makeStyles } from '@material-ui/core'
import React, { useEffect, useState, useCallback } from 'react'
import Footer from './Footer'
import Header from './Header'
import SliderDetails from './SliderDetails'
import DiscoverApi from '../../../services/discoverx/api'
import _ from 'lodash'
import LoaderSkeleton from './loader'
import { useDispatch } from 'react-redux'
import { hideLoader, showErrorMessage } from 'services/loader/actions'
import { getErrMsg } from 'utils'

const useStyles = makeStyles((theme) => ({
	drawer: {
		'& .MuiDrawer-paperAnchorRight': {
			width: '50%',
			maxWidth: '600px',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				padding: 0,
			},
		},
		'& .MuiBackdrop-root': {
			opacity: 0,
		},
		marginAuto: {
			margin: '0 auto',
		},
	},
}))

const DiscoverXSlider = ({ open, onClose, data, isMobileView, onStickyClick, onclickDashboard, isStickySuccess }) => {
	const classes = useStyles()
	const [slideData, setSlideData] = useState({
		loading: false,
		fetchedData: {},
	})
	const { loading, fetchedData } = slideData
	const dispatch = useDispatch()

	const fetchStickyDetails = useCallback(() => {
		setSlideData((prevState) => ({ ...prevState, loading: true }))
		if (open) {
			const onSuccess = (res) => {
				setSlideData((prevState) => ({ ...prevState, loading: false, fetchedData: _.get(res, 'data.data', {}) }))
			}
			const onFailure = (err) => {
				dispatch(
					showErrorMessage(getErrMsg(err), 'Close', () => {
						dispatch(hideLoader())
						onClose()
					})
				)
				setSlideData((prevState) => ({ ...prevState, loading: false, fetchedData: {} }))
			}
			DiscoverApi.getSingleData(data?.ds_id, data?.id).then(onSuccess, onFailure)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.ds_id, data.id, dispatch, open])

	useEffect(() => {
		fetchStickyDetails()
	}, [fetchStickyDetails])
	useEffect(() => {
		if (isStickySuccess) {
			fetchStickyDetails()
		}
	}, [fetchStickyDetails, isStickySuccess])
	console.log('fetchedData', fetchedData)
	return (
		<Drawer anchor='right' open={open} onClose={onClose} className={classes.drawer}>
			{loading ? (
				<LoaderSkeleton />
			) : (
				<div style={{ position: 'relative' }}>
					<Header data={fetchedData} onClose={onClose} onStickyClick={onStickyClick} />
					<SliderDetails data={fetchedData} />
					<Footer onclickDashboard={onclickDashboard} data={fetchedData} isMobileView={isMobileView} />
				</div>
			)}
		</Drawer>
	)
}
//
export default DiscoverXSlider
