import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	InputAdornment,
	TextField,
	Typography,
	Avatar,
	Grid,
	makeStyles,
	FormGroup,
	FormControl,
	FormLabel,
	FormHelperText,
	Icon,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { DateTimePicker } from '@material-ui/pickers'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { formTheName, getImgUrl, stringToHslColor } from 'utils'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import * as Yup from 'yup'

const useStyles = makeStyles((theme) => ({
	cardContainer: {
		width: 246,
		// padding: theme.spacing(1, 0),
		'& .MuiCardContent-root': {
			padding: theme.spacing(1),
		},
		'& .MuiCardContent-root:last-child': {
			paddingBottom: theme.spacing(1),
		},
		'& .MuiCardActions-root': {
			justifyContent: 'space-around',
		},
		'& .MuiButton-label': {
			textTransform: 'capitalize',
		},
		'& .MuiFormControl-marginDense': {
			marginTop: '4px',
		},
	},
	textField: {
		width: '100%',
		'& .MuiFormLabel-root': {
			fontSize: '12px',
			lineHeight: 2,
		},
		'& .MuiOutlinedInput-input': {
			fontSize: '14px',
		},
		'& .MuiAutocomplete-root': {
			'& .MuiTextField-root': {
				marginTop: 0,
			},
		},
	},
	optionTextEllipsis: {
		width: `100%`,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	userPic: {
		width: 36,
		height: 36,
		marginRight: theme.spacing(1),
		fontSize: 17,
	},
	userInfo: { maxWidth: `calc(100% - (36px + 8px))` },
	mainGrid: {
		flexWrap: 'nowrap',
	},
	assigneeDiv: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flex: 1,
		alignItems: 'center',
	},
	text: {
		fontSize: 14,
		flex: 1,
	},
	titleField: {
		margin: 0,
	},
	mt1: {
		marginTop: theme.spacing(1),
	},
}))

const StickySchema = Yup.object().shape({
	sticky_title: Yup.string().trim().max(255, 'Task title should not be more than 255 characters.').required('Please enter the Task Title'),
	due_date: Yup.date().nullable().min(new Date(), 'Due date cannot be in the past.'),
})

const CreateFormDialog = ({ open, handleClose, glassMembers, onCreateSubtask, closeDialog }) => {
	const classes = useStyles()
	const token = useSelector((state) => _.get(state, 'session.authToken'))
	const [isAssigneeListOpen, setIsAssigneeListOpen] = useState(false)

	const toggleAssigneeList = () => {
		setIsAssigneeListOpen(!isAssigneeListOpen)
	}

	const filterOptions = createFilterOptions({
		stringify: ({ first_name, last_name, email }) => `${first_name} ${last_name} ${email}`,
	})

	const formik = useFormik({
		initialValues: {
			sticky_title: '',
			due_date: null,
			assign_to: null,
		},
		validationSchema: StickySchema,
		onSubmit: (e) => onCreateSubtask(e),
	})

	useEffect(() => {
		if (closeDialog) {
			formik?.resetForm()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [closeDialog])

	return (
		<Dialog open={open} handleClose={handleClose} fullWidth maxWidth='xs'>
			<DialogTitle>Create Sub task</DialogTitle>
			<Divider />
			<DialogContent>
				<form onSubmit={formik?.handleSubmit}>
					<FormGroup>
						<FormControl className={classes.textField} variant='outlined' margin='dense' required>
							<FormLabel component='legend'>Subtask Title</FormLabel>
							<TextField
								variant='outlined'
								id='sticky_title'
								margin='dense'
								name='sticky_title'
								type='text'
								autoFocus
								placeholder='Enter Subtask Title'
								className={classes.titleField}
								value={formik.values.sticky_title}
								error={formik.errors.sticky_title}
								helperText={formik.errors.sticky_title && formik.errors.sticky_title}
								onChange={formik.handleChange}
							/>
						</FormControl>
					</FormGroup>
					<FormGroup>
						<FormControl className={classes.textField} variant='outlined' margin='dense'>
							<FormLabel component='legend'>{'Due Date & Time'}</FormLabel>
							<DateTimePicker
								name='due_date'
								id='due_date'
								className={classes.textField}
								autoOk
								fullWidth
								size='small'
								variant='dialog'
								inputVariant='outlined'
								format='DD MMM YYYY, h:mm A'
								value={_.get(formik.values, 'due_date', null)}
								disablePast
								clearable
								error={formik.errors.due_date}
								placeholder={'Choose Due Date & Time'}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<Icon fontSize='small'>date_range</Icon>
										</InputAdornment>
									),
								}}
								onChange={(newDate) => {
									if (newDate) {
										newDate.set({ seconds: 0, milliseconds: 0 })
									}

									formik.handleChange({
										target: {
											name: 'due_date',
											value: newDate,
										},
									})
								}}
							/>
							{formik.errors.due_date && <FormHelperText error>{formik.errors.due_date}</FormHelperText>}
						</FormControl>
					</FormGroup>
					<FormGroup>
						<FormControl className={classes.textField} variant='outlined' margin='dense'>
							<FormLabel component='legend'>Assignee</FormLabel>
							<Autocomplete
								fullWidth
								size='small'
								id='assign_to'
								placeholder='Select Assignee'
								filterOptions={filterOptions}
								onChange={(event, newValue) => {
									formik.setFieldValue('assign_to', newValue)
									toggleAssigneeList()
								}}
								openOnFocus
								value={formik.values.assign_to}
								options={glassMembers}
								getOptionSelected={(option, value) => _.isEqual(option?.user_id, value?.user_id)}
								// eslint-disable-next-line react/jsx-no-duplicate-props
								getOptionLabel={(option) => (option?.first_name && formTheName(option?.first_name, option?.last_name)) || ''}
								renderInput={(params) => (
									<TextField {...params} fullWidth variant='outlined' size='small' id='assign_to' name='assign_to' placeholder='Select Assignee' />
								)}
								renderOption={(option) => {
									const picUrl = _.get(option, 'profile_pic_url', '')
									const full_name = _.join([_.get(option, 'first_name', ''), _.get(option, 'last_name', '')], ' ')
									return (
										<Grid key={option.user_id} container alignItems='center' className={classes.mainGrid}>
											<Grid item>
												<Avatar
													alt={full_name}
													src={picUrl ? getImgUrl(token, picUrl) : null}
													size='small'
													className={classes.userPic}
													style={{ backgroundColor: stringToHslColor(full_name) }}
												>
													{full_name && full_name.substring(0, 1)}
												</Avatar>
											</Grid>
											<Grid item xs className={classes.userInfo}>
												<Typography variant='body2' className={classes.optionTextEllipsis} title={full_name}>
													{full_name}
												</Typography>
												<Typography variant='body2' color='textSecondary' className={classes.optionTextEllipsis} title={option.email}>
													{option.email}
												</Typography>
											</Grid>
										</Grid>
									)
								}}
							/>
						</FormControl>
					</FormGroup>
				</form>
			</DialogContent>
			<Divider className={classes.mt1} />
			<DialogActions>
				<Button
					variant='outlined'
					size={'medium'}
					color='primary'
					onClick={() => {
						handleClose()
						formik?.handleReset()
					}}
				>
					Cancel
				</Button>
				<Button variant='contained' size={'medium'} type='submit' onClick={formik?.handleSubmit} color='primary' disableElevation>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	)
}

CreateFormDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	glassMembers: PropTypes.array,
}

export default CreateFormDialog
