import React from 'react'
import { PageNotFound404 } from 'illustrators'

const pageStyle = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100vw',
	height: '100vh',
}
const PageNotFound = () => {
	return (
		<div style={pageStyle}>
			<PageNotFound404 style={{ fontSize: '20em' }}></PageNotFound404>
		</div>
	)
}

export default PageNotFound
