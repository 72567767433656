import React, { useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { AccordionDetails, Icon, makeStyles, Typography, RadioGroup, FormControlLabel, Radio, Dialog, DialogContent, Button } from '@material-ui/core'
import Accordion from '../../Components/Accordion'
import AccordionSummary from '../../Components/AccordionSummary'
import MembersTable from 'components/MembersTable'
import SearchFabWrapper from '../../Components/SearchFabWrapper'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { GLASS_ROLES } from '../../../../../constants'
import { removeGlassMember, updateGlassMember, updateGlassDetailsAfterEdit, refreshStickyList } from 'services/glass/actions'
import { isModuleHasPerm } from 'utils'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import { usePermission } from 'hooks/usePermission'
import SocketContext from '../../../socket-context'
import GlassApi from '../../../../../services/glass/api'
import ViewColumnIcon from '@material-ui/icons/ViewColumn'
import classnames from 'classnames'
import { showLoader, hideLoader, showErrorMessage } from 'services/loader/actions'
import { getErrMsg, getSuccessMsg } from 'utils'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'

const useStyles = makeStyles((theme) => ({
	accTitle: {
		fontWeight: '500',
	},
	accSummary: {
		padding: theme.spacing(0, 0, 2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0, 0, 1),
		},
	},
	accordion: {
		display: 'flex',
		flexDirection: 'row',
		'& .MuiAccordionSummary-expandIcon': {
			marginRight: '50px',
		},
	},
	radioLabel: {
		'& .MuiFormControlLabel-label': {
			fontSize: '14px',
		},
	},
	container: {
		textAlign: 'center',
		padding: theme.spacing(2),
	},
	alertIcon: {
		color: theme.palette.warning.main,
		fontSize: theme.typography.pxToRem(50),
	},
	subtitleTxt: {
		marginTop: theme.spacing(1.5),
	},
	actionBtn: {
		marginTop: theme.spacing(4),
		marginRight: theme.spacing(2),
	},
}))

const UsersAccordion = ({ onChangeMemberRole, onRemoveMember, label, isOwner, data }) => {

	const classes = useStyles()
	const reduxDispatch = useDispatch()

	const glassDetails = useSelector((state) => _.get(state, 'glass', {}))

	const { sessionId } = useContext(SocketContext)

	const [stickyPermission, setStickyPermission] = useState({ glass_name: glassDetails?.glass_name, glass_desc: glassDetails?.glass_desc, sticky_permission: glassDetails?.sticky_permission })
	const [openConfirmCode, setOpenConfirmCode] = useState(false)


	const handleChange = (event) => {
		setStickyPermission({...stickyPermission, sticky_permission: event.target.value});
		setOpenConfirmCode(true)
	};

	const apiCallByMethod = (formData) => {
		const hideLoaderDialog = () => {
			reduxDispatch(hideLoader())
		}
		const onSuccess = (resp) => {
				hideLoaderDialog()
				reduxDispatch(updateGlassDetailsAfterEdit(_.get(resp, 'data.data')))
				reduxDispatch(refreshStickyList(glassDetails?.glass_code, ''))
				reduxDispatch(showSnackbarWithTimeout(getSuccessMsg(resp, 'Updated Successfully'), 1500))
		}

		const onFailure = (err) => {
			reduxDispatch(
				showErrorMessage(getErrMsg(err), 'Close', () => {
					hideLoaderDialog()
				})
			)
		}
		reduxDispatch(showLoader('Updating Board...'))

        GlassApi.updateGlass(stickyPermission, glassDetails?.glass_code, sessionId).then(onSuccess, onFailure)
	}

	const onClickCancel = () => {
		setStickyPermission({ ...stickyPermission, sticky_permission: glassDetails?.sticky_permission });
		setOpenConfirmCode(false)
	}

	const onClickConfirm = () => {
		apiCallByMethod()
		setOpenConfirmCode(false)
	}


	return (
		<>
		<Accordion defaultExpanded={true}>
			<div className={classes.accordion}>
			<AccordionSummary expandIcon={<Icon fontSize='small'>expand_more</Icon>} aria-controls={label} id={label}>
				<Typography className={classes.accTitle} variant='body2'>
					{label}
				</Typography>
			</AccordionSummary>
			{ _.isEqual(label, 'Members & Guests') &&
			<RadioGroup row aria-label="sticky_permission" name="sticky_permission" className={classes.radioLabel} value={stickyPermission.sticky_permission} onChange={ isOwner && handleChange }>
				<FormControlLabel value="global" control={<Radio size="small" />} label="Global Permission" />
				<FormControlLabel value="sticky_wise" control={<Radio size="small"/>} label="Task wise Permission" />
			</RadioGroup>}
			</div>
			<AccordionDetails className={classes.accSummary}>
				<MembersTable onChangeMemberRole={onChangeMemberRole} onRemoveMember={onRemoveMember} isOwner={isOwner} data={data} />
			</AccordionDetails>
		</Accordion>
					<Dialog fullWidth={true} maxWidth='xs' open={openConfirmCode} aria-labelledby='Loader Popup'>
					<DialogContent>
						<div className={classes.container}>
							<ViewColumnIcon className={classes.alertIcon} />
							<Typography className={classes.subtitleTxt} variant='body1'>
								{'Are you sure, you want to save changes?'}
							</Typography>
							<Button variant='contained' color='primary' className={classnames(classes.actionBtn)} onClick={onClickConfirm} disableElevation>
								Confirm
							</Button>
							<Button variant='contained' className={classnames(classes.actionBtn)} onClick={onClickCancel} color='default' disableElevation>
								Cancel
							</Button>
						</div>
					</DialogContent>
				</Dialog>
		</>
	)
}

UsersAccordion.propTypes = {
	label: PropTypes.string.isRequired,
	isOwner: PropTypes.bool.isRequired,
	onChangeMemberRole: PropTypes.func.isRequired,
	onRemoveMember: PropTypes.func.isRequired,
}

const Members = ({ isOwner, glassCode, openAddMemDialog }) => {
	const classes = useStyles()
	const allGlassMembers = useSelector((state) => _.get(state, 'glass.glass_members'))
	const glassMembers = useMemo(() => _.filter(allGlassMembers, (member) => member?.is_active), [allGlassMembers])
	const [searchText, setSearchText] = useState('')
	const dispatch = useDispatch()
	const glassPerms = usePermission(ROLE_MODULES.GLASS)
	const isAccessible = isOwner && isModuleHasPerm(glassPerms, PERMISSION_TYPE.CREATE)

	const { sessionId } = useContext(SocketContext)

	const onChangeSearchBox = (searchTxt) => {
		setSearchText(searchTxt)
	}
	const onChangeMemberRole = (userId, role) => {
		dispatch(updateGlassMember(sessionId, glassCode, userId, role, true))
	}

	const onRemoveMember = (userId) => {
		dispatch(removeGlassMember(sessionId, glassCode, userId, true))
	}

	const ownersData = useMemo(() => (_.isEmpty(searchText) ? _.filter(glassMembers, { role: GLASS_ROLES.OWNER }) : []), [glassMembers, searchText])
	const membersData = useMemo(
		() => (_.isEmpty(searchText) ? _.filter(glassMembers, (user) => user?.role !== GLASS_ROLES.OWNER) : []),
		[glassMembers, searchText]
	)

	const onClickAddMemFab = () => {
		openAddMemDialog()
	}

	const searchListData = useMemo(
		() =>
			_.isEmpty(searchText)
				? []
				: _.filter(
						glassMembers,
						(user) =>
							new RegExp(_.escapeRegExp(searchText), 'i').test(_.join([user?.first_name, user?.last_name], ' ')) ||
							new RegExp(_.escapeRegExp(searchText), 'i').test(user?.email)
				  ),
		[searchText, glassMembers]
	)

	return (
		<>
			<SearchFabWrapper
				isOwner={isAccessible}
				searchPlaceholder='Search Members'
				onChangeSearchBox={onChangeSearchBox}
				onClickFab={onClickAddMemFab}
				fabIcon='person_add'
			>
				{_.isEmpty(searchText) ? (
					<>
						<UsersAccordion
							onChangeMemberRole={onChangeMemberRole}
							onRemoveMember={onRemoveMember}
							data={ownersData}
							classes={classes}
							label='Owners'
							isOwner={isAccessible}
						/>
						<UsersAccordion
							onChangeMemberRole={onChangeMemberRole}
							onRemoveMember={onRemoveMember}
							data={membersData}
							classes={classes}
							label='Members & Guests'
							isOwner={isAccessible}
						/>
					</>
				) : (
					<MembersTable isOwner={isAccessible} data={searchListData} />
				)}
			</SearchFabWrapper>
		</>
	)
}

Members.propTypes = {
	isOwner: PropTypes.bool.isRequired,
	glassCode: PropTypes.string.isRequired,
}

export default Members
