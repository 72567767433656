import React from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles, Typography } from '@material-ui/core'

GlassXBoard.propTypes = {
	onClickNext: PropTypes.func.isRequired,
}

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'center',
	},
	button: {
		marginTop: theme.spacing(2),
	},
}))

function GlassXBoard({ onClickFinish }) {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<Typography variant='h4' align='center' gutterBottom>
				You'r all set
			</Typography>
			<Typography variant='subtitle1' align='center' color='textSecondary' gutterBottom>
				Start creating a Board
			</Typography>
			<Button onClick={onClickFinish} variant='contained' color='primary' disableElevation size='small' className={classes.button}>
				Create Board
			</Button>
		</div>
	)
}

export default GlassXBoard
