import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	Dialog,
	DialogContent,
	Grid,
	Icon,
	IconButton,
	LinearProgress,
	makeStyles,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core'
import _ from 'lodash'
import Tabs from './Tabs'
import update from 'immutability-helper'
import DiscoverxApi from 'services/discoverx/api'
import peoplexApi from 'services/peoplex/api'
import modelxApi from 'services/modelx/api'
import productsApi from 'services/products/api'
import { useDispatch } from 'react-redux'
import Axios from 'axios'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { getErrMsg, getSuccessMsg } from 'utils'
import { hideLoader, showErrorMessage, showLoader, showSuccessMessage } from 'services/loader/actions'

ExportDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	data: PropTypes.array.isRequired,
	isSelectedAll: PropTypes.bool.isRequired,
	searchParams: PropTypes.object,
}

const useStyles = makeStyles((theme) => ({
	titleWrap: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	title: {
		lineHeight: 'unset',
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		paddingRight: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			fontSize: '14px',
		},
	},
	subtitle: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	toolbar: {
		width: '100%',
		boxSizing: 'border-box',
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
	},
	dialogContent: {
		padding: 0,
	},
}))

function ExportDialog({ open, handleClose, data, isSelectedAll, searchParams, exportList, pageType }) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const classes = useStyles()
	const [{ columns, isLoading, isUpdating }, setState] = useState({ columns: [], isLoading: false, isUpdating: false })
	const dsFetchExec = useRef()
	const dsUpdateExec = useRef()
	const dispatch = useDispatch()

	const updateState = useCallback((updateObj) => {
		setState((prevState) => ({ ...prevState, ...updateObj }))
	}, [])

	useEffect(() => {
		if (open) {
			updateState({ isLoading: true, isUpdating: false, columns: [] })
			if (pageType === 'peoplex') {
				peoplexApi.getExportView(dsFetchExec).then((resp) => {
					updateState({ isLoading: false, columns: _.get(resp, 'data.data', []) })
				})
			} else if (pageType === 'modelx') {
				modelxApi.getExportView(dsFetchExec).then((resp) => {
					updateState({ isLoading: false, columns: _.get(resp, 'data.data', []) })
				})
		    } else {
				productsApi.getExportView(dsFetchExec).then((resp) => {
					updateState({ isLoading: false, columns: _.get(resp, 'data.data', []) })
				})
			}
		}
	}, [open, isSelectedAll, updateState, data, searchParams])

	const onClickExport = () => {
		dispatch(
			showLoader(
				'The Exporting process has been started and the Exported details of the Product board will be downloaded once it is exported completely'
			)
		)
		const exportRecordsParams = {
			search_text: searchParams.query,
			is_select_all: isSelectedAll,
			limit: 10,
			offset: 0,
			product_code: _.isEqual(isSelectedAll, true) ? [] : _.map(exportList, (data) => (data?.product_code)),
		}
		const fetchRecordsParams = {
			search_text: searchParams.query,
			is_select_all: isSelectedAll,
			limit: 10,
			offset: 0,
			people_code: _.isEqual(isSelectedAll, true) ? [] : _.map(exportList, (data) => (data?.people_code)),
		}
		const fetchModelRecordsParams = {
			search_text: searchParams.query,
			is_select_all: isSelectedAll,
			limit: 10,
			offset: 0,
			model_code: _.isEqual(isSelectedAll, true) ? [] : _.map(exportList, (data) => (data?.model_id)),
		}
		const onSuccess = (res) => {
			try {
				const filename = res.headers['content-disposition'].split('filename=')[1]
				const contentType = res.headers['content-type']
				let blob = new Blob([res.data], { type: contentType })
				let url = window.URL.createObjectURL(blob)
				let a = document.createElement('a')
				a.href = url
				a.download = filename
				a.click()
				dispatch(hideLoader())
			} catch (e) {
				dispatch(hideLoader())
			}
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		if (pageType === 'peoplex') {
			peoplexApi.getExport(fetchRecordsParams, dsFetchExec).then(onSuccess, onFailure)
		} else if (pageType === 'modelx') {
			modelxApi.getExport(fetchModelRecordsParams, dsFetchExec).then(onSuccess, onFailure)
		} else {
			productsApi.getExport(exportRecordsParams, dsFetchExec).then(onSuccess, onFailure)
		}
	}

	const updateDatasourceInfo = (dsId, dataSourceObj) => {
		updateState({ isUpdating: true, columns: dataSourceObj })
		if (pageType === 'peoplex') {
			peoplexApi.changeExportView(dataSourceObj, dsUpdateExec).then(
				() => {
					updateState({ isUpdating: false })
				},
				(err) => {
					if (!Axios.isCancel(err)) {
						dispatch(showSnackbarWithTimeout(getErrMsg(err), 3000))
						updateState({ isUpdating: false })
					}
				}
			)
		} else if (pageType === 'modelx') {
			modelxApi.changeExportView(dataSourceObj, dsUpdateExec).then(
				() => {
					updateState({ isUpdating: false })
				},
				(err) => {
					if (!Axios.isCancel(err)) {
						dispatch(showSnackbarWithTimeout(getErrMsg(err), 3000))
						updateState({ isUpdating: false })
					}
				}
			)
		} else {
			productsApi.changeExportView(dataSourceObj, dsUpdateExec).then(
				() => {
					updateState({ isUpdating: false })
				},
				(err) => {
					if (!Axios.isCancel(err)) {
						dispatch(showSnackbarWithTimeout(getErrMsg(err), 3000))
						updateState({ isUpdating: false })
					}
				}
			)
		}
	}

	return (
		<Dialog
			open={open}
			fullScreen={true}
			onClose={handleClose}
			aria-labelledby='task-add-dialog'
			scroll={fullScreen ? 'paper' : 'body'}
			maxWidth={'md'}
		>
			<Toolbar className={classes.toolbar}>
				<IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
					<Icon>close</Icon>
				</IconButton>
				<div className={classes.titleWrap}>
					<Typography className={classes.title} component='div' variant='h6'>
						Export Results
					</Typography>
					{/* <Typography variant='caption' component='div' color='textSecondary'>
						<span className={classes.subtitle}>Note: Maximum 100,000 records only exported.</span>
					</Typography> */}
				</div>
				<Button variant='outlined' size='small' onClick={onClickExport} startIcon={<Icon fontSize='small'>file_upload</Icon>}>
					Export
				</Button>
			</Toolbar>

			<DialogContent className={classes.dialogContent}>
				<Grid container>
					<Grid item xs={12}>
						{isLoading ? (
							<LinearProgress />
						) : (
							<Tabs
								columns={columns}
								updateDatasourceInfo={updateDatasourceInfo}
								data={data}
								isSelectedAll={isSelectedAll}
								searchParams={searchParams}
								isDsUpdating={isUpdating}
								exportList={exportList}
								pageType={pageType}
							/>
						)}
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default ExportDialog
