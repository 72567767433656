import React, { useContext, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography, AccordionDetails, Icon } from '@material-ui/core'
import SettingsList from '../../Components/SettingsList'
import PopoverMenus from 'components/PopoverMenus'
import { useState } from 'react'
import SearchFabWrapper from '../../Components/SearchFabWrapper'
import DialogForm from '../../Components/DialogForm'
import StickyGroupForm from './StickyGroupForm'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import { usePermission } from 'hooks/usePermission'
import { useUrlSearchParams } from 'hooks/use-url-search-params'
import { addGlassLabel, removeGlassLabel, updateGlassLabel, addGlassStickyGroup, updateGlassStickyGroup, removeGlassStickyGroup } from 'services/glass/actions'
import { hideLoader, showConfirmMessage, showErrorMessage, showLoader } from 'services/loader/actions'
import { getErrMsg, getSuccessMsg, isModuleHasPerm } from 'utils'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import Accordion from '../../Components/Accordion'
import AccordionSummary from '../../Components/AccordionSummary'
import SocketContext from '../../../socket-context'

const useStyles = makeStyles((theme) => ({
	settingsList: {
		paddingTop: theme.spacing(1.5),
	},
	labelStatus: {
		paddingTop: theme.spacing(1.5),
	},
	accTitle: {
		fontWeight: '500',
	},
	accSummary: {
		padding: theme.spacing(0, 0, 2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0, 0, 1),
		},
	},
}))

const actionMenus = [
	{
		label: 'Edit',
		icon: 'edit',
		actionName: PERMISSION_TYPE.EDIT,
	},
	{
		label: 'Delete',
		icon: 'delete',
		actionName: PERMISSION_TYPE.DELETE,
	},
]
const LabelStatus = ({ label, className }) => {
	return (
		<Typography variant='body2' color='textSecondary' className={className}>
			{label}
		</Typography>
	)
}
const LabelAccordion = ({ label, actionMenusToShow, onClickLabelAction, listData }) => {
	const classes = useStyles()
	return (
		<Accordion defaultExpanded={true}>
			<AccordionSummary expandIcon={<Icon fontSize='small'>expand_more</Icon>} aria-controls={label} id={label}>
				<Typography className={classes.accTitle} variant='body2'>
					{label}
				</Typography>
			</AccordionSummary>
			<AccordionDetails className={classes.accSummary}>
				{_.isEmpty(listData) ? (
					<LabelStatus label='No labels found' className={classes.labelStatus} />
				) : (
					<SettingsList
						hideActionMenu={_.isEmpty(actionMenusToShow)}
						onClickAction={onClickLabelAction}
						data={listData}
						className={classes.settingsList}
					/>
				)}
			</AccordionDetails>
		</Accordion>
	)
}

const StickyGroup = ({ glassCode, isOwner }) => {
	const classes = useStyles()
	const lblFormRef = useRef()
	const [pageQuery, setPageQuery] = useUrlSearchParams({ openStygpForm: false, stygpId: null }, { openStygpForm: Boolean, stygpId: String }, true)
	const dispatch = useDispatch()

	const { sessionId } = useContext(SocketContext)

	const glassStickyGroup = useSelector((state) => _.get(state, 'glass.settings.sticky_group'))
	const [actionMenuState, setActionMenuState] = useState({ openActions: false, anchorEl: null, stygpId: '' })
	const [searchText, setSearchText] = useState('')

	const { openActions, anchorEl, stygpId } = actionMenuState

	const glassPerms = usePermission(ROLE_MODULES.GLASS)
	const isCreateAccessible = isOwner && isModuleHasPerm(glassPerms, PERMISSION_TYPE.CREATE)
	const actionMenusToShow = _.filter(actionMenus, (menu) => isOwner && isModuleHasPerm(glassPerms, menu?.actionName))

	// console.log('actionMenusToShow', actionMenusToShow)

	const onClickLabelAction = (e, data) => {
		setActionMenuState({ openActions: true, anchorEl: e.currentTarget, stygpId: data?.id })
	}

	const onLblFormSubmitBtn = () => {
		lblFormRef.current.submitForm()
	}

	const onCloseLabelAction = () => {
		setActionMenuState((prev) => ({ ...prev, openActions: false }))
	}

	const performLabelAction = (actionName, stygpId) => {
		switch (actionName) {
			case PERMISSION_TYPE.EDIT:
				setPageQuery({ openStygpForm: true, stygpId: stygpId })
				break
			case PERMISSION_TYPE.DELETE:
				deleteLbl(stygpId)
				break
			default:
				break
		}
		onCloseLabelAction()
	}

	const onClickAddLabel = () => {
		setPageQuery({ openStygpForm: true, stygpId: '' }, true)
	}

	const closeLabelFormDialog = () => {
		setPageQuery({ openStygpForm: false, stygpId: '' }, true)
	}

	const onChangeSearchBox = (searchTxt) => {
		setSearchText(searchTxt)
	}

	const listData = useMemo(
		() =>
			_.map(
				_.isEmpty(searchText) ? glassStickyGroup : _.filter(glassStickyGroup, (stickyGroup) => new RegExp(_.escapeRegExp(searchText), 'i').test(stickyGroup?.sticky_group)),
				(stickyGroup) => ({
					id: stickyGroup?.sticky_group_id,
					name: stickyGroup?.sticky_group,
					url: stickyGroup?.sticky_group_url,
				})
			),
		[searchText, glassStickyGroup]
	)

	const initialValues = useMemo(() => {
		if (pageQuery?.openStygpForm) {
			return _.find(glassStickyGroup, { sticky_group_id: pageQuery?.stygpId })
		} else {
			lblFormRef.current?.resetForm()
		}
	}, [pageQuery, glassStickyGroup])

	const hideAnyInfoDialog = () => {
		dispatch(hideLoader())
	}

	const onSubmitLblForm = (data) => {
		const onSuccess = (resp) => {
			hideAnyInfoDialog()
			dispatch(showSnackbarWithTimeout(getSuccessMsg(resp), 2500))
			closeLabelFormDialog()
		}
		const onError = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => hideAnyInfoDialog()))
		}
		const isAddingStickyGroup = _.isEmpty(data?.sticky_group_id)
		dispatch(showLoader(isAddingStickyGroup ? 'Creating Task Group...' : 'Updating Task Group...'))

		if (isAddingStickyGroup) {
			dispatch(addGlassStickyGroup(sessionId, glassCode, data, onSuccess, onError))
		} else if (!isAddingStickyGroup) {
			let userDetails = data
			if (typeof _.get(data, 'sticky_group_url') === 'string') {
				userDetails['sticky_group_image'] = _.get(userDetails, 'sticky_group_url')
			}
			dispatch(updateGlassStickyGroup(sessionId, glassCode, userDetails, onSuccess, onError))
		}
	}

	const askDeleteConfirm = (onConfirm, onCancel) => {
		dispatch(showConfirmMessage(`Are you sure, you want to delete this Task Group?`, '', 'Confirm', onConfirm, 'Cancel', onCancel))
	}

	const deleteLbl = (stygpId) => {
		const onSuccess = (resp) => {
			hideAnyInfoDialog()
			dispatch(showSnackbarWithTimeout(getSuccessMsg(resp), 2500))
		}
		const onError = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => hideAnyInfoDialog()))
		}
		askDeleteConfirm(
			() => {
				dispatch(showLoader('Deleting Task Group...'))
				dispatch(removeGlassStickyGroup(sessionId, glassCode, stygpId, onSuccess, onError))
			},
			() => {
				hideAnyInfoDialog()
			}
		)
	}

	return (
		<SearchFabWrapper
			isOwner={isCreateAccessible}
			searchPlaceholder='Search for Task Group'
			onChangeSearchBox={onChangeSearchBox}
			onClickFab={onClickAddLabel}
			fabIcon='add'
		>
			<>
                {_.isEmpty(listData) ? (
					<LabelStatus label='No task group found' className={classes.labelStatus} />
				) : (
					<SettingsList
						hideActionMenu={_.isEmpty(actionMenusToShow)}
						onClickAction={onClickLabelAction}
						data={listData}
						className={classes.settingsList}
					/>
				)}
				{!_.isEmpty(actionMenusToShow) && (
					<PopoverMenus
						open={openActions}
						id={stygpId}
						menus={actionMenusToShow}
						anchorEl={anchorEl}
						onClickAction={performLabelAction}
						onClose={onCloseLabelAction}
					/>
				)}
				<DialogForm
					dialogTitle={pageQuery?.stygpId ? 'Edit Task Group' : 'Add Task Group'}
					open={pageQuery?.openStygpForm}
					onClose={closeLabelFormDialog}
					onClickCancelBtn={closeLabelFormDialog}
					onClickOkBtn={onLblFormSubmitBtn}
				>
					<StickyGroupForm initialValues={initialValues} ref={lblFormRef} onSubmit={onSubmitLblForm} action={pageQuery?.stygpId ? 'edit' : 'create'}/>
				</DialogForm>
			</>
		</SearchFabWrapper>
	)
}

export default StickyGroup
