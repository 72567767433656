import { ACTION_TYPES } from '../../../../constants'
import _ from 'lodash'
import update from 'immutability-helper'

const labels = (state, action) => {
	const labelIdx = _.findIndex(state, { label_id: action?.payload?.labelId })
	switch (action.type) {
		case ACTION_TYPES.ADD_GLASS_LABEL:
			return update(state, { $push: [_.get(action, 'payload')] })
		case ACTION_TYPES.UPDATE_GLASS_LABEL:
			const labelToUpdate = _.get(action, 'payload.label')
			return update(state, { [labelIdx]: { $set: { ...labelToUpdate } } })
		case ACTION_TYPES.REMOVE_GLASS_LABEL:
			return update(state, { $splice: [[labelIdx, 1]] })
		default:
			return state
	}
}

export default labels
