import moment from 'moment'
import { ACTION_TYPES, FILTER_CONSTANTS } from '../../../constants'

const initialState = {
	// objectID: [],
	// website: [],
	// stock: [],
	// price: [0, 500000],
	// scrapper: 'all',
	// manual_update: [true, false],
	// retailer : [],
	// product : [],
	// reseller : [],
	[FILTER_CONSTANTS.DISCOUNT]: [0, 100],
	[FILTER_CONSTANTS.PRODUCT_NAME]: [],
	[FILTER_CONSTANTS.RETAILER]: [],
	[FILTER_CONSTANTS.STOCK]: [],
	[FILTER_CONSTANTS.RESELLER]: [],
	[FILTER_CONSTANTS.JOB_STATUS]: [],
	[FILTER_CONSTANTS.REDIRECT_URL]: false,
	[FILTER_CONSTANTS.DATE_RANGE]: {
		startDate: new Date(moment().startOf('day').subtract(2000, 'days')),
		endDate: new Date(moment().endOf('day')),
	},
	[FILTER_CONSTANTS.JOB_NAME]: [],
	[FILTER_CONSTANTS.ASSIGNED_TO]: [],
	[FILTER_CONSTANTS.PRIORITY]: [],
	[FILTER_CONSTANTS.BRAND]: [],
	[FILTER_CONSTANTS.ONLY_COUNTERFEIT]: false,
	[FILTER_CONSTANTS.STATUS]: [],
	[FILTER_CONSTANTS.PRODUCT_ID]: [],
}

const filtersNew = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_THIS_FILTER: {
			return {
				...state,
				[action.payload.filterKey]: action.payload.filter,
			}
		}

		case ACTION_TYPES.SET_FILTER: {
			return {
				...state,
				...action.payload,
			}
		}

		case ACTION_TYPES.SET_STYLE_CODE_FILTER: {
			return {
				...state,
				objectID: [...action.payload],
			}
		}
		case ACTION_TYPES.SET_RETAILER_FILTER: {
			return {
				...state,
				retailer: [...action.payload],
			}
		}
		case ACTION_TYPES.SET_PRODUCT_FILTER: {
			return {
				...state,
				product: [...action.payload],
			}
		}
		case ACTION_TYPES.SET_RESELLER_FILTER: {
			return {
				...state,
				reseller: [...action.payload],
			}
		}
		case ACTION_TYPES.SET_STOCK_FILTER: {
			return {
				...state,
				stock: [...action.payload],
			}
		}
		case ACTION_TYPES.SET_PRICE_FILTER: {
			return {
				...state,
				price: [...action.payload],
			}
		}
		case ACTION_TYPES.SET_SCRAPPER_FILTER: {
			return {
				...state,
				scrapper: action.payload,
				manual_update:
					action.payload === 'all' ? [true, false] : action.payload === 'on' ? [false] : action.payload === 'off' ? [true] : [true, false],
			}
		}
		case ACTION_TYPES.CLEAR_ALL_FILTERS: {
			return Object.assign({}, initialState)
		}
		case ACTION_TYPES.CLEAR_THIS_FILTER: {
			if (action.payload === 'scrapper') {
				return {
					...state,
					[action.payload]: initialState[action.payload],
					manual_update: initialState['manual_update'],
				}
			} else {
				return {
					...state,
					[action.payload]: initialState[action.payload],
				}
			}
		}
		default: {
			return state
		}
	}
}

export default filtersNew
