import { Box } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'

function TabPanel(props) {
	const { children, label, value, index, ...other } = props

	return (
		<div role='tabpanel' hidden={value !== index} id={`tab-${index}`} aria-labelledby={label} {...other}>
			{value === index && <Box>{children}</Box>}
		</div>
	)
}

TabPanel.propTypes = {
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}
export default TabPanel
