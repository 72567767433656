import React from 'react'
import DashboardViewer from 'scences/Dashboard/DashboardViewer'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
	root: {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100vw',
		height: '100vh',
		overflowY: 'hidden',
		overflowX: 'hidden',
		zIndex: '1100',
		background: 'white',
	},
	childGrid: {
		height: '100%',
	},
}))

const DashboardPreviewer = ({ match }) => {
	const { params } = match
	const { accessToken, dashboardId } = params
	const classes = useStyles()
	return (
		<Grid className={classes.root} container>
			<Grid item xs={12} className={classes.childGrid}>
				<DashboardViewer forceRefreshNo={0} isEditMode={false} isEmbedded={true} accessToken={accessToken} dashboardId={dashboardId} />
			</Grid>
		</Grid>
	)
}

export default DashboardPreviewer
