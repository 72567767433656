import React, { useEffect, useState } from 'react'
import { makeStyles, Grid, Typography, IconButton, Icon, Button } from '@material-ui/core'
import FilterPanel from './FilterPanel'
import DefaultFilterPanel from './DefaultFilterPanel'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { clearAllFilters } from '../../services/modelxFiltersNew/actions'
import RangeFilter from './RangeFilter'
import { FILTER_CONSTANTS } from '../../constants'
import { setThisFilter, clearThisFilter, setFilter } from '../../services/modelxFiltersNew/actions'
import { getModelxSuggestions } from '../../services/modelxFilterSuggestions/actions'
import DateFilter from './DateRangeFilter'
import DateRangeFilterPanel from './DateRangeFilterPanel'
import FilterApi from '../../services/modelxFiltersNew/api'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		padding: '15px 0px',
		// [theme.breakpoints.down('1280')]: {
		paddingTop: 30,
		// },
		background: theme.palette.almostBlack[0],
		'& ::-webkit-scrollbar, ::-webkit-scrollbar-track': {
			width: '8px',
			background: 'transparent',
		},
		'& ::-webkit-scrollbar-thumb': {
			background: theme.palette.almostBlack[400],
			height: '10px',
			borderRadius: '4px',
		},
	},
	heading: {
		// letterSpacing: theme.typography.pxToRem(1),
		// color: theme.palette.almostBlack[600],
		// padding: `${theme.spacing(1)}px 0px`,
		fontSize: theme.typography.pxToRem(18),
		letterSpacing: 1,
		color: theme.palette.almostBlack[700],
	},
	resetSpan: {
		padding: theme.spacing(0.5),
		color: theme.palette.almostBlack[800],
		borderRadius: '5px',
		transition: 'background 0.3s',
		cursor: 'pointer',
		// fontFamily: '"Poppins"',
		fontSize: theme.typography.pxToRem(10),
		'&:hover': {
			background: `${theme.palette.primary.main}11`,
			transition: 'background 0.3s',
		},
	},
	header: {
		// [theme.breakpoints.down('1280')]: {
			position: 'absolute',
			top: 0,
			left: 0,
			padding: '10px 24px',
			backgroundColor: theme.palette.common.white,
			zIndex: 100,
			borderBottom: `1px solid ${theme.palette.grey[300]}`,
		// },
	},
	footer: {
		display: 'none',
		// [theme.breakpoints.down('1280')]: {
			// position: 'absolute',
			// display: 'block',
			// bottom: 0,
			// left: 0,
			// padding: theme.spacing(2, 3),
			// backgroundColor: theme.palette.common.white,
			// zIndex: 100,
			// borderTop: `1px solid ${theme.palette.grey[300]}`,
			// textAlign: 'right',
		// },
	},
	filterFooterSection: {
		height: 52,
		position: 'absolute',
		bottom:66,
		backgroundColor: theme.palette.common.white,
		zIndex: 100,
		padding: theme.spacing(1),
		borderTop: `1px solid ${theme.palette.almostBlack[400]}`,
	},
	content: {
		// [theme.breakpoints.down('1280')]: {
			paddingBottom: 64,
			marginTop: '20px',
			paddingLeft: '15px',
			paddingRight: '15px',
			maxHeight: 'calc(100vh - (48px + 74px))',
			overflowX: 'hidden',
			overflowY: 'auto',
		// },
	},
	mLeft8: {
		marginLeft: theme.spacing(1),
	},
}))

const Filters = ({ extraFilterProps, showTheseFilters, isSmallDevice, onCloseDialog, searchSuggestionsProductType = 'old', setExportList, setTableData, setAllPageProduct, filterInitialState, filterOpen, isPickerMode = false, data = null, isPickFrom = null }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const suggestions = useSelector((state) => state.modelxFilterSuggestions)
	const filtersReduxState = useSelector((state) => state.modelxFiltersNew)
	const [filters, setFilters] = useState(useSelector((state) => state.modelxFiltersNew))
	const [isFooterEnabled, setIsFooterEnabled] = useState(false)

	const dataSourceId = useSelector((state) => _.get(state, 'appSettings.data.ds_id'))
	const [trigger, setTrigger] = React.useState(false)
	const params = _.split(window?.location?.search, '&')

	// const [filters, setFilters] = React.useState(filter)

	const fetchSuggestions = (filterKey, keyValue = '', isClearOffset = false) => {
		const offset = 0
		dispatch(getModelxSuggestions(filterKey, offset, keyValue, isPickFrom, data))
	}

	const fetchLoadMoreSuggestions = (filterKey, keyValue = '', isClearOffset = false) => {
		const offset = keyValue.length === 0 && !isClearOffset ? suggestions[filterKey].suggestions.length : 0
		dispatch(getModelxSuggestions(filterKey, offset, keyValue, isPickFrom, data))
	}

	// useEffect(() => {
	// 	if (!_.isEmpty(showTheseFilters)) {
	// 		showTheseFilters.forEach((key) => {
	// 			if (
	// 				[
	// 					FILTER_CONSTANTS.JOB_NAME,
	// 					FILTER_CONSTANTS.ASSIGNED_TO,
	// 					FILTER_CONSTANTS.RETAILER,
	// 					FILTER_CONSTANTS.RESELLER,
	// 					FILTER_CONSTANTS.PRODUCT_NAME,
	// 					FILTER_CONSTANTS.BRAND,
	// 					FILTER_CONSTANTS.STATUS,
	// 					FILTER_CONSTANTS.PRODUCT_ID,
	// 				].includes(key) &&
	// 				_.get(suggestions, `${key}.isInitialState`, false)
	// 			) {
	// 				console.log('fetch 2', key, _.get(suggestions, `${key}.isInitialState`, false), suggestions)
	// 				fetchSuggestions(key)
	// 			}
	// 		})
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [])
	// }, [suggestions, showTheseFilters])

	useEffect(() => {
		let split = _.split(params[2], '=')
		let decode = params[2] !== undefined ? JSON.parse(decodeURIComponent(split[1]).replace(/\+/g, ' ')) : []
		let reloaded = window.performance.getEntriesByType('navigation')[0].type
		let redirectFilter = {...filterInitialState, project_name: [data?.project_title]}
		let filter = {
			filter_obj: !_.isEmpty(decode) && reloaded !== 'reload' && !isPickerMode ? decode : (isPickerMode && _.isEqual(isPickFrom, 'Projects')) ? redirectFilter : (isPickerMode && _.isEqual(isPickFrom, 'Tasks')) ? filterInitialState : {},
			redirect_url: !_.isEmpty(decode) && reloaded === 'navigate' ? true : false,
			redirect: !_.isEmpty(isPickFrom) ? isPickFrom : null,
		}
		localStorage.setItem('redirect_url', filter.redirect_url)
		// let filter_param = { filter_param: {} }
		FilterApi.filter(filter)
			.then((res) => {
				dispatch(setFilter(res.data.data.filter))
				// setFilters({ ...filters, ...res.data.data.filter })
			})
			.catch((err) => {
				// console.log(err, 'err')
			})
	}, [])

	useEffect(() => {
		setFilters(filtersReduxState)
	}, [filtersReduxState])

	useEffect(() => {
		if (_.isEqual(filters, filtersReduxState)) {
			// console.log("yes they are equal");
			setIsFooterEnabled(false)
		} else {
			// console.log("no they arent equal");
			setIsFooterEnabled(true)
		}
	}, [filters, filtersReduxState])


	const handleChange = (filterKey, value, isNotCheckBoxType) => {
		let tempFilters = _.cloneDeep(filters)
		if (_.isNil(isNotCheckBoxType) || isNotCheckBoxType === false) {
			setTimeout(() => {
			if (filters[filterKey].indexOf(value) !== -1) {
				let tempArray = [...filters[filterKey]]
				_.remove(tempArray, (key) => key === value)
				setFilters((prevState) => {
					return { ...prevState, [filterKey]: tempArray }
				})
				// dispatch(setThisFilter(tempArray, filterKey))
				// console.log('calling..1', tempArray)
				setTrigger(true)
				// setTimeout(() => {
					setTrigger(false)
				// }, 1000)
			} else {
				// console.log('calling..2')
				setFilters((prevState) => {
					return { ...prevState, [filterKey]: [...filters[filterKey], value] }
				})
				// dispatch(setThisFilter([...filters[filterKey], value], filterKey))
				setTrigger(true)
				// setTimeout(() => {
					setTrigger(false)
				// }, 1000)
			}
		 }, 1000)
		} else {
			setTimeout(() => {
			// console.log('calling..3')
			setFilters((prevState) => {
				return { ...prevState, [filterKey]: value }
			})
			// dispatch(setThisFilter(value, filterKey))

			setTrigger(true)
			// setTimeout(() => {
				setTrigger(false)
			// }, 1000)
		}, 1000)
		}
	}

	const handleApply = () => {
		FilterApi.updateFilter(filters, isPickFrom)
				.then((res) => {
					// console.log(res, 'res')
					dispatch(setFilter(res.data.data.filter))
					// setExportList([])
					// setTableData([])
					// setAllPageProduct(false)
					// setInit(!init)
					// setFilters({ ...filters, ...res.data.data.filter })
				})
				.catch((err) => {
					// console.log(err, 'err')
					// setExportList([])
					// setTableData([])
					// setAllPageProduct(false)
				})
	}

	// useEffect(() => {
		// if (trigger === true) {
		// 	// console.log('calling..4', filters, suggestions, showTheseFilters)
		// 	FilterApi.updateFilter(filters)
		// 		.then((res) => {
		// 			// console.log(res, 'res')
		// 			dispatch(setFilter(res.data.data.filter))
		// 			setExportList([])
		// 			setTableData([])
		// 			setAllPageProduct(false)
		// 			// setInit(!init)
		// 			// setFilters({ ...filters, ...res.data.data.filter })
		// 		})
		// 		.catch((err) => {
		// 			// console.log(err, 'err')
		// 			setExportList([])
		// 			setTableData([])
		// 			setAllPageProduct(false)
		// 		})
		// }
	// }, [trigger])

	const [clearText, setClearText] = React.useState(false)

	const handleClearFilter = () => {
		// dispatch(clearAllFilters())
		// setClearText(!clearText)
		// setTrigger(true)
		// setTimeout(() => {
		// 	setTrigger(false)
		// }, 1000)
		let filtersState = _.isEqual(isPickFrom, 'Projects') ? { ...filterInitialState, project_name: [data?.project_title] } : { ...filterInitialState, sort_by: filters?.sort_by, sort_by_order: filters?.sort_by_order }
		setFilters(filtersState)
	}

	const handleClearThisFilter = (isCheckbox, filterKey) => {
		// dispatch(clearThisFilter(filterKey))
		// setFilters({...filters, filterKey: filterInitialState[filterKey]})
		setFilters((prevState) => {
			return { ...prevState, [filterKey]: filterInitialState[filterKey] }
		})
		// setTrigger(true)
		// setTimeout(() => {
		// 	setTrigger(false)
		// }, 1000)
	}

	const restBtnClickOnDialog = () => {
		handleClearFilter()
		onCloseDialog()
	}

	// const stockOptions = [
	//     {value: true, text: 'In Stock'},
	//     {value: false, text: 'Sold Out'},
	//     // {value: 'coming-soon', text: 'Coming Soon'}
	// ]
	// const priorityOptions = [
	//     {value: 'high', text: 'High'},
	//     {value: 'medium', text: 'Medium'},
	//     {value: 'low', text: 'Low'},
	//     // {value: 'coming-soon', text: 'Coming Soon'}
	// ]

	// const scrapperOptions = [
	//     {value: 'all', text: 'All'},
	//     {value: 'on', text: 'On'},
	//     {value: 'off', text: 'Off'}
	// ]

	const renderFilters = () => {
		return (
			<>
				{/* {renderProductFilter()} */}
				{/* {renderRetailerFilter()} */}
				{/* {renderStockFilter()} */}
				{/* {renderScrapperFilter()} */}
				{/* {renderDiscountFilter()} */}
				{/* {renderResellerFilter()} */}
				{/* {renderTargetFilter()}
				{renderDiscountFilter()} */}
				{/* {renderJobFilter()} */}
				{/* {renderAssignedFilter()} */}
				{/* {renderPriorityFilter()} */}
			</>
		)
	}

	const renderFiltersOnCondition = (filterKey) => {
		switch (filterKey) {
			case FILTER_CONSTANTS.TARGET_DAYS:
				return renderTargetFilter()
			case FILTER_CONSTANTS.DATE_RANGE:
				return renderDateRangeFilter()
			case FILTER_CONSTANTS.CODE_QUALITY:
				return renderCodeQualityFilter()
			case FILTER_CONSTANTS.PERFORMANCE_SCORE:
				return renderPerformanceScoreFilter()
			case FILTER_CONSTANTS.CREATED_DATE:
				return renderCreatedDateFilter()
			case FILTER_CONSTANTS.REVISIONS:
				return renderRevisionsFilter()
			case FILTER_CONSTANTS.STATUS:
				return renderStatusFilter()
			case FILTER_CONSTANTS.RATING:
				return renderRatingFilter()
			case FILTER_CONSTANTS.SATISFACTION_SCORE:
				return renderSatisfactionScoreFilter()
			case FILTER_CONSTANTS.PROJECT_NAME:
				return renderProjectNameFilter()
			case FILTER_CONSTANTS.GLASSX_NAME:
				return renderGlassxNameFilter()
			case FILTER_CONSTANTS.MODIFIED_BY:
				return renderModifiedByFilter()
			case FILTER_CONSTANTS.CREATED_BY:
				return renderCreatedByFilter()
			case FILTER_CONSTANTS.PERFORMANCE_TIME:
				return renderPerformanceTimeFilter()
			default:
				return null
		}
	}

	const renderTargetFilter = (filterKey = FILTER_CONSTANTS.TARGET_DAYS) => (
		<DateFilter
			key={filterKey}
			title='Target Days'
			initialDates={suggestions[filterKey].suggestions}
			rangeSelected={filters[filterKey]}
			onChangeCommitted={(val) => handleChange(filterKey, val, true)}
			handleClearThisFilter={() => handleClearThisFilter(true, filterKey)}
		/>
	)
	const renderDateRangeFilter = (filterKey = FILTER_CONSTANTS.DATE_RANGE) => (
		<DateFilter
			key={filterKey}
			title='Date Range'
			initialDates={suggestions[filterKey].suggestions}
			rangeSelected={filters[filterKey]}
			onChangeCommitted={(val) => handleChange(filterKey, val, true)}
			handleClearThisFilter={() => handleClearThisFilter(true, filterKey)}
			inputProps={suggestions[filterKey]?.additionalProps || {}}
		/>
	)

	const renderCodeQualityFilter = (filterKey = FILTER_CONSTANTS.CODE_QUALITY) => (
		<RangeFilter
			key={filterKey}
			title='Code Quality'
			min={suggestions[filterKey].suggestions[0]}
			max={suggestions[filterKey].suggestions[1]}
			step={1}
			minTitle={'From'}
			maxTitle={'To'}
			// rangeSuffix={'%'}
			rangeSelected={filters[filterKey]}
			onChangeCommitted={(val) => handleChange(filterKey, val, true)}
			handleClearThisFilter={() =>{handleClearThisFilter(true, filterKey)}}
		/>
	)

	const renderPerformanceScoreFilter = (filterKey = FILTER_CONSTANTS.PERFORMANCE_SCORE) => (
		<RangeFilter
			key={filterKey}
			title='Performance Score'
			min={suggestions[filterKey].suggestions[0]}
			max={suggestions[filterKey].suggestions[1]}
			step={1}
			minTitle={'From'}
			maxTitle={'To'}
			// rangeSuffix={'%'}
			rangeSelected={filters[filterKey]}
			onChangeCommitted={(val) => handleChange(filterKey, val, true)}
			handleClearThisFilter={() => handleClearThisFilter(true, filterKey)}
		/>
	)

	const renderCreatedDateFilter = (filterKey = FILTER_CONSTANTS.CREATED_DATE) => (
		<DateRangeFilterPanel
			// key={filterKey}
			// title='Created Date'
			// initialDates={suggestions[filterKey].suggestions}
			// rangeSelected={filters[filterKey]}
			// onChangeCommitted={(val) => handleChange(filterKey, val, true)}
			// handleClearThisFilter={() => handleClearThisFilter(filterKey)}
			// inputProps={suggestions[filterKey]?.additionalProps || {}}
			filterOpen={filterOpen}
			key={filterKey}
			title='Created Date'
			dataType={'DATE'}
			rangeSelected={filters[filterKey]}
			onChangeCommitted={(val) => handleChange(filterKey, val, true)}
			handleClearThisFilter={() => handleClearThisFilter(true, filterKey)}
		/>
	)

	const renderRevisionsFilter = (filterKey = FILTER_CONSTANTS.REVISIONS) => (
		<DefaultFilterPanel
			key={filterKey}
			isSearchNeeded={true}
			title='Revisions'
			noSort={true}
			options={suggestions[filterKey].suggestions}
			selectedOptions={filters[filterKey]}
			handleCheckBoxClick={(val) => handleChange(filterKey, val)}
			handleClearThisFilter={() => handleClearThisFilter(false, filterKey)}
			clearText={clearText}
			setClearText={setClearText}
		/>
	)

	const renderStatusFilter = (filterKey = FILTER_CONSTANTS.STATUS) => (
		<DefaultFilterPanel
			key={filterKey}
			isSearchNeeded={true}
			title='Status'
			noSort={true}
			options={suggestions[filterKey].suggestions}
			selectedOptions={filters[filterKey]}
			handleCheckBoxClick={(val) => handleChange(filterKey, val)}
			handleClearThisFilter={() => handleClearThisFilter(false, filterKey)}
			clearText={clearText}
			setClearText={setClearText}
		/>
	)

	const renderRatingFilter = (filterKey = FILTER_CONSTANTS.RATING) => (
		<RangeFilter
			key={filterKey}
			title='Rating'
			min={suggestions[filterKey].suggestions[0]}
			max={suggestions[filterKey].suggestions[1]}
			step={1}
			minTitle={'From'}
			maxTitle={'To'}
			// rangeSuffix={'%'}
			rangeSelected={filters[filterKey]}
			onChangeCommitted={(val) => handleChange(filterKey, val, true)}
			handleClearThisFilter={() => handleClearThisFilter(true, filterKey)}
		/>
	)

	const renderSatisfactionScoreFilter = (filterKey = FILTER_CONSTANTS.SATISFACTION_SCORE) => (
		<RangeFilter
			key={filterKey}
			title='Satisfaction Score'
			min={suggestions[filterKey].suggestions[0]}
			max={suggestions[filterKey].suggestions[1]}
			step={1}
			minTitle={'From'}
			maxTitle={'To'}
			// rangeSuffix={'%'}
			rangeSelected={filters[filterKey]}
			onChangeCommitted={(val) => handleChange(filterKey, val, true)}
			handleClearThisFilter={() => handleClearThisFilter(true, filterKey)}
		/>
	)

	const renderProjectNameFilter = (filterKey = FILTER_CONSTANTS.PROJECT_NAME) => (
		<FilterPanel
			noSort={true}
			key={filterKey}
			isFetching={suggestions[filterKey].isLoading}
			isPreLoaded={suggestions[filterKey].isAllLoaded}
			isSearchNeeded={_.isEqual(isPickFrom, 'Projects') ? false : true}
			title='Project Name'
			handleSearch={(keyValue, isClearOffset) => fetchSuggestions(filterKey, keyValue, isClearOffset)}
			optionKey='column_data'
			textKey='column_data'
			clearFilter={handleClearFilter}
			loadMoreFxn={() => fetchLoadMoreSuggestions(filterKey)}
			options={suggestions[filterKey].suggestions}
			selectedOptions={filters[filterKey]}
			handleCheckBoxClick={(val) => handleChange(filterKey, val)}
			handleClearThisFilter={() => {handleClearThisFilter(false, filterKey)}}
			isLoading={suggestions[filterKey].isLoading}
			clearText={clearText}
			setClearText={setClearText}
			disabled={_.isEqual(isPickFrom, 'Projects') ? true : false}
		/>
	)

	const renderGlassxNameFilter = (filterKey = FILTER_CONSTANTS.GLASSX_NAME) => (
		<FilterPanel
			noSort={true}
			key={filterKey}
			isFetching={suggestions[filterKey].isLoading}
			isPreLoaded={suggestions[filterKey].isAllLoaded}
			isSearchNeeded={true}
			title='Board Name'
			handleSearch={(keyValue, isClearOffset) => fetchSuggestions(filterKey, keyValue, isClearOffset)}
			optionKey='column_data'
			textKey='column_data'
			clearFilter={handleClearFilter}
			loadMoreFxn={() => fetchLoadMoreSuggestions(filterKey)}
			options={suggestions[filterKey].suggestions}
			selectedOptions={filters[filterKey]}
			handleCheckBoxClick={(val) => handleChange(filterKey, val)}
			handleClearThisFilter={() => handleClearThisFilter(false, filterKey)}
			isLoading={suggestions[filterKey].isLoading}
			clearText={clearText}
			setClearText={setClearText}
		/>
	)

	const renderModifiedByFilter = (filterKey = FILTER_CONSTANTS.MODIFIED_BY) => (
		<FilterPanel
			noSort={true}
			key={filterKey}
			isFetching={suggestions[filterKey].isLoading}
			isPreLoaded={suggestions[filterKey].isAllLoaded}
			isSearchNeeded={true}
			title='Modified By'
			handleSearch={(keyValue, isClearOffset) => fetchSuggestions(filterKey, keyValue, isClearOffset)}
			optionKey='column_data'
			textKey='column_data'
			clearFilter={handleClearFilter}
			loadMoreFxn={() => fetchLoadMoreSuggestions(filterKey)}
			options={suggestions[filterKey].suggestions}
			selectedOptions={filters[filterKey]}
			handleCheckBoxClick={(val) => handleChange(filterKey, val)}
			handleClearThisFilter={() => handleClearThisFilter(false, filterKey)}
			isLoading={suggestions[filterKey].isLoading}
			clearText={clearText}
			setClearText={setClearText}
		/>
	)

	const renderCreatedByFilter = (filterKey = FILTER_CONSTANTS.CREATED_BY) => (
		<FilterPanel
			noSort={true}
			key={filterKey}
			isFetching={suggestions[filterKey].isLoading}
			isPreLoaded={suggestions[filterKey].isAllLoaded}
			isSearchNeeded={true}
			title='Created By'
			handleSearch={(keyValue, isClearOffset) => fetchSuggestions(filterKey, keyValue, isClearOffset)}
			optionKey='column_data'
			textKey='column_data'
			clearFilter={handleClearFilter}
			loadMoreFxn={() => fetchLoadMoreSuggestions(filterKey)}
			options={suggestions[filterKey].suggestions}
			selectedOptions={filters[filterKey]}
			handleCheckBoxClick={(val) => handleChange(filterKey, val)}
			handleClearThisFilter={() => handleClearThisFilter(false, filterKey)}
			isLoading={suggestions[filterKey].isLoading}
			clearText={clearText}
			setClearText={setClearText}
		/>
	)

	const renderPerformanceTimeFilter = (filterKey = FILTER_CONSTANTS.PERFORMANCE_TIME) => (
		<DefaultFilterPanel
			key={filterKey}
			isSearchNeeded={true}
			title='Performance Time(in secs)'
			noSort={true}
			style={true}
			options={suggestions[filterKey].suggestions}
			selectedOptions={filters[filterKey]}
			handleCheckBoxClick={(val) => handleChange(filterKey, val)}
			handleClearThisFilter={() => handleClearThisFilter(false, filterKey)}
			clearText={clearText}
			setClearText={setClearText}
		/>
	)

	const renderDiscountFilter = (filterKey = FILTER_CONSTANTS.DISCOUNT) => (
		<RangeFilter
			key={filterKey}
			title='Discount'
			min={suggestions[filterKey].suggestions[0]}
			max={suggestions[filterKey].suggestions[1]}
			step={1}
			minTitle={'From'}
			maxTitle={'To'}
			rangeSuffix={'%'}
			rangeSelected={filters[filterKey]}
			onChangeCommitted={(val) => handleChange(filterKey, val, true)}
			handleClearThisFilter={() => handleClearThisFilter(true, filterKey)}
		/>
	)

	return (
		<>
			<Grid container className={classes.root} alignContent='flex-start'>
				<Grid item xs={12} className={classes.header} container justifyContent='space-between' alignItems='center'>
					<Typography variant='h6' className={classes.heading}>
						Filters
					</Typography>
					<div style={{ gap: '8px', display: 'flex' }}>
					{/* {!isSmallDevice && ( */}
						<Typography variant='caption' className={classes.resetSpan} component='span' onClick={handleClearFilter}>
							RESET ALL
						</Typography>
					{/* )} */}
					{/* {isSmallDevice && ( */}
						<IconButton onClick={()=>{onCloseDialog(); setFilters(filtersReduxState)}} size='small'>
							<Icon fontSize='small'>close</Icon>
						</IconButton>
					{/* )} */}
					</div>
				</Grid>
				<Grid item xs={12} className={classes.content}>
					{showTheseFilters && !_.isEmpty(showTheseFilters) ? showTheseFilters.map((key) => renderFiltersOnCondition(key)) : renderFilters()}
					{extraFilterProps && !_.isEmpty(extraFilterProps) ? <FilterPanel {...extraFilterProps} /> : null}
				</Grid>
				{/* <Grid item xs={12} className={classes.footer} container justifyContent='flex-end' alignItems='flex-end'>
					<Button onClick={restBtnClickOnDialog} color='default' variant='outlined' size='small' disableElevation>
						Reset All
					</Button>
					<Button onClick={onCloseDialog} className={classes.mLeft8} color='primary' variant='contained' size='small' disableElevation>
						Done
					</Button>
				</Grid> */}

			{isFooterEnabled ? (
				<Grid item xs={12} container alignItems='center' className={classes.filterFooterSection}>
					<Grid item xs={6} container justifyContent='center' alignItems='center'>
						<Button variant='outlined' color='primary' size='medium' disableElevation onClick={() => setFilters(filtersReduxState)} >
							Cancel
						</Button>
					</Grid>
					<Grid item xs={6} container justifyContent='center' alignItems='center'>
						<Button variant='contained' color='primary' size='medium' disableElevation onClick={() => handleApply()}>
							Apply
						</Button>
					</Grid>
				</Grid>
			) : null}
			</Grid>
		</>
	)
}

export default Filters
