import React, { useState, useCallback, useEffect } from 'react'
import { Grid, makeStyles, Typography, Container, Button, FormControlLabel, Switch, useTheme, useMediaQuery } from '@material-ui/core'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { hideLoader, showLoader, showErrorMessage } from 'services/loader/actions'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { getErrMsg, isModuleHasPerm } from 'utils'
import SelectBox from 'components/SelectBox'
import DashboardsApi from '../../../../../services/dashboards/api'
import GlassApi from '../../../../../services/glass/api'
import { fetchGlassDetails } from '../../../../../services/glass/actions'
import { usePermission } from 'hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'

const useStyles = makeStyles((theme) => ({
	container: {
		margin: 0,
	},
	root: {
		padding: theme.spacing(3, 2, 10),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(2, 2, 8),
		},
	},
	pt15: {
		paddingTop: '20px',
		'& .MuiFormControlLabel-root': {
			marginLeft: '0px',
		},
	},
	label: {
		fontWeight: '500',
	},
	text: {
		// fontWeight: '500',
		// marginTop: '7px',
	},
	selectDashboard: {
		display: 'flex',
		flexDirection: 'row',
		padding: '12px 0px',
	},
	subtitle: {
		display: 'flex',
		alignItems: 'center',
	},
	selectbox: {
		maxWidth: '50%',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
		},
	},
	btn: {
		backgroundColor: theme.palette.primary,
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(2),
	},
}))

const buildDashOptions = (dashboardList) => {
	return (
		_.map(dashboardList, (dashboard) => ({
			id: _.get(dashboard, 'dashboard_id'),
			name: _.get(dashboard, 'dashboard_name'),
			is_system_generated: _.get(dashboard, 'is_system_generated'),
		})) || []
	)
}

const DashboardConfig = ({ glassCode, dashboard, isOwner }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { dashboard_config, dashboard_id, dashboard_name } = dashboard
	const glassPerms = usePermission(ROLE_MODULES.GLASS)
	const isAccessible = isOwner && isModuleHasPerm(glassPerms, PERMISSION_TYPE.CREATE)

	const [dashboardEnable, setDashboardEnable] = useState(dashboard_config)
	const [dashboardState, setDashboardState] = useState({
		isFetching: true,
		dashboardList: [],
	})
	const { isFetching, dashboardList } = dashboardState
	const [dashboardId, setDashboardId] = useState(dashboard_id)
	const [dashboardName, setDashboardName] = useState(dashboard_name)
	const theme = useTheme()

	const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

	const loadDashboardList = useCallback(() => {
		setDashboardState({ isFetching: true, dashboardList: [] })
		DashboardsApi.fetchCustomDashboards(glassCode).then((resp) => {
			setDashboardState({
				isFetching: false,
				dashboardList: buildDashOptions(_.get(resp, 'data.data.dashboards', [])),
			})
		})
	}, [dashboardEnable])

	useEffect(() => {
		loadDashboardList()
	}, [])

	const FilterDashboardList = _.filter(dashboardList, { is_system_generated: false })

	const handleDashboardEnable = (e) => {
		setDashboardEnable(e.target.checked)
		if (e.target.checked === false) {
			setDashboardId(dashboard_id)
		}
	}

	const onChangeSelectBox = (value) => {
		setDashboardId(value)
		let result = _.find(dashboardList, function (n) {
			if (n.id === value) {
				return true
			}
		})
		setDashboardName(result?.name)
	}

	const onCancelBtn = () => {
		setDashboardId(dashboard_id)
		setDashboardEnable(dashboard_config)
	}

	const onClickSaveBtn = () => {
		dispatch(showLoader('Loading please wait...'))
		const dashboardType = dashboardName === 'Default' ? 'Default' : 'Custom'
		GlassApi.updateCustomDashboard(glassCode, dashboardEnable, dashboardType, dashboardId)
			.then((resp) => {
				dispatch(hideLoader())
				dispatch(fetchGlassDetails(glassCode))
				dispatch(showSnackbarWithTimeout(_.get(resp, 'data.more_info', 'The changes has been updated successfully'), 2000))
			})
			.catch((err) => {
				dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
			})
	}

	return (
		<Container className={classes.container} maxWidth={'md'}>
			<Grid container alignItems='flex-start' className={classes.root}>
				<Grid item xs={12} className={classes.pt15}>
					<FormControlLabel
						// value='Enable Dashboard Configuration'
						name='Enable Dashboard Configuration'
						control={<Switch color='primary' />}
						label='Enable Dashboard'
						labelPlacement='start'
						onChange={handleDashboardEnable}
						checked={dashboardEnable}
						disabled={!isAccessible}
					/>
				</Grid>
				<Grid item xs={12} container className={classes.selectDashboard}>
					<Grid item xs={12} sm={2} md={2} className={classes.subtitle}>
						<Typography
							variant='body1'
							className={classes.text}
							style={{ color: !dashboardEnable && '#00000042', marginTop: _.isEmpty(dashboardId) && !isMobile ? '-18px' : '7px' }}
						>
							Dashboard
						</Typography>
					</Grid>
					<Grid item xs={12} sm={10} md={10}>
						<SelectBox
							isLoading={isFetching}
							// label={isFetching ? 'Loading...' : ''}
							noOptionsText='No Dashboard Found'
							inputClass={classes.selectbox}
							name={'dashboard'}
							value={dashboardId}
							onChange={onChangeSelectBox}
							options={FilterDashboardList}
							disabled={!dashboardEnable || !isAccessible}
							isError={_.isEmpty(dashboardId)}
							helperText={_.isEmpty(dashboardId) && 'Please select any one of the Dashboard'}
						/>
					</Grid>
				</Grid>

				<Grid container item xs={12} justifyContent='flex-end' alignItems='flex-end'>
					<Button
						className={classes.btn}
						onClick={onCancelBtn}
						size='small'
						variant='contained'
						color='grey'
						disableElevation
						disabled={!isAccessible || (_.isEqual(dashboard_id, dashboardId) && _.isEqual(dashboard_config, dashboardEnable))}
					>
						Cancel
					</Button>
					<Button
						className={classes.btn}
						onClick={onClickSaveBtn}
						size='small'
						variant='contained'
						color='primary'
						disableElevation
						disabled={
							!isAccessible || _.isEmpty(dashboardId) || (_.isEqual(dashboard_id, dashboardId) && _.isEqual(dashboard_config, dashboardEnable))
						}
					>
						Save
					</Button>
				</Grid>
			</Grid>
		</Container>
	)
}

export default DashboardConfig
