import { ACTION_TYPES } from '../../../constants'

const newProducts = (state = {}, action) => {
	switch (action.type) {
		case ACTION_TYPES.FETCH_NEW_PRODUCTS_PENDING: {
			return {
				...state,
				isFetchingNewProducts: true,
				isFetchingNewProductsFailed: false,
				isFetchingNewProductsError: undefined,
				data: {},
			}
		}
		case ACTION_TYPES.FETCH_NEW_PRODUCTS_FULFILLED: {
			return {
				...state,
				isFetchingNewProducts: false,
				isFetchingNewProductsFailed: false,
				isFetchingNewProductsError: undefined,
				data: action.payload.data.data,
			}
		}
		case ACTION_TYPES.FETCH_NEW_PRODUCTS_REJECTED: {
			return {
				...state,
				isFetchingNewProducts: false,
				isFetchingNewProductsFailed: true,
				isFetchingNewProductsError: action.payload,
				data: {},
			}
		}
		case 'FETCH_PRODUCTS_MATCHING_SEARCH_QUERY': {
			return {
				...state,
			}
		}
		default:
			return state
	}
}

export default newProducts
