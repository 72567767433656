import React, { useRef, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import classnames from 'classnames'
import { Collapse, Grid, Icon, Typography } from '@material-ui/core'

var gridSize = 4
var rowHeight = gridSize * 8
var headerHeight = gridSize * 9

const ResultTable = ({ colDefs, tableLoading, showResultTbl, toggleResultTblShow, viewerRef, rowData, recordsCount, isDisableScrollAnimate }) => {
	const defaultColDef = { resizable: true }
	const containerRef = useRef()

	const autoReSizeAllColumns = (gridColumnApi) => {
		let allColumnIds = []
		gridColumnApi.getAllColumns().forEach((column) => {
			allColumnIds.push(column.colId)
		})
		gridColumnApi.autoSizeColumns(allColumnIds)
	}

	const onGridReady = (params) => {
		// const gridApi = params.api;
		const gridColumnApi = params.columnApi
		autoReSizeAllColumns(gridColumnApi)
	}

	useEffect(() => {
		if (!isDisableScrollAnimate)
			setTimeout(() => {
				containerRef.current.scrollIntoView({ behavior: 'smooth' })
			}, 200)
	}, [rowData, isDisableScrollAnimate])
	console.log('ROWDATA', rowData)
	return (
		<div ref={containerRef} className={classnames('data-view-table-div', 'container-no-fluid')}>
			<Grid container>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					{/* <span className={classnames("v-middle", "result-title")}>Query Results</span> */}
					<div className={classnames('v-middle', 'result-title', 'p-left-20')} role='button' onClick={!tableLoading ? toggleResultTblShow : null}>
						<Typography variant='subtitle1' className='title-color'>
							Query Results
						</Typography>
						<Typography variant='body2' component='span' className='title-count' style={{ marginTop: '2px' }}>
							{tableLoading ? 'Loading Query Results...' : `Showing ${rowData ? rowData.length : 0} of ${recordsCount} Records`}
						</Typography>
						{!tableLoading && <Icon className='dropdown-icon'>{showResultTbl ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>}
					</div>
					{/* <Icon className="v-middle">storage</Icon> &nbsp; */}
				</Grid>
			</Grid>
			<Collapse in={showResultTbl}>
				<div ref={viewerRef}>
					<div className={classnames('ag-theme-material', 'ag-grid', 'records-table')} style={{ height: '500px', width: 'auto' }}>
						<AgGridReact
							columnDefs={colDefs}
							rowData={rowData}
							onGridReady={onGridReady}
							defaultColDef={defaultColDef}
							headerHeight={headerHeight}
							rowHeight={rowHeight}
						></AgGridReact>
					</div>
				</div>
			</Collapse>
		</div>
	)
}

export default ResultTable
