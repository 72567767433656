import React, { useState, useEffect } from 'react'
import { makeStyles, Typography, Dialog, Button, DialogActions } from '@material-ui/core'
import { DateRange } from 'react-date-range'
import _ from 'lodash'
import moment from 'moment'
import { defaultStaticRanges, defaultInputRanges } from '../../utils/defaultDateRanges'

const useStyles = makeStyles((theme) => ({
	calendarWrapper: {
		// position: 'absolute',
		// top: '100%',
		// right: '0%',
		// zIndex: 5,
		padding: theme.spacing(2),
		background: theme.palette.almostBlack[100],
		boxShadow: `0px 0px 5px 0px ${theme.palette.almostBlack[400]}`,
		borderRadius: '10px',
		'& .rdrDefinedRangesWrapper': {
			borderRadius: '10px 0px 0px 10px',
			'& .rdrStaticRanges': {
				borderRadius: '10px 0px 0px 0px',
				'& .rdrStaticRange:first-child, .rdrStaticRange:first-child:hover': {
					borderRadius: '10px 0px 0px 0px',
					'& .rdrStaticRangeLabel': {
						borderRadius: '10px 0px 0px 0px',
					},
				},
			},
		},
		'& .rdrCalendarWrapper': {
			borderRadius: '0px 10px 10px 0px',
			'& .rdrDateDisplayWrapper': {
				borderRadius: '0px 10px 0px 0px',
			},
		},
	},
	dateButtonText: {
		paddingRight: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		fontSize: theme.typography.pxToRem(11),
		letterSpacing: theme.typography.pxToRem(1),
		// fontFamily: '"Roboto"'
	},
	text: {
		fontSize: 14,
		flex: 1,
		display: 'flex',
		paddingLeft: theme.spacing(2),
		alignItems: 'center', //justifyContent: 'center'
	},
}))

const DateInput = ({ initialDates, isSelected, handleDateChange, handleCalendarClose, isOpen, noDateText, textStyle }) => {
	const classes = useStyles()
	const [dates, setDates] = useState([
		{
			startDate: new Date(moment(_.get(initialDates, 'startDate', new Date()), 'YYYY-MM-DD HH:mm:ss').startOf('day').format('ddd, DD MMM YYYY HH:mm:ss')),
			endDate: new Date(moment(_.get(initialDates, 'endDate', new Date()), 'YYYY-MM-DD HH:mm:ss').endOf('day').format('ddd, DD MMM YYYY HH:mm:ss')),
			key: 'dates',
		},
	])

	useEffect(() => {
		if (
			!_.isEqual(moment(initialDates?.startDate).valueOf(), moment(dates[0]?.startDate).valueOf()) ||
			!_.isEqual(moment(initialDates?.endDate).valueOf(), moment(dates[0]?.endDate).valueOf())
		) {
			setDates([
				{
					key: 'dates',
					startDate: new Date(moment(_.get(initialDates, 'startDate', new Date()), 'YYYY-MM-DD HH:mm:ss').startOf('day').format('ddd, DD MMM YYYY HH:mm:ss')),
					endDate: new Date(moment(_.get(initialDates, 'endDate', new Date()), 'YYYY-MM-DD HH:mm:ss').endOf('day').format('ddd, DD MMM YYYY HH:mm:ss')),
				},
			])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialDates, isOpen])

	const handleChange = (dateObj) => {
		setDates([
			{
				key: 'dates',
				startDate: new Date(moment(dateObj?.dates?.startDate, 'YYYY-MM-DD HH:mm:ss').startOf('day').format('ddd, DD MMM YYYY HH:mm:ss')),
				endDate: new Date(moment(dateObj?.dates?.endDate, 'YYYY-MM-DD HH:mm:ss').endOf('day').format('ddd, DD MMM YYYY HH:mm:ss')),
			},
		])
	}

	function formatDateForLabel(dateString, format = 'MMM DD') {
		return moment(dateString).format(format)
	}

	function dateLabel(dateObj) {
		let format = 'MMM DD'
		if (moment(dateObj?.startDate).format('yyyy') !== moment(dateObj?.endDate).format('yyyy')) {
			format = 'MMM DD, yyyy'
		}
		if (formatDateForLabel(dateObj.startDate) === formatDateForLabel(dateObj.endDate)) {
			return `${formatDateForLabel(dateObj.endDate, format)}`
		} else {
			return `${formatDateForLabel(dateObj.startDate, format)} - ${formatDateForLabel(dateObj.endDate, format)}`
		}
	}

	const onClickOkBtn = () => {
		handleDateChange({ ...dates[0] })
	}

	return (
		<>
			<Typography variant='body1' className={classes.text} {...(!!textStyle ? { style: textStyle } : {})}>
				{!_.isEqual(initialDates?.startDate, undefined) ? dateLabel(initialDates) : !!noDateText ? noDateText : ''}
			</Typography>
			{isOpen && (
				<Dialog
					disableRestoreFocus
					onClick={(e) => {
						e.stopPropagation()
						e.preventDefault()
					}}
					open={isOpen}
					onClose={() => {
						handleCalendarClose(false)
					}}
				>
					<DateRange
						className={classes.calendarWrapper}
						onChange={(item) => {
							handleChange(item)
						}}
						showSelectionPreview={true}
						moveRangeOnFirstSelection={false}
						showDateDisplay={false}
						months={1}
						ranges={dates}
						staticRanges={defaultStaticRanges}
						inputRanges={defaultInputRanges}
						direction='horizontal'
					/>
					<DialogActions>
						<Button color='primary' onClick={() => {handleCalendarClose(); handleCalendarClose(false)}}>
							Cancel
						</Button>
						<Button color='primary' onClick={onClickOkBtn}>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	)
}

export default DateInput
