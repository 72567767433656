import React, { useState, useImperativeHandle } from 'react'
import _ from 'lodash'
import { Avatar, Typography, Icon, ButtonBase, IconButton } from '@material-ui/core'
import { getImgUrl, stringToHslColor } from 'utils'
import { GLASS_ROLES } from '../../constants'

export const withCellRenderState = (CellRenderer) => {
	return React.forwardRef((props, ref) => {
		const [value, setValue] = useState(props.value)
		useImperativeHandle(ref, () => {
			return {
				refresh: (params) => {
					if (params.value !== value) {
						setValue(params.value)
					}
					return true
				},
			}
		})
		return <CellRenderer {...props}></CellRenderer>
	})
}

export const ActionCellRenderer = (props) => {
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const onClickMoreOptions = _.get(props, 'colDef.cellRendererParams.onClickMoreOptions')
	const isUpdating = _.get(props, 'data.isUpdating', false)

	const onClickAnchorEl = (event) => {
		onClickMoreOptions(event, _.get(props, 'data', {}))
	}
	return !isUpdating ? (
		<div className={containerClassName}>
			<a href='#no' onClick={onClickAnchorEl} title='Show Actions'>
				<i className='material-icons'>more_horiz</i>
			</a>
		</div>
	) : null
}

export const RoleCellRenderer = (props) => {
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const onClick = _.get(props, 'colDef.cellRendererParams.onClick')
	const disabled = _.get(props, 'colDef.cellRendererParams.disabled')
	const isUpdating = _.get(data, 'isUpdating', false)

	return !isUpdating ? (
		<ButtonBase disabled={disabled} onClick={(e) => onClick(e, data)} className={containerClassName} disableRipple>
			<Typography variant='body2' className='roleTxt' component='div'>
				<span>{_.capitalize(value)}</span>
				{!disabled && <Icon className='dicon'>expand_more</Icon>}
			</Typography>
		</ButtonBase>
	) : null
}

export const RemoveCellRenderer = (props) => {
	const { data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const onClick = _.get(props, 'colDef.cellRendererParams.onClick')
	const isUpdating = _.get(data, 'isUpdating', false)

	return (
		<div className={containerClassName}>
			{!isUpdating && !_.isEqual(_.get(data, 'role'), GLASS_ROLES.OWNER) && (
				<IconButton size='small' onClick={() => onClick(data)} title='Remove User'>
					<Icon fontSize='small'>close</Icon>
				</IconButton>
			)}
		</div>
	)
}

export const NameCellRenderer = (props) => {
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const isRenderMobView = _.get(props, 'colDef.cellRendererParams.isRenderMobView')
	const authToken = _.get(props, 'colDef.cellRendererParams.authToken')
	const fullName = _.join([value, _.get(data, 'last_name')], ' ')
	const profilePic = _.get(data, 'profile_pic_url')
	const getPicUrl = (profilePic) => getImgUrl(authToken, profilePic, 50)
	const profilePicUrl = profilePic && getPicUrl(profilePic)

	if (isRenderMobView) {
		return (
			<div className={containerClassName}>
				<span>
					<Avatar
						size='small'
						style={{ backgroundColor: stringToHslColor(`${data?.first_name} ${data?.last_name}`) }}
						className='avatar-span'
						component='span'
						src={profilePic && profilePicUrl}
					>
						{value && _.isEmpty(profilePic) && _.upperCase(value.substring(0, 1))}
					</Avatar>
				</span>
				<div className='m-details'>
					<Typography variant='body2' component='div'>
						{fullName}
					</Typography>
					<Typography variant='caption' component='div'>
						{_.get(data, 'email')}
					</Typography>
					<Typography variant='caption' color='textSecondary' component='div'>
						{_.get(data, 'role')}
					</Typography>
				</div>
			</div>
		)
	} else
		return (
			<div className={containerClassName}>
				<Avatar
					size='small'
					style={{ backgroundColor: stringToHslColor(`${data?.first_name} ${data?.last_name}`) }}
					className='avatar-span'
					component='span'
					src={profilePic && profilePicUrl}
				>
					{value && _.isEmpty(profilePic) && _.upperCase(value.substring(0, 1))}
				</Avatar>
				<span className='details-span'>{_.startCase(fullName)}</span>
			</div>
		)
}
