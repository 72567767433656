import { SAGA_ACTIONS } from '../../../constants'

export const getModelxTable = (query, search, redirect, redirectFrom, redirectFilter, redirectData, cancelExecutor) => {
	return {
		type: SAGA_ACTIONS.GET_MODELX_TABLE,
		query,
		search,
		redirect,
		redirectFrom,
		redirectFilter,
		redirectData,
		cancelExecutor,
	}
}

export const getModelxDetails = (modelxId, cancelExecutor) => {
	return {
		type: SAGA_ACTIONS.GET_MODELX_DETAILS,
		modelxId,
		cancelExecutor,
	}
}

export const modelxJobs = (modelxId, data, cancelExecutor, successCallback, errCallback) => {
	return {
		type: SAGA_ACTIONS.GET_MODELX_JOBS,
		modelxId,
		data,
		cancelExecutor,
		successCallback,
		errCallback,
	}
}

export const getModelxLogs = (modelxId, cancelExecutor) => {
	return {
		type: SAGA_ACTIONS.GET_MODELX_LOGS,
		modelxId,
		cancelExecutor,
	}
}
