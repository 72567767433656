import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, Grid, Icon, IconButton, makeStyles, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core'

IframeDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	data: PropTypes.array.isRequired,
	isSelectedAll: PropTypes.bool.isRequired,
	searchParams: PropTypes.object,
}

const useStyles = makeStyles((theme) => ({
	titleWrap: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	title: {
		lineHeight: 'unset',
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		paddingRight: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			fontSize: '14px',
		},
	},
	subtitle: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	toolbar: {
		width: '100%',
		boxSizing: 'border-box',
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
	},
	dialogContent: {
		padding: 0,
	},
	iframe: {
		border: 'none',
		width: '100%',
		height: 'calc(100vh - 68px)',
	},
}))

function IframeDialog({ open, handleClose, title, subtitle, url }) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const classes = useStyles()

	return (
		<Dialog
			open={open}
			fullScreen={true}
			onClose={handleClose}
			aria-labelledby='task-add-dialog'
			scroll={fullScreen ? 'paper' : 'body'}
			maxWidth={'md'}
		>
			<Toolbar className={classes.toolbar}>
				<IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
					<Icon>close</Icon>
				</IconButton>
				<div className={classes.titleWrap}>
					<Typography className={classes.title} component='div' variant='h6'>
						{title}
					</Typography>
					{subtitle && (
						<Typography variant='caption' component='div' color='textSecondary'>
							<span className={classes.subtitle}>{subtitle}</span>
						</Typography>
					)}
				</div>
			</Toolbar>

			<DialogContent className={classes.dialogContent}>
				<Grid container>
					<Grid item xs={12}>
						<iframe className={classes.iframe} src={url} title={title}></iframe>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default IframeDialog
