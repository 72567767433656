import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Slide, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import ProjectFilterList from '../ProjectFilterList'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
	dialog: {
		minWidth: 400,
		[theme.breakpoints.down('sm')]: {
			minWidth: 'unset',
		},
	},
	tab: {
		textTransform: 'none',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	customTabTitle: {
		fontSize: 14,
		fontWeight: '500',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	dialogTitle: {
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	dialogContent: {
		padding: 0,
	},
	dialogFooter: {
		padding: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
	textField: {
		paddingBottom: theme.spacing(2),
	},
}))

ProjectFilterDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	onChangeProjFilter: PropTypes.func.isRequired,
	projFilterVal: PropTypes.arrayOf(
		PropTypes.shape({
			project_name: PropTypes.string,
			project_id: PropTypes.string,
		})
	),
}

function ProjectFilterDialog({ open, projFilterVal, onChangeProjFilter, handleClose }) {
	const classes = useStyles()
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const [value, setValue] = useState(projFilterVal)

	useEffect(() => {
		if (open) setValue(projFilterVal)
	}, [open, projFilterVal])

	const onChangeFilter = (projList) => {
		setValue(projList)
	}

	const onClickApplyBtn = () => {
		handleClose()
		onChangeProjFilter(value)
	}

	return (
		<Dialog
			classes={{
				paper: classes.dialog,
			}}
			TransitionComponent={Transition}
			fullScreen={fullScreen}
			maxWidth={'sm'}
			open={open}
			onClose={handleClose}
			aria-labelledby='create-glass'
		>
			<DialogTitle className={classes.dialogTitle} disableTypography={true} id='create-glass-title'>
				<Typography variant='h6'>Project Filter</Typography>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<ProjectFilterList controlled value={value} onChange={onChangeFilter} />
			</DialogContent>
			<DialogActions className={classes.dialogFooter}>
				<Button variant='outlined' size={fullScreen ? 'small' : 'medium'} onClick={handleClose} color='primary'>
					Cancel
				</Button>
				<Button variant='contained' size={fullScreen ? 'small' : 'medium'} onClick={onClickApplyBtn} color='primary' disableElevation>
					Apply
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ProjectFilterDialog
