import React, { useRef, useEffect } from 'react'
import { Divider, Grid, makeStyles, Typography, Chip, TextField, Avatar, Icon, InputAdornment, IconButton, Button, ButtonBase } from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import { useDispatch, useSelector } from 'react-redux'
import { formTheName, getImgUrl, stringToHslColor } from 'utils'
import { getModelxDetails } from '../../../../../services/modelx/actions'
import _ from 'lodash'
import classnames from 'classnames'
import { DateTimePicker } from '@material-ui/pickers'
import htmlToDraft from 'html-to-draftjs'
import { ContentState } from 'draft-js'

const useStyles = makeStyles((theme) => ({
    headerRoot: {
		'& .MuiFormControl-fullWidth': {
			marginTop: 0,
		},
		'& .MuiOutlinedInput-inputMarginDense': {
			padding: theme.spacing(1),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1.2, 2, 0, 2),
		},
		padding: '16px 20px',
	},
    detailsContent: {
		padding: theme.spacing(1, 0),
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: theme.spacing(0.5, 0),
		},
	},
    objBg: {
		backgroundColor: '#f2f6fa',
		padding: theme.spacing(2, '20px'),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1, 2),
		},
	},
    bold: {
		fontWeight: '500',
	},
    noneSection: {
		backgroundColor: theme.palette.grey[100],
		padding: 5,
		fontSize: 14,
		color: theme.palette.grey[600],
		marginTop: theme.spacing(1),
		cursor: 'pointer',
	},
    dateTime: {
        '& .MuiInputBase-input': {
            color: '#171725',
        },
    },
    titleSec: {
		overflow: 'hidden',
		cursor: 'text',
        '& .MuiTypography-h6': {
            fontSize: '14px',
            fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: '500',
            lineHeight: '1.57',
            letterSpacing: '0.00714em',
            },
		'& h5': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
    content: {
        margin: '8px 0px',
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 16px 8px 0px',
    },
    contentKey: {
        fontSize: '14px',
        fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
        fontWeight: '500',
        lineHeight: '1.57',
        letterSpacing: '0.00714em',
        color: '#171725',
    },
    contentValue: {
        marginTop: '8px',
        alignItems: 'center',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        fontSize: '0.875rem',
        fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
        fontWeight: '400',
        lineHeight: '1.43',
        letterSpacing: '0.01071em',
        color: '#171725',
    },
    assigneeDiv: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		marginTop: theme.spacing(1),
	},
	text: {
		fontSize: 14,
		flex: 1,
	},
    userPicSmall: {
		width: 24,
		height: 24,
		fontSize: 13,
	},
    avatar: {
		width: 32,
		height: 32,
		fontSize: 17,
	},
	avatarGrid: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		gap: '5px',
		flexWrap: 'wrap',
		marginTop: theme.spacing(1),
	},
    chipContent: {
        margin: '8px 0px',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiChip-sizeSmall': {
            height: '18px',
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
            fontWeight: '400',
            lineHeight: '1.43',
            letterSpacing: '0.03333em',
            color: '#171725',
            fontSize: '0.875rem',
            boxSizing: 'borderBox',
            alignItems: 'center',
        },
    },
    chip: {
        marginTop: '8px',
        display: 'flex',
        gap: '5px',
    },
    multiTextField: {
        minWidth: '100%',
        maxWidth: '100%',
        '& .MuiOutlinedInput-multiline': {
            color: 'black',
        },
    },
    footerRoot: {
		// borderTop: `1px solid ${theme.palette.grey[300]}`,
		position: 'sticky',
		bottom: 0,
		zIndex: 999,
		background: theme.palette.common.white,
	},
	centerAlign: {
		textAlign: 'center',
		borderRight: `1px solid ${theme.palette.divider}`,
		'& button, & a': {
			padding: theme.spacing(1.5),
			width: '100%',
		},
	},
    labelBtnBase: {
		height: '28px',
		padding: '3px 9px',
		borderRadius: '18px',
		// color: theme.palette.common.white,
        background: '#d1d1d1',
	},
}))


const basicDetails = {
    email: "wersel@datinfi.com",
    first_name: "Wersel",
    last_name: "Development",
    profile_img: "5c592f6716209a24f4125001/profile_picture/5f9142d066dadc1bbd210460_05182023081432.png",
    user_id: "5f9142d066dadc1bbd210460"
}

const members = [
    {
        email: "wersel@datinfi.com",
        first_name: "Wersel",
        last_name: "Development",
        profile_img: "5c592f6716209a24f4125001/profile_picture/5f9142d066dadc1bbd210460_05182023081432.png",
        _id: "5f9142d066dadc1bbd210460"
    },
    {
        email: "dinesh.s@wersel.io",
        first_name: "Dinesh",
        last_name: "S",
        profile_img: "5c592f6716209a24f4125001/profile_picture/60c9c4a445056ee40b8be3b2_06172021151830.png",
        _id: "60c9c4a445056ee40b8be3b2"
    },
    {
        email: "wersel@datinfi.com",
        first_name: "Wersel",
        last_name: "Development",
        profile_img: "5c592f6716209a24f4125001/profile_picture/5f9142d066dadc1bbd210460_05182023081432.png",
        _id: "5f9142d066dadc1bbd210460"
    },
]

// const desc = "<p>Project Description: /B Testing: AI can automate the process of A/B testing, allowing businesses to more efficiently test and optimize various aspects of their websites or marketing campaigns.</p> <p>Scope: The goal of this project is to implement an AI-driven A/B testing system, automating the process to allow businesses to more efficiently test and optimize different aspects of their websites or marketing campaigns.\n\n</p> <p>Budget: 0.31</p> "

const ratingValue = 3.5

const BasicDetails = ({ slider, project, onClickGlassX, onClickDashX, disableDashxBtn }) => {
 
    const classes = useStyles()

    const token = useSelector((state) => _.get(state, 'session.authToken'))

    // const dispatch = useDispatch()

    // const cancelExecutor = useRef()

    // const basicDetails = useSelector((state) => state.modelxTable?.modelx_details)

    // useEffect(() => {
    //     // if (cancelExecutor.current !== undefined) {
    //     //     cancelExecutor.current()
    //     // }
    //     dispatch(getModelxDetails(slider?.id, cancelExecutor))
    // }, [])

    return (
        <div>
            <Grid item lg={12} md={12} className={classnames(classes.detailsContent, classes.objBg)}>
                <Typography className={classes.bold} variant='body2' gutterBottom>
                    Objectives and Key Results
                </Typography>
                <div role='button'>
                    {project?.description &&
                    !_.isEmpty(ContentState.createFromBlockArray(htmlToDraft(project?.description)).getPlainText()) ? (
                        <div className={classes.richTextContent} dangerouslySetInnerHTML={{ __html: project?.description }}></div>
                    ) : (
                        <p className={classes.noneSection}>None</p>
                    )}
                </div>
            </Grid>
            <Grid container justifyContent='space-between' alignItems='flex-start' className={classes.headerRoot}>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Created By'}
                    </Typography>
                    {!_.isEmpty(project?.created_by) ? (
                    <div className={classes.assigneeDiv}>
                        <Avatar
                            {...(!_.isEmpty(project?.created_by?.profile_img) && {
                                src: getImgUrl(token, project?.created_by?.profile_img),
                            })}
                            alt={formTheName(project?.created_by?.first_name, project?.created_by?.last_name)}
                            className={classes.userPicSmall}
                            style={{
                                backgroundColor: stringToHslColor(formTheName(project?.created_by?.first_name, project?.created_by?.last_name)),
                            }}
                        >
                            {_.slice(_.words(formTheName(project?.created_by?.first_name, project?.created_by?.last_name)), 0, 1).reduce((a, t) => (a += t[0]), '')}
                        </Avatar>
                        <Typography variant='body1' className={classes.text} style={{ marginLeft: 4 }}>
                            {formTheName(project?.created_by?.first_name, project?.created_by?.last_name)}
                        </Typography>
                    </div>
                    ) : (
                        <p className={classes.noneSection}>
                            None
                        </p>
                    )}
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Modified By'}
                    </Typography>
                    {!_.isEmpty(project?.modified_by) ? (
                    <div className={classes.assigneeDiv}>
                        <Avatar
                            {...(!_.isEmpty(project?.modified_by?.profile_img) && {
                                src: getImgUrl(token, project?.modified_by?.profile_img),
                            })}
                            alt={formTheName(project?.modified_by?.first_name, project?.modified_by?.last_name)}
                            className={classes.userPicSmall}
                            style={{
                                backgroundColor: stringToHslColor(formTheName(project?.modified_by?.first_name, project?.modified_by?.last_name)),
                            }}
                        >
                            {_.slice(_.words(formTheName(project?.modified_by?.first_name, project?.modified_by?.last_name)), 0, 1).reduce((a, t) => (a += t[0]), '')}
                        </Avatar>
                        <Typography variant='body1' className={classes.text} style={{ marginLeft: 4 }}>
                            {formTheName(project?.modified_by?.first_name, project?.modified_by?.last_name)}
                        </Typography>
                    </div>
                    ) : (
                        <p className={classes.noneSection}>
                            None
                        </p>
                    )}
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                        <Typography variant='caption' className={classes.contentKey}>
                            {'Start Date'}
                        </Typography>
                        {!_.isEmpty(project?.project_start_date) ? (
                            <div className={classes.dateTime}>
                                <DateTimePicker
                                    fullWidth
                                    minDateMessage={<></>}
                                    inputVariant='outlined'
                                    value={project?.project_start_date}
                                    size={'small'}
                                    // name={name}
                                    // error={error}
                                    margin='dense'
                                    clearable
                                    autoFocus
                                    disabled={true}
                                    disablePast={true}
                                    format={'DD MMM YYYY'}
                                    // onChange={(date) => {
                                    //     onChange('', date)
                                    //     toggleAssigneeList()
                                    // }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton size='small'>
                                                    <Icon>date_range</Icon>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    className={classes.inputText}
                                />
                            </div>
                        ) : (
                            <p className={classes.noneSection}>
                                None
                            </p>
                        )}
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                        <Typography variant='caption' className={classes.contentKey}>
                            {'End Date'}
                        </Typography>
                        {!_.isEmpty(project?.project_end_date) ? (
                        <div className={classes.dateTime}>
                                <DateTimePicker
                                    fullWidth
                                    minDateMessage={<></>}
                                    inputVariant='outlined'
                                    value={project?.project_end_date}
                                    size={'small'}
                                    // name={name}
                                    // error={error}
                                    margin='dense'
                                    clearable
                                    autoFocus
                                    disabled={true}
                                    disablePast={true}
                                    format={'DD MMM YYYY'}
                                    // onChange={(date) => {
                                    //     onChange('', date)
                                    //     toggleAssigneeList()
                                    // }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton size='small'>
                                                    <Icon>date_range</Icon>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    className={classes.inputText}
                                />
                            </div>
                        ) : (
                            <p className={classes.noneSection}>
                                None
                            </p>
                        )}
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'List of team members'}
                    </Typography>
                    {!_.isEmpty(project?.project_members) ? (
                    <>
                        <Grid item xs={12} className={classes.avatarGrid}>
                            {_.map(project?.project_members, (member) => (
                                <Avatar
                                    title={`${formTheName(member?.first_name || '', member?.last_name || '')} \n${member?.email || ''}`}
                                    style={{ backgroundColor: stringToHslColor(`${member.first_name} ${member.last_name}`) }}
                                    size='small'
                                    className={classes.avatar}
                                    key={member.user_id}
                                    src={getImgUrl(token, member.profile_url, 50) || null}
                                    // onClick={(e) => handleOpenPopper(e, member)}
                                >
                                    {_.slice(_.words(formTheName(member?.first_name, member?.last_name)), 0, 1).reduce((a, t) => (a += t[0]), '')}
                                </Avatar>
                            ))}
                        </Grid>
                    </>
                    ) : (
                        <p className={classes.noneSection} style={{ marginTop: '11px' }}>
                            None
                        </p>
                    )}
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Project Rating'}
                    </Typography>
                    {_.gt(project?.ratings, 0) ? (
                        <div role='button'>
                            <Grid style={{ marginTop: 8 }} className={classes.priorityItem} container alignItems='center'>
                                <Grid item xs style={{display: 'flex', flexDirection: 'row'}}>
                                <p style={{ marginTop: '0px', fontSize: '15px' }}>{project?.ratings}</p>&nbsp;<Rating name="unique-rating" precision={0.1} value={project?.ratings} readOnly size="small" />							
                                </Grid>
                            </Grid>
				        </div>
                    ) : (
                        <p className={classes.noneSection}>None</p>
                    )}
                </Grid>
                <Grid item lg={12} sm={12} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Labels'}
                    </Typography>
                    {!_.isEmpty(project?.labels) ? (
                    <>
                        <Grid item xs={12} className={classes.avatarGrid}>
                        {_.map(project?.labels, (label) => (
                            <ButtonBase
                                // onClick={openPopver}
                                // disabled={disabled}
                                // style={{ background: _.get(label, 'label_color') }}
                                className={classes.labelBtnBase}
                                key={_.get(label, 'label_id')}
                            >
                                <Typography variant='caption'>{_.get(label, 'label_name')}</Typography>
                            </ButtonBase>
                        ))}
                        </Grid>
                    </>
                    ) : (
                        <p className={classes.noneSection} style={{ marginTop: '11px' }}>
                            None
                        </p>
                    )}
                </Grid>
            </Grid>
            <Grid container className={classes.footerRoot}>
                <Grid item xs={12}>
                        <Divider />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.centerAlign}>
                    <Button size='small' color='primary' disabled={disableDashxBtn} onClick={(e) => onClickDashX(e, project)}>
                        Dashboard
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.centerAlign}>
                    <Button target='_blank' size='small' color='primary' onClick={(e) => onClickGlassX(e, project)} disabled={_.isEmpty(project.glass_code)}>
                        Boards
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
        </div>
    )
}

export default BasicDetails
