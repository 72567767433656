import React, { useState, useEffect, useCallback, useRef } from 'react'
import TaskStatus from './TaskStatus'
import { Grid, makeStyles } from '@material-ui/core'
import Activities from 'components/Activities'
import TimeLogger from 'components/TimeLogger'
import Axios from 'axios'
import { convertLocalTimeToGmtStr, getErrMsg, getSuccessMsg } from 'utils'
import { useDispatch } from 'react-redux'
import GlassApi from 'services/glass/api'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { usePrevious } from 'hooks'
import update from 'immutability-helper'
import { hideLoader, showConfirmMessage } from 'services/loader/actions'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '0 20px',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0),
		},
	},
	verticalSpace: {
		padding: theme.spacing(0, 0, 2, 0),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0, 2),
		},
	},
	timeLogger: {
		padding: '12px 16px 16px 16px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: theme.spacing(2),
			flex: 'inherit',
		},
	},
}))

const TaskUpdates = ({
	isOwner,
	glassCode,
	updateStickyDetails,
	glassLabels,
	membersSuggestions,
	sessionId,
	formik,
	stickyDetails,
	activityTypes,
	isEditEnabled,
	stickyId,
	socketRef,
	isSocketConnected,
	isOwnerOrReporter,
	isTimerEnabled,
	handleCustomLabel,
}) => {
	const classes = useStyles()
	const [taskDetails, setTaskDetails] = useState({ status: '', status_comments: '', ratings: 0, modified_by: '' })
	const prevData = usePrevious(taskDetails)
	const [loading, setLoading] = useState(false)
	const [isTimerLoading, setTimerLoading] = useState(false)
	const dispatch = useDispatch()
	const stickyDetailsSynced = useRef(stickyDetails)

	useEffect(() => {
		stickyDetailsSynced.current = stickyDetails
	}, [stickyDetails])

	const delayOnApi = useCallback(
		_.debounce((val, callback) => {
			callback(val)
		}, 100),
		[]
	)

	useEffect(() => {
		setTaskDetails((prev) => ({
			...prev,
			status: _.get(formik?.values, 'status', 'open'),
			status_comments: _.get(formik?.values, 'status_comments', ' '),
			ratings: _.get(formik?.values, 'ratings', 0),
			created_by: _.join(
				[
					_.get(stickyDetailsSynced.current?.sticky_status?.created_by, 'first_name', ''),
					_.get(stickyDetailsSynced.current?.sticky_status?.created_by, 'last_name', ''),
				],
				' '
			),
			created_date: _.get(stickyDetailsSynced.current?.sticky_status, 'created_date', ''),
		}))
	}, [formik.values])

	const updateStatus = useCallback(
		(status, comments, rating) => {
			setTaskDetails((prev) => ({
				...prev,
				status: status,
				status_comments: comments,
				ratings: rating,
			}))

			setLoading(true)
			GlassApi.updateTask(glassCode, stickyId, sessionId, { status: status, status_comments: comments, ratings: rating })
				.then((res) => {
					updateStickyDetails(_.get(res, 'data.data'))
					setLoading(false)
				})
				.catch((err) => {
					if (!Axios.isCancel(err)) {
						dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
						setLoading(false)
						setTaskDetails(prevData)
					}
				})
		},
		[dispatch, glassCode, prevData, sessionId, stickyId, updateStickyDetails]
	)

	const onErrorTimeLogs = (err) => {
		if (!Axios.isCancel(err)) {
			dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
			setTimerLoading(false)
		}
	}

	const onSuccessTimeLogs = useCallback(
		(opToPerform, hideLoading = true) => {
			hideLoading && setTimerLoading(false)
			updateStickyDetails(update(stickyDetailsSynced.current, { time_logs: opToPerform }, true))
		},
		[updateStickyDetails]
	)

	const pushDummyLog = useCallback(
		(from) => {
			onSuccessTimeLogs({ $push: [{ time_log_id: 1, from, to: null }] }, false)
		},
		[onSuccessTimeLogs]
	)

	const getDummyLogIdx = useCallback(() => {
		return _.findIndex(stickyDetailsSynced.current?.time_logs, { time_log_id: 1 })
	}, [])

	const replaceDummyLog = useCallback(
		(logToReplace) => {
			onSuccessTimeLogs({ [getDummyLogIdx()]: { $set: logToReplace } })
		},
		[onSuccessTimeLogs, getDummyLogIdx]
	)

	const onStartTimer = () => {
		const from = convertLocalTimeToGmtStr(moment().format('YYYY-MM-DD HH:mm:ss'))
		pushDummyLog(from)
		setTimerLoading(true)
		const onSuccess = (resp) => {
			replaceDummyLog(resp?.data?.data)
		}
		const onError = (err) => {
			//remove dummy log idx
			onSuccessTimeLogs({ $splice: [[getDummyLogIdx(), 1]] })
			onErrorTimeLogs(err)
		}
		GlassApi.startStickyTimer(glassCode, stickyDetails?.sticky_code, from, sessionId).then(onSuccess, onError)
	}

	const onStopTimer = () => {
		const to = convertLocalTimeToGmtStr(moment().format('YYYY-MM-DD HH:mm:ss'))

		const replaceTo = (to) => {
			const exstLogIdx = _.findIndex(stickyDetailsSynced.current?.time_logs, { to: null })
			onSuccessTimeLogs({ [exstLogIdx]: { $set: { ...stickyDetailsSynced.current?.time_logs[exstLogIdx], to } } }, false)
		}
		replaceTo(to)

		delayOnApi(to, (to) => {
			setTimerLoading(true)
			const onSuccess = (resp) => {
				const exstLogIdx = _.findIndex(stickyDetailsSynced.current?.time_logs, { time_log_id: resp.data?.data?.time_log_id })
				onSuccessTimeLogs({ [exstLogIdx]: { $set: resp.data?.data } })
			}
			GlassApi.stopStickyTimer(glassCode, stickyDetailsSynced.current?.sticky_code, to, sessionId).then(onSuccess, onErrorTimeLogs)
		})
	}

	const onAddTimeLog = (formData, successCallback) => {
		setTimerLoading(true)
		const onSuccess = (resp) => {
			dispatch(showSnackbarWithTimeout(getSuccessMsg(resp), 1500))
			onSuccessTimeLogs({ $push: [resp?.data?.data] })
			successCallback && successCallback()
		}
		GlassApi.addStickyTimeLog(
			glassCode,
			stickyDetailsSynced.current?.sticky_code,
			{
				from: formData?.from,
				to: formData?.to,
			},
			sessionId
		).then(onSuccess, onErrorTimeLogs)
	}

	const onUpdateTimeLog = (formData, successCallback) => {
		setTimerLoading(true)
		const onSuccess = (resp) => {
			dispatch(showSnackbarWithTimeout(getSuccessMsg(resp), 1500))
			const exstLogIdx = _.findIndex(stickyDetailsSynced.current?.time_logs, { time_log_id: resp.data?.data?.time_log_id })
			onSuccessTimeLogs({ [exstLogIdx]: { $set: resp.data?.data } })
			successCallback && successCallback()
		}
		GlassApi.updateStickyTimeLog(
			glassCode,
			stickyDetailsSynced.current?.sticky_code,
			formData?.time_log_id,
			{
				from: formData?.from,
				to: formData?.to,
			},
			sessionId
		).then(onSuccess, onErrorTimeLogs)
	}

	const hideConfirmBox = () => {
		dispatch(hideLoader())
	}

	const showConfirmBox = (message, confirmBtnTxt, onConfirm) => {
		dispatch(
			showConfirmMessage(
				message,
				'',
				confirmBtnTxt,
				() => {
					hideConfirmBox()
					onConfirm()
				},
				'Cancel',
				hideConfirmBox
			)
		)
	}

	const onRemoveTimeLog = (log) => {
		const onSuccess = (resp) => {
			const exstLogIdx = _.findIndex(stickyDetailsSynced.current?.time_logs, { time_log_id: resp.data?.data?.time_log_id })
			if (exstLogIdx > -1) onSuccessTimeLogs({ $splice: [[exstLogIdx, 1]] })
			dispatch(showSnackbarWithTimeout(getSuccessMsg(resp), 2000))
		}
		showConfirmBox('Do you want to remove this time log?', 'Remove', () => {
			setTimerLoading(true)
			GlassApi.deleteStickyTimeLog(glassCode, stickyDetailsSynced.current?.sticky_code, log?.time_log_id, sessionId).then(onSuccess, onErrorTimeLogs)
		})
	}

	const onClearTimeLogs = () => {
		const onSuccess = (resp) => {
			onSuccessTimeLogs({ $set: [] })
			dispatch(showSnackbarWithTimeout(getSuccessMsg(resp), 2000))
		}
		showConfirmBox('Do you want to clear all the time logs?', 'Clear All', () => {
			setTimerLoading(true)
			GlassApi.clearAllStickyTimeLogs(glassCode, stickyDetailsSynced.current?.sticky_code, sessionId).then(onSuccess, onErrorTimeLogs)
		})
	}
console.log("STICKYDETAILS", stickyDetails)
	return (
		<>
			<Grid container className={classes.root}>
				<Grid item xs={12} md={8} className={classes.verticalSpace}>
					<TaskStatus
						isApproval={formik?.values?.is_approval}
						isOwner={isOwner}
						isEditEnabled={isEditEnabled}
						stickyDetails={stickyDetails}
						taskDetails={taskDetails}
						setTaskDetails={setTaskDetails}
						loading={loading}
						onStatusChange={updateStatus}
					/>
				</Grid>
				<Grid item xs={12} md={4} className={classes.timeLogger}>
					<TimeLogger
						isLoading={isTimerLoading}
						disabled={!isTimerEnabled}
						value={stickyDetails?.time_logs || []}
						estimatedTime={formik.values?.estimation_time}
						onStartTimer={onStartTimer}
						onStopTimer={onStopTimer}
						onAddTimeLog={onAddTimeLog}
						onUpdateTimeLog={onUpdateTimeLog}
						onRemoveTimeLog={onRemoveTimeLog}
						onClearTimeLogs={onClearTimeLogs}
					/>
				</Grid>
				<Grid item xs={12} className={classes.verticalSpace}>
					<Activities
						isEditEnabled={isOwnerOrReporter}
						title={'Activities'}
						is_pinned={false}
						glassCode={glassCode}
						sessionId={sessionId}
						stickyId={stickyId}
						showCommentBox={true}
						activityTypes={activityTypes}
						glassLabels={glassLabels}
						placeholder='Ask a question or post an update'
						socketRef={socketRef}
						isSocketConnected={isSocketConnected}
						suggestions={membersSuggestions}
						showExcludeSystemMessage={true}
						handleCustomLabel={handleCustomLabel}
						newCustomLabel={true}
					/>
				</Grid>
			</Grid>
		</>
	)
}

TaskUpdates.propTypes = {
	isOwner: PropTypes.bool,
	glassCode: PropTypes.string.isRequired,
	glassLabels: PropTypes.array,
	sessionId: PropTypes.string,
	stickyId: PropTypes.string.isRequired,
	membersSuggestions: PropTypes.array,
	formik: PropTypes.object,
	stickyDetails: PropTypes.object,
	activityTypes: PropTypes.array,
	isEditEnabled: PropTypes.bool,
	socketRef: PropTypes.object,
	isSocketConnected: PropTypes.bool,
}

export default TaskUpdates
