import React from 'react'
import { TableRow, withStyles } from '@material-ui/core'

const CustomTableRow = withStyles((theme) => ({
	root: {
		boxSizing: 'border-box',

		'& th:first-child, td:first-child': {
			// borderRadius: '10px 0 0 10px',
			borderLeft: `1px solid transparent`,
		},
		'& th:first-child:not(.MuiTableCell-paddingCheckbox), td:first-child:not(.MuiTableCell-paddingCheckbox)': {
			paddingLeft: theme.spacing(2),
		},
		'& td:last-child': {
			// borderRadius: '0 10px 10px 0',
			borderRight: `1px solid transparent`,
		},
		'& td': {
			borderTop: `1px solid transparent`,
			borderBottom: `1px solid transparent`,
		},
	},
	head: {
		background: theme.palette.almostBlack[100],
		borderRadius: '10px',
		'& th:first-child': {
			// borderRadius: '10px 0 0 10px'
		},
		'& th:last-child': {
			// borderRadius: '0 10px 10px 0'
		},
	},
	selected: {
		backgroundColor: `${theme.palette.secondary.main}11 `,
		'& td': {
			// borderTop: `1px solid ${theme.palette.secondary.main}`,
			// borderBottom: `1px solid ${theme.palette.secondary.main}`
		},
		'& th:first-child, & td:first-child': {
			// borderLeft: `1px solid ${theme.palette.secondary.main}`,
		},
		'& td:last-child': {
			// borderRight: `1px solid ${theme.palette.secondary.main}`,
		},
		'&:hover': {
			backgroundColor: `${theme.palette.secondary.main}33 !important`,
			'& td': {
				borderTop: `1px solid ${theme.palette.secondary.main}66`,
				borderBottom: `1px solid ${theme.palette.secondary.main}66`,
			},
			'& th:first-child, & td:first-child': {
				borderLeft: `1px solid ${theme.palette.secondary.main}66`,
			},
			'& td:last-child': {
				borderRight: `1px solid ${theme.palette.secondary.main}66`,
			},
		},
	},
	hover: {
		'&:not($selected):hover': {
			backgroundColor: `${theme.palette.almostBlack[200]}80`,
		},
	},
}))((props) => (
	<TableRow classes={props.classes} {...props}>
		{props.children}
	</TableRow>
))

export default CustomTableRow
