import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Tabs, Tab } from '@material-ui/core'
import _ from 'lodash'

const DsTabs = withStyles({
	root: {
		borderBottom: '1px solid #e8e8e8',
		minHeight: 38,
	},
})(Tabs)

const DsTab = withStyles((theme) => ({
	root: {
		textTransform: 'none',
		minWidth: 72,
		minHeight: 38,
		maxWidth: 'unset',
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: theme.typography.pxToRem(12),
		marginRight: theme.spacing(4),
		letterSpacing: 1,
		'&:hover': {
			color: theme.palette.primary.main,
			opacity: 1,
		},
		'&$selected': {
			color: theme.palette.primary.main,
			fontWeight: theme.typography.fontWeightMedium,
		},
		'&:focus': {
			color: theme.palette.primary.main,
		},
	},
	selected: {},
}))((props) => <Tab disableRipple {...props} />)

TabsHeader.propTypes = {
	tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
	value: PropTypes.number.isRequired,
	onChangeTab: PropTypes.func,
}

function TabsHeader({ tabs, value, onChangeTab }) {
	return (
		<DsTabs variant='scrollable' scrollButtons='auto' value={value} onChange={onChangeTab} aria-label='datasources'>
			{_.map(tabs, (tab, index) => (
				<DsTab key={index} label={tab} />
			))}
		</DsTabs>
	)
}

export default TabsHeader
