import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Typography, TextField, Grid, Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _, { throttle } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoader } from 'services/loader/actions'
import Slide from '@material-ui/core/Slide'
import { getImgUrl, stringToHslColor } from 'utils'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { addTeamMembers, fetchAllTeamMembers } from 'services/teams/actions'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
	dialogContainer: {
		'& .MuiDialog-paper': {
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
	},
	dialogTitle: {
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	dialogContent: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			width: 400,
		},
	},
	dialogFooter: {
		padding: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
	textField: {
		paddingBottom: theme.spacing(1),
	},
	autocomplete: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(2),
		},
	},
	userPic: {
		width: 37,
		height: 37,
		marginRight: theme.spacing(1),
	},
	info: {
		wordBreak: 'break-word',
	},
}))

const AddMembersDialog = ({ open, teamCode, handleClose }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [usersListState, setUsersListState] = useState({
		loading: false,
		usersList: [],
		selectedUsers: [],
	})
	const accessToken = useSelector((state) => _.get(state, 'session.authToken', ''))
	const [isErrOnSubmit, setErrOnSumbit] = useState(false)

	const { usersList, loading, selectedUsers } = usersListState

	const getPicUrl = useCallback(
		(picLoc) => {
			return _.isEmpty(picLoc) ? '' : getImgUrl(accessToken, picLoc, 50)
		},
		[accessToken]
	)

	const fetchUsersList = useMemo(() => {
		return throttle((searchTxt, callback) => {
			setUsersListState((prevState) => ({
				...prevState,
				loading: true,
				usersList: [],
			}))
			if (searchTxt) {
				dispatch(fetchAllTeamMembers({ search_text: searchTxt, limit: 10, offset: 0, tu_code: teamCode, tu_action: 'add' }, callback, () => {}))
			} else {
				dispatch(fetchAllTeamMembers({ limit: 100, offset: 0, tu_action: 'add', tu_code: teamCode }, callback, () => {}))
			}
		}, 500)
	}, [dispatch, teamCode])

	const handleOwnerInpChng = useCallback(
		(e) => {
			fetchUsersList(e.target.value, (resp) => {
				setUsersListState((prevState) => ({
					...prevState,
					loading: false,
					usersList: _.get(resp, 'data.data.team_members', []),
				}))
			})
		},
		[fetchUsersList]
	)

	const handleOwnerFocus = useCallback(
		(e) => {
			fetchUsersList('', (resp) => {
				setUsersListState((prevState) => ({
					...prevState,
					loading: false,
					usersList: _.get(resp, 'data.data.team_members', []),
				}))
			})
		},
		[fetchUsersList]
	)

	const onChangeUserAutoComplete = (e, value) => {
		setUsersListState((prevState) => ({
			...prevState,
			selectedUsers: _.uniqBy(value, (user) => _.get(user, 'user_id')),
		}))
	}
	const onSubmitForm = (selectedUsers) => {
		const successCallback = () => {
			handleClose(null, true)
		}
		const closeErrDialog = () => {
			dispatch(hideLoader())
		}
		const selectedUserIds = _.map(selectedUsers, (user) => _.get(user, 'user_id'))
		dispatch(addTeamMembers(teamCode, selectedUserIds, successCallback, closeErrDialog))
	}

	const onClickActionBtn = () => {
		const isUsersEmpty = _.isEmpty(selectedUsers)
		if (!isUsersEmpty) onSubmitForm(selectedUsers)

		setErrOnSumbit(isUsersEmpty)
	}

	//reset exsting details on form dialog close event
	useEffect(() => {
		if (!open) setUsersListState({ loading: false, usersList: [], selectedUsers: [] })
	}, [open])
	const showErrMsg = isErrOnSubmit && _.isEmpty(selectedUsers)

	return (
		<Dialog
			classes={{
				paper: classes.dialog,
			}}
			TransitionComponent={Transition}
			maxWidth={'md'}
			open={open}
			onClose={handleClose}
			aria-labelledby='role-form-title'
			className={classes.dialogContainer}
		>
			<DialogTitle className={classes.dialogTitle} disableTypography={true} id='role-dialog-title'>
				<Typography variant='h6'>Add Team Members</Typography>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<Autocomplete
					id='userslist'
					multiple
					noOptionsText='No users found'
					loading={loading}
					loadingText='Finding users...'
					getOptionLabel={(option) => (typeof option === 'string' ? option : option?.full_name)}
					getOptionSelected={(option, value) => option.user_id === value.user_id}
					filterOptions={(x) => x}
					options={usersList}
					autoComplete
					includeInputInList
					filterSelectedOptions
					size={'small'}
					renderOption={(option) => {
						const picUrl = getPicUrl(option.profile_pic_url)
						return (
							<Grid key={option.user_id} container alignItems='center'>
								<Grid item>
									<Avatar
										style={{ backgroundColor: stringToHslColor(`${option?.first_name} ${option?.last_name}`) }}
										src={picUrl}
										size='small'
										className={classes.userPic}
									>
										{_.upperCase(option?.first_name?.substring(0, 1))}
									</Avatar>
								</Grid>
								<Grid item xs>
									<Typography variant='body2'>{option.full_name}</Typography>
									<Typography variant='body2' color='textSecondary'>
										{option.email}
									</Typography>
								</Grid>
							</Grid>
						)
					}}
					onChange={onChangeUserAutoComplete}
					value={selectedUsers}
					className={classes.autocomplete}
					onInputChange={handleOwnerInpChng}
					onFocus={handleOwnerFocus}
					renderInput={(params) => (
						<TextField
							{...params}
							dense='true'
							error={showErrMsg}
							helperText={showErrMsg && 'No users selected'}
							variant='outlined'
							label={'Select Users to add'}
						/>
					)}
				/>
				{/* <Typography className={classes.info} variant="caption" component="div" color="textSecondary">
                Note: Whoever is not assigned to any team unit yet, will be shown in the list otherwise won't.
            </Typography> */}
			</DialogContent>
			<DialogActions className={classes.dialogFooter}>
				<Button variant='outlined' onClick={handleClose} color='primary'>
					Cancel
				</Button>
				<Button variant='contained' onClick={onClickActionBtn} color='primary' disableElevation>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	)
}

AddMembersDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	teamCode: PropTypes.string.isRequired,
}

export default AddMembersDialog
