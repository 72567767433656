import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Icon, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'

const useStyles = makeStyles(() => ({
	root: {
		paddingTop: '24px',
	},
	infoText: {
		letterSpacing: '2px',
	},
	subTitle: {
		color: '#4caf50',
		letterSpacing: '2px',
	},
}))

const InfoCard = ({ icon, title, subTitle, type }) => {
	const classes = useStyles()

	return (
		<Grid container justifyContent='center'>
			<Grid className={classes.root} item xs={12} align='center'>
				<Icon fontSize='large' color={type}>
					{icon}
				</Icon>
				<Typography component='div' variant='h6' className={classes.infoText}>
					{' '}
					{title}
				</Typography>
				{!_.isEmpty(subTitle) && (
					<Typography variant='caption' className={classes.subTitle}>
						{subTitle}
					</Typography>
				)}
			</Grid>
		</Grid>
	)
}

InfoCard.propTypes = {
	icon: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	type: PropTypes.string,
	subTitle: PropTypes.string,
}

export default InfoCard
