import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, ButtonBase, CircularProgress, Fab, Grid, Icon, makeStyles, Typography } from '@material-ui/core'
import LogsDialog from './LogsDialog'
import { useMemo } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { msToDurationTxt } from 'utils'
import classnames from 'classnames'

TimeLogger.propTypes = {
	loading: PropTypes.bool,
	value: PropTypes.arrayOf(
		PropTypes.shape({
			time_log_id: PropTypes.string,
			from: PropTypes.string,
			to: PropTypes.string,
			is_manual: PropTypes.bool,
			created_by: PropTypes.shape({
				first_name: PropTypes.string,
				last_name: PropTypes.string,
				profile_img: PropTypes.string,
				user_id: PropTypes.string,
			}),
		})
	),
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	estimatedTime: PropTypes.number,
	onStartTimer: PropTypes.func.isRequired,
	onStopTimer: PropTypes.func.isRequired,
	onAddTimeLog: PropTypes.func.isRequired,
	onUpdateTimeLog: PropTypes.func.isRequired,
	onRemoveTimeLog: PropTypes.func.isRequired,
	onClearTimeLogs: PropTypes.func.isRequired,
}

const useStyles = makeStyles((theme) => ({
	timerBtn: {
		boxShadow: 'none',
		// marginRight: theme.spacing(1),
		width: 36,
		height: 36,
	},
	timerCount: {
		position: 'relative',
		border: `1px solid ${theme.palette.primary.main}3d`,
		borderRadius: theme.spacing(0.5),
		paddingRight: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		minWidth: '80px',
		overflow: 'hidden',
	},
	filledBg: {
		position: 'absolute',
		background: `${theme.palette.primary.main}3d`,
		height: '100%',
		width: ({ progress }) => progress || '0%',
		top: 0,
		left: 0,
		boxSizing: 'border-box',
		zIndex: '-1',
	},
	timerBtnWrapper: {
		padding: '6px',
		position: 'relative',
	},
	fabProgress: {
		position: 'absolute',
		top: 5,
		left: 5,
		zIndex: 1,
	},
	loader: {
		marginTop: '-4px',
	},
}))

function TimeLogger({
	isLoading,
	value,
	estimatedTime = 0,
	onStartTimer,
	onStopTimer,
	onAddTimeLog,
	onUpdateTimeLog,
	onRemoveTimeLog,
	onClearTimeLogs,
	disabled,
	isFrom,
}) {
	const [openLogDialog, setOpenLogDialog] = useState(false)

	const isTimerRun = useMemo(
		() =>
			_.find(value, (log) => {
				return _.isEmpty(log?.to)
			}),
		[value]
	)

	const [totalTimeSpent, setTotalTimeSpent] = useState(0)

	useEffect(() => {
		const totalSpent = _.reduce(
			value,
			(total, log) => {
				return total + (moment(_.get(log, 'to') || new Date()) - moment(_.get(log, 'from')))
			},
			0
		)
		setTotalTimeSpent(totalSpent < 0 ? 0 : totalSpent)
	}, [value])

	useEffect(() => {
		const logTimer = isTimerRun
			? setInterval(() => {
					setTotalTimeSpent((totalRunTime) => totalRunTime + 1000)
			  }, 1000)
			: null
		return () => {
			logTimer && clearInterval(logTimer)
		}
	}, [isTimerRun])

	const durationText = useMemo(() => msToDurationTxt(totalTimeSpent, true), [totalTimeSpent])

	const timerProgress = useMemo(() => {
		//covert estimated time type to number and convert minutes to milliseconds
		const estTimeInMs = !_.isEmpty(estimatedTime) ? _.toNumber(estimatedTime) * 60 * 1000 : 0
		return estTimeInMs > 0 ? `${(totalTimeSpent / estTimeInMs) * 100}%` : '100%'
	}, [totalTimeSpent, estimatedTime])

	const classes = useStyles({ progress: timerProgress })

	const onClickTimeText = () => {
		setOpenLogDialog(true)
	}

	const closeLogTimeDialog = () => {
		setOpenLogDialog(false)
	}

	return (
		<Grid container alignItems='center' justifyContent='center'>
			<Grid item>
				<Box className={classes.timerBtnWrapper}>
					<Fab
						onClick={isTimerRun ? onStopTimer : onStartTimer}
						size='small'
						disabled={isLoading || disabled}
						aria-label='save'
						color='primary'
						className={classes.timerBtn}
					>
						<Icon fontSize='small'>{isTimerRun ? 'pause' : 'play_arrow'}</Icon>
					</Fab>
					{isLoading && <CircularProgress size={38} className={classnames(classes.fabProgress, _.isEqual(isFrom, 'myTasks') && classes.loader)} />}
				</Box>
			</Grid>
			<Grid item>
				<ButtonBase disabled={isLoading} onClick={!_.isEqual(isFrom, 'myTasks') && onClickTimeText}>
					<Box className={classes.timerCount} padding={0.5}>
						<div className={classes.filledBg}></div>
						<Typography className={classes.durationTxt} variant='body2'>
							{durationText}
						</Typography>
					</Box>
				</ButtonBase>
				<LogsDialog
					open={openLogDialog}
					handleClose={closeLogTimeDialog}
					logs={value}
					isLoading={isLoading}
					onAddTimeLog={onAddTimeLog}
					onUpdateTimeLog={onUpdateTimeLog}
					onRemoveTimeLog={onRemoveTimeLog}
					onClearTimeLogs={onClearTimeLogs}
				/>
			</Grid>
		</Grid>
	)
}

export default TimeLogger
