import React, { useCallback, useEffect, useState } from 'react'
import {
	Dialog,
	DialogTitle,
	DialogActions,
	Typography,
	DialogContent,
	Slide,
	Grid,
	useMediaQuery,
	useTheme,
	TextField,
	Chip,
	Avatar,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

// import { Formik } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import _ from 'lodash'
import { getErrMsg, getImgUrl, stringToHslColor } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import MembersTable from 'components/MembersTable'
import GlassApi from '../../services/glass/api'
import { hideLoader, showLoader } from 'services/loader/actions'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import PropTypes from 'prop-types'
import { addGlassMembers, removeGlassMember, updateGlassMember } from 'services/glass/actions'
import update from 'immutability-helper'

const useStyles = makeStyles((theme) => ({
	dialog: {
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
			top: 20,
		},
	},
	addButton: {
		height: '100%',
	},
	dialogTitle: {
		fontWeight: 600,
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
		'& p': {
			fontSize: '13px',
		},
	},

	dialogFooter: {
		padding: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		fontWeight: 600,
		color: theme.palette.almostBlack[900],
	},
	addBtn: {
		[theme.breakpoints.down('xs')]: {
			textAlign: 'right',
		},
	},
	formSec: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		'& .MuiAutocomplete-root': {
			paddingRight: theme.spacing(1),
			'& .MuiFormControl-marginDense': {
				marginTop: '4px',
			},
		},
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
			alignItems: 'flex-end',
			'& .MuiAutocomplete-root': {
				paddingRight: theme.spacing(0),
			},
		},
	},
	userPic: {
		width: 37,
		height: 37,
		marginRight: theme.spacing(1),
	},
	dialogContent: {
		minHeight: '200px',
	},
	membersTblWrapper: {
		padding: theme.spacing(1.5, 0),
	},
}))

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const MemberAutoComplete = ({
	classes,
	onMemberSelect,
	onMembersSearch,
	selectedMembers,
	membersOption,
	authToken,
	isMobileDevice,
	onClickCreateBtn,
	loading,
	membersList,
}) => {
	const getPicUrl = (profilePic) => getImgUrl(authToken, profilePic, 50)
	const userId = useSelector((state) => _.get(state, 'session.userId'))

	const optionsList = membersOption.filter((item) => !membersList.some((member) => member.user_id === item.user_id) && userId !== item.user_id)
	return (
		<form className={classes.formSec}>
			<Autocomplete
				multiple
				fullWidth
				size='small'
				id='tags-outlined1'
				placeholder='please select members'
				noOptionsText='No users found'
				disableCloseOnSelect
				onChange={onMemberSelect}
				loading={loading}
				onInputChange={onMembersSearch}
				value={selectedMembers}
				options={optionsList}
				getOptionLabel={(option) => (option ? option.first_name : '')}
				getOptionSelected={(option, value) => option.user_id === value.user_id}
				filterSelectedOptions
				filterOptions={(options, state) => {
					return options
				}}
				renderTags={(tagValue, getTagProps) =>
					tagValue.map((option, index) => (
						<Chip size='small' label={_.join([option?.first_name, option?.last_name], ' ')} {...getTagProps({ index })} />
					))
				}
				renderInput={(params) => <TextField {...params} fullWidth variant='outlined' margin='dense' id='searchableFields' name='searchableFields' />}
				renderOption={(option) => {
					const picUrl = getPicUrl(option.profile_pic_url)
					const full_name = _.join([_.get(option, 'first_name', ''), _.get(option, 'last_name', '')], ' ')
					return (
						<Grid key={option.user_id} container alignItems='center'>
							<Grid item>
								<Avatar
									style={{ backgroundColor: stringToHslColor(`${option.first_name} ${option.last_name}`) }}
									src={picUrl}
									size='small'
									className={classes.userPic}
								>
									{_.upperCase(full_name.substring(0, 1))}
								</Avatar>
							</Grid>
							<Grid item xs>
								<Typography variant='body2'>{full_name}</Typography>
								<Typography variant='body2' color='textSecondary'>
									{option.email}
								</Typography>
							</Grid>
						</Grid>
					)
				}}
			/>
			<div className={classes.addBtn}>
				<Button
					disabled={_.isEmpty(selectedMembers)}
					variant='contained'
					size={isMobileDevice ? 'small' : 'medium'}
					onClick={onClickCreateBtn}
					color='primary'
					disableElevation
					fullWidth
				>
					Add
				</Button>
			</div>
		</form>
	)
}

MemberAutoComplete.propTypes = {
	classes: PropTypes.object.isRequired,
	onMemberSelect: PropTypes.func,
	onMembersSearch: PropTypes.func,
	selectedMembers: PropTypes.array,
	membersOption: PropTypes.array,
	authToken: PropTypes.string,
	isMobileDevice: PropTypes.bool,
	onClickCreateBtn: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	membersList: PropTypes.array,
	isUpdateRedux: PropTypes.bool,
}

const AddMemberDialog = (props) => {
	const { open, handleClose, sessionId, glassCode, isUpdateRedux = false } = props

	const [selectedMembers, setSelectedMembers] = useState([])
	const accessToken = useSelector((state) => _.get(state, 'session.authToken', ''))
	const classes = useStyles()
	const theme = useTheme()
	const [searchText, setSearchText] = useState('')
	const [membersOption, setMembersOption] = useState([])
	const [fetchingList, setFetchingList] = useState(false)
	const [membersList, setMembersList] = useState([])
	const dispatch = useDispatch()

	useEffect(() => {
		if (!open) {
			setSelectedMembers([])
			setMembersList([])
		}
	}, [open])

	const usersListCall = useCallback(() => {
		setFetchingList(true)
		GlassApi.fetchAddMembersList(glassCode, searchText)
			.then((res) => {
				setFetchingList(false)
				setMembersOption(_.get(res, 'data.data.users', []))
			})
			.catch((err) => {
				setFetchingList(false)
				setMembersOption([])
			})
	}, [searchText, glassCode])

	const onMembersSearch = useCallback(
		_.debounce((e, value) => {
			setSearchText(value)
		}, 300),
		[]
	)

	useEffect(() => {
		if (open) {
			usersListCall(glassCode, searchText)
		}
	}, [searchText, glassCode, usersListCall, open])

	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'))

	const onMemberSelect = (e, newValue) => {
		setSelectedMembers(newValue)
	}

	const addMembersOnRedux = (newMembers) => {
		if (isUpdateRedux) dispatch(addGlassMembers(newMembers))
	}

	const updateMemberIsUpdateFlag = (memberIdx, isUpdating) => {
		setMembersList(update(membersList, { [memberIdx]: { $set: { ...membersList[memberIdx], isUpdating } } }))
	}

	const onChangeMemberRole = (userId, role) => {
		const memberIdx = _.findIndex(membersList, { user_id: userId })
		updateMemberIsUpdateFlag(memberIdx, true)
		dispatch(
			updateGlassMember(
				sessionId,
				glassCode,
				userId,
				role,
				isUpdateRedux,
				(resp) => {
					//successcallback
					setMembersList(update(membersList, { [memberIdx]: { $set: _.get(resp, 'data.data.glass_members[0]') } }))
				},
				(err) => {
					//errorcallback
					updateMemberIsUpdateFlag(memberIdx, false)
					dispatch(showSnackbarWithTimeout(getErrMsg(err), 2500))
				}
			)
		)
	}

	const onRemoveMember = (userId) => {
		const memberIdx = _.findIndex(membersList, { user_id: userId })
		updateMemberIsUpdateFlag(memberIdx, true)
		dispatch(
			removeGlassMember(
				sessionId,
				glassCode,
				userId,
				isUpdateRedux,
				(resp) => {
					//successcallback
					setMembersList(update(membersList, { $splice: [[memberIdx, 1]] }))
				},
				(err) => {
					updateMemberIsUpdateFlag(memberIdx, false)
					dispatch(showSnackbarWithTimeout(getErrMsg(err), 2500))
				}
			)
		)
	}

	const onClickCreateBtn = () => {
		dispatch(showLoader('Please wait updating members...'))
		let ids = _.map(selectedMembers, (member) => _.get(member, 'user_id', ''))
		GlassApi.addGlassMembers(sessionId, glassCode, ids)
			.then((res) => {
				setSelectedMembers([])
				dispatch(hideLoader())
				const newMembers = _.get(res, 'data.data.glass_members', [])
				setMembersList((prevMemList) => [...prevMemList, ...newMembers])
				addMembersOnRedux(newMembers)
			})
			.catch((err) => {
				setSelectedMembers([])
				dispatch(hideLoader())
				dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
			})
	}

	const onClickCancel = () => {
		handleClose()
	}

	return (
		<Dialog
			classes={{
				paper: classes.dialog,
			}}
			TransitionComponent={Transition}
			fullScreen={fullScreen}
			fullWidth={true}
			maxWidth='sm'
			open={open}
			onClose={onClickCancel}
			aria-labelledby='create-glass'
		>
			<DialogTitle className={classes.dialogTitle} id='create-member-title'>
				Add Members
				<Typography variant='subtitle1' component='p'>
					Start typing a name, Email address to add to your team. You can also add people as guests
				</Typography>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<MemberAutoComplete
					classes={classes}
					loading={fetchingList}
					onMemberSelect={onMemberSelect}
					onMembersSearch={onMembersSearch}
					selectedMembers={selectedMembers}
					membersOption={membersOption}
					authToken={accessToken}
					isMobileDevice={isMobileDevice}
					onClickCreateBtn={onClickCreateBtn}
					membersList={membersList}
				/>
				<div className={classes.membersTblWrapper}>
					{membersList.length > 0 ? (
						<MembersTable isOwner={true} data={membersList} onChangeMemberRole={onChangeMemberRole} onRemoveMember={onRemoveMember} />
					) : (
						<></>
					)}
				</div>
			</DialogContent>
			<DialogActions className={classes.dialogFooter}>
				<Button variant='outlined' size={fullScreen ? 'small' : 'medium'} onClick={handleClose} color='primary'>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	)
}

AddMemberDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func,
	glassCode: PropTypes.string,
	sessionId: PropTypes.string,
}

export default AddMemberDialog
