import { Button, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
	button: {
		padding: theme.spacing(1, 3),
		textTransform: 'capitalize',
		margin: theme.spacing(0.5),
	},
}))

const NewLaneSection = ({ t, onClick }) => {
	const classes = useStyles()
	return (
		<Button onClick={onClick} className={classes.button} fullWidth disableElevation>
			<span className={classes.plus}>+</span>Add Pane
		</Button>
	)
}

export default NewLaneSection
