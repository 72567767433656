import { ACTION_TYPES } from '../../../../constants'
import _ from 'lodash'
import Axios from 'axios'

const getDefaultState = () => ({
	isUpdating: false,
	isError: true,
	errMsg: '',
	resp: {},
})

const updateDashboard = (state = getDefaultState(), action) => {
	switch (action.type) {
		case ACTION_TYPES.UPDATE_DASHBOARD_DATA_PENDING:
			return {
				...state,
				isUpdating: true,
				isError: false,
				errMsg: '',
				resp: {},
			}
		case ACTION_TYPES.UPDATE_DASHBOARD_DATA_FULFILLED:
			return {
				...state,
				isUpdating: false,
				isError: false,
				errMsg: '',
				resp: _.get(action, 'payload.data.data', {}),
			}
		case ACTION_TYPES.UPDATE_DASHBOARD_DATA_REJECTED:
			const resp = _.get(action, 'payload')
			if (Axios.isCancel(resp) || JSON.stringify(resp) === '{}') return { ...state }
			else
				return {
					...state,
					isUpdating: false,
					isError: true,
					errMsg: _.get(resp, 'response.data.message', 'Something went wrong on server'),
					resp: {},
				}
		default:
			return state
	}
}

export default updateDashboard
