import React, { useState } from 'react'
import { Grid, TextField, Typography, Switch, FormControlLabel, RadioGroup, Radio, InputAdornment, IconButton, Icon, Button } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from 'formik'
import classnames from 'classnames'
import File from 'components/File'
import BackupIcon from '@material-ui/icons/Backup'
import InputField from '../../../../../components/TextField'
import SelectBox from '../../../../../components/SelectBox'
import { useDispatch } from 'react-redux'
import { hideAdvaceSnackbar, showAdvanceSnackbar } from 'services/snackbarAdvance/actions'
import { DateTimePicker, DatePicker } from '@material-ui/pickers'
import { convertGmtToLocalTime } from 'utils'
import { MULTI_USER_TEMPLATE } from '../../../../../constants'
import FileSaver from 'file-saver'

const useStyles = makeStyles((theme) => ({
	profilePic: {
		width: 170,
		height: 170,
		[theme.breakpoints.down('xs')]: {
			width: 140,
			height: 140,
			margin: '0 auto',
		},
	},
	flexRootResponsive: {
		display: 'flex',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
	},
	basicHeaderRight: {
		flex: 1,
		paddingLeft: theme.spacing(2),
		marginTop: theme.spacing(0.8),
		[theme.breakpoints.down('xs')]: {
			flex: 'unset',
			paddingLeft: 0,
		},
	},
	basicHeaderLeft: {
		maxWidth: 170,
		[theme.breakpoints.down('xs')]: {
			flex: 1,
			maxWidth: '100%',
			paddingBottom: theme.spacing(2),
			textAlign: 'center',
		},
	},
	textField: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
	},
	email: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
		'& .MuiInputBase-input': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		marginTop: '17.6px',
	},
	profileURL: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
		'& .MuiInputBase-input': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		// marginTop: '10px',
	},
	parallelInputRoot: {
		width: 'calc(50% - 8px)',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	parallelInputEnd: {
		marginLeft: 16,
		[theme.breakpoints.down('xs')]: {
			marginLeft: 0,
		},
	},
	basicHeader: {
		marginBottom: theme.spacing(0.5),
		[theme.breakpoints.down('xs')]: {
			marginBottom: 0,
		},
	},
	pwdTitle: {
		textTransform: 'none',
	},
	statusIndicator: {
		marginLeft: theme.spacing(2),
		borderRadius: '50%',
		width: '10px',
		height: '10px',
		display: 'inline-block',
		marginRight: theme.spacing(1),
	},
	fileInput: {
		display: 'none',
	},
	uploadFile: {
		'& .MuiFormControl-root': {
			minWidth: '50%',
		},
	},
	downloadTemp: {
		margin: '6px 0px',
	},
	dateInput: {
		// marginTop: '0px',
		// marginBottom: '0px',
		marginBottom: theme.spacing(2.2),
		'& .MuiInputBase-root.Mui-disabled': {
			color: '#171725',
		},
		'& .MuiFormLabel-root.Mui-disabled': {
			color: 'inherit',
		},
		'& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
	},
}))

const gender = [
	{
		id: 'Male',
		name: 'Male',
	},
	{
		id: 'Female',
		name: 'Female',
	},
	{
		id: 'Others',
		name: 'Others',
	},
]

const BasicDetails = ({ isEditMode, isCreateMode, isViewMode, formik }) => {
	const classes = useStyles()

	const reduxDispatch = useDispatch()
	const hiddenFileInput = React.useRef(null)
	const handleClick = (event) => {
		hiddenFileInput.current.click()
	}

	const [type, setType] = useState('single_user')

	const handleChangeType = (event) => {
		setType(event.target.value)
		formik.setFieldValue('type', event.target.value)
	}

	const handleChange = (e, form) => {
		let imgArray = Array.from(e.target.files)
		let result = _.find(imgArray, function (file) {
			if (_.get(file, 'size', 0) >= 1073741824) return true
		})
		let isFileExceeded = result ? true : false
		if (isFileExceeded) {
			reduxDispatch(
				showAdvanceSnackbar({
					msg: 'File size is too large. maximum file size is 1 gb.',
					severity: 'error',
				})
			)
			setTimeout(() => {
				reduxDispatch(hideAdvaceSnackbar())
			}, 2000)
			e.target.value = ''
		} else {
			form.setFieldValue('multiple_users', e.target.files[0])
			// setFileUploaded(e.target.files[0])
			e.target.value = ''
		}
	}

	const showFieldValue = (value) => {
		return isViewMode ? (_.isEmpty(_.trim(value)) ? '-' : value) : value
	}

	const onClickTemplete = () => {
		let sliceSize = 1024
		let byteCharacters = atob(MULTI_USER_TEMPLATE)
		let bytesLength = byteCharacters.length
		let slicesCount = Math.ceil(bytesLength / sliceSize)
		let byteArrays = new Array(slicesCount)
		for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
			let begin = sliceIndex * sliceSize
			let end = Math.min(begin + sliceSize, bytesLength)
			let bytes = new Array(end - begin)
			for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
				bytes[i] = byteCharacters[offset].charCodeAt(0)
			}
			byteArrays[sliceIndex] = new Uint8Array(bytes)
		}
		FileSaver.saveAs(new Blob(byteArrays, { type: 'application/vnd.ms-excel' }), 'template.xlsx')
	}

	return (
		<Grid container>
			{isCreateMode && (
				<Grid item xs={12} style={{ paddingBottom: '12px' }}>
					<div>
						<RadioGroup
							row
							aria-label='sticky_permission'
							name='sticky_permission'
							className={classes.radioLabel}
							value={formik?.values?.type || 'single_user'}
							onChange={handleChangeType}
						>
							<FormControlLabel value='single_user' control={<Radio size='small' />} label='Single User' />
							<FormControlLabel value='multiple_users' control={<Radio size='small' />} label='Multiple Users' />
						</RadioGroup>
					</div>
				</Grid>
			)}
			{_.isEqual(formik?.values?.type, 'multiple_users') && isCreateMode ? (
				<>
					<Grid item xs={12} className={classes.uploadFile}>
						<Button variant='contained' onClick={onClickTemplete} size={'small'} color='primary' className={classes.downloadTemp} disableElevation>
							{'Download Template'}
						</Button>
					</Grid>
					<Grid item xs={12} className={classes.uploadFile}>
						<Field
							type='text'
							name='multiple_users'
							render={({ field, form }) => {
								return (
									<InputField
										placeholder={'Upload file*'}
										field={field}
										form={form}
										// disabled={isViewMode}
										// value={form.values.attachment}
										fieldValue={form?.values?.dc_config?.file_name || form?.values?.multiple_users?.name || ''}
										disabled
										margin='dense'
										InputProps={{
											endAdornment: (
												<div>
													<input
														accept={('.csv', '.xls', '.xlsx')}
														type='file'
														className={classes.fileInput}
														ref={hiddenFileInput}
														onChange={(e) => {
															handleChange(e, form)
														}}
													/>
													<InputAdornment>
														<IconButton onClick={handleClick} color='primary' disabled={isViewMode}>
															<BackupIcon />
														</IconButton>
													</InputAdornment>
												</div>
											),
										}}
									/>
								)
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Field type='text' name='is_active'>
							{({ field }) => (
								<FormControlLabel
									value='end'
									control={
										<Switch
											color='primary'
											onChange={(event) =>
												field.onChange({
													target: {
														name: 'is_active',
														value: event.target.checked,
													},
												})
											}
											checked={field.value}
											readOnly={isViewMode}
										/>
									}
									label={'Active'}
									labelPlacement='end'
								/>
							)}
						</Field>
						{isCreateMode && <Alert severity={'info'}>{'The login credentials will be sent to the user email address.'}</Alert>}
					</Grid>
				</>
			) : (
				<>
					<Grid item xs={12} className={classnames(classes.flexRootResponsive, classes.basicHeader)}>
						<div className={classes.basicHeaderLeft}>
							<Field type='text' name='profile_picture'>
								{({ field, form }) => (
									<File
										disabled={isViewMode}
										value={form.values.profile_picture}
										label='Active'
										field={field}
										form={form}
										acceptsExts={['jpg', 'png', 'jpeg']}
										resolution={[1024, 1024]}
										placeholder={''}
										imgRatioClass={classes.profilePic}
									/>
								)}
							</Field>
						</div>
						<div className={classes.basicHeaderRight}>
							<div className={classes.flexRootResponsive}>
								<div className={classes.parallelInputRoot}>
									<Field type='text' name='first_name'>
										{({ field, form }) => (
											<TextField
												variant='outlined'
												margin='none'
												size='small'
												fullWidth
												name={field.name}
												label={'First Name*'}
												type='text'
												autoComplete='off'
												onChange={field.onChange}
												onBlur={field.onBlur}
												value={field.value || ''}
												disabled={isViewMode}
												className={classes.textField}
												helperText={form.touched[field.name] && form.errors[field.name]}
												error={Boolean(form.errors[field.name] && form.touched[field.name])}
											/>
										)}
									</Field>
								</div>
								<div className={classnames(classes.parallelInputRoot, classes.parallelInputEnd)}>
									<Field type='text' name='last_name'>
										{({ field, form }) => (
											<TextField
												variant='outlined'
												margin='none'
												size='small'
												fullWidth
												name={field.name}
												label={'Last Name'}
												type='text'
												autoComplete='off'
												onChange={field.onChange}
												onBlur={field.onBlur}
												value={showFieldValue(field.value) || ''}
												disabled={isViewMode}
												className={classes.textField}
												helperText={form.touched[field.name] && form.errors[field.name]}
												error={Boolean(form.errors[field.name] && form.touched[field.name])}
											/>
										)}
									</Field>
								</div>
							</div>
							<div className={classes.flexRootResponsive}>
								<div className={classes.parallelInputRoot}>
									<Field type='text' name='people_id'>
										{({ field, form }) => (
											<TextField
												variant='outlined'
												margin='none'
												size='small'
												fullWidth
												name={field.name}
												label={'People ID*'}
												type='text'
												autoComplete='off'
												onChange={field.onChange}
												onBlur={field.onBlur}
												value={field.value || ''}
												disabled={isViewMode}
												className={classes.textField}
												helperText={form.touched[field.name] && form.errors[field.name]}
												error={Boolean(form.errors[field.name] && form.touched[field.name])}
											/>
										)}
									</Field>
								</div>
								<div className={classnames(classes.parallelInputRoot, classes.parallelInputEnd)}>
									<Field type='text' name='phone'>
										{({ field, form }) => (
											<TextField
												variant='outlined'
												margin='none'
												size='small'
												fullWidth
												name={field.name}
												label={'Phone'}
												type='text'
												autoComplete='off'
												onChange={field.onChange}
												onBlur={field.onBlur}
												value={showFieldValue(field.value) || ''}
												disabled={isViewMode}
												className={classes.textField}
												helperText={form.touched[field.name] && form.errors[field.name]}
												error={Boolean(form.errors[field.name] && form.touched[field.name])}
											/>
										)}
									</Field>
								</div>
							</div>
							<div className={classes.flexRootResponsive}>
								<div className={classes.parallelInputRoot}>
									<Field type='text' name='doj'>
										{({ field, form }) => (
											<DatePicker
												name={field.name}
												id={field.name}
												className={classes.dateInput}
												autoOk
												size='small'
												variant='inline'
												inputVariant='outlined'
												label='Date of Joining'
												format='DD MMM YYYY'
												value={field.value || null}
												fullWidth
												minDateMessage={<></>}
												error={Boolean(form.errors[field.name] && form.touched[field.name])}
												helperText={form.touched[field.name] && form.errors[field.name]}
												margin='none'
												clearable
												// autoFocus
												disabled={isViewMode}
												InputProps={{
													endAdornment: (
														<React.Fragment>
															{!isViewMode ? (
																<InputAdornment position='end'>
																	<IconButton size='small'>
																		<Icon>date_range</Icon>
																	</IconButton>
																</InputAdornment>
															) : null}
														</React.Fragment>
													),
												}}
												onChange={(date) => {
													formik.setFieldValue('doj', date)
												}}
											/>
										)}
									</Field>
								</div>
								<div className={classnames(classes.parallelInputRoot, classes.parallelInputEnd)}>
									<Field type='text' name='gender'>
										{({ field, form }) => (
											<SelectBox
												label='Gender'
												options={gender}
												disabled={isViewMode}
												value={field.value || ''}
												margin='none'
												inputClass={classes.textField}
												freeSolo={isViewMode ? true : false}
												helperText={form.touched[field.name] && form.errors[field.name]}
												isError={Boolean(form.errors[field.name] && form.touched[field.name])}
												onChange={(value, e) => {
													form.handleChange({
														target: {
															name: 'gender',
															value,
														},
													})
												}}
											/>
										)}
									</Field>
								</div>
							</div>
							{/* <Field type='text' name='phone'>
						{({ field, form }) => (
							<TextField
								variant='outlined'
								margin='none'
								size='small'
								fullWidth
								name={field.name}
								label={'Phone'}
								type='text'
								autoComplete='off'
								onChange={field.onChange}
								onBlur={field.onBlur}
								value={showFieldValue(field.value) || ''}
								disabled={isViewMode}
								className={classes.textField}
								helperText={form.touched[field.name] && form.errors[field.name]}
								error={Boolean(form.errors[field.name] && form.touched[field.name])}
							/>
						)}
					</Field> */}
						</div>
					</Grid>
					<Grid item xs={12}>
						<Field type='text' name='email'>
							{({ field, form }) => (
								<TextField
									variant='outlined'
									margin='none'
									size='small'
									fullWidth
									name={field.name}
									label={'Email address*'}
									type='text'
									autoComplete='off'
									onChange={field.onChange}
									onBlur={field.onBlur}
									value={field.value || ''}
									disabled={isViewMode || _.get(form, 'values.is_email_verified', false)}
									className={classes.email}
									helperText={form.touched[field.name] && form.errors[field.name]}
									error={Boolean(form.errors[field.name] && form.touched[field.name])}
								/>
							)}
						</Field>
					</Grid>
					<Grid item xs={12}>
						<Field type='text' name='profile_url'>
							{({ field, form }) => (
								<TextField
									variant='outlined'
									margin='none'
									size='small'
									fullWidth
									name={field.name}
									label={'Profile URL'}
									type='text'
									autoComplete='off'
									onChange={field.onChange}
									onBlur={field.onBlur}
									value={showFieldValue(field.value) || ''}
									disabled={isViewMode}
									className={classes.profileURL}
									helperText={form.touched[field.name] && form.errors[field.name]}
									error={Boolean(form.errors[field.name] && form.touched[field.name])}
								/>
							)}
						</Field>
					</Grid>
					<Grid item xs={12}>
						{!isCreateMode && isEditMode && _.get(formik, 'values.is_email_verified', false) && (
							<>
								<Typography variant='overline' component='div' gutterBottom className={classes.pwdTitle}>
									Change Password
								</Typography>
								<Field type='text' name='password'>
									{({ field, form }) => (
										<TextField
											variant='outlined'
											margin='none'
											size='small'
											fullWidth
											name={field.name}
											label={'Password'}
											onChange={field.onChange}
											onBlur={field.onBlur}
											type='password'
											value={field.value}
											disabled={isViewMode}
											className={classes.textField}
											helperText={form.touched[field.name] && form.errors[field.name]}
											autoComplete='new-password'
											error={Boolean(form.errors[field.name] && form.touched[field.name])}
										/>
									)}
								</Field>
								<Field type='text' name='retype_password'>
									{({ field, form }) => (
										<TextField
											variant='outlined'
											margin='none'
											size='small'
											fullWidth
											name={field.name}
											label={'Re-type Password'}
											type='password'
											autoComplete='new-password'
											onChange={field.onChange}
											onBlur={field.onBlur}
											value={field.value}
											disabled={isViewMode}
											className={classes.textField}
											helperText={form.touched[field.name] && form.errors[field.name]}
											error={Boolean(form.errors[field.name] && form.touched[field.name])}
										/>
									)}
								</Field>
							</>
						)}

						{!isCreateMode && (
							<Typography style={{ display: 'flex', padding: isViewMode ? '0px 0px 16px 0px' : '5px 0px 7px 0px', marginLeft: isViewMode && '16px' }}>
								<div>Creation Type:</div>&nbsp;&nbsp;
								<div>{_.isEqual(formik?.values?.type, 'multiple_users') ? 'Multiple' : 'Individual'}</div>
							</Typography>
						)}

						<Field type='text' name='is_active'>
							{({ field }) =>
								isEditMode || isCreateMode ? (
									<FormControlLabel
										value='end'
										control={
											<Switch
												color='primary'
												onChange={(event) =>
													field.onChange({
														target: {
															name: 'is_active',
															value: event.target.checked,
														},
													})
												}
												checked={field.value}
												readOnly={isViewMode}
											/>
										}
										label={'Active'}
										labelPlacement='end'
									/>
								) : (
									<Typography>
										<span
											style={{
												backgroundColor: field.value ? '#82c43c' : '#fc5a5a',
											}}
											className={classes.statusIndicator}
										></span>
										{field.value ? 'Active' : 'Inactive'}
									</Typography>
								)
							}
						</Field>
						{(isCreateMode || (isEditMode && !_.get(formik, 'values.is_email_verified', false))) && (
							<Alert severity={isCreateMode || _.get(formik, 'values.is_email_verified', false) ? 'info' : 'warning'}>
								{!isCreateMode
									? 'Email address is not verified yet. Please check the login credentials in your registered email address.'
									: 'The login credentials will be sent to the user email address.'}
							</Alert>
						)}
					</Grid>
				</>
			)}
		</Grid>
	)
}

BasicDetails.propTypes = {
	isCreateMode: PropTypes.bool.isRequired,
	isEditMode: PropTypes.bool.isRequired,
	isViewMode: PropTypes.bool.isRequired,
	formik: PropTypes.object.isRequired,
}

export default BasicDetails
