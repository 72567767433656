import update from 'immutability-helper'
import _ from 'lodash'

export const initialState = {
	isFetchingModelx: false,
	model: [],
	totalModelx: 0,
}

export function reducer(state = initialState, action) {
	switch (action.type) {
		case 'fetchigModelxPending':
			return { ...state, isFetchingModelx: true }
		case 'fetchingModelxSuccess':
			return {
				...state,
				isFetchingModelx: false,
				model: action.data,
				totalModelx: action.totalModelx,
			}
		case 'modelxUpdate':
			let tempActIndex = _.findIndex(state.model, (o) => o?.model_id === action?.data?.model_id)
			if (tempActIndex > -1) {
				let tempState = update(state, {
					model: { [tempActIndex]: { $merge: action.data } },
				})
				return tempState
			}
			return state
		default:
			throw new Error()
	}
}
