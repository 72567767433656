import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Icon, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { GLASS_ROLES } from '../../../constants'
import _ from 'lodash'

ActionSheet.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	role: PropTypes.string,
	onClickAction: PropTypes.func.isRequired,
	userId: PropTypes.string.isRequired,
}

const menus = [
	{
		label: 'Make Owner',
		icon: 'supervisor_account',
		menuFor: [GLASS_ROLES.MEMBER, GLASS_ROLES.GUEST],
		actionName: 'make_owner',
	},
	{
		label: 'Make Member',
		icon: 'person',
		menuFor: [GLASS_ROLES.OWNER, GLASS_ROLES.GUEST],
		actionName: 'make_member',
	},
	{
		label: 'Make Guest',
		icon: 'person',
		menuFor: [GLASS_ROLES.MEMBER, GLASS_ROLES.OWNER],
		actionName: 'make_guest',
	},
	{
		label: 'Remove',
		icon: 'close',
		menuFor: [GLASS_ROLES.MEMBER, GLASS_ROLES.GUEST],
		actionName: 'remove_member',
	},
]

function ActionSheet({ open, onClose, role, userId, onClickAction }) {
	const list = useCallback(
		() => (
			<List>
				{_.map(
					_.filter(menus, (menu) => _.indexOf(menu.menuFor, role) > -1),
					(menu) => (
						<ListItem button key={menu.label} onClick={() => onClickAction(menu.actionName, userId)}>
							<ListItemIcon>
								<Icon>{menu.icon}</Icon>
							</ListItemIcon>
							<ListItemText primary={menu.label} />
						</ListItem>
					)
				)}
			</List>
		),
		[role, onClickAction, userId]
	)

	return (
		<div>
			<Drawer anchor={'bottom'} open={open} onClose={onClose}>
				{list()}
			</Drawer>
		</div>
	)
}

export default ActionSheet
