import { ACTION_TYPES } from '../../../constants'

const toUpdateObject = (state = {}, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_UPDATE_OBJECT: {
			return {
				...state,
				...action.payload,
			}
		}
		default: {
			return state
		}
	}
}

export default toUpdateObject
