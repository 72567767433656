import Axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import _ from 'lodash'
const CancelToken = Axios.CancelToken

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	fetchCardDetails: (glassId, cardId, cancelExecutor) => {
		if (cancelExecutor.current) cancelExecutor.current()
		return Axios.get(`${API_ENDPOINTS.CARD_DEATAILS}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassId, cardId },
			},
		})
	},
	fetchActivityDetails: (glassId, cardId, offset, limit, cancelExecutor) => {
		if (cancelExecutor.current) cancelExecutor.current()
		return Axios.get(`${API_ENDPOINTS.CARD_ACTIVITY}`, {
			params: { offset, limit },
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassId, cardId },
			},
		})
	},
	updateActivityDetail: (glassId, cardId, commentDetails) => {
		const formData = new FormData()
		const formDataKeys = _.keys(commentDetails)
		for (let i = 0; i < formDataKeys.length; i++) {
			const value = commentDetails[formDataKeys[i]]
			formData.append(formDataKeys[i], _.isNull(value) ? '' : value)
		}
		return Axios.put(`${API_ENDPOINTS.CARD_ACTIVITY}`, formData, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassId, cardId },
			},
		})
	},
	createActivity: (glassId, cardId, commentDetails) => {
		const formData = new FormData()
		formData.append('comments', commentDetails.comments)
		formData.append('activity_type', commentDetails.activity_type)
		commentDetails.attachments.map((file) => formData.append('attachments', file))

		return Axios.post(`${API_ENDPOINTS.CARD_ACTIVITY}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				isAuthRequired: true,
				path: { glassId, cardId },
			},
		})
	},
	updateCardDetails: (glassId, cardId, data) => {
		const formData = new FormData()
		formData.append('title', data.title)
		formData.append('assignee', data.assignee)
		formData.append('description', data.description)
		formData.append('due_date', data.due_date)
		formData.append('priority', data.priority)
		formData.append('label_ids', data.label_ids)
		_.map(_.get(data, 'attachments', []), (file) => formData.append('attachments', file))
		return Axios.put(`${API_ENDPOINTS.CARD_DEATAILS}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				isAuthRequired: true,
				path: { glassId, cardId },
			},
		})
	},

	deleteAttachment: (glassId, cardId, attachmentId) => {
		return Axios.delete(API_ENDPOINTS.DELETE_ATTACHMENTS, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassId, cardId },
			},
			data: { attachment_ids: [attachmentId] },
		})
	},

	fetchCardProducts: (glassId, cardId, params) => {
		return Axios.post(
			`${API_ENDPOINTS.CARD_PRODUCTS}`,
			{ ...params },
			{
				headers: {
					'Content-Type': 'application/json',
					isAuthRequired: true,
					path: { glassId, cardId },
				},
			}
		)
	},
	updateProduct: (glassId, cardId, data) => {
		return Axios.put(
			`${API_ENDPOINTS.CARD_PRODUCTS}`,
			{ ...data },
			{
				headers: {
					'Content-Type': 'application/json',
					isAuthRequired: true,
					path: { glassId, cardId },
				},
			}
		)
	},

	fetchCardPeoplex: (glassId, cardId, params) => {
		return Axios.post(
			`${API_ENDPOINTS.CARD_PEOPLEX}`,
			{ ...params },
			{
				headers: {
					'Content-Type': 'application/json',
					isAuthRequired: true,
					path: { glassId, cardId },
				},
			}
		)
	},

	fetchCardModelx: (glassId, cardId, params) => {
		return Axios.post(
			`${API_ENDPOINTS.CARD_MODELX}`,
			{ ...params },
			{
				headers: {
					'Content-Type': 'application/json',
					isAuthRequired: true,
					path: { glassId, cardId },
				},
			}
		)
	},

	fetchSearchResults: (glassId, cardId, params) => {
		return Axios.post(
			`${API_ENDPOINTS.CARD_RESULTS}`,
			{ ...params, offset: `${params.offset}`, limit: `${params.limit}` },
			{
				headers: {
					'Content-Type': 'application/json',
					isAuthRequired: true,
					path: { glassId, cardId },
				},
			}
		)
	},
}
