import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import CloseIcon from '@material-ui/icons/Close'
import _ from 'lodash'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		height: 70,
		flexDirection: 'row',
		// justifyContent: 'space-between',
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		flex: '1 0 auto',
		padding: '10px 2px',
		'& .MuiCardContent-root:last-child': {
			paddingBottom: '0px',
		},
	},
	contentOnly: {
		maxWidth: '75%',
		flex: '1 0 auto',
		padding: '10px 15px',
		'& .MuiCardContent-root:last-child': {
			paddingBottom: '0px',
		},
	},
	cover: {
		width: 75,
		margin: 10,
	},
	controls: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(1),
		// paddingBottom: theme.spacing(1),
	},
	playIcon: {
		height: 38,
		width: 38,
	},
	actionBtn: {
		position: 'absolute',
		// left: '95%',
		// top: '-9%',
		backgroundColor: 'lightgray',
		color: 'gray',
		marginLeft: 105,
	},
	delBtn: {
		background: theme.palette.error.main,
		color: theme.palette.common.white,
		padding: '3px',
		'& span': {
			fontSize: '0.75rem',
		},
		'&:hover': {
			background: theme.palette.error.dark,
		},
	},
	cardAction: {
		alignSelf: 'stretch',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'flex-start',
		// 👇 Edit padding to further adjust position
		p: 0,
	},
	title: {
		fontSize: '0.875rem',
		fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
		fontWeight: '400',
		lineHeight: '1.43',
		letterSpacing: '0.01071em',
	},
	titleOnly: {
		fontSize: '0.875rem',
		fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
		fontWeight: '400',
		lineHeight: '1.43',
		letterSpacing: '0.01071em',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		width: '165px',
	},
}))

export default function MediaControlCard({ cardData, deleteStream, isViewMode, isCreateMode, isEditMode }) {
	const classes = useStyles()
	const theme = useTheme()
	const imgUrl = cardData?.image ? require(`../../../../../images/${cardData?.image}`) : ''

	return (
		<>
			<Card className={classes.root}>
				{(!_.isEmpty(cardData.url) || !_.isEmpty(cardData?.image)) &&
				<CardMedia className={classes.cover} image={cardData.url || imgUrl?.default} title={cardData.name} />}
				<CardContent className={classnames((!_.isEmpty(cardData.url) || !_.isEmpty(cardData?.image)) ? classes.content : classes.contentOnly)}>
					<div style={{ display: 'flex' }}>
						<div>
							<Typography component='h5' ariant='subtitle1' className={classnames((!_.isEmpty(cardData.url) || !_.isEmpty(cardData?.image)) ? classes.title : classes.titleOnly)}>
								{cardData.name}
							</Typography>
							<Typography variant='subtitle1' color='textSecondary' className={classnames((!_.isEmpty(cardData.url) || !_.isEmpty(cardData?.image)) ? classes.title : classes.titleOnly)}>
								{cardData.name}
							</Typography>
						</div>
					</div>
				</CardContent>
				{(isCreateMode || isEditMode) &&
				<CardActions disableSpacing className={classes.cardAction}>
					<IconButton key='Delete' className={classes.delBtn} onClick={() => deleteStream(cardData.id)} size='small' aria-label='Delete'>
						<CloseIcon fontSize='inherit' />
					</IconButton>
				</CardActions>}
			</Card>
		</>
	)
}
