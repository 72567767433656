import React, { useEffect, useState, useRef } from 'react'
import { DialogTitle, Drawer, Icon, makeStyles, Button, Typography, Grid, IconButton, CircularProgress, Box } from '@material-ui/core'
import _ from 'lodash'
import FilterExpansionPanel from './FilterExpansionPanel'
import FilterCreator from './FilterCreator'
import api from 'services/discoverx/api'
import { hideLoader, showConfirmMessage, showErrorMessage, showLoader } from 'services/loader/actions'
import { useDispatch } from 'react-redux'
import { getErrMsg } from 'utils'
import EditFilterDialog from './EditFilterDialog'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		border: `1px solid red`,
	},
	muiDrawerPaper: {
		height: `calc(100% - ${theme.headerHeight})`,
	},
	dialogTitleContainer: {
		fontSize: theme.typography.pxToRem(15),
		padding: theme.spacing(1, 2),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		boxShadow: `0px 0px 4px 0px rgb(100 100 100 / 40%)`,
	},
	titleContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		gap: `8px`,
	},
	title: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 500,
	},
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: `8px`,
	},
	contentContainer: {
		flex: 1,
		height: '100%',
		padding: theme.spacing(2, 4),
	},
}))

const FilterMaster = (props) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [state, setState] = useState({
		isLoading: true,
		data: [],
		savedFilters: [],
		isError: false,
	})
	const [savedFilters, setSavedFilters] = useState({
		isLoading: true,
		isError: false,
		data: {},
	})
	const [editDialogState, setEditDialogState] = useState({
		filterData: {},
		dsData: {},
		isOpen: false,
	})

	const hasChanges = useRef(false)

	const formatSavedFilters = (d) => {
		if (!_.isEmpty(d) && _.isArray(d)) {
			return _.reduce(
				d,
				(acc, o) => {
					acc[o?.ds_id || 'general'] = [..._.get(acc, o?.ds_id, []), o]
					return acc
				},
				{}
			)
		}
		return {}
	}

	const handleFetchAllFilters = (successCallback, errorCallback) => {
		setSavedFilters({ ...state, isLoading: true, isError: false })
		api
			.getAllFilters()
			.then((resp) => {
				//console.log('get all filters --- ', resp, formatSavedFilters(resp?.data?.data?.filter_details))
				setSavedFilters({
					...state,
					isLoading: false,
					isError: false,
					data: formatSavedFilters(resp?.data?.data),
				})
				successCallback && successCallback()
			})
			.catch((err) => {
				console.log('error getting all filters --- ', err)
				setSavedFilters({
					...state,
					isLoading: false,
					isError: true,
					data: {},
				})
				errorCallback && errorCallback()
			})
	}

	useEffect(() => {
		setState({ ...state, isError: false, data: [], isLoading: true })
		dispatch(showLoader())
		handleFetchAllFilters()
		api
			.fetchDataSourcesList()
			.then((resp) => {
				//console.log('response from fetchDataSourcesList -- ', resp?.data?.data?.datasource_details)
				setState({
					...state,
					isError: false,
					data: resp?.data?.data || [],
					isLoading: true,
				})
				dispatch(hideLoader())
			})
			.catch((err) => {
				console.log('err from fetchDataSourcesList -- ', err)
				setState({ ...state, isError: true, data: [], isLoading: true })
				dispatch(showErrorMessage(getErrMsg(err), 'Okay', () => dispatch(hideLoader())))
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleFilterSave = ({ id, ...values }) => {
		// console.log('filter data ---- ', values)
		setEditDialogState({ ...editDialogState, isLoading: true })
		const onSuccess = (resp) => {
			// console.log('resopnse from update filters --- ', resp);
			hasChanges.current = true
			handleFetchAllFilters()
			setEditDialogState({ filterData: {}, dsData: {}, isOpen: false })
		}
		const onError = (err) => {
			console.log('err from update filters --- ', err)
			dispatch(showErrorMessage(getErrMsg(err), 'Okay', () => dispatch(hideLoader())))
		}
		const apiFunc = !_.isEmpty(id) ? api.updateFilter : api.addFilter
		apiFunc({ ...values }, id)
			.then(onSuccess)
			.catch(onError)
	}

	const handleFilterDelete = (filterId) => {
		dispatch(showLoader('Deleting filter...'))
		api
			.deleteFilter(filterId)
			.then((resp) => {
				// console.log('resp from deleting filter', resp);
				hasChanges.current = true
				handleFetchAllFilters(
					() => dispatch(hideLoader()),
					() => dispatch(showErrorMessage('Error fetching filters!', 'Okay', () => dispatch(hideLoader())))
				)
			})
			.catch((err) => {
				console.log('err deleting filter -- ', err)
				dispatch(showErrorMessage(getErrMsg(err), 'Okay', () => dispatch(hideLoader())))
			})
	}

	const handleClickDelete = (filterId) => {
		dispatch(
			showConfirmMessage(
				'Are you Sure?',
				'The filter will be deleted permanently. Wish to continue?',
				'Yes',
				() => handleFilterDelete(filterId),
				'No',
				() => dispatch(hideLoader()),
				'warning'
			)
		)
	}

	return (
		<>
			<Drawer anchor='bottom' classes={{ paper: classes.muiDrawerPaper }} open={!!props?.isOpen} onClose={props?.handleClose}>
				<DialogTitle id='inPageFilters-dialog' disableTypography={true} className={classes.dialogTitleContainer}>
					<div className={classes.titleContainer}>
						<Icon fontSize='small'>tune</Icon>
						<Typography variant='h5' className={classes.title}>
							Filters Setup
						</Typography>
					</div>
					<div className={classes.buttonsContainer}>
						{/* <Button size="small" variant="outlined" onClick={props?.handleClose} className={classes.cancelButton}>Cancel</Button>
                    <Button size="small" variant="contained" color='primary' disableElevation onClick={props?.handleClose} className={classes.cancelButton}>Apply</Button> */}
						<IconButton size='small' onClick={() => [props?.handleClose(hasChanges.current), props.callBack(true)]}>
							<Icon>close</Icon>
						</IconButton>
					</div>
				</DialogTitle>
				<div className={classes.contentContainer}>
					{state?.data?.map((o) => (
						<FilterExpansionPanel
							key={o?.ds_id}
							panelIcon='dns'
							filterLen={_.get(savedFilters, `data.${o?.ds_id || 'general'}`, []).length}
							panelTitle={o?.ds_name || 'DataSource Name'}
						>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									{savedFilters?.isLoading ? (
										<Box padding={2} align='center'>
											<CircularProgress size={24} />
											<Typography component='div' variant='caption'>
												Loading...
											</Typography>
										</Box>
									) : (
										<FilterCreator
											data={_.get(savedFilters, `data.${o?.ds_id || 'general'}`, [])}
											onClickDelete={(e) => handleClickDelete(e)}
											onClickEdit={(e) =>
												setEditDialogState({
													isOpen: true,
													filterData: e,
													dsData: o,
												})
											}
										/>
									)}
								</Grid>
								<Grid container item xs={12} justifyContent='center' style={{ paddingTop: 8 }}>
									<Button
										variant='contained'
										disableElevation
										color='primary'
										onClick={() =>
											setEditDialogState({
												filterData: {},
												dsData: o,
												isOpen: true,
											})
										}
									>
										+ Add Filter
									</Button>
								</Grid>
							</Grid>
						</FilterExpansionPanel>
					))}
				</div>
			</Drawer>
			<EditFilterDialog
				{...editDialogState}
				appliedFilters={savedFilters?.data}
				handleFilterSave={handleFilterSave}
				handleClose={() => setEditDialogState({ ...editDialogState, isOpen: false })}
			/>
		</>
	)
}

export default FilterMaster
