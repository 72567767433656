import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from 'formik'
import PermissionInput from 'components/PermissionInput'

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(2),
		height: '100%',
		display: 'flex',
		boxSizing: 'border-box',
	},
}))

const Permissions = ({ isViewMode, isMobileView }) => {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<Field type='text' name='modules'>
				{({ form, field }) => (
					<PermissionInput name={field.name} isMobileView={isMobileView} onChange={field.onChange} value={field.value || []} viewMode={isViewMode} />
				)}
			</Field>
		</div>
	)
}

Permissions.propTypes = {
	isViewMode: PropTypes.bool.isRequired,
	isMobileView: PropTypes.bool.isRequired,
}

export default Permissions
