import React, { useState, useImperativeHandle } from 'react'
import _ from 'lodash'
import { Chip, IconButton } from '@material-ui/core'
import { convertGmtToLocalTime } from 'utils'

export const withCellRenderState = (CellRenderer) => {
	return React.forwardRef((props, ref) => {
		const [value, setValue] = useState(props.value)
		useImperativeHandle(ref, () => {
			return {
				refresh: (params) => {
					if (params.value !== value) {
						setValue(params.value)
					}
					return true
				},
			}
		})
		return <CellRenderer {...props}></CellRenderer>
	})
}

export const ActionCellRenderer = (props) => {
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const onClickMoreOptions = _.get(props, 'colDef.cellRendererParams.onClickMoreOptions')

	const isEditable = _.get(props, 'data.is_editable', true)

	const onClickAnchorEl = (event) => {
		onClickMoreOptions(event, _.get(props, 'data', {}))
	}

	return (
		<div className={containerClassName}>
			{isEditable ? (
				<IconButton size='small' onClick={onClickAnchorEl} title='Show Actions'>
					<i className='material-icons'>more_horiz</i>
				</IconButton>
			) : (
				<i className='material-icons'>lock</i>
			)}
		</div>
	)
}

export const DateCellRenderer = (props) => {
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	return (
		<div className={containerClassName}>
			<span style={{ backgroundColor: value ? '#82c43c' : '#fc5a5a' }}></span>
			{data ? convertGmtToLocalTime(data.created_date, 'DD MMM YYYY hh:mm a') : ' '}
		</div>
	)
}

export const NameCellRenderer = (props) => {
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const isDefaultRole = _.get(data, 'is_default', false)

	return (
		<div className={containerClassName} title={value}>
			{value} {isDefaultRole && <Chip color='primary' size='small' label={'Default'} />}
		</div>
	)
}
