import {
	Divider,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
	makeStyles,
	Avatar,
	Table,
	TableRow,
	TableBody,
	TableCell,
	Switch,
	TableHead,
	Grid,
	TablePagination,
	Card,
	CardHeader,
	Snackbar,
} from '@material-ui/core'
import React, { useCallback, useEffect, useReducer, useState } from 'react'
import _ from 'lodash'
import SearchBox from 'components/SearchBox'
import TableHeaders from 'components/TableHeaders'
import propTypes from 'prop-types'
import { initialState, reducer } from './reducer'
import CardApi from '../../../../../../services/glass/cards/api'
import { Alert, Skeleton } from '@material-ui/lab'
import { NoRecordsIllustration } from '../../../../../../illustrators'
import { useSelector } from 'react-redux'
import { getErrMsg } from 'utils'

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiTypography-body1': {
			fontSize: '14px',
		},
		'& .MuiTypography-subtitle1': {
			fontSize: '14px',
		},
		'& .MuiListItem-root': {
			paddingTop: 0,
			paddingBottom: 0,
		},
		'& .MuiListItem-gutters': {
			paddingLeft: 0,
		},
		'& .MuiList-padding': {
			paddingTop: 0,
			paddingBottom: 0,
		},
		'& .MuiDivider-inset': {
			marginLeft: 0,
		},
		'& .MuiCardHeader-root': {
			padding: 0,
		},
		paddingBottom: theme.spacing(1),
		padding: '0px 20px 8px 20px',
	},
	table: {
		'& .MuiTableCell-root': {
			borderBottom: '0px',
		},
	},
	thead: {
		backgroundColor: '#9e9c9c14',
	},
	titleSec: {
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
		'& .searchbar-container': {
			padding: 0,
		},
	},
	searchWithoutPadding: {
		padding: theme.spacing(0, 0),
	},
	zeroPadding: {
		padding: 0,
	},
	noRecordsIllustration: {
		width: '100px',
		height: '100px',
	},
	infoContainer: {
		textAlign: 'center',
		margin: theme.spacing(0.5, 0),
	},
	productTitle: {
		'-webkit-line-clamp': 1,
		display: '-webkit-box',
		overflow: 'hidden',
		'-webkit-box-orient': 'vertical',
	},
	inline: {
		'-webkit-line-clamp': 1,
		display: '-webkit-box',
		overflow: 'hidden',
		'-webkit-box-orient': 'vertical',
	},
}))

const ProductTable = ({ state, dispatch, classes, onPageChange, params, onRowChange, glassId, cardId }) => {
	const [snackbar, setSnackbar] = useState({
		show: false,
		text: '',
		color: '',
		disableClose: false,
	})
	const labelAdjective = useSelector((state) => _.get(state, 'appSettings.data', 'Counterfeit'))
	// const headers = [
	// 	{
	// 		name: _.get(labelAdjective, 'company_adjective', 'Counterfiet'),
	// 		width: '10%',
	// 		align: 'left',
	// 	},
	// 	{ name: 'Products', width: '100%', align: 'left' },
	// ]

	const onRowSwitchChange = (item) => {
		let tempData = {
			confirm_counterfeit: !_.get(item, 'confirm_counterfeit', false),
			product_id: _.get(item, 'product_id', ''),
		}
		setSnackbar({
			show: true,
			text: 'processing...',
			color: 'info',
			disableClose: true,
		})
		const onSuccessProduct = (res, idx) => {
			setSnackbar({
				show: false,
				text: '',
				color: 'info',
				disableClose: false,
			})
			let tempArr = []
			_.forEach(_.get(state, 'products', []), (row) => {
				if (_.isEqual(_.get(row, 'product_id', ''), idx)) {
					tempArr.push({
						...row,
						confirm_counterfeit: _.get(tempData, 'confirm_counterfeit', false),
					})
				} else {
					tempArr.push(row)
				}
			})
			dispatch({
				type: 'fetchingProductsSuccess',
				data: tempArr,
				totalProducts: state.totalProducts,
			})
		}
		const onFailureProduct = (err) => {
			setSnackbar({
				show: true,
				text: getErrMsg(err),
				color: 'error',
				disableClose: true,
			})
		}

		CardApi.updateProduct(glassId, cardId, tempData).then((res) => {
			onSuccessProduct(res, _.get(item, 'product_id', ''))
		}, onFailureProduct)
	}

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={snackbar.show}
				autoHideDuration={2000}
				onClose={
					snackbar.disableClose
						? () => {}
						: () => {
								setSnackbar((prevState) => ({ ...prevState, show: false }))
						  }
				}
			>
				<Alert
					onClose={
						snackbar.disableClose
							? () => {}
							: () => {
									setSnackbar((prevState) => ({ ...prevState, show: false }))
							  }
					}
					severity={snackbar.color}
				>
					{snackbar.text}
				</Alert>
			</Snackbar>

			{_.get(state, 'isFetchingProducts', false) ? (
				<>
					<Card elevation={0}>
						<CardHeader
							avatar={<Skeleton animation='wave' variant='circle' width={40} height={40} />}
							title={<Skeleton animation='wave' height={30} width='100%' />}
							subheader={
								<>
									<Skeleton animation='wave' height={20} width='100%' />
									<Skeleton animation='wave' height={20} width='100%' />
								</>
							}
						/>
					</Card>
					<Card elevation={0}>
						<CardHeader
							avatar={<Skeleton animation='wave' variant='circle' width={40} height={40} />}
							title={<Skeleton animation='wave' height={30} width='100%' />}
							subheader={
								<>
									<Skeleton animation='wave' height={20} width='100%' />
									<Skeleton animation='wave' height={20} width='80%' />
								</>
							}
						/>
					</Card>
				</>
			) : (
				<Table className={classes.table} aria-label='simple table' size='small'>
					{/* <TableHeaders headers={headers} /> */}
					<TableBody>
						{_.isEmpty(state.products) ? (
							<TableRow>
								<TableCell colSpan={2}>
									<div className={classes.infoContainer}>
										<NoRecordsIllustration className={classes.noRecordsIllustration} />
										<Typography variant='body1'>No Records Found</Typography>
									</div>
								</TableCell>
							</TableRow>
						) : (
							_.map(state.products, (item) => (
								<TableRow key={item.product_id} hover>
									{/* <TableCell component='td' scope='row'>
										<Switch
											checked={_.get(item, 'confirm_counterfeit', false)}
											onChange={() => {
												onRowSwitchChange(item)
											}}
											color='primary'
											name='counterfiet'
											inputProps={{ 'aria-label': 'secondary checkbox' }}
										/>
									</TableCell> */}
									<TableCell component='td' scope='row'>
										<List>
											<ProductListItem item={item} classes={classes} />
										</List>
									</TableCell>
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			)}

			{!_.get(state, 'isFetchingProducts', false) && !_.isEmpty(state.products) && (
				<TablePagination
					component='div'
					count={_.get(state, 'totalProducts', 0)}
					page={params.offset / params.limit}
					rowsPerPageOptions={[5, 10, 15]}
					onChangePage={onPageChange}
					rowsPerPage={params.limit}
					onChangeRowsPerPage={onRowChange}
				/>
			)}
		</>
	)
}

ProductTable.propTypes = {
	state: propTypes.object,
	classes: propTypes.object,
	params: propTypes.object,
	onPageChange: propTypes.func,
	onRowChange: propTypes.func,
}

const ProductListItem = ({ item, classes }) => {
	return (
		<div key={item.product_id}>
			<ListItem alignItems='flex-start'>
				<ListItemAvatar>
					<Avatar variant='rounded' alt={_.get(item, 'product_name', '')} src={_.get(item, 'image_url', '')} />
				</ListItemAvatar>
				<ListItemText
					primary={
						<Typography component={'p'} className={classes.productTitle}>
							{_.get(item, 'product_name', '')}
						</Typography>
					}
					secondary={
						<React.Fragment>
							{item?.brand && `${item?.brand}`}
							{item?.market_place && ` - ${item?.market_place}`}
							{item?.seller_name && ` - Seller : ${item?.seller_name}`}
							<br />
							{item?.market_price && `Market Price :  ${item?.market_price}`} {item?.selling_price && `- Selling Price :  ${item?.selling_price}`}
						</React.Fragment>
					}
				/>
			</ListItem>
			<Divider variant='inset' component='li' />
		</div>
	)
}

ProductTable.propTypes = {
	item: propTypes.object,
	classes: propTypes.object,
}

const ProductList = ({ glassId, cardId, socketIORef }) => {
	const classes = useStyles()
	const [state, dispatch] = useReducer(reducer, initialState)
	const [params, setParams] = useState({
		limit: 5,
		offset: 0,
		search_text: '',
		// search_fields: ['product_name'],
	})

	useEffect(() => {
		dispatch({ type: 'fetchigproductsPending' })
		const onSuccess = (res) => {
			dispatch({
				type: 'fetchingProductsSuccess',
				data: _.get(res, 'data.products', []),
				totalProducts: _.get(res, 'data.total_records', 0),
			})
		}
		const onFailure = (err) => {
			dispatch({ type: 'fetchingProductsSuccess', data: [], totalProducts: 0 })
		}

		if (glassId && cardId) {
			CardApi.fetchCardProducts(glassId, cardId, params).then(onSuccess, onFailure)
		}
	}, [glassId, cardId, params])

	// useEffect(() => {
	// 	if (socketIORef?.current?.active) {
	// 		socketIORef.current.on('card_product_updated', function (resp) {
	// 			if (_.isEqual(cardId, resp?.card_id)) {
	// 				dispatch({ type: 'productUpdate', data: resp?.data })
	// 			}
	// 		})
	// 	}
	// }, [socketIORef?.current?.active])

	const onPageChange = (e, pageNo) => {
		setParams({ ...params, offset: pageNo * params.limit })
	}
	const onRowChange = (e) => {
		setParams({ ...params, limit: parseInt(e.target.value), offset: 0 })
	}
	const onSearchChange = useCallback(
		_.debounce((value) => {
			setParams({ ...params, search_text: value })
		}, 300),
		[]
	)

	return (
		<div className={classes.root}>
			<Grid container className={classes.titleSec}>
				<Grid item lg={6} xs={12} sm={6}>
					<Typography variant='overline' component='div'>
						Products
					</Typography>
				</Grid>
				<Grid item lg={6} xs={12} sm={6}>
					<SearchBox
						placeholder='Search'
						size='small'
						onChange={(e) => {
							onSearchChange(e.target.value)
						}}
						containerClassName={classes.zeroPadding}
					/>
				</Grid>
			</Grid>
			<ProductTable
				state={state}
				dispatch={dispatch}
				classes={classes}
				params={params}
				onPageChange={onPageChange}
				onRowChange={onRowChange}
				glassId={glassId}
				cardId={cardId}
			/>
		</div>
	)
}
ProductList.propTypes = {
	glassId: propTypes.string.isRequired,
	cardId: propTypes.string.isRequired,
	socketIORef: propTypes.object,
}
export default ProductList
