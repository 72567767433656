import { ACTION_TYPES } from '../../../constants'

const initState = {
	isFetchingModelxDetails: true,
	isFetchingModelxDetailsError: false,
	isFetchingModelxDetailsFailed: '',
	isFetchingModelxList: true,
	isFetchingModelxListError: false,
	isFetchingModelxListFailed: '',
	isFetchingModelxLogs: true,
	isFetchingModelxLogsError: false,
	isFetchingModelxLogsFailed: '',
	isFetchingModelxJobs: true,
	isFetchingModelxJobsError: false,
	isFetchingModelxJobssFailed: '',
	modelx_list: {},
	modelx_details: {},
	modelx_logs: {},
}

const modelxTable = (state = initState, action) => {
	switch (action.type) {
		case ACTION_TYPES.GET_MODELX_TABLE_PENDING: {
			return {
				...state,
				isFetchingModelxList: true,
				isFetchingModelxListFailed: false,
				isFetchingModelxListError: undefined,
				modelx_list: {},
			}
		}
		case ACTION_TYPES.GET_MODELX_TABLE_FULFILLED: {
			return {
				...state,
				isFetchingModelxList: false,
				isFetchingModelxListFailed: false,
				isFetchingModelxListError: undefined,
				modelx_list: action.payload.data.data,
			}
		}
		case ACTION_TYPES.GET_MODELX_TABLE_REJECTED: {
			return {
				...state,
				isFetchingModelxList: false,
				isFetchingModelxListFailed: true,
				isFetchingModelxListError: action.payload,
				modelx_list: {},
			}
		}
		case ACTION_TYPES.GET_MODELX_DETAILS_PENDING: {
			return {
				...state,
				isFetchingModelxDetails: true,
				isFetchingModelxDetailsFailed: false,
				isFetchingModelxDetailsError: undefined,
				modelx_details: {},
			}
		}
		case ACTION_TYPES.GET_MODELX_DETAILS_FULFILLED: {
			return {
				...state,
				isFetchingModelxDetails: false,
				isFetchingModelxDetailsFailed: false,
				isFetchingModelxDetailsError: undefined,
				modelx_details: action.payload.data.data,
			}
		}
		case ACTION_TYPES.GET_MODELX_DETAILS_REJECTED: {
			return {
				...state,
				isFetchingModelxDetails: false,
				isFetchingModelxDetailsFailed: true,
				isFetchingModelxDetailsError: action.payload,
				modelx_details: {},
			}
		}
		case ACTION_TYPES.GET_MODELX_LOGS_PENDING: {
			return {
				...state,
				isFetchingLogs: true,
				isFetchingLogsFailed: false,
				isFetchingLogsError: undefined,
				modelx_logs: {},
			}
		}
		case ACTION_TYPES.GET_MODELX_LOGS_FULFILLED: {
			return {
				...state,
				isFetchingLogs: false,
				isFetchingLogsFailed: false,
				isFetchingLogsError: undefined,
				modelx_logs: action.payload.data.data,
			}
		}
		case ACTION_TYPES.GET_MODELX_LOGS_REJECTED: {
			return {
				...state,
				isFetchingLogs: false,
				isFetchingLogsFailed: true,
				isFetchingLogsError: action.payload,
				modelx_logs: {},
			}
		}
		case ACTION_TYPES.GET_MODELX_JOBS_PENDING: {
			return {
				...state,
				isFetchingJobs: true,
				isFetchingJobsFailed: false,
				isFetchingJobsError: undefined,
				// modelx_logs: {},
			}
		}
		case ACTION_TYPES.GET_MODELX_JOBS_FULFILLED: {
			const { sticky_code, status } = action.payload
			const updatedModels = state.modelx_list.chart_data.map((model) => {
				if (model.model_id === sticky_code) {
					return { ...model, status: status }
				} else {
					return model
				}
			})
			return {
				...state,
				isFetchingJobs: false,
				isFetchingJobsFailed: false,
				isFetchingJobsError: undefined,
				modelx_details: { ...state.modelx_details, status: action.payload.status },
				modelx_list: { ...state.modelx_list, chart_data: updatedModels },
			}
		}
		case ACTION_TYPES.GET_MODELX_JOBS_REJECTED: {
			return {
				...state,
				isFetchingJobs: false,
				isFetchingJobsFailed: true,
				isFetchingJobsError: action.payload,
				// modelx_logs: {},
			}
		}
		default:
			return state
	}
}

export default modelxTable
