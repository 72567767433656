import { ACTION_TYPES } from '../../../constants'

const initState = {
	isFetchingPeoplexDetails: true,
	isFetchingPeoplexDetailsError: false,
	isFetchingPeoplexDetailsFailed: '',
	isFetchingPeople: true,
	isFetchingPeopleError: false,
	isFetchingPeopleFailed: '',
	data: {},
	peoplex_details: {},
}

const peoplexTable = (state = initState, action) => {
	switch (action.type) {
		case ACTION_TYPES.GET_PEOPLEX_TABLE_PENDING: {
			return {
				...state,
				isFetchingPeople: true,
				isFetchingPeopleFailed: false,
				isFetchingPeopleError: undefined,
				data: {},
			}
		}
		case ACTION_TYPES.GET_PEOPLEX_TABLE_FULFILLED: {
			return {
				...state,
				isFetchingPeople: false,
				isFetchingPeopleFailed: false,
				isFetchingPeopleError: undefined,
				data: action.payload.data.data,
			}
		}
		case ACTION_TYPES.GET_PEOPLEX_TABLE_REJECTED: {
			return {
				...state,
				isFetchingPeople: false,
				isFetchingPeopleFailed: true,
				isFetchingPeopleError: action.payload,
				data: {},
			}
		}
		case ACTION_TYPES.GET_PEOPLEX_DETAILS_PENDING: {
			return {
				...state,
				isFetchingPeoplexDetails: true,
				isFetchingPeoplexDetailsFailed: false,
				isFetchingPeoplexDetailsError: undefined,
				peoplex_details: {},
			}
		}
		case ACTION_TYPES.GET_PEOPLEX_DETAILS_FULFILLED: {
			return {
				...state,
				isFetchingPeoplexDetails: false,
				isFetchingPeoplexDetailsFailed: false,
				isFetchingPeoplexDetailsError: undefined,
				peoplex_details: action.payload.data.data,
			}
		}
		case ACTION_TYPES.GET_PEOPLEX_DETAILS_REJECTED: {
			return {
				...state,
				isFetchingPeoplexDetails: false,
				isFetchingPeoplexDetailsFailed: true,
				isFetchingPeoplexDetailsError: action.payload,
				peoplex_details: {},
			}
		}
		default:
			return state
	}
}

export default peoplexTable
