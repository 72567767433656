const eventBus = {
	on(event, callback) {
		document.addEventListener(event, callback)
	},
	dispatch(event, detail) {
		document.dispatchEvent(new CustomEvent(event, { detail }))
	},
	remove(event, callback) {
		document.removeEventListener(event, callback)
	},
}

export default eventBus
