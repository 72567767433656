import { put, call, takeEvery } from 'redux-saga/effects'
import { ACTION_TYPES, SAGA_ACTIONS } from '../../../constants'
import peoplexApi from '../api'
import _ from 'lodash'

function* watchworkGetPeoplexTable() {
	yield takeEvery(SAGA_ACTIONS.GET_PEOPLEX_TABLE, workGetPeoplexTable)
}

function* workGetPeoplexTable(action) {
	const { successCallback, errCallback } = action
	yield put({ type: ACTION_TYPES.GET_PEOPLEX_TABLE_PENDING })
	try {
		const products = yield call(peoplexApi.getProductsTableData, action.query, action.search, action.redirect, action.cancelExecutor)
		yield put({
			type: ACTION_TYPES.GET_PEOPLEX_TABLE_FULFILLED,
			payload: products,
		})
		successCallback && (yield call(successCallback, products))
	} catch (err) {
		yield put({ type: ACTION_TYPES.GET_PEOPLEX_TABLE_REJECTED, payload: err })
		errCallback && (yield call(errCallback, err))
		console.log(err)
	}
}

function* watchWorkGetPeoplexDetails() {
	yield takeEvery(SAGA_ACTIONS.GET_PEOPLEX_DETAILS, workGetPeoplexDetails)
}

function* workGetPeoplexDetails(action) {
	yield put({ type: ACTION_TYPES.GET_PEOPLEX_DETAILS_PENDING })

	try {
		const peoplex = yield call(peoplexApi.getPeoplexDataDetails, action.peoplexId, action.cancelExecutor)
		yield put({
			type: ACTION_TYPES.GET_PEOPLEX_DETAILS_FULFILLED,
			payload: peoplex,
		})
	} catch (err) {
		yield put({ type: ACTION_TYPES.GET_PEOPLEX_DETAILS_REJECTED, payload: err })
		console.log(err)
	}
}

export default [watchworkGetPeoplexTable(), watchWorkGetPeoplexDetails()]
