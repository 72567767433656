import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Icon, Button, IconButton, MenuItem, MenuList, ClickAwayListener, ButtonGroup, Popover, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import classnames from 'classnames'
import SearchBox from '../SearchBox'
import _ from 'lodash'
import { sortBy } from 'lodash'
import CustomPopoverMenus from 'components/CustomPopoverMenus'

const useStyles = makeStyles((theme) => ({
	PageHeaderSort: {
		paddingTop: theme.spacing(2),
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down('xs')]: {
			paddingTop: theme.spacing(0),
			marginBottom: theme.spacing(1),
		},
	},
	icon: {
		marginRight: '12px',
	},
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	pageTitle: {
		color: '#666',
		letterSpacing: '2px',
		'& span': {
			verticalAlign: 'middle',
			fontSize: theme.typography.pxToRem(22),
		},
	},
	titleIcon: {
		marginRight: theme.spacing(1.5),
	},
	staticContent: {
		marginBottom: theme.spacing(2),
	},
	actionContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		'& Button': {
			marginLeft: theme.spacing(1),
		},
		'& > div': {
			marginTop: '2px',
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(2),
			justifyContent: 'center',
			'& Button': {
				// maxWidth:400,
				marginLeft: theme.spacing(0),
				width: '100%',
			},
		},
	},
	multiActionContainer: {
		textAlign: 'end',
		alignSelf: 'center',
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(2),
			textAlign: 'center',
		},
	},
	searchAndAddGrid: {
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(2),
		},
	},
	searchBarContainer: {
		padding: '0 !important',
		[theme.breakpoints.down('xs')]: {
			padding: '0 !important',
		},
	},
	searchBarWidth: {
		[theme.breakpoints.down('xs')]: {
			maxWidth: 'none !important',
		},
	},
    sortBtn: {
		// display: 'block',
		// marginLeft: 'auto',
		width: '100%',
		textTransform: 'capitalize',
		fontWeight: 'normal',
		color: theme.palette.grey[600],
		padding: '4px 10px',
		'&:hover': {
			backgroundColor: 'transparent',
			color: theme.palette.primary.main,
		},
		'& .MuiIcon-fontSizeSmall': {
			fontSize: '1.25rem',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '4px 5px',
		},
		[theme.breakpoints.only('sm')]: {
			textAlign: 'right',
		},
	},
	sortedValBtn: {
        padding: '4px 1px 4px 10px',
        width: '140px',
        textAlign: 'end',
		'& .MuiButton-label': {
			width: '95%',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			display: 'block',
			textOverflow: 'ellipsis',

			[theme.breakpoints.down('sm')]: {
				lineHeight: 'unset',
			},
			[theme.breakpoints.down(330)]: {
				width: '65%',
			},
		},
	},
    iconBtn: {
        padding: '12px 3px',
    },
	actionBtn: {
		padding: '3px 10px',
		fontSize: '0.8125rem',
	},
	pickActionBtn: {
		padding: '3px 10px',
		fontSize: '0.8125rem',
		height: '40px',
	},
	menuList: {
		'& .MuiListItem-root': {
			minWidth: '115px',
		},
	},
}))

const PageHeaderSort = ({ title, titleIcon, onChangeSearch, className, actions, sort, children, sortable_fields, sortingDetails, setSortingDetails, searchQuery = '', sortFilter, multiActions=false, options, open, anchorRef, selectedIndex, buttonIcon, handleClick, handleMenuItemClick, handleToggle, handleClose, isPickerMode = false, handleCancel, handlePicked, cancelButtonLabel, pickButtonLabel }) => {
	const classes = useStyles()
    const [sortAnchorEl, setSortAnchorEl] = React.useState(null)

    const activityState = sortingDetails?.sort_by
	const activityIcon = sortingDetails?.sort_by_order === 'desc' ? 'arrow_upward' : 'arrow_downward'

    const sortable = sortable_fields.map((item) => {
		const container = {}

		container.label = item.name
		container.id = item.id
		// container.icon = sortingDetails?.field === item ? (sortingDetails?.sort_by === 'asc' ? 'arrow_downward' : 'arrow_upward') : 'arrow_downward'
		container.actionName = 'SORT'

		return container
	})

	sortable?.unshift({ label: 'None', id: null, actionName: 'SORT' })

    const toggleSortBtn = (event) => {
		setSortAnchorEl(event.currentTarget)
	}

    const handleCloseMenu = () => {
		setSortAnchorEl(null)
	}

    const sortBy = (field, type) => {
		let order = _.isEqual(type, 'arrow_downward') ? 'asc' : 'desc'
		if (field === 'None') {
			setSortingDetails({ sort_by: '', sort_by_order: '' })
            setSortAnchorEl(null)
			sortFilter('none', 'asc')
		} else {
			setSortingDetails({ sort_by: field, sort_by_order: order })
            setSortAnchorEl(null)
			sortFilter(field, order)
		}
	}

	return (
		<Grid container direction='row' justifyContent='center' className={classnames(classes.PageHeaderSort, className)} alignItems='center'>
			<Grid item xs={12} md={12} lg={12} className={classes.staticContent}>
				<Grid container>
					<Grid item md={4} sm={12} className={classes.titleContainer}>
						<Typography variant='h5' className={classes.pageTitle} component='div'>
							{(!_.isEqual(titleIcon, 'psychology_icon') && !_.isEqual(titleIcon, 'people_alt_icon')) ?
							<span className={classnames('material-icons', classes.titleIcon)}>{titleIcon}</span> :
							<Icon className={classes.icon}>{titleIcon}</Icon>}
							<span>{title}</span>
						</Typography>
					</Grid>
					<Grid item md={8} sm={12} xs={12} className={classes.searchAndAddGrid}>
						<Grid container>
							<Grid item md={6} sm={6} xs={12}>
								<SearchBox
									value={searchQuery}
									placeholder='Search'
									searchBarClassName={classes.searchBarWidth}
									containerClassName={classes.searchBarContainer}
									onChange={(e) => onChangeSearch(e)}
								></SearchBox>
							</Grid>
                            <Grid item md={3} sm={3} xs={12} className={classes.actionContainer}>
                                <div className={classes.btnContainer} style={{ display: 'flex', flexDirection: 'row'}}>
                                    <Button
                                        size='small'
                                        className={classnames(classes.sortBtn, !_.isEqual(sortingDetails?.sort_by, null) && classes.sortedValBtn)}
                                        onClick={toggleSortBtn}
                                        startIcon={_.isEqual(sortingDetails?.sort_by, null) && <Icon fontSize='small'>sort</Icon>}
                                    >
                                       {_.isEqual(sortingDetails?.sort_by, null) ? 'Sort by' : _.get(_.find(sortable_fields, { id: sortingDetails.sort_by }), 'name')}
                                    </Button>
                                    {!_.isEqual(sortingDetails?.sort_by, null) && (
                                        <IconButton onClick={() => sortBy(activityState, activityIcon === 'arrow_upward' ? 'arrow_downward' : 'arrow_upward')} className={classes.iconBtn}>
                                            <Icon onClick={() => sortBy(activityState, activityIcon === 'arrow_upward' ? 'arrow_downward' : 'arrow_upward')} fontSize='small'>
                                                {activityIcon}
                                            </Icon>
                                        </IconButton>
                                    )}
                                </div>
							</Grid>
							{(actions && !isPickerMode) &&
							<Grid item md={3} sm={3} xs={12} className={classes.actionContainer}>
								<div>{actions}</div>
							</Grid>}
							{isPickerMode &&
							<Grid item md={3} sm={3} xs={12} className={classes.actionContainer} style={{ gap: '5px' }}>
							<Button variant='outlined' onClick={handleCancel} className={classes.pickActionBtn}>
								{cancelButtonLabel}
							</Button>
							<Button variant="contained" color="primary" onClick={() => {handlePicked()}} className={classes.pickActionBtn}>{pickButtonLabel}</Button>
							</Grid>}
							{(multiActions && !isPickerMode) &&
							<Grid item md={3} sm={3} xs={12} className={classes.multiActionContainer}>
								<ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button" style={{ boxShadow: "none" }}>
								<Button startIcon={  _.isEqual(options[selectedIndex], 'TASK') && buttonIcon} className={classes.actionBtn} onClick={() => handleClick()}>{options[selectedIndex]}</Button>
								<Button
									color="primary"
									size="small"
									aria-controls={open ? 'split-button-menu' : undefined}
									aria-expanded={open ? 'true' : undefined}
									aria-label="select merge strategy"
									aria-haspopup="menu"
									onClick={handleToggle}
									className={classes.actionBtn}
								>
									<ArrowDropDownIcon />
								</Button>
								</ButtonGroup>

								<Popover open={open} anchorEl={anchorRef.current} onClose={handleClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'center',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}
								>
									<Paper>
										<ClickAwayListener onClickAway={handleClose}>
										<MenuList id="split-button-menu" className={classes.menuList}>
											{options.map((option, index) => (
											<MenuItem
												key={option}
												disabled={index === 2}
												selected={index === selectedIndex}
												onClick={(event) => handleMenuItemClick(event, index)}
												className={classes.actionBtn}
											>
												{option}
											</MenuItem>
											))}
										</MenuList>
										</ClickAwayListener>
									</Paper>
								</Popover>
							</Grid>}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
            <CustomPopoverMenus
                open={Boolean(sortAnchorEl)}
                id={'0'}
                menus={sortable}
                anchorEl={sortAnchorEl}
                // onClickAction={sortBy}
                onClose={handleCloseMenu}
                sortBy={sortBy}
                activityState={activityState}
                activityIcon={activityIcon}
                className={classes.sortPopover}
            />
			{children && (
				<Grid item xs={12} md={12} lg={12} sm={12}>
					{children}
				</Grid>
			)}
		</Grid>
	)
}

PageHeaderSort.propTypes = {
	title: PropTypes.string.isRequired,
	titleIcon: PropTypes.string.isRequired,
	className: PropTypes.string,
	onChangeSearch: PropTypes.func,
	actions: PropTypes.node,
    sort: PropTypes.node,
	children: PropTypes.node,
}

export default PageHeaderSort
