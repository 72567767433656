import { ACTION_TYPES } from '../../../constants'
// import _ from 'lodash'
import membersReducer from './members'
import labelsReducer from './labels'
import stickyGroupsReducer from './stickyGroup'
import paneReducer from './panes'
import actTypesReducer from './activityTypes'
import { getErrMsg } from 'utils'
import update from 'immutability-helper'
import stickyListReducer from './stickyList'

const initState = {
	isFetching: true,
	isFailed: false,
	errorMessage: '',
	project_name: '',
	project_code: '',
	glass_name: '',
	glass_code: '',
	glass_desc: '',
	glass_members: [],
	settings: {},
	stickyList: [],
	stickyDetails: [],
	created_by: {},
	created_date: '',
	modified_by: '',
	modified_date: '',
	socket_session_id: '',
	selectedFilters: {},
}

const membersActionTypes = [
	ACTION_TYPES.UPDATE_GLASS_MEMBER_PENDING,
	ACTION_TYPES.UPDATE_GLASS_MEMBER_FULFILLED,
	ACTION_TYPES.UPDATE_GLASS_MEMBER_REJECTED,
	ACTION_TYPES.UPDATE_GLASS_MEMBER,
	ACTION_TYPES.ADD_GLASS_MEMBERS,
	ACTION_TYPES.REMOVE_GLASS_MEMBER,
]

const labelsActionTypes = [ACTION_TYPES.ADD_GLASS_LABEL, ACTION_TYPES.UPDATE_GLASS_LABEL, ACTION_TYPES.REMOVE_GLASS_LABEL]

const stickyGroupActionTypes = [ACTION_TYPES.ADD_GLASS_STICKY_GROUP, ACTION_TYPES.UPDATE_GLASS_STICKY_GROUP, ACTION_TYPES.REMOVE_GLASS_STICKY_GROUP]

const paneActionTypes = [ACTION_TYPES.ADD_GLASS_PANE, ACTION_TYPES.UPDATE_GLASS_PANE, ACTION_TYPES.REMOVE_GLASS_PANE]

const stickyActionTypes = [
	ACTION_TYPES.ADD_GLASS_STICKY,
	ACTION_TYPES.UPDATE_GLASS_STICKY,
	ACTION_TYPES.REMOVE_GLASS_STICKY,
	ACTION_TYPES.REMOVE_GLASS_MULTI_STICKY,
]

const actTypesActionTypes = [ACTION_TYPES.ADD_GLASS_ACTIVITY_TYPE, ACTION_TYPES.UPDATE_GLASS_ACTIVITY_TYPE, ACTION_TYPES.REMOVE_GLASS_ACTIVITY_TYPE]

const glass = (state = initState, action) => {
	switch (true) {
		case action.type === ACTION_TYPES.FETCH_GLASS_DETAILS_PENDING: {
			return {
				...initState,
				isFetching: true,
				isFailed: false,
				errorMessage: '',
			}
		}
		case action.type === ACTION_TYPES.FETCH_GLASS_DETAILS_FULFILLED: {
			return {
				isFetching: false,
				isFailed: false,
				errorMessage: '',
				...action.payload,
			}
		}
		case action.type === ACTION_TYPES.FETCH_GLASS_DETAILS_REJECTED: {
			return {
				...state,
				isFetching: false,
				isFailed: true,
				errorMessage: getErrMsg(action.payload),
			}
		}
		case action.type === ACTION_TYPES.GET_GLASS_FILTERS_SUCCESS: {
			return {
				...state,
				selectedFilters: action.payload,
			}
		}
		case membersActionTypes.indexOf(action.type) > -1:
			return state?.isFetching
				? state
				: {
						...state,
						glass_members: membersReducer(state?.glass_members, action),
				  }
		case labelsActionTypes.indexOf(action.type) > -1:
			return state?.isFetching ? state : update(state, { settings: { labels: { $set: labelsReducer(state?.settings?.labels, action) } } })
		case stickyGroupActionTypes.indexOf(action.type) > -1:
			return state?.isFetching
				? state
				: update(state, { settings: { sticky_group: { $set: stickyGroupsReducer(state?.settings?.sticky_group, action) } } })
		case paneActionTypes.indexOf(action.type) > -1:
			return state?.isFetching ? state : update(state, { settings: { panes: { $set: paneReducer(state?.settings?.panes, action) } } })
		case actTypesActionTypes.indexOf(action.type) > -1:
			return state?.isFetching
				? state
				: update(state, { settings: { activity_type: { $set: actTypesReducer(state?.settings?.activity_type, action) } } })
		case action.type === ACTION_TYPES.UPDATE_GLASS_STICKY_ESC:
			return state?.isFetching
				? state
				: update(state, {
						settings: { sticky_escalation: { $set: action.payload.sticky_escalation }, sticky_reminder: { $set: action.payload.sticky_reminder } },
				  })
		case action.type === ACTION_TYPES.UPDATE_GLASS_LAYOUT:
			return state?.isFetching
				? state
				: update(state, {
						settings: { layout: { $set: action.payload.layout } },
				  })
		case action.type === ACTION_TYPES.UPDATE_GLASS_DETAILS_AFTER_EDIT:
			return state?.isFetching
				? state
				: {
						...state,
						glass_desc: action?.payload?.glass_desc,
						glass_name: action?.payload?.glass_name,
						modified_by: action?.payload?.modified_by,
						modified_date: action?.payload?.modified_date,
						sticky_permission: action?.payload?.sticky_permission,
				  }
		case action.type === ACTION_TYPES.UPDATE_STICKY_LIST:
			return state?.isFetching
				? state
				: update(state, {
						stickyList: { $set: action.payload.stickyList },
				  })
		case stickyActionTypes.indexOf(action.type) > -1:
			return state?.isFetching ? state : update(state, { stickyList: { $set: stickyListReducer(state?.stickyList, action, state?.settings?.panes) } })
		case action.type === ACTION_TYPES.RESET_GLASS:
			return initState
		default: {
			return state
		}
	}
}

export default glass
