import { ACTION_TYPES } from '../../../../constants'
import _ from 'lodash'
import update from 'immutability-helper'

const members = (state, action) => {
	const memberIdx = _.findIndex(state, { user_id: action?.payload?.userId })
	switch (action.type) {
		case ACTION_TYPES.UPDATE_GLASS_MEMBER_PENDING:
			return update(state, { [memberIdx]: { $set: { ...state[memberIdx], isUpdating: true } } })
		case ACTION_TYPES.UPDATE_GLASS_MEMBER_FULFILLED:
			const memberToUpdate = _.get(action, 'payload.data')
			return update(state, { [memberIdx]: { $set: { ...memberToUpdate } } })
		case ACTION_TYPES.UPDATE_GLASS_MEMBER_REJECTED:
			return update(state, { [memberIdx]: { $set: { ...state[memberIdx], isUpdating: false } } })
		case ACTION_TYPES.UPDATE_GLASS_MEMBER:
			return update(state, { [memberIdx]: { $set: _.get(action, 'payload') } })
		case ACTION_TYPES.ADD_GLASS_MEMBERS:
			//union method will replace the member if already exists in the state otherwise add the member
			//Replacing member will have flag is_active as true
			return [..._.unionBy(_.get(action, 'payload'), state, 'user_id')]
		// return update(state, { $push: [..._.get(action, 'payload')] }) //old method it won't work
		case ACTION_TYPES.REMOVE_GLASS_MEMBER:
			//This will update the existing member is_active flag as false
			return update(state, { [memberIdx]: { $set: { ...state[memberIdx], is_active: false } } })
		// return update(state, { $splice: [[memberIdx, 1]] }) //old method it won't work'
		default:
			return state
	}
}

export default members
