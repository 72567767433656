import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	filter: (filter_param) => {
		return Axios.post(API_ENDPOINTS.DEFAULT_FILTERS, filter_param, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
	updateFilter: (query) => {
		return Axios.post(API_ENDPOINTS.FILTER_UPDATE, query, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
}
