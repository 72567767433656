import React, { useState, useRef } from 'react'
import {
	Grid,
	Card,
	CardActions,
	CardContent,
	CardActionArea,
	Collapse,
	Icon,
	IconButton,
	Button,
	Divider,
	Typography,
	makeStyles,
	CircularProgress,
	Avatar,
	CardMedia,
	Chip,
} from '@material-ui/core'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { getImgUrl } from 'utils'
import defaultImg from '../../../images/default_avatar.png'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
	infoContainer: {
		textAlign: 'center',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		'& h6': {
			paddingTop: theme.spacing(2),
		},
	},
	loadingIndicatorContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		paddingTop: theme.spacing(6),
		paddingBottom: theme.spacing(6),
	},
	noRecordsIllustration: {
		width: '100px',
		height: '100px',
	},
	NoRecordsFound: {
		display: 'flex',
		justifyContent: 'center',
	},
	CardContainer: {
		// desktop view removed

		// display: 'flex',
		// flexDirection: 'column',
		// flexWrap: 'nowrap',

		// desktop view added
		display: 'flex',
		flexDirection: 'row',
		gap: '12px',
		flexWrap: 'wrap',
		marginBottom: '12px',
	},
	Card: {
		marginBottom: '0px',

		// desktop view added
		[theme.breakpoints.between(1390, 1515)]: {
			maxWidth: '32.6%',
			flexBasis: '32.6%',
		},
		[theme.breakpoints.between(1280, 1390)]: {
			maxWidth: '32.5%',
			flexBasis: '32.5%',
		},
		[theme.breakpoints.between(1125, 1280)]: {
			maxWidth: '32.6%',
			flexBasis: '32.6%',
		},
		[theme.breakpoints.between(995, 1125)]: {
			maxWidth: '32.5%',
			flexBasis: '32.5%',
		},
		[theme.breakpoints.between(960, 995)]: {
			maxWidth: '32.45%',
			flexBasis: '32.45%',
		},
		[theme.breakpoints.between(897, 960)]: {
			maxWidth: '49.3%',
			flexBasis: '49.3%',
		},
		[theme.breakpoints.between(791, 897)]: {
			maxWidth: '49.2%',
			flexBasis: '49.2%',
		},
		[theme.breakpoints.between(707, 791)]: {
			maxWidth: '49.1%',
			flexBasis: '49.1%',
		},
		[theme.breakpoints.between(660, 707)]: {
			maxWidth: '49%',
			flexBasis: '49%',
		},
		[theme.breakpoints.down(660)]: {
			maxWidth: '100%',
			flexBasis: '100%',
		},
		flexGrow: '0',
		maxWidth: '24.25%',
		flexBasis: '24.25%',
	},
	cardAction: {
		// background: '#fafafa',
	},
	mainCard: {
		padding: '8px !important',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		maxWidth: '100%',
	},
	media: {
		width: '100%',
		height: '140px',
		marginBottom: theme.spacing(1),
	},
	id: {
		fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
		fontWeight: '400',
		lineHeight: '1.75',
		letterSpacing: '0.00938em',
		fontSize: '0.9rem',
	},
	name: {
		fontSize: '18px',
	},
	title: {
		fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
		fontWeight: '400',
		lineHeight: '1.334',
		letterSpacing: '0em',
		fontSize: '1.1rem',
	},
	description: {
		fontSize: '16px',
	},
	locationIcn: {
		height: '23px',
		width: '23px',
		marginLeft: '-5px',
		color: '#65b17f',
	},
	location: {
		fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
		fontWeight: '400',
		lineHeight: '1.75',
		letterSpacing: '0.00938em',
		fontSize: '0.9rem',
		flexDirection: 'row',
		margin: '4px 0px',
	},
	subtitle1: {
		fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
		fontWeight: '400',
		lineHeight: '1.75',
		letterSpacing: '0.00938em',
		fontSize: '0.9rem',
	},
	subtitle2: {
		fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
		fontWeight: '400',
		lineHeight: '1.75',
		letterSpacing: '0.00938em',
		fontSize: '0.9rem',
	},
	success: {
		color: '#65b17f',
	},
	failed: {
		color: 'rgb(244, 67, 54)',
	},
	likeIcon: {
		fontSize: '14px',
		padding: '0px',
		color: '#65b17f',
		margin: '-2px 4px',
	},
	dislikeIcon: {
		fontSize: '14px',
		padding: '0px',
		color: 'rgb(244, 67, 54)',
		margin: '-2px 4px',
		transform: 'scaleX(-1)',
	},
	expandContainer: {
		margin: '0px',
	},
	cardExpanded: {
		padding: '1px 8px 8px 8px !important',
	},
	projectTitle: {
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
	},
	glassCode: {
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		marginTop: '0.35em',
	},
	glassTitle: {
		fontSize: theme.typography.pxToRem(18),
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		[theme.breakpoints.down('xs')]: {
			fontSize: theme.typography.pxToRem(16),
		},
	},
	selectedSummaryInfoRow: {
		'& .MuiTableCell-body': {
			height: 54,
			paddingLeft: `0 !important`,
			paddingRight: `0 !important`,
		},
		'& .MuiTableCell-root': {
			padding: '0px 0px 16px 0px !important',
		},
	},
	selectedSummaryInfoRowCustom: {
		'& .MuiTableCell-body': {
			height: 54,
			paddingLeft: `0 !important`,
			paddingRight: `0 !important`,
		},
		'& .MuiTableCell-root': {
			padding: '0px 0px 16px 0px !important',
		},
		[theme.breakpoints.up(600)]: {
			display: 'grid',
		},
	},
	selectionContainer: {
		[theme.breakpoints.down(535)]: {
			display: 'flex',
			flexDirection: 'column',
			textAlign: 'center',
		},
	},
	selectionInfoContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: theme.palette.almostBlack[100],
		padding: '4px 0px',
		// borderRadius: 5
	},
	selectionSummarySpan: {
		marginLeft: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		padding: theme.spacing(1),
		color: theme.palette.primary.main,
		cursor: 'pointer',
		// borderRadius: 5,
		'&:hover': {
			background: `${theme.palette.primary.main}11`,
		},
	},
	selectionSummary: {
		marginLeft: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		padding: theme.spacing(1),
		color: 'black',
		cursor: 'pointer',
	},
	price: {
		display: 'flex',
		flexDirection: 'column',
	},
	mobTableTitle: {
		color: '#9cadad',
		textTransform: 'uppercase',
		fontSize: '10px',
		fontWeight: '500',
		letterSpacing: '1px',
	},
	flagContainer: {
		display: 'flex',
		flexFlow: 'row',
		alignItems: 'center',
		maxWidth: 'min-content',
	},
	flagText: {
		'& .MuiTypography-body2': {
			padding: '2px 3px',
		},
	},
	flagCircle: {
		width: theme.spacing(1),
		height: theme.spacing(1),
		display: 'inline-block',
		marginRight: theme.spacing(1),
		borderRadius: '50%',
		background: theme.palette.warning.light,
	},
	avatarIcon: {
		'& .MuiAvatar-root': {
			width: '35px',
			height: '35px',
		},
	},
	mobLinkIcon: {
		'& .MuiIconButton-root': {
			padding: '0px 5px',
		},
		'& .material-icons': {
			fontSize: '20px',
		},
	},
	tableBody2: {
		color: theme.palette.almostBlack[800],
		letterSpacing: theme.typography.pxToRem(0.1),
	},
	sellingPriceText: {
		fontSize: theme.typography.pxToRem(14),
		fontWeight: 500,
		color: theme.palette.info.main,
		paddingRight: theme.spacing(0.5),
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	marketPriceText: {
		fontSize: theme.typography.pxToRem(14),
		fontWeight: 500,
		paddingRight: theme.spacing(0.5),
		color: theme.palette.success.main,
	},
	dataList: {
		marginRight: '5px',
		'& .MuiChip-sizeSmall': {
			height: '18px',
		},
	},
	mobImageIcon: {
		justifyContent: 'center',
		'& .MuiIconButton-root': {
			padding: '0px',
			'& .MuiIcon-root': {
				fontSize: '23px',
			},
		},
	},
	mobImage: {
		width: '100px',
		height: '60px',
	},
	rating: {
		fontWeight: '500',
		marginRight: theme.spacing(0.5),
		display: 'flex',
		flexDirection: 'row',
		'& .MuiIcon-root': {
			fontSize: '0.95rem',
			marginLeft: '2px',
			color: '#ffb400',
		},
	},
	dashX: {
		textAlignLast: 'start',
		padding: '18px 0px 12px 0px',
	},
	onlyDashX: {
		textAlignLast: 'center',
		padding: '18px 0px 12px 0px',
	},
	actionBtn: {
		'& .MuiGrid-item': {
			padding: '18px 12px 4px 12px',
		},
	},
	iconTxt: {
		fontSize: '17px',
		marginLeft: '4px',
	},
	glassX: {
		textAlignLast: 'start',
		padding: '18px 0px 12px 0px',
	},
	dashboardIcon: {
		'& .material-icons': {
			fontSize: '20px',
		},
	},
	viewColumnIcon: {
		'& .material-icons': {
			fontSize: '26px',
		},
	},
	profilePic: {
		width: 170,
		height: 170,
		[theme.breakpoints.down('xs')]: {
			width: 140,
			height: 140,
			margin: '0 auto',
		},
	},
	root: {
		border: `1px dashed ${theme.palette.text.secondary}`,
		position: 'relative',
		'&.error': {
			border: `1px dashed ${theme.palette.error.main}`,
		},
		'&:hover': {
			'& $delBtn': {
				display: 'flex',
				'&:hover': {
					color: theme.palette.error.main,
					backgroundColor: theme.palette.primary.contrastText,
				},
			},
		},
	},
	prevImg: {
		width: '100%',
		height: '100%',
	},
}))

const TableBody = (props) => {
	const {
		record,
		keyForCheckBoxSelectedList,
		expanded,
		handleSliderOpen,
		mobileHeaderKeys,
		isSelectMode,
		handleSelectData,
		setExportList,
		exportList,
		handleSelect,
		handleCheckBoxClick,
		isPickerMode,
		addPickedList,
		flagStatus,
		flag,
		idx,
		onClickFxn,
		onClickDashX,
		pageType,
		handleClickDashX,
		disableDashxBtn,
		onClickGlassX,
		handleClickGlassX,
		mobileContent,
		getExpandContent,
		setExpanded,
	} = props

	const classes = useStyles()

	const [isLongPress, setIsLongPress] = useState(false)
	const timeoutRef = useRef(null)

	const token = useSelector((state) => _.get(state, 'session.authToken'))
	const getPicUrl = (profilePic) => getImgUrl(token, profilePic, 650)

	const handleRegularClick = () => {
		// handleSliderClick(record)
		if (_.isEqual(pageType, 'modelx') || _.isEqual(pageType, 'peoplex')) {
			handleSliderOpen(record)
		} else {
			const valueIn = _.includes(expanded, record[keyForCheckBoxSelectedList])
			if (!valueIn) {
				setExpanded(_.concat(expanded, record[keyForCheckBoxSelectedList]))
			} else {
				setExpanded(_.without(expanded, record[keyForCheckBoxSelectedList]))
			}
		}
	}

	const handleLongPress = () => {
		isSelectMode && handleSelectData(record)
		setExportList(_.concat(exportList, record[keyForCheckBoxSelectedList]))
		isPickerMode && handleSelect(record)
		isSelectMode && handleSelect(record)
		handleCheckBoxClick(record)
		setIsLongPress(true)
	}

	const handleMouseDown = () => {
		timeoutRef.current = setTimeout(handleLongPress, 500)
	}

	const handleMouseUp = () => {
		clearTimeout(timeoutRef.current)

		if (!isLongPress) {
			handleRegularClick()
		}

		setIsLongPress(false)
	}

	const handleTouchStart = (event) => {
		event.preventDefault()
		handleMouseDown()
	}

	const handleTouchEnd = (event) => {
		event.preventDefault()
		handleMouseUp()
	}

	const handleTouchCancel = (event) => {
		event.preventDefault()
		handleMouseUp()
	}

	return (
		<>
			<Grid item key={record[keyForCheckBoxSelectedList]} className={classes.Card}>
				<Card variant='outlined'>
					<CardActionArea
						className={_.includes(expanded, record[keyForCheckBoxSelectedList]) && classes.cardAction}
						// onClick={(e) => {
						// 	handleExpandClick(e, record)
						// }}
						// onMouseDown={(e) => {
						// 	!selectionMode && handleMouseDown(e, record)
						// }}
						// onMouseUp={() => {
						// 	handleMouseUp()
						// }}
						// onTouchStart={(e) => {
						// 	!selectionMode && handleMouseDown(e, record)
						// }}
						// onTouchEnd={() => {
						// 	handleMouseUp()
						// }}

						onMouseDown={handleMouseDown}
						onMouseUp={handleMouseUp}
						onTouchStart={handleTouchStart}
						onTouchEnd={handleTouchEnd}
						onTouchCancel={handleTouchCancel}
					>
						<CardContent className={classes.mainCard}>
							{(isPickerMode
								? _.some(addPickedList, (obj) => _.isEqual(obj.id, record[keyForCheckBoxSelectedList]))
								: _.includes(exportList, record[keyForCheckBoxSelectedList])) && (
								<span className='card-icon-span'>
									<span className='card-selection-bg'></span>
									<Icon className='card-icon-style'>done</Icon>
								</span>
							)}
							{mobileHeaderKeys && mobileHeaderKeys['profileImg'] && (
								<CardMedia
									className={classes.media}
									height='140'
									image={!_.isEmpty(record[mobileHeaderKeys['profileImg']]) ? getPicUrl(record[mobileHeaderKeys['profileImg']]) : defaultImg}
									title={record[mobileHeaderKeys['title']]}
								/>
							)}
							{(mobileHeaderKeys['id_1'] || mobileHeaderKeys['score']) && (
								<Grid container style={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '5px' }}>
									<Grid item>
										<Typography variant='caption' color='textSecondary'>
											{mobileHeaderKeys && mobileHeaderKeys['id_1'] && record[mobileHeaderKeys['id_1']]}
										</Typography>
									</Grid>
									<Grid item>
										<Typography variant='caption' color='textSecondary' className={classes.rating}>
											{mobileHeaderKeys && mobileHeaderKeys['score'] && mobileHeaderKeys['score']?.title} :{' '}
											{mobileHeaderKeys && mobileHeaderKeys['score'] && record[mobileHeaderKeys['score']?.datakey]}
											<Icon>star_icon</Icon>
										</Typography>
									</Grid>
								</Grid>
							)}
							{(mobileHeaderKeys['id_2'] || mobileHeaderKeys['rating']) && (
								<Grid container style={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '5px' }}>
									<Grid item>
										<Typography variant='caption' color='textSecondary' gutterBottom className={classes.id}>
											{mobileHeaderKeys && mobileHeaderKeys['id_2'] && record[mobileHeaderKeys['id_2']]}
										</Typography>
									</Grid>
									<Grid item>
										<Typography variant='caption' color='textSecondary' className={classes.rating}>
											{mobileHeaderKeys && mobileHeaderKeys['rating'] && record[mobileHeaderKeys['rating']]}
											<Icon>star_icon</Icon>
										</Typography>
									</Grid>
								</Grid>
							)}
							<Typography variant='caption' color='textSecondary' gutterBottom className={classes.id}>
								{mobileHeaderKeys && mobileHeaderKeys['id'] && record[mobileHeaderKeys['id']]}
							</Typography>
							<Typography
								variant='h5'
								component='h2'
								className={classes.name}
								style={{ display: !_.isEmpty(mobileHeaderKeys['name']) ? 'list-item' : 'none' }}
							>
								{mobileHeaderKeys && mobileHeaderKeys['name'] && record[mobileHeaderKeys['name']]}
							</Typography>
							<Typography variant='h5' component='h2' className={classes.title}>
								{mobileHeaderKeys && mobileHeaderKeys['title'] && record[mobileHeaderKeys['title']]}
							</Typography>
							{mobileHeaderKeys && mobileHeaderKeys['description'] && (
								<Typography variant='caption' gutterBottom className={classes.description}>
									{mobileHeaderKeys && mobileHeaderKeys['description'] && record[mobileHeaderKeys['description']]}
								</Typography>
							)}
							<Typography
								variant='caption'
								color='textSecondary'
								gutterBottom
								className={classes.subtitle1}
								style={{ display: !_.isEmpty(mobileHeaderKeys['subtitle1']) ? 'list-item' : 'none' }}
							>
								{!_.isEmpty(mobileHeaderKeys) && !_.isEmpty(mobileHeaderKeys['subtitle1']) && record[mobileHeaderKeys['subtitle1']]}
							</Typography>
							<Typography
								variant='caption'
								color='textSecondary'
								gutterBottom
								className={classes.location}
								style={{ display: !_.isEmpty(mobileHeaderKeys['location']) ? 'flex' : 'none' }}
							>
								<Icon className={classes.locationIcn}>location_on</Icon>
								{!_.isEmpty(mobileHeaderKeys) && !_.isEmpty(mobileHeaderKeys['location']) && !_.isEmpty(record[mobileHeaderKeys['location']])
									? record[mobileHeaderKeys['location']]
									: 'None'}
							</Typography>
							<Typography
								variant='body2'
								component='p'
								className={classnames(
									classes.subtitle2,
									_.isEqual(record[mobileHeaderKeys['subtitle2']], 'success') && classes.success,
									_.isEqual(record[mobileHeaderKeys['subtitle2']], 'failed') && classes.failed
								)}
							>
								{mobileHeaderKeys && mobileHeaderKeys['subtitle2'] && record[mobileHeaderKeys['subtitle2']]}
							</Typography>
							<Grid container style={{ justifyContent: 'space-evenly', alignItems: 'center', marginTop: '5px', gap: '5px' }}>
								{!_.isEmpty(mobileHeaderKeys) && !_.isEmpty(mobileHeaderKeys['subtitleA']) && (
									<Grid item>
										<Typography variant='caption' color='textSecondary'>
											{mobileHeaderKeys['subtitleA']?.title} : {record[mobileHeaderKeys['subtitleA']?.datakey]}
										</Typography>
									</Grid>
								)}
								<Divider orientation='vertical' flexItem />
								{!_.isEmpty(mobileHeaderKeys) && !_.isEmpty(mobileHeaderKeys['subtitleB']) && (
									<Grid item>
										<Typography variant='caption' color='textSecondary'>
											{mobileHeaderKeys['subtitleB']?.title} : {record[mobileHeaderKeys['subtitleB']?.datakey]}
										</Typography>
									</Grid>
								)}
							</Grid>
							{(flagStatus || mobileHeaderKeys['actions']) && (
								<Grid container style={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '5px' }}>
									{flagStatus && <Grid item>{flag(flagStatus, idx, record)}</Grid>}
									{mobileHeaderKeys && mobileHeaderKeys['actions'] && (
										<Grid item className={classes.mobLinkIcon} key={idx}>
											<IconButton
												size={'size'}
												onClick={(e) => onClickFxn(e, mobileHeaderKeys['actions'], record)}
												title={'Link'}
												aria-label={'Link'}
											>
												<Icon style={mobileHeaderKeys['actions']?.styleIcon ? mobileHeaderKeys['actions']?.styleIcon : {}}>
													{mobileHeaderKeys['actions']?.icon}
												</Icon>
											</IconButton>
										</Grid>
									)}
								</Grid>
							)}
							{onClickDashX && _.isEqual(pageType, 'peoplex') && (
								<Grid container spacing={2} xs={12} style={{ justifyContent: 'center' }} className={classes.actionBtn}>
									{onClickDashX && (
										<Grid item xs={12} className={classes.onlyDashX}>
											<Button key={idx} size='small' color='primary' onClick={(e) => handleClickDashX(e, record)} disabled={disableDashxBtn}>
												{'Dashboard'}
											</Button>
										</Grid>
									)}
								</Grid>
							)}
							{(onClickDashX || onClickGlassX) && _.isEqual(pageType, 'modelx') && (
								<Grid container spacing={2} xs={12} style={{ justifyContent: 'center' }} className={classes.actionBtn}>
									{onClickDashX && (
										<Grid item xs={6} className={classes.dashX}>
											<Button key={idx} size='small' color='primary' disableDashxBtn={disableDashxBtn} onClick={(e) => handleClickDashX(e, record)}>
												{'Dashboard'}
											</Button>
										</Grid>
									)}
									{onClickGlassX && (
										<Grid item xs={6} className={classes.glassX}>
											<Button key={idx} size='small' color='primary' onClick={(e) => handleClickGlassX(e, record)}>
												{'Boards'}
											</Button>
										</Grid>
									)}
								</Grid>
							)}
						</CardContent>
					</CardActionArea>
					<Collapse
						in={_.includes(expanded, record[keyForCheckBoxSelectedList]) && !_.isEqual(pageType, 'modelx') && !_.isEqual(pageType, 'peoplex')}
						timeout='auto'
						unmountOnExit
					>
						<CardContent className={classes.cardExpanded}>
							<Grid container spacing={2} xs={12} className={classes.expandContainer}>
								{mobileContent.map((data, idx) => getExpandContent(data, idx, record))}
							</Grid>
							{(onClickDashX || onClickGlassX) && (
								<Grid container spacing={2} xs={12} style={{ justifyContent: 'center' }}>
									{onClickDashX && (
										<Grid item xs={6} className={classes.dashX}>
											<IconButton size='small' title='Show Actions' className={classes.dashboardIcon} onClick={(e) => handleClickDashX(e, record)}>
												<i className='material-icons' fontSize='small'>
													dashboard
												</i>
												<Typography variant='body2' component='p' className={classes.iconTxt}>
													Dashboard
												</Typography>
											</IconButton>
										</Grid>
									)}
									{onClickGlassX && (
										<Grid item xs={6} className={classes.glassX}>
											<IconButton size='small' title='Show Actions' className={classes.viewColumnIcon} onClick={(e) => handleClickGlassX(e, record)}>
												<i className='material-icons' fontSize='small'>
													view_column
												</i>
												<Typography variant='body2' component='p' className={classes.iconTxt}>
													Boards
												</Typography>
											</IconButton>
										</Grid>
									)}
								</Grid>
							)}
						</CardContent>
					</Collapse>
				</Card>
			</Grid>
		</>
	)
}

export default TableBody
