import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import { getImgUrl } from 'utils'
import { useSelector } from 'react-redux'

import {
	Icon,
	IconButton,
	Typography,
	List,
	ListItem,
	Divider,
	ListItemText,
	ListItemAvatar,
	Avatar,
	Button,
	ButtonBase,
	Collapse,
} from '@material-ui/core'
import moment from 'moment'
import _ from 'lodash'
import { convertGmtToLocalTime, stringToHslColor } from 'utils'
import GlassApi from '../../../services/glass/api'
import { useDispatch } from 'react-redux'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { getErrMsg } from 'utils'
import { Skeleton } from '@material-ui/lab'
// import update from 'immutability-helper'

const useStyles = makeStyles((theme) => ({
	drawer: {
		'& .MuiDrawer-paper': {
			maxWidth: '36ch',
			width: '100%',
			minWidth: '36ch',
		},
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '12px 16px',
	},
	menuTitle: {
		fontSize: '1rem',
		paddingRight: '16px',
		flex: '1',
	},
	listHolder: {
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			background: `${theme.palette.primary.main}`,
		},
	},
	listContainer: {
		width: '100%',
		padding: 0,
		// '& .MuiListItemText-secondary': {
		// 	display: '-webkit-box',
		// 	WebkitLineClamp: 2,
		// 	WebkitBoxOrient: 'vertical',
		// 	overflow: 'hidden',
		// 	textOverflow: 'ellipsis',
		// },
		'& .MuiDivider-root': {
			backgroundColor: 'rgb(0 0 0 / 8%)',
		},
	},
	userList: {
		display: 'flex',
		flexDirection: 'column',
	},
	sortButton: {
		textTransform: 'none',
		color: '#92929d',
		lineHeight: 'normal',
		'& .MuiButton-endIcon > span': {
			fontSize: '16px',
		},
		paddingRight: '8px',
	},
	showMoreBtn: {
		margin: '0 auto',
	},
	listSubText: {
		marginTop: 15,
	},
	loadingSkeleton: {
		display: 'flex',
		padding: '16px',
		'& .MuiSkeleton-circle': {
			marginRight: '10px',
		},
	},
	lineSkeleton: {
		width: '100%',
	},
	userName: {
		fontWeight: 500,
	},
	createdDate: {
		fontSize: 12,
		marginTop: theme.spacing(0.5),
	},
	refreshBtn: {
		backgroundColor: `${theme.palette.success.main}54`,
	},
	refreshBtnTxt: {
		padding: theme.spacing(1),
	},
}))

const Header = ({ handleClose, handleSort, buttonUI }) => {
	const classes = useStyles()
	return (
		<>
			<div className={classes.header}>
				<Typography variant='h6' className={classes.menuTitle}>
					Actions
				</Typography>
				<Button size='small' className={classes.sortButton} endIcon={<Icon fontSize='small'>{buttonUI.btnIcon}</Icon>} onClick={handleSort}>
					{buttonUI.btnText}
				</Button>
				<IconButton size='small' title='Close Menu' onClick={handleClose}>
					<Icon fontSize='small'>close</Icon>
				</IconButton>
			</div>
			<Divider />
		</>
	)
}

const ActivityItem = ({ userName, createdDate, comments, loading, date_gmt, img_Src, authToken }) => {
	const classes = useStyles()
	const getPicUrl = (img_Src) => getImgUrl(authToken, img_Src, 50)
	const profilePicUrl = img_Src && getPicUrl(img_Src)

	return (
		<List className={classes.listContainer}>
			<ListItem alignItems='flex-start'>
				<ListItemAvatar>
					{loading ? (
						<div>
							<Skeleton animation='wave' variant='circle' width={40} height={40} />
						</div>
					) : (
						<Avatar
							alt={userName}
							src={_.isEmpty(profilePicUrl) ? 'src' : profilePicUrl}
							style={{
								backgroundColor: stringToHslColor(userName),
							}}
						/>
					)}
				</ListItemAvatar>
				<ListItemText
					primary={
						loading && (
							<div>
								<Skeleton animation='wave' height={10} width='80%' />
							</div>
						)
					}
					secondary={
						loading ? (
							<div>
								<Skeleton animation='wave' height={40} width='80%' />
							</div>
						) : (
							<React.Fragment>
								<Typography
									title={convertGmtToLocalTime(date_gmt, 'DD MMM YYYY hh:mm a')}
									component='span'
									variant='body2'
									className={classes.userName}
									color='textPrimary'
								>
									{userName}
								</Typography>
								<Typography component='span' variant='body2' className={classes.comments}>
									{` ${comments}`}
								</Typography>
								<Typography component='p' variant='body2' className={classes.createdDate}>
									{createdDate}
								</Typography>
							</React.Fragment>
						)
					}
				/>
			</ListItem>
			<Divider variant='inset' component='li' />
		</List>
	)
}

const ActivityList = ({ testArray, loading }) => {
	const classes = useStyles()
	const authToken = useSelector((state) => _.get(state, 'session.authToken', ''))

	return (
		<div className={classes.listHolder}>
			{!_.isEmpty(testArray) ? (
				<>
					{testArray.map((item, index) => (
						<ActivityItem
							key={index}
							userName={_.join([_.get(item, 'created_by.first_name', ''), _.get(item, 'created_by.last_name', '')], ' ')}
							createdDate={moment(_.get(item, 'created_date', '')).fromNow()}
							comments={_.get(item, 'log_notes', '')}
							loading={loading}
							date_gmt={_.get(item, 'created_date', '')}
							img_Src={_.get(item, 'created_by.profile_pic_url', '')}
							authToken={authToken}
						/>
					))}
				</>
			) : loading ? (
				<div className={classes.loadingSkeleton}>
					<Skeleton animation='wave' variant='circle' width={54} height={44} />
					<div className={classes.lineSkeleton}>
						<Skeleton animation='wave' height={10} width='80%' />
						<Skeleton animation='wave' height={40} width='80%' />
					</div>
				</div>
			) : (
				<>
					<Typography variant='body1' component='div' align='center' className={classes.listSubText}>
						No Activities Found
					</Typography>
				</>
			)}
		</div>
	)
}

const ActivityDrawer = ({ isSocketConnected, socketRef, open, handleClose, glassCode }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [buttonUI, setButtonUI] = useState({ btnText: 'Newest first', btnIcon: 'arrow_upward' })
	const [activityState, setActivityState] = useState({
		loading: true,
		activityList: [],
		limit: 50,
		offset: 0,
		sort_by: 'desc',
		total_count: 0,
		showRefreshBtn: false,
	})
	const { loading, activityList, limit, offset, sort_by, total_count, showRefreshBtn } = activityState

	const logListener = useRef()

	useEffect(() => {
		if (isSocketConnected) {
			logListener.current = (resp) => {
				if (sort_by === 'desc')
					setActivityState((prevState) => ({
						...prevState,
						offset: prevState.offset + 1,
						total_count: prevState.total_count + 1,
						activityList: [resp.data, ...prevState.activityList],
					}))
				else setActivityState((prevState) => ({ ...prevState, showRefreshBtn: true }))
			}
			socketRef.current?.on('glass_logs', logListener.current)
		}
		return () => {
			if (isSocketConnected && logListener.current) {
				socketRef.current?.off('sticky_update', logListener.current)
			}
		}
	}, [isSocketConnected, socketRef, sort_by])

	useEffect(() => {
		if (open && loading) {
			GlassApi.fetchGlassLogs(glassCode, limit, offset, sort_by)
				.then((res) => {
					let actions = _.get(res, 'data.data.logs', [])
					setActivityState((prevState) => ({
						...prevState,
						loading: false,
						showRefreshBtn: false,
						activityList: offset === 0 ? actions : [...prevState.activityList, ...actions],
						total_count: _.get(res, 'data.data.total_count', 0),
					}))
				})
				.catch((err) => {
					setActivityState((prevState) => ({ ...prevState, loading: false }))
					dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
				})
		} else if (!open) {
			setActivityState((prevState) => ({
				...prevState,
				loading: true,
				activityList: [],
				total_count: 0,
				offset: 0,
				limit: 50,
			}))
		}
	}, [open, loading, offset, limit, glassCode, sort_by, dispatch])

	const onClickShowMoreBtn = () => {
		setActivityState((prevState) => ({
			...prevState,
			offset: prevState.offset + limit,
			loading: true,
		}))
	}

	const hideShowMoreBtn = total_count === activityList.length

	const handleSort = () => {
		let buttonText = buttonUI.btnText === 'Newest first' ? 'Oldest first' : 'Newest first'
		let buttonIcon = buttonUI.btnIcon === 'arrow_upward' ? 'arrow_downward' : 'arrow_upward'
		setButtonUI({ btnText: buttonText, btnIcon: buttonIcon })
		setActivityState((prevState) => ({
			...prevState,
			sort_by: buttonText === 'Newest first' ? 'desc' : 'asc',
			limit: prevState.activityList.length,
			loading: true,
			offset: 0,
		}))
	}

	return (
		<React.Fragment>
			<Drawer anchor='right' open={open} onClose={handleClose} className={classes.drawer}>
				<Header handleClose={handleClose} handleSort={handleSort} buttonUI={buttonUI} />
				{!loading && showRefreshBtn && (
					<ButtonBase onClick={handleSort} className={classes.refreshBtn}>
						<Collapse in={!loading}>
							<Typography className={classes.refreshBtnTxt} align='center' component='div' variant='caption'>
								New logs found, Click here to refresh
							</Typography>
						</Collapse>
					</ButtonBase>
				)}

				<ActivityList testArray={activityList} loading={loading && offset === 0} />
				{!hideShowMoreBtn && (
					<Button disabled={loading} onClick={onClickShowMoreBtn} className={classes.showMoreBtn} size='small' color='primary'>
						{loading ? 'Loading...' : `Show More (${total_count - activityList.length})`}
					</Button>
				)}
			</Drawer>
		</React.Fragment>
	)
}

export default ActivityDrawer
