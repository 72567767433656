import React, { useMemo, useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Card, CardHeader, CardMedia, CardContent, CardActions, Icon, Avatar, IconButton, Typography, Grid, Button } from '@material-ui/core'
import TableCell from '../TableCell/index'
import TableRow from '../TableRow/index'
import { NoRecordsIllustration } from '../../illustrators'
import { red } from '@material-ui/core/colors'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ShareIcon from '@material-ui/icons/Share'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { PRIORITY_LIST, STICKY_STATUS, STICKY_ESC_TIMER } from 'constants/index'
import { GLASS_ROLES } from 'constants/index'
import { convertGmtToLocalTime, getImgUrl, stringToHslColor, getErrMsg } from 'utils'
import { hideLoader, showErrorMessage } from 'services/loader/actions'
import TuneIcon from '@material-ui/icons/Tune'
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined'
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined'
import classnames from 'classnames'
import AppEventBus from 'utils/event-bus'
import _ from 'lodash'
import moment from 'moment'
import Attachemnt from './Attachment'
import Labels from './Labels'
import { Skeleton } from '@material-ui/lab'
import useLazyPaginate from 'hooks/useLazyPaginate'
import InputLabels from 'components/InputLabels'
import { ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import GlassApi from '../../services/glass/api'
import update from 'immutability-helper'
import Activities from './Activities'
import { useMediaQuery } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	cardContainer: {
		alignSelf: 'center',
		padding: '20px',
		width: '64%',
		[theme.breakpoints.down(600)]: {
			width: '94%',
			padding: '10px 20px 0px 20px',
		},
	},
	root: {
		'& .MuiCardHeader-root': {
			padding: '16px 16px 8px 16px',
		},
	},
	infoContainer: {
		textAlign: 'center',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		'& h6': {
			paddingTop: theme.spacing(2),
		},
	},
	NoRecordsFound: {
		display: 'flex',
		justifyContent: 'space-around',
	},
	createDate: {
		display: 'flex',
		justifyContent: 'center',
	},
	noRecordsIllustration: {
		width: '100px',
		height: '100px',
	},
	stickyTitle: {
		fontSize: '0.95rem',
	},
	stickyId: {
		display: 'flex',
		padding: '0px 16px',
		alignItems: 'center',
		fontSize: '14px',
	},
	media: {
		height: 0,
		paddingTop: '56.25%', // 16:9
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	avatar: {
		backgroundColor: red[500],
	},
	subHeader: {
		display: 'flex',
		flexDirection: 'row',
	},
	chip: {
		width: 'auto',
		padding: theme.spacing(0.2, 0.8),
		backgroundColor: (props) => props.priorityColor || theme.palette.error.main,
		color: theme.palette.common.white,
		marginRight: theme.spacing(1),
		fontWeight: '500',
	},
	dueDate: {
		display: 'flex',
		alignItems: 'center',
		color: theme.palette.text.secondary,
		'& span': {
			fontWeight: '400',
		},
	},
	dateIcon: {
		fontSize: theme.typography.pxToRem(13),
		marginRight: '8px',
		color: 'inherit',
	},
	markBlue: {
		color: theme.palette.info.main,
		'& span': {
			fontWeight: '500',
		},
	},
	markYellow: {
		color: theme.palette.warning.main,
		'& span': {
			fontWeight: '500',
		},
	},
	markRed: {
		color: theme.palette.error.main,
		'& span': {
			fontWeight: '500',
		},
	},
	markGreen: {
		color: theme.palette.success.main,
		'& span': {
			fontWeight: '500',
		},
	},
	markOrange: {
		color: theme.palette.warning.main,
		'& span': {
			fontWeight: '500',
		},
	},
	richTextContent: {
		fontSize: 14,
	},
	descriptionBtn: {
		color: '#65b17f',
		textTransform: 'lowercase',
		fontSize: '14px',
		cursor: 'pointer',
		fontWeight: 'bold',
		display: 'inline-block',
		marginBottom: '8px',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	stickyReactionMain: {
		padding: '0px 16px 16px',
	},
	stickyReactions: {
		marginLeft: '0px',
		display: 'flex',
		flexDirection: 'row',
		paddingTop: '2px',
	},
	likeIcon: {
		fontSize: '14px',
		padding: '3px 1px',
		color: '#65b17f',
		transform: 'scaleX(-1)',
	},
	reactionCount: {
		fontSize: '13px',
		color: 'slategray',
	},
	activityCount: {
		fontSize: '13px',
		color: 'slategray',
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	dislikeIcon: {
		fontSize: '14px',
		padding: '3px 1px',
		color: 'rgb(244, 67, 54)',
	},
	dot: {
		color: 'slategray',
	},
	bar: {
		color: 'slategray',
	},
	replyBtn: {
		fontSize: '10px',
		padding: '0px 0px',
		minWidth: '42px',
	},
	loadingSkeleton: {
		display: 'flex',
		padding: '8px',
		margin: '8px 0px',
		justifyContent: 'center',
		'& .MuiSkeleton-circle': {
			marginRight: '10px',
		},
		[theme.breakpoints.down(600)]: {
			width: '128%',
			alignSelf: 'center',
		},
	},
	lineSkeleton: {
		width: '60%',
	},
	activityTypeItem: {
		textAlign: '-webkit-right',
	},
	gridContainer: {
		display: 'flex',
	},
}))

const DueDateInfo = React.memo(({ classes, dueDate, stickyStatus, isApproval }) => {
	const escTimerListener = useRef()
	const isRejected = useMemo(() => _.isEqual(stickyStatus, STICKY_STATUS.REJECTED), [stickyStatus])
	const isCompleted = useMemo(
		() => (isApproval ? _.isEqual(stickyStatus, STICKY_STATUS.APPROVED) : _.isEqual(stickyStatus, STICKY_STATUS.COMPLETED)),
		[isApproval, stickyStatus]
	)
	const isInprogress = useMemo(() => _.isEqual(stickyStatus, STICKY_STATUS.INPROGRESS), [stickyStatus])
	const isOpened = useMemo(() => _.isEqual(stickyStatus, STICKY_STATUS.OPEN), [stickyStatus])
	const isReopened = useMemo(() => _.isEqual(stickyStatus, STICKY_STATUS.REOPENED), [stickyStatus])

	const isWaiting = useMemo(() => isApproval && _.isEqual(stickyStatus, STICKY_STATUS.COMPLETED), [isApproval, stickyStatus])

	const [{ isEscalated, isReminder }, setState] = useState({
		isEscalated: false,
		isReminder: false,
	})

	const dueDateTime = useMemo(() => {
		setState({ isEscalated: false, isReminder: false })
		return dueDate ? moment(convertGmtToLocalTime(dueDate)) : null
	}, [dueDate])

	useEffect(() => {
		if (dueDateTime) {
			escTimerListener.current = (e) => {
				const { stickyEscValHrs, stickyRemindMins, currentDateTime, isFirstTrigger } = e.detail
				if (isEscalated && !isFirstTrigger) return
				else if (moment(dueDateTime).add(stickyEscValHrs, 'h') < currentDateTime) setState({ isReminder: false, isEscalated: true })
				else if (!_.isEmpty(stickyRemindMins) && moment(dueDateTime).subtract(_.toNumber(stickyRemindMins), 'm') < currentDateTime)
					setState((prev) => ({ ...prev, isReminder: true }))
				else if (isReminder) setState((prev) => ({ ...prev, isEscalated: false, isReminder: false }))
			}
			AppEventBus.on(STICKY_ESC_TIMER, escTimerListener.current)
		}
		return () => {
			AppEventBus.remove(STICKY_ESC_TIMER, escTimerListener.current)
		}
	}, [dueDateTime, isEscalated, isReminder])

	return (
		<Grid
			className={classnames(classes.dueDate, {
				[classes.markYellow]: isReminder,
				[classes.markRed]: isEscalated || isRejected,
				[classes.markBlue]: isInprogress,
				[classes.markGreen]: isCompleted,
				[classes.markOrange]: isWaiting,
			})}
			alignItems='center'
			justifycontent='center'
		>
			<Icon title={`Task Status: ${_.capitalize(stickyStatus)}`} className={classes.dateIcon} fontSize='small'>
				{isCompleted
					? 'verified'
					: isInprogress
					? 'hourglass_top'
					: isRejected
					? 'thumb_down'
					: isOpened
					? 'play_circle'
					: isReopened
					? 'replay_circle_filled'
					: dueDate
					? 'pending_actions'
					: ''}
			</Icon>
			{dueDate && (
				<Typography
					title={`Due date: ${dueDateTime.format('MMM DD, YYYY hh:mm a')}`}
					variant='caption'
					color='inherit'
					style={{ display: 'contents' }}
				>
					{dueDateTime.format('MMM DD')}
				</Typography>
			)}
		</Grid>
	)
})

const Cards = (props) => {
	const { sticky, onSliderClick, token, userDetails, updateStickyItem, isSocketConnected, socketIO, callSocketFunction } = props
	const {
		sticky_title,
		sticky_tone,
		created_date,
		priority,
		sticky_status,
		is_approval,
		due_date,
		created_by,
		reporting_to,
		sticky_code,
		attachments,
		attachments_count,
		labels,
		glass_code,
		sticky_desc,
		sticky_reactions,
		activities_count,
		project_title,
		history,
	} = sticky
	const priorityObj = useMemo(() => _.find(PRIORITY_LIST, { value: priority }), [priority])
	const classes = useStyles({ priorityColor: priorityObj?.color })
	const dispatch = useDispatch()

	const [glassDetails, setGlassDetails] = useState({
		activityCount: activities_count,
		activityTypes: [],
		glassLabels: [],
		glassMembers: [],
		glassCode: '',
	})
	const { activityCount, activityTypes, glassLabels, glassMembers, glassCode } = glassDetails
	const [showFullDescription, setShowFullDescription] = useState(false)
	const paragraphRef = useRef(null)

	const toggleDescription = () => {
		setShowFullDescription(!showFullDescription)
		if (showFullDescription) {
			paragraphRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			})
		}
	}
	const [showActivityBox, setShowActivityBox] = useState(false)
	const [showActivity, setShowActivity] = useState(false)

	const currentUserId = useSelector((state) => _.get(state, 'session.user.userDetails.user_id'))
	console.log('USERID**', currentUserId)
	const reportedTo = useMemo(() => _.isEqual(reporting_to, currentUserId), [currentUserId, reporting_to])
	const isOwner = useMemo(() => _.findIndex(glassMembers, { user_id: currentUserId, role: GLASS_ROLES.OWNER }) > -1, [glassMembers, currentUserId])
	const isGuest = useMemo(() => _.findIndex(glassMembers, { user_id: currentUserId, role: GLASS_ROLES.GUEST }) > -1, [glassMembers, currentUserId])
	const isOwnerOrReporter = (isOwner || reportedTo) && !isGuest

	const likeCount = _.sum(
		_.map(
			_.map(sticky_reactions, (reactions) => {
				return reactions
			}),
			(data) => {
				return data?.like
			}
		)
	)
	const dislikeCount = _.sum(
		_.map(
			_.map(sticky_reactions, (reactions) => {
				return reactions
			}),
			(data) => {
				return data?.dislike
			}
		)
	)
	const reactionStatus = _.toString(
		_.map(_.filter(sticky_reactions, { created_by: userDetails?.user_id }), (data) => {
			return data?.reactions
		})
	)

	const membersSuggestions = useMemo(
		() => _.map(glassMembers, (member) => ({ text: _.join([member?.first_name, member?.last_name], ' '), value: member?.email, url: '' })),
		[glassMembers]
	)

	useEffect(() => {
		setGlassDetails((prevState) => ({
			...prevState,
			activityCount: activities_count,
		}))
	}, [activities_count])

	const updateStickyReactions = (sticky, value) => {
		let formData = {
			reactions: value,
		}
		const onSuccess = (res) => {
			updateStickyItem(res?.data?.data[0], sticky?.sticky_code)
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		GlassApi.updateStickyReactions(sticky?.glass_code, sticky?.sticky_code, formData).then(onSuccess, onFailure)
	}

	const openActivityBox = () => {
		setShowActivityBox(!showActivityBox)
	}

	const openActivity = () => {
		setShowActivity(!showActivity)
	}
	console.log('STATUS', sticky_title)
	console.log('TONE', sticky_tone)
	console.log('CREATEDDATE', created_date)

	const emojis = ['\u{1F621}', '\u{1F615}', '\u{1F61E}', '\u{1F64F}', '\u{1F60C}', '\u{1F622}', '\u{1F610}', '\u{1F91E}', '\u{1F6A8}']

	const toneToEmojiIndex = {
		frustration: 0,
		concerned: 1,
		dissatisfaction: 2,
		respectful: 3,
		polite: 4,
		sad: 5,
		calm: 6,
		hopeful: 7,
		urgent: 8,
	}
	const emojiIndex = toneToEmojiIndex[sticky_tone]
	const emoji = emojis[emojiIndex]
	const isMobile = useMediaQuery('(max-width:600px)')
	const isMobileOrTablet = useMediaQuery('(max-width:960px)')
	const isMobileView = useMediaQuery('(max-width:600px)')
	const isTabletView = useMediaQuery('(min-width:601px) and (max-width:1024px)')

	const formattedDate = isMobileOrTablet
		? convertGmtToLocalTime(created_date, 'DD MMM YYYY hh:mm a')
		: convertGmtToLocalTime(created_date, 'DD MMM YYYY hh:mm a')
	console.log('PROJECT_TITLE', project_title)

	const maxLength = 120 // Adjust the max length as needed
	const truncatedTitle = project_title.length > maxLength ? `${project_title.substring(0, maxLength)}...` : project_title
	// const formattedDate = isMobileOrTablet ? convertGmtToLocalTime(created_date, 'DD MMM YYYY') : convertGmtToLocalTime(created_date, 'DD MMM YYYY')
	return (
		<Card className={classes.root} ref={paragraphRef}>
			<CardHeader
				avatar={
					<Grid
						item
						className={classes.compactAvatarWrapper}
						title={`${created_by?.first_name || ''} ${created_by?.last_name || ''}\n${created_by?.email}`}
					>
						<Avatar
							className={classes.cardAvatar}
							src={created_by?.profile_img ? getImgUrl(token, created_by?.profile_img, 50) : null}
							style={{ backgroundColor: stringToHslColor(`${created_by?.first_name} ${created_by?.last_name}`) }}
						>
							{_.slice(created_by?.first_name, 0, 1)}
						</Avatar>
					</Grid>
				}
				action={
					<IconButton aria-label='settings' onClick={(e) => onSliderClick(e, sticky)}>
						<Icon style={{ fontSize: '1.25rem' }}>tune_icon</Icon>
					</IconButton>
				}
				title={
					<>
						<div
							style={{
								display: 'flex',
								justifyContent: isMobile ? 'flex-start' : 'space-between',
								flexDirection: isMobileOrTablet ? 'column' : 'row',
								alignItems: 'flex-start',
								marginTop: '2px',
							}}
						>
							<Typography
								variant='subtitle2'
								className={classes.stickyTitle}
								title={sticky_title}
								style={{
									marginBottom: isMobileOrTablet ? '8px' : '0',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
								}}
							>
								{isMobileView
									? sticky_title.length > 30
										? `${sticky_title.substring(0, 20)}...`
										: sticky_title
									: isTabletView
									? sticky_title.length > 40
										? `${sticky_title.substring(0, 40)}...`
										: sticky_title
									: sticky_title.length > 80
									? `${sticky_title.substring(0, 80)}...`
									: sticky_title}
							</Typography>

							<Typography
								variant='body2'
								color='textSecondary'
								style={{
									alignSelf: isMobileOrTablet ? 'flex-start' : 'center',
									marginBottom: isMobileOrTablet ? '10px' : '0',
								}}
							>
								<div>{formattedDate}</div>
							</Typography>
						</div>
					</>
				}
				date={
					<Typography variant='body2' color='textSecondary' style={{ float: 'right' }}>
						{convertGmtToLocalTime(created_date, 'DD MMM YYYY hh:mm a')}
					</Typography>
				}
				subheader={
					<div className={classes.subHeader}>
						{priorityObj && (
							<Grid title='Priority' item>
								<Typography className={classes.chip} variant='caption'>
									{priorityObj?.name}
								</Typography>
							</Grid>
						)}

						<DueDateInfo classes={classes} stickyStatus={sticky_status?.status} isApproval={is_approval} dueDate={due_date} />
					</div>
				}
			/>
			<div className={classes.gridContainer}>
				<Typography className={classes.stickyId} variant='body2' color='textSecondary'>
					{sticky_code}
				</Typography>
				<span color='textSecondary'>-</span>
				<Typography
					title={project_title}
					className={classes.projectTitle}
					variant='body2'
					color='textSecondary'
					style={{
						marginLeft: isMobileOrTablet ? '8px' : '8px',
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
					}}
				>
					{/* {truncatedTitle} */}
					{isMobileView
						? project_title.length > 30
							? `${project_title.substring(0, 20)}...`
							: project_title
						: isTabletView
						? project_title.length > 40
							? `${project_title.substring(0, 40)}...`
							: project_title
						: project_title.length > 80
						? `${project_title.substring(0, 120)}...`
						: project_title}
				</Typography>
			</div>
			{!_.isEmpty(attachments) ? (
				<Attachemnt fileList={attachments} attachments_count={attachments_count} accessToken={token} sticky={sticky} sticky_title={sticky_title} />
			) : null}

			<CardContent>
				<div>
					{!_.isEmpty(ContentState.createFromBlockArray(htmlToDraft(!_.isNull(sticky_desc) ? sticky_desc : '')).getPlainText()) ? (
						<div
							className={classes.richTextContent}
							dangerouslySetInnerHTML={{
								__html:
									!_.isNull(sticky_desc) && !showFullDescription
										? sticky_desc.slice(0, 300)
										: !_.isNull(sticky_desc) && showFullDescription
										? sticky_desc
										: '',
							}}
						></div>
					) : null}
					{(!_.isNull(sticky_desc) ? sticky_desc.length : 0) > 300 && (
						<Typography className={classes.descriptionBtn} onClick={toggleDescription}>
							{showFullDescription ? '...read less' : 'read more...'}
						</Typography>
					)}
				</div>
				{!_.isEmpty(labels) && (
					<div className={classes.varticalSpace}>
						<Labels disabled={true} value={_.map(labels, (label) => label?.label_id)} labels={labels} isDescription={true} />
					</div>
				)}
			</CardContent>
			<CardActions disableSpacing className={classes.stickyReactionMain}>
				<div className={classes.stickyReactions}>
					{_.isEqual(reactionStatus, 'like') ? (
						<Icon
							className={classes.likeIcon}
							onClick={() => {
								updateStickyReactions(sticky, '')
							}}
						>
							thumb_up_icon
						</Icon>
					) : (
						<ThumbUpOutlinedIcon
							className={classes.likeIcon}
							onClick={() => {
								updateStickyReactions(sticky, 'like')
							}}
						/>
					)}
					&nbsp;<div className={classes.dot}>.</div>&nbsp;
					<Typography className={classes.reactionCount}>{likeCount}</Typography>&nbsp;<div className={classes.bar}>|</div>&nbsp;
					{_.isEqual(reactionStatus, 'dislike') ? (
						<Icon
							className={classes.dislikeIcon}
							onClick={() => {
								updateStickyReactions(sticky, '')
							}}
						>
							thumb_down_icon
						</Icon>
					) : (
						<ThumbDownOutlinedIcon
							className={classes.dislikeIcon}
							onClick={() => {
								updateStickyReactions(sticky, 'dislike')
							}}
						/>
					)}
					&nbsp;<div className={classes.dot}>.</div>&nbsp;
					<Typography className={classes.reactionCount}>{dislikeCount}</Typography>&nbsp;<div className={classes.bar}>|</div>&nbsp;
					<Button
						className={classes.replyBtn}
						onClick={() => {
							openActivityBox()
						}}
					>
						Reply
					</Button>{' '}
					{_.gt(activityCount, 0) && (
						<>
							<div className={classes.dot}>&nbsp;.&nbsp;</div>
							<Typography
								className={classes.activityCount}
								onClick={() => {
									openActivity()
								}}
							>
								{activityCount} {_.gt(activityCount, 1) ? `Replies` : `Reply`}
							</Typography>
						</>
					)}
				</div>
				<div className='ms-auto'>
					<span style={{ fontSize: '20px', margin: '0 5px', cursor: 'pointer' }} title={sticky_tone}>
						{emoji}
					</span>
				</div>
			</CardActions>
			{(showActivityBox || showActivity) && (
				<Activities
					isEditEnabled={isOwnerOrReporter}
					title={'Activities'}
					is_pinned={false}
					glassCode={glass_code}
					// sessionId={sessionId}
					stickyId={sticky_code}
					showCommentBox={showActivityBox ? true : false}
					showActivity={showActivity}
					setShowActivity={setShowActivity}
					activityTypes={activityTypes}
					glassLabels={glassLabels}
					placeholder='Ask a question or post an update'
					// socketRef={socketRef}
					isSocketConnected={isSocketConnected}
					suggestions={membersSuggestions}
					showExcludeSystemMessage={true}
					setGlassDetails={setGlassDetails}
					glassDetails={glassDetails}
					glass_code={glass_code}
					callSocketFunction={callSocketFunction}
					openActivity={openActivity}
					socketIO={socketIO}
					history={history}
				/>
			)}
		</Card>
	)
}

const FeedView = (props) => {
	const {
		myTaskStickies,
		onSliderClick,
		token,
		showLoading,
		setData,
		data,
		totalCount,
		getStickyListView,
		setParam,
		param,
		setPageQuery,
		isSocketConnected,
		socketIO,
		history,
	} = props
	const classes = useStyles()

	const bottomDividerRef = useRef()
	const myTaskListSynced = useRef(myTaskStickies)
	const stickyUpdatesListener = useRef()
	const dispatch = useDispatch()
	const userDetails = useSelector((state) => _.get(state, 'session.user.userDetails', {}))
	const [callSocket, SetCallSocket] = useState(false)

	useEffect(() => {
		myTaskListSynced.current = myTaskStickies
	}, [myTaskStickies])

	useLazyPaginate(bottomDividerRef, () => {
		if (!showLoading && totalCount > myTaskStickies.length) {
			setParam((prevState) => ({ ...prevState, offset: prevState.offset + 5 }))
			// setPageQuery((prevState) => ({ ...prevState, offset: prevState.offset + 5 }))
		}
	})

	const updateStickyItem = useCallback((replyItemToUpt, sticky_code) => {
		let stickyIndex = _.findIndex(myTaskListSynced.current, { sticky_code: sticky_code })
		let opToPerform = null
		if (stickyIndex > -1) {
			const updateSticky = { [stickyIndex]: { $set: { ...replyItemToUpt } } }
			opToPerform = updateSticky
		} else {
			opToPerform = null
		}
		if (opToPerform === null) {
			setData((prevState) => ({
				...prevState,
			}))
		} else {
			setData((prevState) => ({
				...prevState,
				myTaskStickies: update(prevState?.myTaskStickies, opToPerform),
			}))
		}
	}, [])

	const callSocketFunction = () => {
		SetCallSocket(true)
	}

	useEffect(() => {
		SetCallSocket(false)
		if (isSocketConnected) {
			stickyUpdatesListener.current = (resp) => {
				if (!_.isEmpty(_.get(resp, 'data[0].sticky_code'))) {
					//skip redux update since it's from socket already we have listener on socket-events-watcher.js
					// updateStickyDetails(resp?.data, true)
					updateStickyItem(resp?.data[0], _.get(resp, 'data[0].sticky_code'))
				}
			}
			socketIO.current?.on('feed_sticky_update', stickyUpdatesListener.current)
		}
		return () => {
			if (isSocketConnected && stickyUpdatesListener?.current) {
				stickyUpdatesListener.current = (resp) => {
					// console.log('feed view sticky update resp', resp)
				}
				socketIO.current?.off('feed_sticky_update', stickyUpdatesListener.current)
			}
		}
	}, [socketIO, isSocketConnected, history, dispatch, updateStickyItem, callSocket === true])

	const NoRecordsFound = () => {
		return (
			<TableRow>
				<TableCell>
					<div className={classes.infoContainer}>
						<NoRecordsIllustration className={classes.noRecordsIllustration} />
						<Typography variant='body1'>No Records Found</Typography>
					</div>
				</TableCell>
			</TableRow>
		)
	}
	console.log('myTaskStickies', myTaskStickies)
	return (
		<>
			{myTaskStickies.map((sticky) => {
				return (
					<>
						<div className={classes.cardContainer}>
							<Cards
								sticky={sticky}
								onSliderClick={onSliderClick}
								token={token}
								userDetails={userDetails}
								updateStickyItem={updateStickyItem}
								isSocketConnected={isSocketConnected}
								socketIO={socketIO}
								history={history}
								callSocketFunction={callSocketFunction}
								myTaskStickies={myTaskStickies}
							/>
						</div>
					</>
				)
			})}

			{showLoading && (
				<div className={classes.loadingSkeleton}>
					<Skeleton animation='wave' variant='circle' width={39} height={36} />
					<div className={classes.lineSkeleton}>
						<Grid container direction='row'>
							<Grid item xs={6}>
								<Skeleton animation='wave' height={18} width='90%' />
							</Grid>
							<Grid item xs={6} className={classes.activityTypeItem}>
								<Skeleton animation='wave' height={18} width='50%' />
							</Grid>
						</Grid>
						<Skeleton animation='wave' height={18} width='40%' />
						<Skeleton animation='wave' height={45} width='100%' />
					</div>
				</div>
			)}

			{!showLoading && _.isEmpty(myTaskStickies) && (
				<div className={classes.NoRecordsFound}>
					<NoRecordsFound />
				</div>
			)}

			{!showLoading && totalCount > myTaskStickies.length && (
				<div style={{ alignSelf: 'center', display: 'flex', padding: '20px' }} ref={bottomDividerRef}></div>
			)}
		</>
	)
}

export default FeedView
