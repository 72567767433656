import React, { useState, useRef } from 'react'
import {
    Grid,
    Card,
    CardActions,
    CardContent,
    CardActionArea,
    Collapse,
    Icon,
    IconButton,
    Button,
    Divider,
	Typography,
    makeStyles,
	CircularProgress,
    Avatar,
} from '@material-ui/core'
import TableCell from '../CustomTableCell'
import TableRow from '../CustomTableRow'
import { NoRecordsIllustration } from '../../../illustrators'
import _ from 'lodash'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
	infoContainer: {
		textAlign: 'center',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		'& h6': {
			paddingTop: theme.spacing(2),
		},
	},
	loadingIndicatorContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		paddingTop: theme.spacing(6),
		paddingBottom: theme.spacing(6),
	},
	noRecordsIllustration: {
		width: '100px',
		height: '100px',
	},
    CardContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        marginBottom: '12px',
    },
    Card: {
        marginBottom: '8px',
    },
    mainCard: {
        padding: '8px !important',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    projectTitle: {
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
	},
	glassCode: {
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		marginTop: '0.35em',
	},
	glassTitle: {
		fontSize: theme.typography.pxToRem(18),
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		[theme.breakpoints.down('xs')]: {
			fontSize: theme.typography.pxToRem(16),
		},
	},
    selectedSummaryInfoRow: {
		'& .MuiTableCell-body': {
			height: 54,
			paddingLeft: `0 !important`,
			paddingRight: `0 !important`,
		},
	},
	selectionContainer: {
		[theme.breakpoints.down(535)]: {
			display: 'flex',
			flexDirection: 'column',
			textAlign: 'center',
		},
	},
	selectionInfoContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: theme.palette.almostBlack[100],
		// borderRadius: 5
	},
	selectionSummarySpan: {
		marginLeft: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		padding: theme.spacing(1),
		color: theme.palette.primary.main,
		cursor: 'pointer',
		// borderRadius: 5,
		'&:hover': {
			background: `${theme.palette.primary.main}11`,
		},
	},
	selectionSummary: {
		marginLeft: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		padding: theme.spacing(1),
		color: 'black',
		cursor: 'pointer',
	},
    price: {
        display: 'flex',
        flexDirection: 'column',
    },
    cardExpanded: {
        padding: '8px 8px 10px 8px !important'
    },
    cardExpContainer: {
        // '& .MuiGrid-item': {
        //     padding: '6px 8px',
        // },
    },
    mobTableTitle: {
		color: '#9cadad',
		textTransform: 'uppercase',
		fontSize: '10px',
		fontWeight: '500',
		letterSpacing: '1px',
	},
    flagContainer: {
        display: 'flex', 
        flexFlow: 'row', 
        alignItems: 'center', 
        maxWidth: 'min-content',
    },
    flagText: {
		'& .MuiTypography-body2': {
			padding: '2px 3px',
		},
	},
    flagCircle: {
		width: theme.spacing(1),
		height: theme.spacing(1),
		display: 'inline-block',
		marginRight: theme.spacing(1),
		borderRadius: '50%',
		background: theme.palette.warning.light,
	},
    avatarIcon: {
        '& .MuiAvatar-root': {
            width: '35px',
            height: '35px',
        },
    },
    mobLinkIcon: {
		'& .MuiIconButton-root': {
			padding: '0px 5px',
		},
        '& .material-icons': {
            fontSize: '20px',
        },
	},
    tableBody2: {
		color: theme.palette.almostBlack[800],
		letterSpacing: theme.typography.pxToRem(0.1),
	},
    sellingPriceText: {
		fontSize: theme.typography.pxToRem(14),
		fontWeight: 500,
		color: theme.palette.info.main,
		paddingRight: theme.spacing(0.5),
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	marketPriceText: {
		fontSize: theme.typography.pxToRem(14),
		fontWeight: 500,
		paddingRight: theme.spacing(0.5),
		color: theme.palette.success.main,
	},
    mobImageIcon: {
        justifyContent: 'center',
		'& .MuiIconButton-root': {
			padding: '0px',
			'& .MuiIcon-root': {
				fontSize: '23px',
			},
		},
	},
    mobImage: {
		width: '100px',
		height: '60px',
	},
    dashX: {
        marginTop: '3px',
        textAlignLast: 'center',
    },
    glassX: {
        textAlignLast: 'center',
    },
    dashboardIcon: {
		'& .material-icons': {
			fontSize: '20px',
		},
	},
	viewColumnIcon: {
		'& .material-icons': {
			fontSize: '26px',
		},
	},
}))

const TableMobBody = (props) => {

    const { 
        data,
        isLoading,
        handleCheckBoxClick,
        exportList,
        setExportList,
        renderSelectionSummary,
        keyForCheckBoxSelectedList,
        paginationFooterDetails,
        handleSelectAllProducts,
        clearSelection,
        type,
        handleSelectAllPageProducts,
        allPageProduct, 
        selectionMode,
        setSelectionMode,
        onClickDashX,
        onClickGlassX,
        mobileHeaderKeys,
        mobileContent,
        flagStatus,
        handleSelectCurrentPage
    } = props

    const classes = useStyles()

    const [expanded, setExpanded] = useState([])

    const timerId = useRef()
    const avatarAchorRef = useRef(null)

    const handleMouseDown = (e, record) => {
        timerId.current = setTimeout(() => {
        setSelectionMode(true)
        setExportList(_.concat(exportList, record[keyForCheckBoxSelectedList]))
        handleCheckBoxClick(e, record)
        }, 300) 
    }

    const handleMouseUp = () => {
        clearTimeout(timerId.current)
        if (selectionMode) {
        setSelectionMode(false)
        }
    }

    const handleExpandClick = (e, record) => {
        const valueIn = _.includes(expanded, record[keyForCheckBoxSelectedList])
        if (!valueIn) {
            setExpanded(_.concat(expanded, record[keyForCheckBoxSelectedList]))
        } else {
            setExpanded(_.without(expanded, record[keyForCheckBoxSelectedList]))
        }
    }

    const handleSelectClick = (e, record) => {
        const valueIn = _.includes(exportList, record[keyForCheckBoxSelectedList])
        handleCheckBoxClick(e, record)
        if (!valueIn) {
            setExportList(_.concat(exportList, record[keyForCheckBoxSelectedList]))
        } else {
            setExportList(_.without(exportList, record[keyForCheckBoxSelectedList]))
        }

        if (_.size(exportList) <= 1 && selectionMode && valueIn) {
            handleMouseUp()
        }
    }

    function handleClickDashX(e, record) {
        e.stopPropagation()
        onClickDashX(e, record)
    }

    function handleClickGlassX(e, record) {
        e.stopPropagation()
        if (!_.isNil(record?.glass_code) && !_.isNil(record[keyForCheckBoxSelectedList])) {
            onClickGlassX(e, record)
        }
    }

    const onClickFxn = (e, actionObj, record) => {
        e.stopPropagation()
        actionObj.onClick(e, record)
    }

    const NoRecordsFound = () => {
		return (
			<TableRow>
				<TableCell>
					<div className={classes.infoContainer}>
						<NoRecordsIllustration className={classes.noRecordsIllustration} />
						<Typography variant='body1'>No Records Found</Typography>
					</div>
				</TableCell>
			</TableRow>
		)
	}

	const LoadingIndicator = () => {
		return (
			<TableRow>
				<TableCell>
					<div className={classes.loadingIndicatorContainer}>
						<CircularProgress size={30} />
					</div>
				</TableCell>
			</TableRow>
		)
	}

    const flag = (data, idx, record) => {
        const checkFlagStatus = (record, flagConditions) => {
            if (_.lowerCase(_.toString(record)) === _.lowerCase(_.toString(flagConditions.yes)) || record === true) {
                return true
            } else if (_.lowerCase(_.toString(record)) === _.lowerCase(_.toString(flagConditions.no)) || record === false) {
                return false
            } else {
                return flagConditions.indeterminate
            }
        }
        const flagStatus = !_.isEmpty(_.get(data, 'flagCondition', {})) ? checkFlagStatus(record[data?.dataKey], data.flagCondition) : record
        const renderFlagComponentFxn = (componentKey) => {
            const isObject = _.isObject(_.get(data, componentKey))
            const isFunction = _.isFunction(_.get(data, componentKey))

            if (isObject && isFunction) {
                return _.get(
                    data,
                    componentKey
                )(_.get(data, 'textKey', '-') !== '-' ? { text: _.get(record, _.get(data, 'textKey')) } : {})
            } else if (isObject && !isFunction) {
                return _.get(data, componentKey)
            }
        }
        return (
            <Grid container key={idx} xs={data?.size ? data?.size : 12} className={classes.flagContainer}>
                <Grid item container style={{ marginLeft: '0px' }}>
                <div style={_.isNil(data?.style) ? {} : data?.style} className={classes.flagText}>
                    {_.get(data, 'flagComponents', '-') !== '-' ? (
                        flagStatus === true ? (
                            renderFlagComponentFxn('flagComponents.yes')
                        ) : flagStatus === false ? (
                            renderFlagComponentFxn('flagComponents.no')
                        ) : (
                            renderFlagComponentFxn('flagComponents.indeterminate')
                        )
                    ) : (
                        <>
                            <span
                                className={classnames(classes.flagCircle, {
                                    [classes.active]: flagStatus === true,
                                    [classes.deActive]: flagStatus === false,
                                })}
                            ></span>
                            <Typography variant='body2' component='span' className={classes.tableBody2}>
                                {flagStatus === true
                                    ? _.get(data, 'flagCondition.yes', 'Yes')
                                    : flagStatus === false
                                    ? _.get(data, 'flagCondition.no', 'No')
                                    : _.get(data, 'flagCondition.indeterminate')}
                            </Typography>
                        </>
                    )}
                </div>
                </Grid>
            </Grid>
        )
    }

    const getExpandContent = (data, idx, record) => {

        function _getPriceDetails({ sellingPrice, marketPrice, discount }) {
            if ((!_.isNil(discount) && _.toNumber(discount) === 0) || (_.toNumber(sellingPrice) && _.toNumber(marketPrice))) {
                return {
                    sellingPrice: !_.isNil(sellingPrice) ? _.toNumber(sellingPrice) : _.toNumber(marketPrice),
                }
            } else if (!_.isNil(sellingPrice) && !_.isNil(marketPrice) && !_.isNil(discount)) {
                return { sellingPrice, marketPrice, discount }
            } else if (!_.isNil(sellingPrice) && !_.isNil(marketPrice) && _.isNil(discount)) {
                return {
                    sellingPrice,
                    marketPrice,
                    discount: ((sellingPrice - marketPrice) / marketPrice) * 100,
                }
            } else if (!_.isNil(sellingPrice) && _.isNil(marketPrice) && !_.isNil(discount)) {
                return {
                    sellingPrice,
                    marketPrice: (100 * sellingPrice) / (100 - discount),
                    discount,
                }
            } else if (_.isNil(sellingPrice) && !_.isNil(marketPrice) && !_.isNil(discount)) {
                return {
                    sellingPrice: marketPrice - (marketPrice * discount) / 100,
                    marketPrice,
                    discount,
                }
            } else {
                return null
            }
        }

        const price = _getPriceDetails({
            sellingPrice: _.isNumber(_.get(record, 'selling_price'))
                ? _.get(record, 'selling_price').toFixed(2)
                : _.get(record, 'selling_price'),
            marketPrice: _.isNumber(_.get(record, 'market_price'))
                ? _.get(record, 'market_price').toFixed(2)
                : _.get(record, 'market_price'),
            discount: _.get(record, 'product_discount'),
        })

        switch(_.get(data, 'type', '')) {
            case 'flag':
                const checkFlagStatus = (record, flagConditions) => {
                    if (_.lowerCase(_.toString(record)) === _.lowerCase(_.toString(flagConditions.yes)) || record === true) {
                        return true
                    } else if (_.lowerCase(_.toString(record)) === _.lowerCase(_.toString(flagConditions.no)) || record === false) {
                        return false
                    } else {
                        return flagConditions.indeterminate
                    }
                }
                const flagStatus = !_.isEmpty(_.get(data, 'flagCondition', {})) ? checkFlagStatus(record[data?.dataKey], data.flagCondition) : record
                const renderFlagComponentFxn = (componentKey) => {
                    const isObject = _.isObject(_.get(data, componentKey))
                    const isFunction = _.isFunction(_.get(data, componentKey))

                    if (isObject && isFunction) {
                        return _.get(
                            data,
                            componentKey
                        )(_.get(data, 'textKey', '-') !== '-' ? { text: _.get(record, _.get(data, 'textKey')) } : {})
                    } else if (isObject && !isFunction) {
                        return _.get(data, componentKey)
                    }
                }
                return (
                    <Grid container key={idx} xs={data?.size ? data?.size : 4} className={classes.flagContainer}>
                        <Grid item container>
                            <Typography variant='caption' color='textSecondary'>
                                {data?.name}:
                            </Typography>
                        </Grid>
                        <Grid item container style={{ marginLeft: '5px' }}>
                        <div style={_.isNil(data?.style) ? {} : data?.style} className={classes.flagText}>
                            {_.get(data, 'flagComponents', '-') !== '-' ? (
                                flagStatus === true ? (
                                    renderFlagComponentFxn('flagComponents.yes')
                                ) : flagStatus === false ? (
                                    renderFlagComponentFxn('flagComponents.no')
                                ) : (
                                    renderFlagComponentFxn('flagComponents.indeterminate')
                                )
                            ) : (
                                <>
                                    <span
                                        className={classnames(classes.flagCircle, {
                                            [classes.active]: flagStatus === true,
                                            [classes.deActive]: flagStatus === false,
                                        })}
                                    ></span>
                                    <Typography variant='body2' component='span' className={classes.tableBody2}>
                                        {flagStatus === true
                                            ? _.get(data, 'flagCondition.yes', 'Yes')
                                            : flagStatus === false
                                            ? _.get(data, 'flagCondition.no', 'No')
                                            : _.get(data, 'flagCondition.indeterminate')}
                                    </Typography>
                                </>
                            )}
                        </div>
                        </Grid>
                    </Grid>
                )
            case 'link':
                const onClickFxn = (e, actionObj) => {
                    actionObj.onClick(e, record)
                }
                return(
                    <Grid item container className={classes.mobLinkIcon} key={idx} xs={data?.size ? data?.size : 4}>
                        <IconButton
                            size={'size'}
                            onClick={(e) => onClickFxn(e, data?.actions)}
                            title={'Link'}
                            aria-label={'Link'}
                        >
                            <Icon style={data?.styleIcon ? data?.styleIcon : {}}>{data?.actions?.icon}</Icon>
                        </IconButton>
                    </Grid>
                )
            case 'marketPrice':
                return (
                    <>
                        {!_.isNil(price) && !_.isEmpty(price) ? (
                            <>
                                <Grid container item xs={data?.size ? data?.size : 4} key={idx}>
                                    {price.marketPrice ? (
                                        <Typography variant='h5' title={`Market Price: RM${price.marketPrice}`} component='span' className={classes.marketPriceText}>
                                            {_.get(data, 'name')}: {_.get(record, 'market_price')}
                                        </Typography>
                                    ) : null}
                                </Grid>
                            </>
                        ) : null}
                    </>
                )
            case 'sellingPrice':
                return (
                    <>
                        {!_.isNil(price) && !_.isEmpty(price) ? (
                            <>
                                <Grid container item xs={data?.size ? data?.size : 4} key={idx}>
                                    {price.sellingPrice ? (
                                        <Typography variant='h5' title={`Selling Price: RM${price.sellingPrice}`} component='span' className={classes.sellingPriceText}>
                                            {_.get(data, 'name')}: {_.get(record, 'selling_price')}
                                        </Typography>
                                    ) : null}
                                </Grid>
                            </>
                        ) : null}
                    </>
                )
            case 'image':
                let imgSrc = _.get(record, data?.dataKey, '-')
                const secImgSrc = _.get(record, data?.secImgKey, '-')
                let secImgSrcCount = secImgSrc !== '-' ? secImgSrc.length - 1 : -1
                const loadSecondarySrc = (e) => {
                    if (secImgSrcCount !== -1) {
                        e.target.src = secImgSrc[secImgSrcCount]
                        secImgSrcCount -= 1
                    }
                }
                const popUpFxn = _.get(data, 'imgPopUpFxn', () => null)
                function handlePopUpFxn(e) {
                    e.stopPropagation()
                    popUpFxn(record)
                }
                return(
                    <Grid item container className={classes.mobImageIcon} xs={data?.size ? data?.size : 4} key={idx}>
                        {imgSrc !== '-' ? (
                            <img className={classes.mobImage} onClick={handlePopUpFxn} onError={loadSecondarySrc} src={imgSrc} alt={record.name} />
                        ) : (
                            <Typography variant='subtitle1'>Image</Typography>
                        )}
                    </Grid>
                )
            default: 
                return (
                    <Grid container item xs={data?.size ? data?.size : 4} key={idx}>
                        <Grid item>
                            <Typography variant='caption' color='textSecondary' gutterBottom>
                            {_.get(data, 'name')}:&nbsp;
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='caption' color='textSecondary' gutterBottom>
                            {record[data.dataKey]}
                            </Typography>
                            {!_.isNil(_.get(data, 'subTitleRenderFxn')) ? _.get(data, 'subTitleRenderFxn')(record) : null}
                        </Grid>
                    </Grid>
                )
        }
    }

    return (
        <>
            {_.isEmpty(data) ?
            isLoading ?

            <LoadingIndicator /> :

            <NoRecordsFound /> :

            <>

            {data && data.length > 0 && exportList && exportList.length > 0 ? (
                <TableRow className={classes.selectedSummaryInfoRow}>
                    <TableCell>
                        <div className={classes.selectionInfoContainer}>
                            <Typography variant='subtitle1' className={classes.selectionContainer}>
                                <span className={classes.selectionSummary}>
                                    {renderSelectionSummary()}
                                </span>
                                {_.isEqual(data.map((product) => product[keyForCheckBoxSelectedList]).sort(), exportList.sort()) ? (
                                    _.get(paginationFooterDetails, 'total', 0) > 12 ? (
                                        _.isNil(handleSelectAllProducts) ? (
                                            <>
                                                <span className={classes.selectionSummarySpan} onClick={clearSelection}>
                                                    Clear Selection
                                                </span>
                                                {_.isEqual(type, 'EXPORT SELECTED') &&
                                                <span className={classes.selectionSummarySpan} onClick={handleSelectAllPageProducts}>
                                                {_.isEqual(allPageProduct, false) && `Select results of all pages`} 
                                                </span>}
                                            </>
                                        ) : (
                                            <span className={classes.selectionSummarySpan} onClick={handleSelectAllProducts}>
                                                Select all {_.get(paginationFooterDetails, 'total', 0)} products.
                                            </span>
                                        )
                                    ) : (
                                        <span className={classes.selectionSummarySpan} onClick={clearSelection}>
                                            Clear Selection
                                        </span>
                                    )
                                ) : (
                                    <>
                                        <span className={classes.selectionSummarySpan} onClick={clearSelection}>
                                            Clear Selection
                                        </span>
                                        {exportList.length < 12 ?
                                        <span className={classes.selectionSummarySpan} onClick={handleSelectCurrentPage}>
                                            Select all
                                        </span> :
                                        <>
                                        {_.isEqual(type, 'EXPORT SELECTED') &&
                                        <span className={classes.selectionSummarySpan} onClick={handleSelectAllPageProducts}>
                                        {_.isEqual(allPageProduct, false) && `Select results of all pages`} 
                                        </span>}
                                        </>}
                                    </>
                                )}
                            </Typography>
                        </div>
                    </TableCell>
                </TableRow>
            ) : null}


            <Grid container className={classes.CardContainer}>
            {data.map((record, idx) => (
                <Card className={classes.Card} variant="outlined" key={record[keyForCheckBoxSelectedList]}>
                    <CardActionArea>
                    <CardContent className={classes.mainCard} 
                        onClick={(e)=> { selectionMode ? handleSelectClick(e, record) : handleExpandClick(e, record)}} 
                        onMouseDown={(e)=> {!selectionMode && handleMouseDown(e, record)}}
                        onMouseUp={()=> {handleMouseUp()}}
                        onTouchStart={(e)=> {!selectionMode && handleMouseDown(e, record)}}
                        onTouchEnd={()=> {handleMouseUp()}}
                    >
                        {_.includes(exportList, record[keyForCheckBoxSelectedList]) &&
                        <span className='card-icon-span'>
                            <span className='card-selection-bg'></span>
                            <Icon className='card-icon-style'>done</Icon>
                        </span>}
                        <Typography variant='caption' color='textSecondary' gutterBottom>
                            {(mobileHeaderKeys && mobileHeaderKeys['id']) && record[mobileHeaderKeys['id']]}
                        </Typography>
                        <Typography variant='h5' component='h2'>
                            {(mobileHeaderKeys && mobileHeaderKeys['title']) && record[mobileHeaderKeys['title']]}
                        </Typography>
                        <Typography variant='caption' gutterBottom>
                            {(mobileHeaderKeys && mobileHeaderKeys['description']) && record[mobileHeaderKeys['description']]}
                        </Typography>
                        <Typography variant='caption' color='textSecondary' gutterBottom>
                            {(mobileHeaderKeys && mobileHeaderKeys['subtitle1']) && record[mobileHeaderKeys['subtitle1']]}
                        </Typography>
                        <Typography variant="body2" component="p" >
                            {(mobileHeaderKeys && mobileHeaderKeys['subtitle2']) && record[mobileHeaderKeys['subtitle2']]}
                        </Typography>
                        <Grid container style={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '0px' }}>
                                <Grid item>
                                    {mobileHeaderKeys['imgContent1'] &&
                                    <Typography variant='caption' color='textSecondary' gutterBottom>
                                        {(mobileHeaderKeys && mobileHeaderKeys['imgContent1']) && record[mobileHeaderKeys['imgContent1']]}
                                    </Typography>}
                                    {mobileHeaderKeys['imgContent2'] &&
                                    <Typography variant="body2" component="p" >
                                        {(mobileHeaderKeys && mobileHeaderKeys['imgContent2']) && record[mobileHeaderKeys['imgContent2']]}
                                    </Typography>}
                                </Grid>
                                {mobileHeaderKeys['imgPopUpFxn'] &&
                                <Grid item className={classes.avatarIcon} key={idx}>
                                    <Avatar
                                        onClick={() => mobileHeaderKeys['imgPopUpFxn'](record)}
                                        ref={avatarAchorRef}
                                        size='small'
                                        className={classnames('profile-circle', 'background-circle')}
                                        // style={{ backgroundColor: stringToHslColor(userDisplayName) }}
                                        component='div'
                                        src={'https://img.freepik.com/free-photo/wide-angle-shot-single-tree-growing-clouded-sky-during-sunset-surrounded-by-grass_181624-22807.jpg?w=2000'}
                                    >
                                        <Typography variant='caption'>{'None'}</Typography>
                                    </Avatar>
                                </Grid>}
                            </Grid>
                        <Grid container style={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '5px' }}>
                            {flagStatus && 
                            <Grid item>
                                {flag( flagStatus, idx, record )}
                            </Grid>}
                            {(mobileHeaderKeys && mobileHeaderKeys['actions']) &&
                            <Grid item className={classes.mobLinkIcon} key={idx}>
                                <IconButton
                                    size={'size'}
                                    onClick={(e) => onClickFxn(e, mobileHeaderKeys['actions'], record)}
                                    title={'Link'}
                                    aria-label={'Link'}
                                >
                                    <Icon style={mobileHeaderKeys['actions']?.styleIcon ? mobileHeaderKeys['actions']?.styleIcon : {}}>{mobileHeaderKeys['actions']?.icon}</Icon>
                                </IconButton>
                            </Grid>}
                        </Grid>
                    </CardContent>
                    </CardActionArea>
                    <Collapse in={_.includes(expanded, record[keyForCheckBoxSelectedList])} timeout="auto" unmountOnExit>
                    <Divider/>
                        <CardContent className={classes.cardExpanded}>
                        <Grid container spacing={2} xs={12} className={classes.cardExpContainer}>
                            {mobileContent.map((data, idx) => (
                                getExpandContent(data, idx, record)
                            ))}
                        </Grid>
                        {(onClickDashX || onClickGlassX) &&
                        <Grid container spacing={2} xs={12} style={{ justifyContent: 'center' }}>
                            {onClickDashX &&
                            <Grid item xs={6} className={classes.dashX}>
                            <IconButton size='small' title='Show Actions' className={classes.dashboardIcon} onClick={(e)=> handleClickDashX(e, record)}>
									<i className='material-icons' fontSize='small'>dashboard</i>
							</IconButton>
                            </Grid>}
                            {onClickGlassX &&
                            <Grid item xs={6} className={classes.glassX}>
                            <IconButton size='small' title='Show Actions' className={classes.viewColumnIcon} onClick={(e)=> handleClickGlassX(e, record)}>
									<i className='material-icons' fontSize='small'>view_column</i>
							</IconButton>
                            </Grid>}
                        </Grid>}
                        </CardContent>
                    </Collapse>
                </Card>
            ))}
            </Grid>
            </>
            }
        </>
    )
}

export default TableMobBody
