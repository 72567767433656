import React, { useState, useEffect } from 'react'
import { makeStyles, Accordion, AccordionSummary, Icon, Grid, Typography, AccordionDetails, Dialog, TextField } from '@material-ui/core'
// import SearchBox from '../../PainSearchBox';
// import { useDispatch } from 'react-redux';
import _ from 'lodash'
import classnames from 'classnames'
import { DateRangePicker } from 'react-date-range'
import { format, isSameDay } from 'date-fns'
import { defaultStaticRanges, defaultDoubleInputRanges } from '../../../utils/defaultDateRanges'

const useStyles = makeStyles((theme) => ({
	expansionPanelRoot: {
		borderBottom: 0,
		background: 'transparent',
		boxShadow: 'none',
		'&.Mui-expanded': {
			margin: theme.spacing(0.5, 0),
		},
		'&:not(:last-child)': {
			// borderBottom: '1px solid rgba(0, 0, 0, .125)',
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 0,
		},
		'& .MuiAccordionSummary-expandIcon': {
			color: theme.palette.almostBlack[600],
		},
	},
	expanded: {},
	filterPanelTitleContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	resetSpan: {
		padding: theme.spacing(0.5),
		color: theme.palette.almostBlack[800],
		borderRadius: '5px',
		transition: 'background 0.3s',
		// fontFamily: '"Poppins"',
		fontSize: theme.typography.pxToRem(10),
		'&:hover': {
			background: `${theme.palette.primary.main}11`,
			transition: 'background 0.3s',
		},
	},
	root: {
		padding: theme.spacing(0),
		'&.Mui-expanded': {
			minHeight: 'unset',
			// margin: theme.spacing(0.5,0)
		},
		'& .MuiIconButton-colorPrimary:hover': {
			backgroundColor: 'transparent',
		},
		'& .MuiSvgIcon-root': {
			// color: `${theme.palette.primary.dark}`
		},
		'& .MuiTypography-body1': {
			lineHeight: 0,
			fontWeight: 400,
		},
		'& .MuiFormGroup-row': {
			width: '100%',
			justifyContent: 'space-between',
		},
	},
	muiPanelSummaryRoot: {
		'&.Mui-expanded': {
			margin: theme.spacing(0.5, 0),
		},
	},
	iconRoot: {
		minWidth: 'auto',
	},
	listRoot: {},
	listItemRoot: {
		paddingTop: theme.spacing(0),
		paddingBottom: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		cursor: 'pointer',
	},
	filterPanelHeading: {
		color: theme.palette.almostBlack[800],
		letterSpacing: theme.typography.pxToRem(0.8),
	},
	listText: {
		color: theme.palette.almostBlack[800],
		textTransform: 'uppercase',
		'& .MuiTypography-body1': {
			lineHeight: 0,
			fontWeight: 400,
		},
	},
	searchContainer: {
		padding: theme.spacing(1, 0),
	},
	capitaliseText: {
		textTransform: 'capitalize !important',
	},
	sliderDivLabels: {
		paddingBottom: 6,
	},
	flexContainer: {
		display: 'flex',
		width: '100%',
		position: 'relative',
		padding: theme.spacing(1, 0),
	},
	paddingForSlider: {
		padding: theme.spacing(0, 0.5),
	},
	flexCell: {
		flex: 1,
	},
	textRight: {
		textAlign: 'right',
	},
	rangeLabels: {
		color: theme.palette.almostBlack[600],
	},
	infoText: {
		fontSize: theme.typography.pxToRem(10),
		fontWeight: '400',
		color: theme.palette.almostBlack[500],
	},
	calendarContainer: {
		// maxWidth: 230,
		// position: 'relative',
		'& .MuiIcon-root': {
			fontSize: theme.typography.pxToRem(16),
		},
		'& .MuiButtonGroup-root': {
			minHeight: 30,
			'& .MuiButton-outlined': {
				// height: '32px',
				background: theme.palette.almostBlack[0],
				// color: theme.palette.almostBlack[800]
			},
			'& .MuiButton-outlined:first-child': {
				// borderRadius: '10px 0px 0px 10px',
				color: theme.palette.almostBlack[800],
			},
			'& .MuiButton-outlined:last-child': {
				// borderRadius: '0px 10px 10px 0px',
			},
			'& .MuiButton-outlined:only-child': {
				// borderRadius: '10px',
			},
		},
	},
	calendarWrapper: {
		// position: 'absolute',
		// top: '100%',
		// right: '0%',
		// zIndex: 5,
		padding: theme.spacing(2),
		background: theme.palette.almostBlack[100],
		boxShadow: `0px 0px 5px 0px ${theme.palette.almostBlack[400]}`,
		borderRadius: '10px',
		'& .rdrDefinedRangesWrapper': {
			borderRadius: '10px 0px 0px 10px',
			'& .rdrStaticRanges': {
				borderRadius: '10px 0px 0px 0px',
				'& .rdrStaticRange:first-child, .rdrStaticRange:first-child:hover': {
					borderRadius: '10px 0px 0px 0px',
					'& .rdrStaticRangeLabel': {
						borderRadius: '10px 0px 0px 0px',
					},
				},
			},
		},
		'& .rdrCalendarWrapper': {
			borderRadius: '0px 10px 10px 0px',
			'& .rdrDateDisplayWrapper': {
				borderRadius: '0px 10px 0px 0px',
			},
		},
	},
	dateButtonText: {
		paddingRight: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		fontSize: theme.typography.pxToRem(11),
		letterSpacing: theme.typography.pxToRem(1),
		// fontFamily: '"Roboto"'
	},
	dateInputText: {
		margin: 0,
		cursor: 'pointer',
		'& .MuiOutlinedInput-input': {
			fontSize: theme.typography.pxToRem(12),
			color: theme.palette.almostBlack[800],
			cursor: 'pointer',
		},
		'& .MuiIcon-root': {
			color: theme.palette.almostBlack[800],
			cursor: 'pointer',
		},
		'& .MuiOutlinedInput-root': {
			marginLeft: `0px !important`,
		},
	},
}))

function formatDateForLabel(dateString, isReturnAsDate) {
	//   console.log("dateString", dateString)
	let dt = new Date(dateString)
	let today = new Date()
	let todayStr = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
	let yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
	let yesterdayStr = yesterday.getDate() + '/' + (yesterday.getMonth() + 1) + '/' + yesterday.getFullYear()
	let dtStr = dt.getDate() + '/' + (dt.getMonth() + 1) + '/' + dt.getFullYear()
	if (_.isEqual(todayStr, dtStr) && !isReturnAsDate) {
		return 'Today'
	} else if (_.isEqual(yesterdayStr, dtStr) && !isReturnAsDate) {
		return 'Yesterday'
	} else {
		return format(dt, 'dd/MM/yyyy')
	}
}

var clickedTimes = 0

const DateRangeInput = ({ initialDates, handleDateChange, rangeSelected, inputProps = {} }) => {
	const classes = useStyles()

	const [isCalendarOpen, setIsCalendarOpen] = useState(false)

	const [dates, setDates] = useState([
		{
			startDate: initialDates && initialDates.startDate ? initialDates.startDate : new Date(),
			endDate: initialDates && initialDates.endDate ? initialDates.endDate : new Date(),
			key: 'dates',
		},
	])

	useEffect(() => {
		if (
			(!isSameDay(dates[0]?.startDate, rangeSelected?.startDate) || !isSameDay(dates[0]?.endDate, rangeSelected?.endDate)) &&
			!_.isEmpty(rangeSelected)
		) {
			setDates([
				{
					key: 'dates',
					startDate: rangeSelected?.startDate,
					endDate: rangeSelected?.endDate,
				},
			])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rangeSelected])

	const handleChange = (dateObj) => {
		console.log(dateObj, dates)
		clickedTimes = clickedTimes + 1
		const hasStartDateChanged = format(dateObj.dates.startDate, 'dd-MM-yyyy') !== format(dates[0].startDate, 'dd-MM-yyyy')
		const hasEndDateChanged = format(dateObj.dates.endDate, 'dd-MM-yyyy') !== format(dates[0].endDate, 'dd-MM-yyyy')

		if (hasStartDateChanged || hasEndDateChanged || _.isEmpty(rangeSelected)) {
			setDates([
				{
					key: 'dates',
					startDate: Object.values(dateObj)[0].startDate,
					endDate: Object.values(dateObj)[0].endDate,
				},
			])
		}

		if (
			dateObj.dates.key !== 'dates' ||
			(hasStartDateChanged && hasEndDateChanged && clickedTimes >= 2) ||
			(!hasStartDateChanged && clickedTimes >= 2) ||
			(!hasEndDateChanged && clickedTimes >= 2)
		) {
			if (dateObj.dates.key !== 'custom range') {
				clickedTimes = 0
				setIsCalendarOpen(false)
			}
		}
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => handleDateChange && handleDateChange(dates[0]), [dates])

	useEffect(() => {
		if (!isCalendarOpen) {
			clickedTimes = 0
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCalendarOpen, clickedTimes])

	function dateLabel(dateObj) {
		if (formatDateForLabel(dateObj.startDate) === formatDateForLabel(dateObj.endDate)) {
			// return (
			//     <Typography variant='subtitle2' className={classes.dateButtonText}>{formatDateForLabel(dateObj.endDate)}</Typography>
			// )
			return formatDateForLabel(dateObj.endDate)
		} else {
			// return(<>
			//     <Typography variant='subtitle2' className={classes.dateButtonText}>{format(dateObj.startDate, 'dd/MM/yyyy')}</Typography>
			//     -
			//     <Typography variant='subtitle2' className={classes.dateButtonText}>{format(dateObj.endDate, 'dd/MM/yyyy')}</Typography>
			// </>)
			return `${format(dateObj.startDate, 'dd/MM/yyyy')} - ${format(dateObj.endDate, 'dd/MM/yyyy')}`
		}
	}

	return (
		<>
			<TextField
				className={classes.dateInputText}
				InputProps={{ endAdornment: <Icon>date_range</Icon> }}
				variant='outlined'
				label='Date Range'
				fullWidth
				size='small'
				value={_.isEmpty(rangeSelected) ? 'No Dates Selected' : `${dateLabel(dates[0])}`}
				onClick={() => setIsCalendarOpen(!isCalendarOpen)}
			/>

			<Dialog open={isCalendarOpen} maxWidth='md' disableRestoreFocus className={classes.calendarContainer} onClose={() => setIsCalendarOpen(false)}>
				<DateRangePicker
					className={classes.calendarWrapper}
					onChange={(item) => {
						handleChange(item)
					}}
					showSelectionPreview={true}
					moveRangeOnFirstSelection={false}
					months={2}
					ranges={_.isEmpty(rangeSelected) ? [{ key: 'dates', startDate: new Date(), endDate: new Date() }] : dates}
					staticRanges={defaultStaticRanges}
					inputRanges={defaultDoubleInputRanges}
					{...inputProps}
					// maxDate={new Date()}
					direction='horizontal'
				/>
			</Dialog>
		</>
	)
}

const DateFilter = (props) => {
	const { title, rangeSelected, initialDates, onChangeCommitted, handleClearThisFilter, inputProps = {} } = props
	console.log('props of date filter :::: ', props)

	const classes = useStyles()
	const [isExpanded, setIsExpanded] = useState(false)

	// const handleChange = (e, newValue) => {
	//     setValue(newValue);
	// }

	const handleResetClick = (e) => {
		e.stopPropagation()
		// console.log('clicked');
		handleClearThisFilter()
	}

	const handleChangeCommitted = (value) => {
		if (isExpanded) {
			onChangeCommitted(value)
		}
	}

	// console.log("formatDateForLabel(initialDates.startDate)",formatDateForLabel(initialDates.startDate))
	// console.log("formatDateForLabel(initialDates.endDate)",formatDateForLabel(initialDates.endDate))
	// console.log("formatDateForLabel(_.get(rangeSelected, 'startDate', '31-12-2019'))",formatDateForLabel(_.get(rangeSelected, 'startDate', '31-12-2019')), _.get(rangeSelected, 'startDate', '31-12-2019'))
	// console.log("formatDateForLabel(_.get(rangeSelected, 'endDate', '31-12-2018'))",formatDateForLabel(_.get(rangeSelected, 'endDate', '31-12-2018')), _.get(rangeSelected, 'endDate', '31-12-2018'))

	return (
		<>
			<Accordion
				expanded={isExpanded}
				classes={{
					root: classes.expansionPanelRoot,
					expanded: classes.expanded,
				}}
				onChange={() => setIsExpanded(!isExpanded)}
				elevation={0}
			>
				<AccordionSummary classes={{ root: classes.root, content: classes.muiPanelSummaryRoot }} expandIcon={<Icon>keyboard_arrow_down</Icon>}>
					<div className={classes.filterPanelTitleContainer}>
						<Typography className={classes.filterPanelHeading} variant='subtitle2'>
							{title}
						</Typography>
						{!(isSameDay(initialDates?.startDate, rangeSelected?.startDate) && isSameDay(initialDates?.endDate, rangeSelected?.endDate)) ? (
							<Typography variant='caption' className={classes.resetSpan} component='span' onClick={handleResetClick}>
								RESET
							</Typography>
						) : null}
					</div>
				</AccordionSummary>
				<AccordionDetails classes={{ root: classes.root }}>
					<Grid container>
						<div className={classnames(classes.flexContainer, classes.sliderDivLabels)}>
							<DateRangeInput
								initialDates={initialDates}
								handleDateChange={(e) => handleChangeCommitted(e)}
								rangeSelected={rangeSelected}
								inputProps={inputProps}
							/>
						</div>
					</Grid>
				</AccordionDetails>
			</Accordion>
		</>
	)
}

export default DateFilter
