import { ACTION_TYPES } from '../../../../constants'
import _ from 'lodash'
import update from 'immutability-helper'

const reIndexPanes = (paneArry, paneToUpdate, startIdx, endIdx, incORDec) => {
	const result = _.reduce(
		paneArry,
		(modPaneArry, pane) => {
			if (pane?.pane_id === paneToUpdate?.pane_id) {
				pane = paneToUpdate
			} else if (pane?.pane_position >= startIdx && pane.pane_position <= endIdx) {
				pane.pane_position += incORDec
			}
			modPaneArry.push(pane)
			return modPaneArry
		},
		[]
	)
	return result
}

const paneReducer = (state, action) => {
	const paneIdx = _.findIndex(state, { pane_id: action?.payload?.paneId })
	switch (action.type) {
		case ACTION_TYPES.ADD_GLASS_PANE:
			return update(state, { $push: [_.get(action, 'payload')] })
		case ACTION_TYPES.UPDATE_GLASS_PANE:
			const paneToUpdate = _.get(action, 'payload.pane')
			const nextPos = _.get(paneToUpdate, 'pane_position')
			const prevPos = _.get(state[paneIdx], 'pane_position')
			//if pane position changed
			if (nextPos !== prevPos) {
				const isBackWardPosChange = nextPos < prevPos
				return update(state, {
					$set: reIndexPanes(
						state,
						paneToUpdate,
						isBackWardPosChange ? nextPos : prevPos + 1, //startIdx
						isBackWardPosChange ? prevPos - 1 : nextPos,
						isBackWardPosChange ? 1 : -1
					),
				})
			}
			return update(state, { [paneIdx]: { $set: { ...paneToUpdate } } })
		case ACTION_TYPES.REMOVE_GLASS_PANE:
			const deletePanePos = state[paneIdx]?.pane_position
			const updatedState = update(state, { $splice: [[paneIdx, 1]] })
			const isDeletedLastItem = deletePanePos === updatedState.length
			return isDeletedLastItem ? updatedState : reIndexPanes(updatedState, null, deletePanePos + 1, updatedState.length, -1)
		default:
			return state
	}
}

export default paneReducer
