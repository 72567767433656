import { API_ENDPOINTS } from '../../constants'
import { ACTION_TYPES } from '../../constants'
import { showLoader, hideLoader, showErrorMessage } from '../loader/actions'
import Axios from 'axios'
import _ from 'lodash'
const CancelToken = Axios.CancelToken

const _filterDefGroupsMaker = (groups) => {
	const _rulesMaker = (group) => {
		const rules = !_.isEmpty(_.get(group, 'value', []))
			? {
					condition: group.condition,
					field_name: _.isEmpty(_.get(group, 'field_name'))
						? _.get(group, 'elasticConfig', '')
						: _.get(group, 'field_name', _.get(group, 'elasticConfig', '')),
					id: group.id,
					value: group.value,
			  }
			: {}
		if (!_.isEmpty(rules)) {
			return {
				rules: [rules],
				condition: _.includes(group.condition, 'not') ? 'AND' : 'OR',
			}
		}
	}
	return groups
		.map((group) => _rulesMaker(group))
		.reduce((acc, group) => {
			if (!_.isNil(group)) {
				acc.push(group)
			}
			return acc
		}, [])
}

export const getFilterDefinitionFromUIFilters = (dsFilterList) => {
	return _.filter(dsFilterList, (dsFilter) => !_.isEmpty(_.get(dsFilter, 'filters', []))).map((dataSourceFilters) => ({
		ds_id: dataSourceFilters.dataSourceId,
		condition: 'AND',
		groups: _filterDefGroupsMaker(dataSourceFilters.filters),
	}))
}

export const buildUIFiltersFromApiResp = (apiRespFilters) => {
	return apiRespFilters.map((dataSourceFilters) => ({
		dataSourceId: _.get(dataSourceFilters, 'ds_id'),
		filters: _.get(dataSourceFilters, 'groups[0].rules'),
	}))
}

export const buildIPFiltersFromApiResp = (apiIPFiltersUnConditioned) => {
	const apiIPFilters = _.isEmpty(apiIPFiltersUnConditioned) ? [] : apiIPFiltersUnConditioned
	return apiIPFilters.map((dataSourceFilters) => ({
		dataSourceId: _.get(dataSourceFilters, 'ds_id'),
		inPageFilters: _.get(dataSourceFilters, 'rules'),
	}))
}

const getInfoFromResp = (response, defaultMsg) => {
	return _.get(response, 'data.more_info', _.get(response, 'data.message', defaultMsg))
}

const showErrMsg = (dispatch, err) => {
	const errMsg = getInfoFromResp(err.response, 'Something Went Wrong!')
	dispatch(
		showErrorMessage(`ERROR : ${errMsg}, Please try again.`, 'Close', (e) => {
			dispatch(hideLoader())
		})
	)
}

const updateFiltersPopupDashboard = (dispatch, dashboardId, dashboardData, successCallback) => {
	dispatch(showLoader('Applying filters...'))
	Axios.put(API_ENDPOINTS.DASHBOARD_PATH_API, dashboardData, {
		headers: {
			isAuthRequired: true,
			'content-type': 'application/json',
			path: { dashboardId },
		},
	})
		.then(() => {
			dispatch(hideLoader())
			successCallback && successCallback()
		})
		.catch((err) => {
			showErrMsg(dispatch, err)
		})
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	fetchPopUpDashboard: (apiDomain = '', dashboardId, authKey = '') => {
		return (dispatch) => {
			dispatch({ type: ACTION_TYPES.FETCHING_POPUP_DASHBOARD_DETAILS_PENDING })
			Axios.get(`${apiDomain}${API_ENDPOINTS.DASHBOARD_PATH_API}`, {
				headers: {
					isAuthRequired: true,
					authKey: authKey,
					path: { dashboardId },
				},
			})
				.then((resp) => {
					dispatch({
						type: ACTION_TYPES.FETCHING_POPUP_DASHBOARD_DETAILS_FULFILLED,
						payload: resp,
					})
				})
				.catch((err) => {
					dispatch({
						type: ACTION_TYPES.FETCHING_POPUP_DASHBOARD_DATA_REJECTED,
						payload: err,
					})
				})
		}
	},
	fetchPopUpDashboardAndApplyFilters: (apiDomain = '', dashboardId, authKey = '', filtersToAdd, successCallback, errorCallback) => {
		const _getFilterDefinitionFromUIFilters = (dsFilterList) => {
			const _getAllFilterRules = (arrayOfDS) => arrayOfDS.reduce((acc, dsObj) => acc.concat(_.get(dsObj, 'rules', [])), [])
			return _.filter(dsFilterList, (dsFilter) => !_.isEmpty(_.get(dsFilter, 'rules', []))).map((dataSourceFilters, currIdx, allDSWithFilters) => ({
				ds_id: dataSourceFilters.ds_id,
				condition: 'AND',
				groups: _filterDefGroupsMaker(_getAllFilterRules(allDSWithFilters)),
			}))
		}

		return (dispatch) => {
			// dispatch(showLoader('Fetching Data...'))
			Axios.get(`${apiDomain}${API_ENDPOINTS.DASHBOARD_PATH_API}`, {
				headers: {
					isAuthRequired: true,
					authKey: authKey,
					path: { dashboardId },
				},
			})
				.then((resp) => {
					const ipf = _.cloneDeep(_.get(resp, 'data.data.in_page_filters', []))
					const dashboardId = _.get(resp, 'data.data.dashboard_id')
					const dsIdList = _.uniq(_.get(resp, 'data.data.graph_details', []).map((graphDetail) => _.get(graphDetail, 'ds_id', '')))

					const tempInPageFilters = dsIdList.map((dsId) => {
						const matchedIPF = _.find(ipf, (o) => o.ds_id === dsId)
						if (!_.isEmpty(matchedIPF)) {
							const fieldNamesOfFiltersToAdd = filtersToAdd.map((filter) => filter.columnName)
							const fieldNamesOfMatchedIPF = matchedIPF.rules.map((rule) => rule.field_name)
							const differentFieldNames = _.difference(fieldNamesOfFiltersToAdd, fieldNamesOfMatchedIPF)
							const sameFieldNames = _.intersection(fieldNamesOfFiltersToAdd, fieldNamesOfMatchedIPF)
							differentFieldNames.forEach((name) => {
								const matchingFilterToAdd = _.find(filtersToAdd, (o) => o.columnName === name)
								matchedIPF.rules.push({
									field_name: name,
									value: _.uniq(
										_.isArray(_.get(matchingFilterToAdd, 'value')) ? _.get(matchingFilterToAdd, 'value', []) : [_.get(matchingFilterToAdd, 'value')]
									),
									isHidden: true,
									condition: 'equals',
									component: 'autocomplete',
								})
							})
							sameFieldNames.forEach((name) => {
								const matchingFilterToAdd = _.find(filtersToAdd, (o) => o.columnName === name)
								const isEqualConditionAvail = _.find(matchedIPF.rules, (o) => o.field_name === name && o.condition === 'equals')
								matchedIPF.rules.map((rule, idx) => {
									if (isEqualConditionAvail) {
										if (rule.field_name === name && rule.condition === 'equals') {
											matchedIPF.rules[idx].value = _.uniq(
												_.concat(
													rule.value,
													_.isArray(_.get(matchingFilterToAdd, 'value'))
														? _.get(matchingFilterToAdd, 'value', [])
														: [_.get(matchingFilterToAdd, 'value')]
												)
											)
										}
									} else {
										matchedIPF.rules.push({
											field_name: name,
											condition: 'equals',
											component: 'autocomplete',
											isHidden: true,
											value: _.uniq(
												_.isArray(_.get(matchingFilterToAdd, 'value'))
													? _.get(matchingFilterToAdd, 'value', [])
													: [_.get(matchingFilterToAdd, 'value')]
											),
										})
									}
									return true
								})
							})
							return matchedIPF
						} else {
							return {
								ds_id: dsId,
								rules: filtersToAdd.map((filter) => ({
									field_name: _.get(filter, 'columnName', ''),
									value: _.uniq(_.isArray(_.get(filter, 'value')) ? _.get(filter, 'value', []) : [_.get(filter, 'value')]),
									isHidden: true,
									// component: "autocomplete",
									condition: 'equals',
									// filterName: _.get(filter, 'columnName')
								})),
							}
						}
					})

					const dataToAttach = {
						dashboard_name: _.get(resp, 'data.data.dashboard_name', 'Untitled Dashboard'),
						dashboard_layout: _.get(resp, 'data.data.dashboard_layout'),
						graphs: _.get(resp, 'data.data.graphs'),
						in_page_filters: _.get(resp, 'data.data.in_page_filters', []),
						popup_filters: tempInPageFilters,
						filter_definitions: _getFilterDefinitionFromUIFilters(tempInPageFilters),
					}
					Axios.put(API_ENDPOINTS.DASHBOARD_PATH_API, dataToAttach, {
						headers: {
							isAuthRequired: true,
							'content-type': 'application/json',
							path: { dashboardId },
						},
					})
						.then((response) => {
							// dispatch(hideLoader())
							const tempObj = { data: { data: dataToAttach } }
							_.merge(tempObj, resp)
							dispatch({
								type: ACTION_TYPES.FETCHING_POPUP_DASHBOARD_DETAILS_FULFILLED,
								payload: tempObj,
							})
							successCallback && successCallback()
						})
						.catch((err) => {
							console.log('error while putting data', err)
							successCallback && successCallback()
							// dispatch(hideLoader());
						})
				})
				.catch((err) => {
					console.log('Something Bad Happened and Thrown an Error', err)
					errorCallback && errorCallback()
					// successCallback && successCallback();
					dispatch({
						type: ACTION_TYPES.FETCHING_POPUP_DASHBOARD_DETAILS_REJECTED,
					})
					// dispatch(hideLoader());
				})
		}
	},

	fetchPopUpDashboardData: (apiDomain = '', dashboardId, authKey = '', cancelExecutor, tempFilterDefs) => {
		return (dispatch) => {
			cancelExecutor.current && cancelExecutor.current()
			dispatch({ type: ACTION_TYPES.FETCHING_POPUP_DASHBOARD_DATA_PENDING })
			Axios.post(
				apiDomain + API_ENDPOINTS.DASHBOARD_FETCH_DATA_API,
				{ ...(tempFilterDefs ? { filter_definitions: tempFilterDefs } : {}) }, //{ filter_definitions: tempFilterDefs || []},
				{
					cancelToken: new CancelToken(function executor(c) {
						cancelExecutor.current = c
					}),
					headers: {
						isAuthRequired: true,
						authKey: authKey,
						path: { dashboardId },
					},
				}
			)
				.then((resp) => {
					dispatch({
						type: ACTION_TYPES.FETCHING_POPUP_DASHBOARD_DATA_FULFILLED,
						payload: resp,
					})
				})
				.catch((err) => {
					dispatch({
						type: ACTION_TYPES.FETCHING_POPUP_DASHBOARD_DATA_REJECTED,
						payload: err,
					})
				})
		}
	},

	updatePopupDashboardFilters: (dashboardId, dashboardData, successCallback) => {
		return (dispatch) => {
			updateFiltersPopupDashboard(dispatch, dashboardId, dashboardData, successCallback)
		}
	},
}
