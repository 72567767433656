import {
	addDays,
	endOfDay,
	startOfDay,
	startOfMonth,
	endOfMonth,
	addMonths,
	startOfWeek,
	endOfWeek,
	isSameDay,
	differenceInCalendarDays,
} from 'date-fns'

const defineds = {
	startOfWeek: startOfWeek(new Date()),
	endOfWeek: new Date(),
	endOfThisWeek: endOfWeek(new Date()),
	startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
	endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
	startOfNextWeek: startOfWeek(addDays(new Date(), +7)),
	endOfNextWeek: endOfWeek(addDays(new Date(), +7)),
	startOfToday: startOfDay(new Date()),
	endOfToday: endOfDay(new Date()),
	startOfYesterday: startOfDay(addDays(new Date(), -1)),
	endOfYesterday: endOfDay(addDays(new Date(), -1)),
	startOfMonth: startOfMonth(new Date()),
	endOfMonth: new Date(),
	endOfThisMonth: endOfMonth(new Date()),
	startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
	endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
	startOfNextMonth: startOfMonth(addMonths(new Date(), +1)),
	endOfNextMonth: endOfMonth(addMonths(new Date(), +1)),
}

const staticRangeHandler = {
	range: {},
	isSelected(range) {
		const definedRange = this.range()
		return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
	},
}

export function createStaticRanges(ranges) {
	return ranges.map((range) => ({ ...staticRangeHandler, ...range }))
}

export const defaultStaticRanges = createStaticRanges([
	{
		label: 'Today',
		range: () => ({
			startDate: defineds.startOfToday,
			key: 'today',
			endDate: defineds.endOfToday,
		}),
	},
	{
		label: 'Yesterday',
		range: () => ({
			startDate: defineds.startOfYesterday,
			key: 'yesterday',
			endDate: defineds.endOfYesterday,
		}),
	},
	{
		label: 'Last 5 Days',
		range: () => ({
			startDate: addDays(defineds.startOfToday, -4),
			key: 'last 5 days',
			endDate: defineds.startOfToday,
		}),
	},
	{
		label: 'This Week',
		range: () => ({
			startDate: defineds.startOfWeek,
			key: 'this week',
			endDate: defineds.endOfWeek,
		}),
	},
	{
		label: 'Last Week',
		range: () => ({
			startDate: defineds.startOfLastWeek,
			key: 'last week',
			endDate: defineds.endOfLastWeek,
		}),
	},
	{
		label: 'This Month',
		range: () => ({
			startDate: defineds.startOfMonth,
			key: 'this month',
			endDate: defineds.endOfMonth,
		}),
	},
	{
		label: 'Last Month',
		range: () => ({
			startDate: defineds.startOfLastMonth,
			key: 'last month',
			endDate: defineds.endOfLastMonth,
		}),
	},
])

export const defaultInputRanges = [
	{
		label: 'days up to today',
		range(value) {
			return {
				startDate: addDays(defineds.startOfToday, (Math.max(Number(value), 0) - 0) * -1),
				key: 'custom range',
				endDate: defineds.endOfToday,
			}
		},
		getCurrentValue(range) {
			if (!isSameDay(range.endDate, defineds.endOfToday)) return '-'
			if (!range.startDate) return '∞'
			return differenceInCalendarDays(defineds.endOfToday, range.startDate)
		},
	},
]

export const defaultDoubleInputRanges = [
	{
		label: 'days up to today',
		range(value) {
			console.log('values from days up to today', value)
			return {
				startDate: addDays(defineds.startOfToday, (Math.max(Number(value), 0) - 0) * -1),
				key: 'custom range',
				endDate: defineds.endOfToday,
			}
		},
		getCurrentValue(range) {
			if (!isSameDay(range.endDate, defineds.endOfToday)) return '-'
			if (!range.startDate) return '∞'
			return differenceInCalendarDays(defineds.endOfToday, range.startDate)
		},
	},
	{
		label: 'days starting today',
		range(value) {
			return {
				startDate: defineds.startOfToday,
				key: 'custom range',
				endDate: addDays(defineds.startOfToday, (Math.max(Number(value), 0) - 0) * +1),
			}
		},
		getCurrentValue(range) {
			if (!isSameDay(range.startDate, defineds.startOfToday)) return '-'
			if (!range.endDate) return '∞'
			return differenceInCalendarDays(range.endDate, defineds.startOfToday)
		},
	},
]

export const fromTodayInputRange = [
	{
		label: 'days starting today',
		range(value) {
			return {
				startDate: defineds.startOfToday,
				key: 'custom range',
				endDate: addDays(defineds.startOfToday, (Math.max(Number(value), 0) - 0) * +1),
			}
		},
		getCurrentValue(range) {
			if (!isSameDay(range.startDate, defineds.startOfToday)) return '-'
			if (!range.endDate) return '∞'
			return differenceInCalendarDays(range.endDate, defineds.startOfToday)
		},
	},
]

export const fromTodayStaticRanges = createStaticRanges([
	{
		label: 'Today',
		range: () => ({
			startDate: defineds.startOfToday,
			key: 'today',
			endDate: defineds.endOfToday,
		}),
	},
	{
		label: '5 Days from Today',
		range: () => ({
			startDate: defineds.startOfToday,
			key: 'five days from today',
			endDate: addDays(defineds.endOfToday, +4),
		}),
	},
	{
		label: 'End of This Week',
		range: () => ({
			startDate: defineds.startOfToday,
			key: 'end of this week',
			endDate: defineds.endOfThisWeek,
		}),
	},
	{
		label: 'Next Week',
		range: () => ({
			startDate: defineds.startOfNextWeek,
			key: 'next week',
			endDate: defineds.endOfNextWeek,
		}),
	},
	{
		label: 'End of This Month',
		range: () => ({
			startDate: defineds.startOfToday,
			key: 'end of this month',
			endDate: defineds.endOfThisMonth,
		}),
	},
	{
		label: 'End of Next Month',
		range: () => ({
			startDate: defineds.startOfToday,
			key: 'end of next month',
			endDate: defineds.endOfNextMonth,
		}),
	},
	{
		label: 'Next Month',
		range: () => ({
			startDate: defineds.startOfNextMonth,
			key: 'next month',
			endDate: defineds.endOfNextMonth,
		}),
	},
])
