import { Icon, makeStyles, Grid, IconButton } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import _ from 'lodash'
// Import Swiper styles
import 'swiper/swiper.scss'
import ReactPlayer from 'react-player'
import { API_ENDPOINTS } from 'constants/index'

const useStyles = makeStyles((theme) => ({
	swiperRoot: {
		margin: theme.spacing(2, 0),
		'& .MuiIcon-fontSizeSmall': {
			fontSize: '2.25rem',
		},
	},
	slideContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
		boxSizing: 'border-box',
		padding: '5px',
	},
	slideImage: {
		maxHeight: '100%',
		maxWidth: '100%',
	},
	mobileStepper: {
		width: '100%',
		borderRadius: '10px',
	},
}))

const SwiperCarousel = ({ imgUrl, videoUrl }) => {
	const classes = useStyles()
	const [swiper, setSwiper] = useState(null)
	const [activeStep, setActiveStep] = useState(0)
	const [arrayLength, setArrayLength] = useState(0)
	const params = {
		onSwiper: setSwiper,
		autoHeight: true,
		slidesPerView: 1,
	}
	const handleNext = () => {
		if (!_.isEmpty(swiper)) {
			swiper.slideNext()
			setActiveStep(activeStep + 1)
		}
	}

	const handleBack = () => {
		if (!_.isEmpty(swiper)) {
			swiper.slidePrev()
			setActiveStep(activeStep - 1)
		}
	}

	useEffect(() => {
		let mergedData =
			imgUrl && videoUrl ? [...imgUrl, ...videoUrl].length : !imgUrl && videoUrl ? videoUrl?.length : imgUrl && !videoUrl ? imgUrl?.length : 0
		setArrayLength(mergedData)
	}, [imgUrl, videoUrl])

	return (
		<Grid className={classes.swiperRoot} container alignItems='center' justifyContent='center'>
			<Grid item lg={1} xs={1}>
				<IconButton disabled={activeStep === 0} onClick={handleBack} size='small'>
					<Icon fontSize='small'>chevron_left</Icon>
				</IconButton>
			</Grid>
			<Grid item lg={10} xs={10}>
				<Swiper className={classes.swiperContainer} {...params}>
					{!_.isEmpty(imgUrl) &&
						_.map(imgUrl, (url) => (
							<SwiperSlide className={classes.slideContent}>
								<img className={classes.slideImage} alt={''} src={url} />
							</SwiperSlide>
						))}
					{!_.isEmpty(videoUrl) &&
						_.map(videoUrl, (url) => (
							<SwiperSlide className={classes.slideContent}>
								<ReactPlayer
									className={classes.reactPlayer}
									url={API_ENDPOINTS.CORS_API + url}
									width={'100%'}
									height={'200px'}
									playing={false}
									controls={true}
									onContextMenu={(e) => e.preventDefault()}
								/>
							</SwiperSlide>
						))}
				</Swiper>
				{}
			</Grid>
			<Grid item lg={1} xs={1}>
				<IconButton disabled={_.isEqual(activeStep, arrayLength - 1)} onClick={handleNext} size='small'>
					<Icon fontSize='small'>chevron_right</Icon>
				</IconButton>
			</Grid>
			{/* <MobileStepper
				steps={4}
				position='static'
				variant='text'
				className={classes.mobileStepper}
				activeStep={activeStep}
				nextButton={
					<Button classes={navButtonClasses} className={classes.navButtons} size='small' onClick={handleNext} disabled={activeStep === 4 - 1}>
						Next
						<Icon>navigate_next</Icon>
					</Button>
				}
				backButton={
					<Button classes={navButtonClasses} className={classes.navButtons} size='small' onClick={handleBack} disabled={activeStep === 0}>
						<Icon>navigate_before</Icon>
						Prev
					</Button>
				}
			/> */}
		</Grid>
	)
}

export default SwiperCarousel
