import React, { useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import { getImgUrl } from 'utils'
import { useSelector } from 'react-redux'
import {
	Icon,
	IconButton,
	Typography,
	List,
	ListItem,
	Divider,
	ListItemText,
	ListItemAvatar,
	Avatar,
	Button,
	ButtonBase,
	Collapse,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core'
import moment from 'moment'
import _ from 'lodash'
import { convertGmtToLocalTime, stringToHslColor } from 'utils'
import { useDispatch } from 'react-redux'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { getErrMsg } from 'utils'
import { Skeleton } from '@material-ui/lab'
import classnames from 'classnames'
import notificationActionPerformer from './NotificationActionPerformer'
import { useHistory } from 'react-router'
import update from 'immutability-helper'
import NotificationApi from 'services/notifications/api'
import Axios from 'axios'

const useStyles = makeStyles((theme) => ({
	drawer: {
		'& .MuiDrawer-paper': {
			maxWidth: '36ch',
			width: '100%',
			minWidth: '36ch',
		},
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '12px 16px',
	},
	menuTitle: {
		fontSize: '1rem',
		paddingRight: '16px',
		flex: '1',
	},
	listHolder: {
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			background: `${theme.palette.primary.main}`,
		},
	},
	listContainer: {
		width: '100%',
		padding: 0,
		// '& .MuiListItemText-secondary': {
		// 	display: '-webkit-box',
		// 	WebkitLineClamp: 2,
		// 	WebkitBoxOrient: 'vertical',
		// 	overflow: 'hidden',
		// 	textOverflow: 'ellipsis',
		// },
		'& .MuiDivider-root': {
			backgroundColor: 'rgb(0 0 0 / 8%)',
		},
	},
	listItemRoot: {
		paddingRight: theme.spacing(1),
	},
	listItemUnshown: {
		background: theme.palette.grey[100],
	},
	userList: {
		display: 'flex',
		flexDirection: 'column',
	},
	sortButton: {
		textTransform: 'none',
		color: '#92929d',
		lineHeight: 'normal',
		'& .MuiButton-endIcon > span': {
			fontSize: '16px',
		},
		paddingRight: '8px',
	},
	showMoreBtn: {
		margin: '0 auto',
	},
	listSubText: {
		marginTop: 8,
	},
	noNotfIcon: {
		display: 'inherit',
		paddingTop: theme.spacing(1.5),
		margin: '0 auto',
		color: theme.palette.grey[500],
	},
	loadingSkeleton: {
		display: 'flex',
		padding: '16px',
		'& .MuiSkeleton-circle': {
			marginRight: '10px',
		},
	},
	lineSkeleton: {
		width: '100%',
	},
	userName: {
		fontWeight: 500,
		letterSpacing: '1px',
	},
	createdDate: {
		fontSize: 12,
		marginTop: theme.spacing(0.5),
	},
	refreshBtn: {
		backgroundColor: `${theme.palette.success.main}54`,
	},
	refreshBtnTxt: {
		padding: theme.spacing(1),
	},
	markIcon: {
		fontSize: 12,
		color: theme.palette.info.dark,
	},
	notificationContent: {
		display: 'flex',
	},
	notfTxtWrap: {
		flex: '1',
	},
	notfMarkBtnWrap: {
		display: 'flex',
		justifyContent: 'center',
	},
	checkboxWrapper: {
		margin: 0,
	},
	avatar: {
		width: '35px',
		height: '35px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		lineHeight: 'unset',
	},
	avatarRoot: {
		minWidth: '45px',
	},
}))

const Header = ({ handleClose, handleSort, buttonUI }) => {
	const classes = useStyles()
	return (
		<>
			<div className={classes.header}>
				<Typography variant='h6' className={classes.menuTitle}>
					Notifications
				</Typography>
				<Button size='small' className={classes.sortButton} endIcon={<Icon fontSize='small'>{buttonUI.btnIcon}</Icon>} onClick={handleSort}>
					{buttonUI.btnText}
				</Button>
				<IconButton size='small' title='Close Menu' onClick={handleClose}>
					<Icon fontSize='small'>close</Icon>
				</IconButton>
			</div>
			<Divider />
		</>
	)
}

const NotificationItem = ({
	userName,
	createdDate,
	comments,
	loading,
	img_Src,
	isRead,
	isShown,
	authToken,
	onClick,
	onChangeReadStatus,
	showDivider,
}) => {
	const classes = useStyles()
	const getPicUrl = (img_Src) => getImgUrl(authToken, img_Src, 50)
	const profilePicUrl = img_Src && getPicUrl(img_Src)

	const onClickMarkCheckbox = (e) => {
		e.stopPropagation()
		onChangeReadStatus(!isRead)
	}

	return (
		<>
			<ListItem
				button
				onClick={onClick}
				className={classnames({ [classes.listItemUnshown]: !isShown }, classes.listItemRoot)}
				alignItems='flex-start'
			>
				<ListItemAvatar className={classes.avatarRoot}>
					{loading ? (
						<div>
							<Skeleton animation='wave' variant='circle' width={40} height={40} />
						</div>
					) : (
						<Avatar
							className={classes.avatar}
							alt={userName}
							src={_.isEmpty(profilePicUrl) ? 'src' : profilePicUrl}
							style={{
								backgroundColor: stringToHslColor(userName),
							}}
						/>
					)}
				</ListItemAvatar>
				<ListItemText
					primary={
						loading ? (
							<div>
								<Skeleton animation='wave' height={20} width='100%' />
							</div>
						) : null
					}
					secondary={
						loading ? (
							<div>
								<Skeleton animation='wave' height={20} width='100%' />
							</div>
						) : (
							<div className={classes.notificationContent}>
								<div className={classes.notfTxtWrap}>
									<Typography className={classes.userName} component='span' variant='body2' color='textPrimary'>
										{userName}
									</Typography>
									<Typography className={classes.comments} component='span' variant='body2' color='textSecondary'>
										{` ${comments}`}
									</Typography>
									<Typography className={classes.createdDate} component='p' variant='caption' color='textSecondary'>
										{createdDate}
									</Typography>
								</div>
								<div className={classes.notfMarkBtnWrap}>
									<FormControlLabel
										className={classes.checkboxWrapper}
										title={`Mark as ${isRead ? 'Unread' : 'Read'}`}
										control={
											<Checkbox
												onClick={onClickMarkCheckbox}
												icon={
													<Icon check={isRead} className={classes.markIcon}>
														circle
													</Icon>
												}
												checked={isRead}
												checkedIcon={<Icon className={classes.markIcon}></Icon>}
											/>
										}
									/>
								</div>
							</div>
						)
					}
				/>
			</ListItem>
			{showDivider && <Divider variant='inset' component='li' />}
		</>
	)
}

const NotificationList = ({ onClickNotification, updateNotificationReadStatus, notifications, loading }) => {
	const classes = useStyles()
	const authToken = useSelector((state) => _.get(state, 'session.authToken', ''))

	return (
		<div className={classes.listHolder}>
			{!_.isEmpty(notifications) ? (
				<List>
					{notifications.map((item, index) => (
						<NotificationItem
							key={index}
							userName={_.join([_.get(item, 'created_by.first_name', ''), _.get(item, 'created_by.last_name', '')], ' ')}
							createdDate={convertGmtToLocalTime(_.get(item, 'created_date', ''), 'DD MMM YYYY hh:mm A')}
							comments={_.get(item, 'notification_msg', '')}
							loading={loading}
							date_gmt={_.get(item, 'created_date', '')}
							img_Src={_.get(item, 'created_by.profile_pic_url', '')}
							isRead={_.get(item, 'is_read', false)}
							isShown={_.get(item, 'is_shown', false)}
							authToken={authToken}
							onClick={(e) => {
								onClickNotification(item, index)
							}}
							onChangeReadStatus={(isRead) => {
								updateNotificationReadStatus(item, index, isRead)
							}}
							showDivider={notifications.length - 1 > index}
						/>
					))}
				</List>
			) : loading ? (
				<div className={classes.loadingSkeleton}>
					<Skeleton animation='wave' variant='circle' width={54} height={44} />
					<div className={classes.lineSkeleton}>
						<Skeleton animation='wave' height={10} width='100%' />
						<Skeleton animation='wave' height={40} width='100%' />
					</div>
				</div>
			) : (
				<>
					<Icon fontSize='large' className={classes.noNotfIcon}>
						notifications_active
					</Icon>
					<Typography variant='body2' color='textSecondary' component='div' align='center' className={classes.listSubText}>
						No Notifications Found
					</Typography>
				</>
			)}
		</div>
	)
}

const NotificationDrawer = ({ isSocketConnected, socketRef, open, handleClose }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()
	const notfApiCancelExec = useRef()
	const [activityState, setActivityState] = useState({
		loading: false,
		notificationList: [],
		limit: 50,
		offset: 0,
		sort_by: 'desc',
		total_count: 0,
		showRefreshBtn: false,
	})
	const { loading, notificationList, limit, offset, sort_by, total_count, showRefreshBtn } = activityState

	const notificationListener = useRef()

	useEffect(() => {
		if (isSocketConnected) {
			notificationListener.current = (resp) => {
				setActivityState((prevState) => ({ ...prevState, showRefreshBtn: true }))
			}
			socketRef.current?.on('notification_received', notificationListener.current)
		}
		return () => {
			if (isSocketConnected && notificationListener.current) {
				socketRef.current?.off('notification_received', notificationListener.current)
			}
		}
	}, [isSocketConnected, socketRef, sort_by])

	useEffect(() => {
		if (open && loading) {
			NotificationApi.fetchNotifications(limit, offset, sort_by, notfApiCancelExec)
				.then((res) => {
					let notifications = _.get(res, 'data.data.notifications', [])
					setActivityState((prevState) => ({
						...prevState,
						loading: false,
						showRefreshBtn: false,
						notificationList: offset === 0 ? notifications : [...prevState.notificationList, ...notifications],
						total_count: _.get(res, 'data.data.total_count', 0),
					}))
				})
				.catch((err) => {
					if (!Axios.isCancel(err)) {
						setActivityState((prevState) => ({ ...prevState, loading: false }))
						dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
					}
				})
		} else if (!open) {
			notfApiCancelExec.current && notfApiCancelExec.current()
			setActivityState((prevState) => ({
				...prevState,
				loading: true,
				notificationList: [],
				total_count: 0,
				offset: 0,
				limit: 50,
			}))
		}
	}, [open, loading, offset, limit, sort_by, dispatch])

	const onClickShowMoreBtn = () => {
		setActivityState((prevState) => ({
			...prevState,
			offset: prevState.offset + limit,
			loading: true,
		}))
	}

	const hideShowMoreBtn = total_count === notificationList.length

	const refreshList = (sortBy = 'desc') => {
		setActivityState((prevState) => ({
			...prevState,
			sort_by: sortBy,
			limit: 50,
			loading: true,
			offset: 0,
		}))
	}

	const handleSort = () => {
		refreshList(sort_by === 'asc' ? 'desc' : 'asc')
	}

	const updateNotificationReadStatus = (notification, notificationIdx, isRead) => {
		setActivityState((prevState) => ({
			...prevState,
			notificationList: update(notificationList, { [notificationIdx]: { $set: { ...notification, is_read: isRead } } }),
		}))
		NotificationApi.updateNotification(notification?.notification_id, isRead)
			.then((res) => {
				//successfully updated the read status of the notification
			})
			.catch((err) => {
				if (!Axios.isCancel(err)) {
					setActivityState((prevState) => ({
						...prevState,
						notificationList: update(notificationList, { [notificationIdx]: { $set: { ...notification, is_read: !isRead } } }),
					}))
					dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
				}
			})
	}

	const onClickNotificationItem = (notification, notificationIdx) => {
		handleClose()
		if (!notification?.is_read) {
			//mark notification as read
			updateNotificationReadStatus(notification, notificationIdx, true)
		}
		notificationActionPerformer(notification, history)
	}

	const buttonUI = useMemo(
		() => ({ btnText: sort_by === 'desc' ? 'Newest first' : 'Oldest first', btnIcon: sort_by === 'desc' ? 'arrow_upward' : 'arrow_downward' }),
		[sort_by]
	)

	return (
		<React.Fragment>
			<Drawer anchor='right' open={open} onClose={handleClose} className={classes.drawer}>
				<Header handleClose={handleClose} handleSort={handleSort} buttonUI={buttonUI} />
				{!loading && showRefreshBtn && (
					<ButtonBase
						onClick={() => {
							refreshList()
						}}
						className={classes.refreshBtn}
					>
						<Collapse in={!loading}>
							<Typography className={classes.refreshBtnTxt} align='center' component='div' variant='caption'>
								New notifications found, Click here to refresh
							</Typography>
						</Collapse>
					</ButtonBase>
				)}

				<NotificationList
					onClickNotification={onClickNotificationItem}
					updateNotificationReadStatus={updateNotificationReadStatus}
					notifications={notificationList}
					loading={loading && offset === 0}
				/>
				{!hideShowMoreBtn && (
					<Button disabled={loading} onClick={onClickShowMoreBtn} className={classes.showMoreBtn} size='small' color='primary'>
						{loading ? 'Loading...' : `Show More (${total_count - notificationList.length})`}
					</Button>
				)}
			</Drawer>
		</React.Fragment>
	)
}

export default NotificationDrawer
