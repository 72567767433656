import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles, Icon, Typography } from '@material-ui/core'
import _ from 'lodash'
import TeamUnits from 'scences/UserManagement/TeamUnits'
import { usePermission } from 'hooks/usePermission'
import { ROLE_MODULES } from 'constants/modules'

TeamUnitsWrapper.propTypes = {
	onClickNext: PropTypes.func.isRequired,
}

const useStyles = makeStyles((theme) => ({
	root: {},
	button: {
		marginTop: theme.spacing(2),
		float: 'right',
	},
	info: {
		paddingBottom: theme.spacing(2),
	},
}))

function TeamUnitsWrapper({ onClickNext }) {
	const classes = useStyles()
	const teamPerms = usePermission(ROLE_MODULES.TEAM_MANAGEMENT)
	const [state, setState] = useState({ teamId: null, action: '', isRefreshList: false, parentTuCode: null, teamunitsLength: 0 })
	const { action, teamId, isRefreshList, parentTuCode, teamunitsLength } = state

	const changeState = (newState) => {
		setState((prevState) => ({ ...prevState, ...newState }))
	}

	return (
		<div className={classes.root}>
			<Typography className={classes.info} variant='subtitle1' color='textSecondary' align='center' gutterBottom>
				Create the team units and add the members to the team units
			</Typography>
			<TeamUnits
				isEmbedded={true}
				action={action}
				teamId={teamId}
				perms={teamPerms}
				isRefreshList={isRefreshList}
				parentTuCode={parentTuCode}
				changeEmbededState={changeState}
			/>
			<Button
				onClick={onClickNext}
				variant='contained'
				color='primary'
				disableElevation
				size='small'
				className={classes.button}
				endIcon={<Icon>east</Icon>}
				disabled={teamunitsLength === 0}
			>
				Next
			</Button>
		</div>
	)
}

export default TeamUnitsWrapper
