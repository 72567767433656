import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid, Typography } from '@material-ui/core'
import TeamsTreeView from 'components/TeamsTreeView'
import TeamMembers from '../TeamMembers'
import { NoTeamMembers } from 'illustrators'
import _ from 'lodash'
import LoadingIndicator from 'components/LoadingIndicator'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
	root: {
		border: `1px solid ${theme.palette.grey[300]}`,
		padding: `${theme.spacing(2)}px ${theme.spacing(2.5)}px ${theme.spacing(3)}px`,
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(2),
		minHeight: 264,
		[theme.breakpoints.down('sm')]: {
			marginLeft: theme.spacing(0),
			marginRight: theme.spacing(0),
		},
	},
	treeRoot: {
		flexGrow: 1,
	},
	addRootUnitBtn: {
		float: 'right',
		[theme.breakpoints.down('xs')]: {
			float: 'left',
		},
	},
	header: {
		paddingBottom: theme.spacing(1),
	},
	treeviewWrapper: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			paddingLeft: theme.spacing(0),
			paddingRight: theme.spacing(0),
		},
	},
	emptyIco: {
		fontSize: '8em',
		margin: '0 auto 24px',
		display: 'block',
	},
	loadingWrapper: {
		marginTop: theme.spacing(4),
	},
}))

export default function TeamDetails({
	addBtnClick,
	onTeamNodeSelected,
	teamData,
	showViewOption,
	onTeamNodeAction,
	isShowTeamView,
	selectedTeamId,
	teamBackBtnClick,
	loading,
	isCreateAllowed,
	isEditAllowed,
	isDeleteAllowed,
	selectedTeamName,
	className,
}) {
	const classes = useStyles()

	if (isShowTeamView) {
		return (
			<TeamMembers
				showAddMemberBtn={isEditAllowed}
				showBackBtn={true}
				onBackBtnClick={teamBackBtnClick}
				selectedTeamId={selectedTeamId}
				isEditAllowed={isEditAllowed}
				selectedTeamName={selectedTeamName}
			/>
		)
	}

	return (
		<Grid item className={classnames(classes.root, className)}>
			<Grid item container xs={12} className={classes.header}>
				<Grid item xs={12} lg={8}>
					<Typography variant='h6' gutterBottom>
						Team Units Tree
					</Typography>
				</Grid>
				{isCreateAllowed && (
					<Grid item xs={12} lg={4}>
						<Button className={classes.addRootUnitBtn} onClick={addBtnClick} size='small' variant='contained' color='primary' disableElevation>
							Add root unit
						</Button>
					</Grid>
				)}
			</Grid>
			<Grid item xs={12} className={classes.treeviewWrapper}>
				{_.isEmpty(teamData) ? (
					<>
						{loading ? (
							<div className={classes.loadingWrapper}>
								<LoadingIndicator />
							</div>
						) : (
							<>
								<NoTeamMembers className={classes.emptyIco} />
								<Typography align='center' variant='body2' color='textSecondary'>
									No teams found
								</Typography>
							</>
						)}
					</>
				) : (
					<TeamsTreeView
						showViewOption={showViewOption}
						data={teamData}
						isEditAllowed={isEditAllowed}
						isCreateAllowed={isCreateAllowed}
						isDeleteAllowed={isDeleteAllowed}
						onNodeSelect={onTeamNodeSelected}
						onNodeAction={onTeamNodeAction}
						activeNodeId={selectedTeamId}
					/>
				)}
			</Grid>
		</Grid>
	)
}
