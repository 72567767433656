import { Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'

const StickyLoader = () => {
	return (
		<>
			<Grid container spacing={1}>
				<Grid item lg={10}>
					<Skeleton width='100%' height={30} variant={'h1'} />
					<Skeleton width='40%' height={10} variant={'h6'} style={{ marginTop: '10px' }} />
				</Grid>
				<Grid item lg={1}>
					<Skeleton width='100%' height={30} variant={'h1'} />
				</Grid>
				<Grid item lg={1}>
					<Skeleton width='100%' height={30} variant={'h1'} />
				</Grid>
				<Grid container item={12} spacing={1} style={{ marginTop: '10px' }}>
					<Grid item lg={4}>
						<Skeleton width='100%' height={25} variant={'h1'} />
					</Grid>
					<Grid item lg={4}>
						<Skeleton width='100%' height={25} variant={'h1'} />
					</Grid>
					<Grid item lg={12}>
						<Skeleton width='100%' height={2} variant={'h1'} />
					</Grid>
				</Grid>
			</Grid>
			<Grid container spacing={1} style={{ marginTop: '20px' }}>
				<Grid lg={6}>
					<div style={{ padding: '12px 12px 12px 0' }}>
						<Skeleton width='50%' height={15} variant={'h1'} />
						<Skeleton width='80%' height={25} variant={'h1'} style={{ marginTop: '10px' }} />
					</div>
				</Grid>
				<Grid lg={6}>
					<div style={{ padding: '12px 12px 12px 0' }}>
						<Skeleton width='50%' height={15} variant={'h1'} />
						<Skeleton width='80%' height={25} variant={'h1'} style={{ marginTop: '10px' }} />
					</div>
				</Grid>
			</Grid>
			<Grid container spacing={1} style={{ marginTop: '32px' }}>
				<Grid lg={6}>
					<div style={{ padding: '12px 12px 12px 0' }}>
						<Skeleton width='50%' height={15} variant={'h1'} />
						<Skeleton width='100%' height={25} variant={'h1'} style={{ marginTop: '10px' }} />
					</div>
				</Grid>
				<Grid lg={6}>
					<div style={{ padding: '12px 12px 12px 0' }}>
						<Skeleton width='50%' height={15} variant={'h1'} />
						<Skeleton width='100%' height={25} variant={'h1'} style={{ marginTop: '10px' }} />
					</div>
				</Grid>
			</Grid>
			<Grid container spacing={1} style={{ marginTop: '32px' }}>
				<Grid lg={12}>
					<div style={{ padding: '0px 12px 12px 0' }}>
						<Skeleton width='30%' height={15} variant={'h1'} />
						<Skeleton width='100%' height={25} variant={'h1'} style={{ marginTop: '10px' }} />
					</div>
				</Grid>
			</Grid>
		</>
	)
}

export default StickyLoader
