import { ACTION_TYPES } from '../../../constants'
// import _ from 'lodash'
import { getErrMsg } from 'utils'
import _ from 'lodash'
import update from 'immutability-helper'

const initState = {
	isFetchingLabel: true,
	isFetchingLabelFailed: false,
	isFetchingLabelError: '',
	labelsList: {},
	isFetchingLabelCategory: true,
	isFetchingLabelCategoryFailed: false,
	isFetchingLabelCategoryError: '',
	labelsCategoryList: {},
	isFetchingDepartment: true,
	isFetchingDepartmentFailed: false,
	isFetchingDepartmentError: '',
	departmentList: {},
	isFetchingDesignation: true,
	isFetchingDesignationFailed: false,
	isFetchingDesignationError: '',
	designationList: {},
}

const label = (state = initState, action) => {
    const labelIdx = _.findIndex(state?.labelsList?.labels, { label_id: action?.payload?.labelId })
	const departmentIdx = _.findIndex(state?.departmentList?.departments, { department_id: action?.payload?.departmentId })
	const designationIdx = _.findIndex(state?.designationList?.designations, { designation_id: action?.payload?.designationId })
	switch (action.type) {
        case ACTION_TYPES.GET_USER_LABEL_PENDING: {
			return {
				...state,
				isFetchingLabel: true,
				isFetchingLabelFailed: false,
				isFetchingLabelError: undefined,
				labelsList: {},
			}
		}
		case ACTION_TYPES.GET_USER_LABEL_FULFILLED: {
			return {
				...state,
				isFetchingLabel: false,
				isFetchingLabelFailed: false,
				isFetchingLabelError: undefined,
				labelsList: action.payload.data.data,
			}
		}
		case ACTION_TYPES.GET_USER_LABEL_REJECTED: {
			return {
				...state,
				isFetchingLabel: false,
				isFetchingLabelFailed: true,
				isFetchingLabelError: action.payload,
				labelsList: {},
			}
		}
		case ACTION_TYPES.GET_LABEL_CATEGORY_PENDING: {
			return {
				...state,
				isFetchingLabelCategory: true,
				isFetchingLabelCategoryFailed: false,
				isFetchingLabelCategoryError: undefined,
				labelsCategoryList: {},
			}
		}
		case ACTION_TYPES.GET_LABEL_CATEGORY_FULFILLED: {
			return {
				...state,
				isFetchingLabelCategory: false,
				isFetchingLabelCategoryFailed: false,
				isFetchingLabelCategoryError: undefined,
				labelsCategoryList: action.payload.data.data,
			}
		}
		case ACTION_TYPES.GET_LABEL_CATEGORY_REJECTED: {
			return {
				...state,
				isFetchingLabel: false,
				isFetchingLabelFailed: true,
				isFetchingLabelError: action.payload,
				labelsCategoryList: {},
			}
		}
        case ACTION_TYPES.ADD_USER_LABEL: {
			return {
                ...state, 
                labelsList: { labels: [...state.labelsList.labels, action.payload], total_count: state.labelsList.total_count + 1 },
            }
        }
        case ACTION_TYPES.UPDATE_USER_LABEL: {
			const labelToUpdate = _.get(action, 'payload.label')
            const { label_id, label_name } = labelToUpdate
            const updatedState = [...state?.labelsList?.labels]
            updatedState[labelIdx] = { ...updatedState[labelIdx], label_name: label_name }
			return {
                ...state,
                labelsList: { labels: updatedState, total_count: state.labelsList.total_count },
            }
        }
        case ACTION_TYPES.REMOVE_USER_LABEL: {
			return {
                ...state, 
                labelsList: { labels: state.labelsList.labels.filter(item => item.label_id !== action.payload.labelId), total_count: state.labelsList.total_count - 1 },
            }
        }
		case ACTION_TYPES.GET_USER_DEPARTMENT_PENDING: {
			return {
				...state,
				isFetchingDepartment: true,
				isFetchingDepartmentFailed: false,
				isFetchingDepartmentError: undefined,
				departmentList: {},
			}
		}
		case ACTION_TYPES.GET_USER_DEPARTMENT_FULFILLED: {
			return {
				...state,
				isFetchingDepartment: false,
				isFetchingDepartmentFailed: false,
				isFetchingDepartmentError: undefined,
				departmentList: action.payload.data.data,
			}
		}
		case ACTION_TYPES.GET_USER_DEPARTMENT_REJECTED: {
			return {
				...state,
				isFetchingDepartment: false,
				isFetchingDepartmentFailed: true,
				isFetchingDepartmentError: action.payload,
				departmentList: {},
			}
		}
		case ACTION_TYPES.ADD_USER_DEPARTMENT: {
			return {
                ...state, 
                departmentList: { departments: [...state.departmentList.departments, action.payload], total_count: state.departmentList.total_count + 1 },
            }
        }
        case ACTION_TYPES.UPDATE_USER_DEPARTMENT: {
			const departmentToUpdate = _.get(action, 'payload.department')
            const { department_id, department_name } = departmentToUpdate
            const updatedState = [...state?.departmentList?.departments]
            updatedState[departmentIdx] = { ...updatedState[departmentIdx], department_name: department_name }
			return {
                ...state,
                departmentList: { departments: updatedState, total_count: state.departmentList.total_count },
            }
        }
        case ACTION_TYPES.REMOVE_USER_DEPARTMENT: {
			return {
                ...state, 
                departmentList: { departments: state.departmentList.departments.filter(item => item.department_id !== action.payload.departmentId), total_count: state.departmentList.total_count - 1 },
            }
        }
		case ACTION_TYPES.GET_USER_DESIGNATION_PENDING: {
			return {
				...state,
				isFetchingDesignation: true,
				isFetchingDesignationFailed: false,
				isFetchingDesignationError: undefined,
				designationList: {},
			}
		}
		case ACTION_TYPES.GET_USER_DESIGNATION_FULFILLED: {
			return {
				...state,
				isFetchingDesignation: false,
				isFetchingDesignationFailed: false,
				isFetchingDesignationError: undefined,
				designationList: action.payload.data.data,
			}
		}
		case ACTION_TYPES.GET_USER_DESIGNATION_REJECTED: {
			return {
				...state,
				isFetchingDesignation: false,
				isFetchingDesignationFailed: true,
				isFetchingDesignationError: action.payload,
				designationList: {},
			}
		}
		case ACTION_TYPES.ADD_USER_DESIGNATION: {
			return {
                ...state, 
                designationList: { designations: [...state.designationList.designations, action.payload], total_count: state.designationList.total_count + 1 },
            }
        }
        case ACTION_TYPES.UPDATE_USER_DESIGNATION: {
			const designationToUpdate = _.get(action, 'payload.designation')
            const { designation_id, designation_name } = designationToUpdate
            const updatedState = [...state?.designationList?.designations]
            updatedState[designationIdx] = { ...updatedState[designationIdx], designation_name: designation_name }
			return {
                ...state,
                designationList: { designations: updatedState, total_count: state.designationList.total_count },
            }
        }
        case ACTION_TYPES.REMOVE_USER_DESIGNATION: {
			return {
                ...state, 
                designationList: { designations: state.designationList.designations.filter(item => item.designation_id !== action.payload.designationId), total_count: state.designationList.total_count - 1 },
            }
        }
		default: {
			return state
		}
	}
}

export default label
