import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
	Avatar,
	Box,
	Divider,
	Fade,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListSubheader,
	makeStyles,
	Popover,
	Typography,
} from '@material-ui/core'
import SearchBar from 'components/SearchBox'
import _ from 'lodash'
import { Skeleton } from '@material-ui/lab'
import GlassApi from 'services/glass/api'
import { getAvatarTxt, stringToHslColor } from 'utils'
import { useHistory } from 'react-router-dom'
import Axios from 'axios'

GlassSwitchPopover.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	anchorEl: PropTypes.any,
}

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: '2px',
		maxHeight: '450px',
	},
	contentWrapper: {
		paddingTop: theme.spacing(2),
		width: '260px',
	},
	searchBoxRoot: {
		padding: theme.spacing(0, 1.5),
	},
}))

const GlassListItem = ({ glassCode, onClick, glassName, projectName }) => {
	const avatarTxt = getAvatarTxt(glassName)
	const avatarBg = stringToHslColor(avatarTxt)

	return (
		<ListItem
			button
			onClick={(e) => {
				onClick(e, glassCode)
			}}
		>
			<ListItemAvatar>
				<Avatar variant='square' alt={glassName} style={{ backgroundColor: avatarBg }}>
					{avatarTxt}
				</Avatar>
			</ListItemAvatar>
			<ListItemText primary={glassName} secondary={projectName} />
		</ListItem>
	)
}

const LoadingItem = () => {
	return (
		<ListItem button>
			<ListItemAvatar>
				<Skeleton variant='rect' width={40} height={40} />
			</ListItemAvatar>
			<ListItemText
				primary={
					<Box paddingBottom={0.4}>
						<Skeleton variant='rect' width={100} height={15} marginbottom={12} />
					</Box>
				}
				secondary={<Skeleton variant='rect' width={90} height={15} />}
			/>
		</ListItem>
	)
}

const GlassList = ({ showSubheader, isLoading, data, onClickListItem }) => {
	return (
		<List
			subheader={
				showSubheader && (
					<ListSubheader component='div' id='nested-list-subheader'>
						Top List
					</ListSubheader>
				)
			}
			dense={true}
		>
			{isLoading ? (
				<LoadingItem />
			) : _.isEmpty(data) ? (
				<Box paddingLeft={2} paddingRight={2}>
					<Typography align='center' variant='caption'>
						No boards found
					</Typography>
				</Box>
			) : (
				_.map(data, (glass, index) => (
					<div key={glass?.glass_code}>
						<GlassListItem onClick={onClickListItem} glassCode={glass?.glass_code} glassName={glass?.glass_name} projectName={glass?.project_name} />
						{!_.isEqual(data?.length, index + 1) && <Divider variant='fullWidth' component='li' />}
					</div>
				))
			)}
		</List>
	)
}

GlassList.prototype = {
	isLoading: PropTypes.bool.isRequired,
	data: PropTypes.arrayOf(
		PropTypes.shape({
			glassCode: PropTypes.string.isRequired,
			glass_name: PropTypes.string.isRequired,
			project_name: PropTypes.string.isRequired,
		})
	),
}

function GlassSwitchPopover({ open, anchorEl, handleClose }) {
	const classes = useStyles()

	const [state, setState] = useState({ isLoading: false, searchText: '', data: [] })
	const { searchText, data, isLoading } = state
	const glassFetchCancelExec = useRef()
	const history = useHistory()

	const deplayedSearch = useCallback(
		_.debounce((q, callback) => {
			callback(q)
		}, 300),
		[]
	)

	const onChangeSearch = (e) => {
		deplayedSearch(e.target.value, (query) => {
			setState((prevState) => ({ ...prevState, isLoading: true, searchText: query }))
		})
	}

	useEffect(() => {
		if (isLoading) {
			GlassApi.fetchAllGlasses(
				searchText,
				0,
				5,
				'',
				glassFetchCancelExec,
				(resp) => {
					setState((prevState) => ({ ...prevState, isLoading: false, data: _.get(resp, 'data.data.glass', []) }))
				},
				(err) => {
					if (!Axios.isCancel(err)) setState((prevState) => ({ ...prevState, isLoading: false, data: [] }))
				}
			)
		}
	}, [isLoading, searchText])

	const onClickListItem = (e, glassCode) => {
		handleClose()
		history.push(`/glassx/view/${glassCode}`)
	}

	useEffect(() => {
		if (!open) setState({ isLoading: false, data: [], searchText: '' })
		else setState((prevState) => ({ ...prevState, isLoading: true }))
	}, [open])

	return (
		<Popover
			id={'glass-switch-popover'}
			open={open}
			className={classes.root}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			TransitionComponent={Fade}
			TransitionProps={{ timeout: 0 }}
		>
			<div className={classes.contentWrapper}>
				<SearchBar onChange={onChangeSearch} containerClassName={classes.searchBoxRoot} size='small' placeholder='Search' />
				<GlassList showSubheader={_.isEmpty(searchText)} isLoading={isLoading} data={data} onClickListItem={onClickListItem} />
			</div>
		</Popover>
	)
}

export default GlassSwitchPopover
