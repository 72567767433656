import React, { useEffect, useState } from 'react'
import AddTaskDialog from 'components/AddStickyDialog'

const withAddStickyDialog = (Component) => (props) => {
	const [dialogState, setDialogState] = useState({
		openStickyDialog: false,
		stickyFor: '',
		stickySourceId: '',
		stickyIntentDetails: [],
		selectAll: false,
		filterData: [],
		query: '',
		totalRecords: 0,
	})
	const [refreshNeeded, setRefreshNeeded] = useState(false)
	const { openStickyDialog, filesToAttach, stickyFor, stickySourceId, stickyIntentDetails, selectAll, filterData, query, totalRecords } = dialogState

	useEffect(() => {
		return setRefreshNeeded(false)
	}, [refreshNeeded])

	const openDialog = (filesToAttach, stickyFor, stickySourceId, stickyIntentDetails = [], selectAll = false, filterData = {}, query = '', totalRecords = 0) => {
		setDialogState({
			openStickyDialog: true,
			filesToAttach,
			stickyFor,
			stickySourceId,
			stickyIntentDetails,
			selectAll,
			filterData,
			query,
			totalRecords,
		})
	}

	const closeDialog = () => {
		setDialogState({
			openStickyDialog: false,
			stickyFor: '',
			stickySourceId: '',
			stickyIntentDetails: [],
			selectAll: false,
			filterData: [],
			query: '',
			totalRecords: 0,
		})
	}

	const onStickySuccess = () => {
		setRefreshNeeded(true)
	}

	return (
		<>
			<Component openStickyDialog={openDialog} isStickySuccess={refreshNeeded} {...props} />
			<AddTaskDialog
				open={openStickyDialog}
				filesToAttach={filesToAttach}
				stickyFor={stickyFor}
				stickySourceId={stickySourceId}
				stickyIntentDetails={stickyIntentDetails}
				selectAll={selectAll}
				filterData={filterData}
				query={query}
				totalRecords={totalRecords}
				handleClose={closeDialog}
				onStickySuccess={onStickySuccess}
			/>
		</>
	)
}

export default withAddStickyDialog
