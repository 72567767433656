import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Tabs, Tab, Typography, Badge } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { PAGE_MODES } from '../../../../constants'
import Validator from './validator'
import { Form } from 'formik'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { hideLoader } from 'services/loader/actions'
import { createNewUser, fetchUserViaSaga, updateUser } from 'services/users/actions'
import BasicDetails from './BasicDetails'
import ProfileDetails from './ProfileDetails'
import Roles from './Roles'
import Slide from '@material-ui/core/Slide'
import classnames from 'classnames'
import TeamUnit from './TeamUnit'
import { convertLocalTimeToGmtStr, convertGmtToLocalTime } from 'utils'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const getDialogTitle = (action) => {
	switch (action) {
		case PAGE_MODES.CREATE:
			return 'Add User'
		case PAGE_MODES.EDIT:
			return 'Edit User'
		case PAGE_MODES.VIEW:
			return 'View User'
		default:
			return ''
	}
}

const useStyles = makeStyles((theme) => ({
	dialog: {
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
			top: 20,
		},
		[theme.breakpoints.up('md')]: {
			maxWidth: '700px',
		},
	},
	tab: {
		textTransform: 'none',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	customTabTitle: {
		fontSize: 14,
		fontWeight: '500',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	dialogTitle: {
		paddingBottom: 0,
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	dialogContent: {
		paddingTop: theme.spacing(2),
	},
	dialogFooter: {
		padding: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
	badge: {
		marginLeft: theme.spacing(1),
	},
	hideBtn: {
		display: 'none',
	},
}))

const FormDialog = ({
	open,
	userId,
	action,
	handleClose,
	isRoleListLoading,
	roleList,
	isEditAllowed,
	changeToEditMode,
	isCreateAllowed,
	isDeleteAllowed,
}) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const dialogTitle = getDialogTitle(action)
	const classes = useStyles()
	const [activeTabIdx, setActiveTabIdx] = useState(0)
	const [extUsrState, setExtUsrState] = useState({
		isFetchingUsr: false,
		userDetails: {},
	})

	const formRef = useRef()
	const dispatch = useDispatch()

	const { isFetchingUsr, userDetails } = extUsrState

	const isEditMode = _.isEqual(action, PAGE_MODES.EDIT)
	const isCreateMode = _.isEqual(action, PAGE_MODES.CREATE)
	const isViewMode = _.isEqual(action, PAGE_MODES.VIEW)

	const onChangeTab = (e, tabIdx) => {
		setActiveTabIdx(tabIdx)
	}

	const onSubmitForm = (values) => {
		const successCallback = () => {
			handleClose(null, true)
		}
		const closeErrDialog = () => {
			dispatch(hideLoader())
		}
		const tuCodes = _.join(_.get(values, 'tu_code', []), ',')

		if (action === PAGE_MODES.CREATE) {
			// const { password, retype_password, tu_details, ...userDetails } = values
			let userDetails = {
				about: _.get(values, 'about', ''),
				address: _.get(values, 'address', ''),
				area_of_interest: _.get(values, 'area_of_interest', []),
				college_name: _.get(values, 'college_name', ''),
				course: _.get(values, 'course', ''),
				education_qualification: _.get(values, 'education_qualification', ''),
				email: _.get(values, 'email', ''),
				experience: _.get(values, 'experience', ''),
				first_name: _.get(values, 'first_name', ''),
				goals: _.get(values, 'goals', []),
				hobbies: _.get(values, 'hobbies', []),
				is_active: _.get(values, 'is_active'),
				is_email_verified: _.get(values, 'is_email_verified'),
				last_name: _.get(values, 'last_name', ''),
				password: _.get(values, 'password', ''),
				phone: _.get(values, 'phone', ''),
				profile_pic_url: _.get(values, 'profile_pic_url', ''),
				profile_picture: _.get(values, 'profile_picture', ''),
				profile_url: _.get(values, 'profile_url', ''),
				retype_password: _.get(values, 'retype_password', ''),
				role_id: _.get(values, 'role_id', []),
				skill_sets: _.get(values, 'skill_sets', []).map((obj) => obj.label_id),
				labels: _.get(values, 'skill_sets', []).map((obj) => obj.label_id),
				tu_code: _.get(values, 'tu_code', []),
				tu_details: _.get(values, 'tu_details', []),
				type: _.get(values, 'type', ''),
				multiple_users: _.get(values, 'multiple_users', ''),
				location: _.get(values, 'location', ''),
				talent_type: _.get(values, 'talent_type', ''),
				talent_ranking: _.get(values, 'talent_ranking', ''),
				category: _.get(values, 'category', ''),
				designation: _.get(values, 'designation.designation_id', null),
				communication: _.get(values, 'communication', ''),
				department: _.get(values, 'department.department_id', null),
				doj: values.doj ? convertLocalTimeToGmtStr(values.doj) : '',
				people_id: _.get(values, 'people_id', ''),
				gender: _.get(values, 'gender', ''),
				dob: values.dob ? convertLocalTimeToGmtStr(values.dob) : '',
			}
			dispatch(createNewUser({ ...userDetails, tu_code: tuCodes }, successCallback, closeErrDialog))
		} else if (action === PAGE_MODES.EDIT) {
			let userDetails = {
				about: _.get(values, 'about', ''),
				address: _.get(values, 'address', ''),
				area_of_interest: _.get(values, 'area_of_interest', []),
				college_name: _.get(values, 'college_name', ''),
				course: _.get(values, 'course', ''),
				education_qualification: _.get(values, 'education_qualification', ''),
				email: _.get(values, 'email', ''),
				experience: _.get(values, 'experience', ''),
				first_name: _.get(values, 'first_name', ''),
				goals: _.get(values, 'goals', []),
				hobbies: _.get(values, 'hobbies', []),
				is_active: _.get(values, 'is_active'),
				is_email_verified: _.get(values, 'is_email_verified'),
				last_name: _.get(values, 'last_name', ''),
				password: _.get(values, 'password', ''),
				phone: _.get(values, 'phone', ''),
				// profile_pic_url: _.get(values, 'profile_pic_url', ''),
				profile_picture: _.get(values, 'profile_picture', ''),
				profile_url: _.get(values, 'profile_url', ''),
				retype_password: _.get(values, 'retype_password', ''),
				role_id: _.get(values, 'role_id', []),
				skill_sets: _.get(values, 'skill_sets', []).map((obj) => obj.label_id),
				labels: _.get(values, 'skill_sets', []).map((obj) => obj.label_id),
				tu_code: _.get(values, 'tu_code', []),
				tu_details: _.get(values, 'tu_details', []),
				type: _.get(values, 'type', ''),
				location: _.get(values, 'location', ''),
				talent_type: _.get(values, 'talent_type', ''),
				talent_ranking: _.get(values, 'talent_ranking', ''),
				category: _.get(values, 'category', ''),
				designation: _.get(values, 'designation.designation_id', null),
				communication: _.get(values, 'communication', ''),
				department: _.get(values, 'department.department_id', null),
				doj: values.doj ? convertLocalTimeToGmtStr(values.doj) : '',
				people_id: _.get(values, 'people_id', ''),
				gender: _.get(values, 'gender', ''),
				dob: values.dob ? convertLocalTimeToGmtStr(values.dob) : '',
			}
			if (typeof _.get(userDetails, 'profile_picture') === 'string') {
				userDetails['profile_pic_url'] = _.get(userDetails, 'profile_picture')
			}
			const roleIds = _.join(_.get(userDetails, 'role_id', []), ',')
			dispatch(updateUser(userId, { ...userDetails, role_id: roleIds, tu_code: tuCodes }, successCallback, closeErrDialog))
		}
	}

	const onClickEditBtn = () => {
		if (action === PAGE_MODES.VIEW) {
			changeToEditMode(userId)
		}
	}

	const onFormSubmitErr = () => {
		if (!_.isEmpty(formRef.current, 'state.errors')) {
			const keys = _.keys(formRef?.current?.errors)
			const type = formRef?.current?.values?.type
			const tab1 = _.filter(keys, (key) => _.every([_.includes(['first_name', 'people_id', 'email', 'multiple_users'], key)]))
			const tab2 = _.filter(keys, (key) =>
				_.every([_.includes(['talent_type', 'talent_ranking', 'category', 'designation', 'department', 'communication', 'location'], key)])
			)
			const tab3 = _.filter(keys, (key) => _.every([_.includes(['role_id'], key)]))
			if (!_.isEmpty(tab1)) {
				setActiveTabIdx(0)
			} else if (!_.isEmpty(tab2)) {
				setActiveTabIdx(1)
			} else if (!_.isEmpty(tab3)) {
				if (_.isEqual(action, PAGE_MODES.CREATE) && _.isEqual(type, 'multiple_users')) {
					setActiveTabIdx(1)
				} else {
					setActiveTabIdx(2)
				}
			} else {
				setActiveTabIdx(0)
			}
		}
	}

	const onClickActionBtn = () => {
		formRef.current && formRef.current.submitForm()
	}

	useEffect(() => {
		if (!_.isEqual(action, PAGE_MODES.CREATE) && !_.isEmpty(action) && _.isEmpty(userDetails)) {
			setExtUsrState((prevState) => ({ ...prevState, isFetchingUsr: true }))
			dispatch(
				fetchUserViaSaga(
					userId,
					(resp) => {
						const {
							first_name,
							last_name,
							email,
							phone,
							profile_url,
							is_email_verified,
							is_active,
							profile_pic_url,
							type,
							education_qualification,
							course,
							experience,
							college_name,
							goals,
							skill_sets,
							area_of_interest,
							hobbies,
							about,
							address,
							roles,
							team_units,
							location,
							talent_type,
							talent_ranking,
							category,
							designation,
							communication,
							department,
							doj,
							people_id,
							gender,
							dob,
						} = _.get(resp, 'data.data')
						const role_id = _.map(roles, (role) => role?.role_id)
						const tu_code = _.map(team_units, (tu) => tu?.tu_code)
						const userDetails = {
							first_name,
							last_name,
							email,
							phone,
							profile_url,
							type,
							education_qualification,
							course,
							experience,
							college_name,
							goals,
							skill_sets,
							area_of_interest,
							hobbies,
							about,
							address,
							location,
							talent_type,
							talent_ranking,
							category,
							designation,
							communication,
							department,
							people_id,
							gender,
							doj: !_.isEmpty(doj) ? convertGmtToLocalTime(doj) : null,
							dob: !_.isEmpty(dob) ? convertGmtToLocalTime(dob) : null,
							is_active,
							is_email_verified,
							tu_code,
							role_id,
							password: '',
							retype_password: '',
							profile_picture: profile_pic_url,
							tu_details: team_units,
						}
						setExtUsrState((prevState) => ({
							...prevState,
							isFetchingUsr: false,
							userDetails,
						}))
					},
					() => {
						setExtUsrState((prevState) => ({
							...prevState,
							isFetchingUsr: false,
						}))
						dispatch(hideLoader())
						handleClose(null, true)
					}
				)
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [action, userDetails, userId])

	//reset exsting user details on form dialog close event
	useEffect(() => {
		if (!open) {
			setExtUsrState((prevState) => ({
				...prevState,
				isFetchingUsr: false,
				userDetails: {},
			}))
			setActiveTabIdx(0)
		}
	}, [open])

	const openDialog = open && !isFetchingUsr
	return (
		<Dialog
			classes={{
				paper: classes.dialog,
			}}
			TransitionComponent={Transition}
			fullScreen={fullScreen}
			maxWidth={'sm'}
			open={openDialog}
			onClose={handleClose}
			aria-labelledby='user-form-title'
		>
			{!isFetchingUsr ? (
				<Validator formMode={action} initialFormData={userDetails} onSubmit={onSubmitForm} ref={formRef}>
					{(formik) => {
						return (
							<>
								<DialogTitle className={classes.dialogTitle} disableTypography={true} id='user-dialog-title'>
									<Typography variant='h6'>{dialogTitle}</Typography>
									<Tabs
										orientation='horizontal'
										value={activeTabIdx}
										indicatorColor='primary'
										onChange={onChangeTab}
										variant='fullWidth'
										aria-label='User form tabs'
									>
										<Tab className={classes.tab} label={'Basic Details'}></Tab>
										{((_.isEqual(formik?.values?.type, 'single_user') && isCreateMode) || isViewMode || isEditMode) && (
											<Tab className={classes.tab} label={'Profile Details'}></Tab>
										)}
										<Tab
											className={classes.tab}
											label={
												<Typography className={classes.customTabTitle}>
													Roles{' '}
													{_.get(formik, 'values.role_id.length', 0) > 0 && (
														<Badge color='primary' className={classes.badge} badgeContent={_.get(formik, 'values.role_id.length', 0)} />
													)}
												</Typography>
											}
										></Tab>
										<Tab
											className={classes.tab}
											label={
												<Typography className={classes.customTabTitle}>
													Team Units{' '}
													{_.get(formik, 'values.tu_code.length', 0) > 0 && (
														<Badge color='primary' className={classes.badge} badgeContent={_.get(formik, 'values.tu_code.length', 0)} />
													)}
												</Typography>
											}
										></Tab>
									</Tabs>
								</DialogTitle>
								<DialogContent className={classes.dialogContent}>
									<Form id='user-form' autoComplete='new-password'>
										<Effect formik={formik} onSubmissionError={onFormSubmitErr} />
										<div hidden={activeTabIdx !== 0} className={classes.tabPanel}>
											<BasicDetails isViewMode={isViewMode} isEditMode={isEditMode} isCreateMode={isCreateMode} formik={formik} />
										</div>
										{((_.isEqual(formik?.values?.type, 'single_user') && isCreateMode) || isViewMode || isEditMode) && (
											<div hidden={activeTabIdx !== 1} className={classes.tabPanel}>
												<ProfileDetails
													isViewMode={isViewMode}
													isEditMode={isEditMode}
													isCreateMode={isCreateMode}
													formik={formik}
													isEditAllowed={isEditAllowed}
													isCreateAllowed={isCreateAllowed}
													isDeleteAllowed={isDeleteAllowed}
												/>
											</div>
										)}
										<div
											hidden={_.isEqual(formik?.values?.type, 'multiple_users') && isCreateMode ? activeTabIdx !== 1 : activeTabIdx !== 2}
											className={classes.tabPanel}
										>
											<Roles
												isViewMode={isViewMode}
												isEditMode={isEditMode}
												isCreateMode={isCreateMode}
												isRoleListLoading={isRoleListLoading}
												roleList={roleList}
												formRef={formRef}
											/>
										</div>
										<div
											hidden={_.isEqual(formik?.values?.type, 'multiple_users') && isCreateMode ? activeTabIdx !== 2 : activeTabIdx !== 3}
											className={classes.tabPanel}
										>
											<TeamUnit
												isViewMode={isViewMode}
												teamDetails={_.get(userDetails, 'tu_details', [])}
												isCreateMode={isCreateMode || isEditMode}
											/>
										</div>
									</Form>
								</DialogContent>
								<DialogActions className={classes.dialogFooter}>
									<Button variant='outlined' size={fullScreen ? 'small' : 'medium'} onClick={handleClose} color='primary'>
										{isViewMode ? 'Close' : 'Cancel'}
									</Button>

									<Button
										variant='contained'
										onClick={isViewMode ? onClickEditBtn : onClickActionBtn}
										size={fullScreen ? 'small' : 'medium'}
										className={classnames({
											[classes.hideBtn]: isViewMode && !isEditAllowed,
										})}
										color='primary'
										disableElevation
									>
										{isViewMode ? 'Edit' : 'Save'}
									</Button>
								</DialogActions>
							</>
						)
					}}
				</Validator>
			) : (
				<></>
			)}
		</Dialog>
	)
}

function Effect(props) {
	const effect = () => {
		if (props.formik.submitCount > 0 && !props.formik.isValid) {
			props.onSubmissionError()
		}
	}
	useEffect(effect, [props.formik.submitCount])
	return null
}

FormDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	changeToEditMode: PropTypes.func.isRequired,
	isRoleListLoading: PropTypes.bool.isRequired,
	roleList: PropTypes.array,
	userId: PropTypes.string,
	action: PropTypes.string,
	isEditAllowed: PropTypes.bool,
}

export default FormDialog
