import React from 'react'
import { Grid, Avatar, Typography } from '@material-ui/core'
import classnames from 'classnames'
import { stringToHslColor } from 'utils'

const ListItem = ({ name = '', email = '', isSelected = false, onClick = () => null, src = 'fake_path', isDontHighlight = false, classes }) => {
	return (
		<Grid
			container
			alignItems='center'
			style={{ flexWrap: 'nowrap' }}
			onClick={(e) => {
				e.preventDefault()
				onClick()
			}}
			className={classnames(classes.listItemContainer, {
				[classes.selectedListItem]: isSelected,
			})}
		>
			<Avatar
				alt={name}
				src={isDontHighlight ? 'src' : src}
				size='small'
				className={classes.userPic}
				style={isDontHighlight ? {} : { backgroundColor: stringToHslColor(name) }}
			/>
			<Grid item xs>
				<Typography variant='body2' className={classes.optionTextEllipsis} title={name}>
					{name}
				</Typography>
				<Typography variant='body2' color='textSecondary' title={email} className={classes.optionTextEllipsis}>
					{email}
				</Typography>
			</Grid>
		</Grid>
	)
}

export default ListItem
