import { makeStyles, Grid, Typography, Button } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'
import CreateFormDialog from './CreateFomDialog'
import LinkStickyForm from './LinkStickyForm'

const useStyles = makeStyles((theme) => ({
	formRoot: {
		margin: theme.spacing(2, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 0),
		},
	},
	mb2: {
		marginBottom: theme.spacing(2),
	},
	orText: {
		marginTop: theme.spacing(4),
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(1),
		},
	},
	createBtn: {
		marginTop: theme.spacing(1),
	},
}))

const SubTaskForm = ({
	glassMembers,
	glassCode,
	closeDialog,
	stickyCode,
	needRefresh,
	onCreateSubtask,
	refreshOptions,
	formik,
	isOwnerOrReporter,
}) => {
	const classes = useStyles()
	const [openCreateForm, setOpenCreateForm] = useState(false)
	const toggleCreateForm = useCallback(() => {
		setOpenCreateForm(!openCreateForm)
	}, [openCreateForm])

	useEffect(() => {
		if (closeDialog) {
			setOpenCreateForm(false)
		}
	}, [closeDialog])

	return (
		<div className={classes.formRoot}>
			<Grid container className={classes.mb2} alignItems='flex-start' justifyContent='center' spacing={1}>
				<Grid item lg={5} sm={5} xs={12}>
					<Typography variant='subtitle2' component='p'>
						Link Subtask
					</Typography>
					<LinkStickyForm
						isOwnerOrReporter={isOwnerOrReporter}
						formik={formik}
						needRefresh={needRefresh}
						glassCode={glassCode}
						stickyCode={stickyCode}
						refreshOptions={refreshOptions}
					/>
				</Grid>
				<Grid item lg={2} sm={2} xs={12}>
					<Typography className={classes.orText} color='textSecondary'>
						OR
					</Typography>
				</Grid>
				<Grid item lg={5} sm={5} xs={12}>
					<Typography variant='subtitle2' component='p'>
						Create Subtask
					</Typography>
					<Button
						className={classes.createBtn}
						disabled={!isOwnerOrReporter}
						color='primary'
						variant='contained'
						disableElevation
						onClick={toggleCreateForm}
					>
						Create
					</Button>
				</Grid>
			</Grid>
			<CreateFormDialog
				open={openCreateForm}
				handleClose={toggleCreateForm}
				glassMembers={glassMembers}
				closeDialog={closeDialog}
				onCreateSubtask={onCreateSubtask}
			/>
		</div>
	)
}

export default SubTaskForm
