import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, Typography } from '@material-ui/core'
import { msToDurationTxt } from 'utils'

const Timer = React.memo(({ startMs }) => {
	const [ms, setMs] = useState(startMs)
	const durationText = useMemo(() => msToDurationTxt(ms), [ms])

	useEffect(() => {
		const logTimer = setInterval(() => {
			setMs((ms) => ms + 1000)
		}, 1000)
		return () => {
			clearInterval(logTimer)
		}
	}, [])

	return (
		<Box>
			<Typography variant='body2' align='center' gutterBottom>
				Currently Active Session
			</Typography>
			<Typography variant='h2' align='center' gutterBottom>
				{durationText}
			</Typography>
			<Divider />
		</Box>
	)
})

Timer.propTypes = {
	startMs: PropTypes.number.isRequired,
}

export default Timer
