import { ACTION_TYPES, API_ENDPOINTS } from '../../../constants'
import api from '../api'
import Axios from 'axios'
const CancelToken = Axios.CancelToken

export const fetchPopUpDashboard = (apiDomain, dashboardId, authKey) => ({
	type: ACTION_TYPES.FETCHING_POPUP_DASHBOARD_DETAILS,
	payload: api.fetchPopUpDashboard(apiDomain, dashboardId, authKey),
})

export const fetchPopUpDashboardAndApplyFilters = (apiDomain, dashboardId, authKey, filtersToAdd) => ({
	type: ACTION_TYPES.FETCHING_POPUP_DASHBOARD_DETAILS,
	payload: api.fetchPopUpDashboardAndApplyFilters(apiDomain, dashboardId, authKey, filtersToAdd),
})

export const updatePopupDashboardInReducerAndFetchData = (ipFilters) => ({
	type: ACTION_TYPES.UPDATE_POPUP_DASHBOARD_DETAILS,
	payload: ipFilters,
})

export const fetchPopupDashboardGraphsData = (apiDomain = '', dashboardId = '', authKey = '', cancelExecutor) => ({
	type: ACTION_TYPES.FETCHING_POPUP_DASHBOARD_DATA,
	payload: Axios.get(apiDomain + API_ENDPOINTS.DASHBOARD_FETCH_DATA_API, {
		cancelToken: new CancelToken(function executor(c) {
			cancelExecutor.current = c
		}),
		headers: { isAuthRequired: true, authKey: authKey, path: { dashboardId } },
	}),
	// payload: api.fetchDashboardData(apiDomain, dashboardId, authKey)
})

export const resetPopUpDashboard = () => ({
	type: ACTION_TYPES.RESET_POPUP_DASHBOARD,
})
