import { useFormik } from 'formik'
import React from 'react'
import newPasswordImg from '../../images/new-password.png'
import * as Yup from 'yup'
import { Button, CircularProgress, TextField, Typography, Link } from '@material-ui/core'
import forgotPasswordApi from '../../services/forgotPassword/api'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoader, showLoader, showErrorMessage, showSuccessMessage } from 'services/loader/actions'
import { getErrMsg, getSuccessMsg } from 'utils'

// eslint-disable-next-line no-useless-escape
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/

const passwordSchema = Yup.object().shape({
	new_password: Yup.string()
		.min(8, 'The password should contain minimum 8 characters')
		.matches(passwordRegex, 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character')
		.required('Please enter your New Password'),
	confirm_password: Yup.string()
		.oneOf([Yup.ref('new_password'), null], `New Password & Re-Enter password does not match`)
		.required('Please re-enter your New Password'),
})

const isLoading = false

const SetNewPassword = ({ history, match }) => {
	const queryParams = new URLSearchParams(window.location.search)
	const token = queryParams.get('token')
	const dispatch = useDispatch()

	const isAuthenticated = useSelector((state) => state?.session?.isAuthenticated || false)

	React.useEffect(() => {
		if (token === null || isAuthenticated) {
			history.push('/')
		}
	}, [history, token, isAuthenticated])

	const onSubmit = (e) => {
		dispatch(showLoader('loading please wait...'))
		const onSuccess = (res) => {
			dispatch(
				showSuccessMessage(getSuccessMsg(res), 'Close', (e) => {
					dispatch(hideLoader())
					history.push('/')
				})
			)
		}

		const onFailure = (err) => {
			dispatch(
				showErrorMessage(getErrMsg(err), 'Close', (e) => {
					dispatch(hideLoader())
					// history.push('/')
				})
			)
		}
		forgotPasswordApi.setPassword({ ...e, token }).then(onSuccess, onFailure)
	}
	const onLoginClick = () => {
		history.push('/')
	}
	const formik = useFormik({
		initialValues: {
			new_password: '',
			confirm_password: '',
		},
		onSubmit: onSubmit,
		validationSchema: passwordSchema,
	})

	return (
		<div className='page-container'>
			<div className='image-container'>
				<img src={newPasswordImg} alt={'new password'} className='loginBG' />
			</div>
			<div className='form-container'>
				<Typography variant='h5' component={'h6'} className='password-t'>
					Set New Password
				</Typography>
				<form className='login-form' onSubmit={formik?.handleSubmit}>
					<TextField
						variant='outlined'
						size='small'
						type='password'
						margin='normal'
						label='New Password'
						name='new_password'
						onChange={formik?.handleChange}
						value={formik?.values?.new_password}
						error={Boolean(formik?.errors.new_password) && formik?.touched?.new_password}
						helperText={Boolean(formik?.errors.new_password) && formik?.touched?.new_password && formik?.errors.new_password}
					/>
					<TextField
						variant='outlined'
						size='small'
						margin='normal'
						label='Confirm New Password'
						type='password'
						name='confirm_password'
						onChange={formik?.handleChange}
						value={formik?.values?.confirm_password}
						error={Boolean(formik?.errors.confirm_password) && formik?.touched?.confirm_password}
						helperText={Boolean(formik?.errors.confirm_password) && formik?.touched?.confirm_password && formik?.errors.confirm_password}
					/>
					<Button type='submit' className='btn-login' variant='contained' color='secondary' disableElevation disabled={isLoading}>
						{isLoading ? <CircularProgress size={26} className='progressbar' /> : 'Change Password'}
					</Button>
					<Link className='forgot-btn' onClick={onLoginClick} color='primary'>
						Back to login
					</Link>
				</form>
			</div>
		</div>
	)
}

export default SetNewPassword
