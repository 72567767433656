import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	Icon,
	IconButton,
	makeStyles,
	Radio,
	RadioGroup,
	Typography,
} from '@material-ui/core'
import React, { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import { TextField } from '@material-ui/core'
import NewCategoryDialog from './NewCategoryDialog'
import DiscoverxApi from 'services/discoverx/api'
import { useDispatch } from 'react-redux'
import { hideLoader, showConfirmMessage, showErrorMessage, showLoader, showSuccessMessage } from 'services/loader/actions'
import { getErrMsg, getSuccessMsg } from 'utils'

const useStyles = makeStyles((theme) => ({
	dialogContent: {
		padding: theme.spacing(2, 3),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2),
		},
	},
	createTitle: {
		marginTop: theme.spacing(2),
	},
	categoryName: {
		marginTop: theme.spacing(1),
		'& .MuiFormHelperText-contained': {
			marginLeft: 0,
		},
	},
	categorySection: {
		maxHeight: 200,
		overflowY: 'auto',
		marginLeft: '-11px',
		'& fieldset': {
			width: '100%',
		},
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgba(0,0,0,.1)',
			borderRadius: 10,
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		'& .MuiFormControlLabel-root': {
			'& .MuiFormControlLabel-label': {
				display: '-webkit-box',
				WebkitLineClamp: 1,
				WebkitBoxOrient: 'vertical',
				overflow: 'hidden',
			},
		},
		'& .MuiGrid-container': {
			'& .MuiGrid-grid-xs-9': {
				'& .MuiFormControlLabel-root': {
					marginLeft: 0,
				},
			},
		},
	},
	closeBtn: {
		color: theme.palette.error.main,
	},
	newCategoryName: {
		width: '100%',
		'& .MuiFormHelperText-contained': {
			marginLeft: 0,
		},
	},
	loadingTxt: {
		marginTop: theme.spacing(1),
		display: 'block',
	},
}))

const SaveResultPopup = ({ open, handleClose, isMobileView, formik }) => {
	const classes = useStyles()
	const [createClicked, setCreateClicked] = useState(false)
	const [listData, setListData] = useState({
		loading: false,
		categories: [],
	})
	const { categories, loading } = listData
	const [isEditMode, setIsEditMode] = useState(false)
	const dispatch = useDispatch()
	const [categoryVal, setCategoryVal] = useState()

	// const [categories, setCategories] = useState([])

	const getCategoriesList = useCallback(() => {
		const onSuccess = (res) => {
			setListData((prevState) => ({ ...prevState, loading: false, categories: res?.data?.data }))
		}

		const onFailure = (err) => {
			setListData((prevState) => ({ ...prevState, loading: false, categories: [] }))
		}
		if (open) {
			setListData((prevState) => ({ ...prevState, loading: true, categories: [] }))
			DiscoverxApi.getSavedCategories().then(onSuccess, onFailure)
		}
	}, [open])

	useEffect(() => {
		getCategoriesList()
	}, [getCategoriesList])

	const handleClosePopup = () => {
		handleClose()
		formik?.resetForm()
	}
	const toggleCategoryDialog = () => {
		setCreateClicked(!createClicked)
	}

	const handleEditCategory = (data) => {
		setCategoryVal(data)
		setIsEditMode(true)
		toggleCategoryDialog()
	}

	const deleteCategoryFxn = (id) => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			getCategoriesList()
			dispatch(
				showSuccessMessage(getSuccessMsg(res), 'Close', (e) => {
					dispatch(hideLoader())
				})
			)
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		DiscoverxApi.removeCategory(id).then(onSuccess, onFailure)
	}

	const onClickDeleteBtn = (data) => {
		dispatch(
			showConfirmMessage(
				['Are you sure, you want to delete this category?'],
				'',
				'Confirm',
				(e) => {
					deleteCategoryFxn(_.get(data, 'category_id'))
				},
				'Cancel',
				(e) => {
					dispatch(hideLoader())
				}
			)
		)
	}

	return (
		<>
			<Dialog maxWidth='sm' fullWidth open={open} scroll='paper' onClose={handleClosePopup} aria-labelledby='create-glass'>
				<DialogTitle className={classes.dialogTitle} id='create-member-title'>
					Save results
				</DialogTitle>
				<Divider />

				<DialogContent className={classes.dialogContent}>
					<form onSubmit={formik?.handleSubmit}>
						<Grid container spacing={isMobileView ? 0 : 1}>
							<Grid item lg={6}>
								<Typography variant='subtitle2'> Choose Category *</Typography>
								{Boolean(formik?.errors?.category && formik?.touched?.category) && (
									<FormHelperText error={true}>{formik?.errors?.category}</FormHelperText>
								)}
								{loading ? (
									<Typography className={classes.loadingTxt} variant='caption'>
										Loading data...
									</Typography>
								) : !loading && _.isEmpty(categories) ? (
									<Typography className={classes.loadingTxt} variant='caption'>
										No categories found.
									</Typography>
								) : (
									<div className={classes.categorySection}>
										<FormControl component='fieldset'>
											<RadioGroup
												aria-label='category'
												id='category'
												name='category'
												value={formik?.values?.category}
												onChange={formik?.handleChange}
											>
												{_.map(categories, (category) => (
													<Grid container direction='row' justifyContent='center' alignItems='center'>
														<Grid item xs={9}>
															<FormControlLabel
																value={category?.category_id}
																control={<Radio size='small' />}
																label={<Typography variant='body2'>{category?.category_name}</Typography>}
															/>
														</Grid>
														<Grid item xs={3}>
															<IconButton size='small'>
																<Icon
																	fontSize='small'
																	onClick={() => {
																		handleEditCategory(category)
																	}}
																>
																	edit
																</Icon>
															</IconButton>
															<IconButton
																size='small'
																className={classes.closeBtn}
																onClick={() => {
																	onClickDeleteBtn(category)
																}}
															>
																<Icon fontSize='small'>close</Icon>
															</IconButton>
														</Grid>
													</Grid>
												))}
											</RadioGroup>
										</FormControl>
									</div>
								)}
							</Grid>
							<Grid item lg={6}>
								<Typography variant='subtitle2'> Name Your Result *</Typography>
								<TextField
									variant='outlined'
									margin='dense'
									name='resultName'
									id='resultName'
									fullWidth
									size='small'
									className={classes.categoryName}
									value={formik?.values?.resultName}
									error={Boolean(formik?.errors?.resultName)}
									helperText={formik?.errors?.resultName ? formik?.errors?.resultName : 'Type in a identifyable name for your search'}
									onChange={formik?.handleChange}
								/>
							</Grid>
						</Grid>
					</form>
					<Grid container>
						<Grid Item lg={6}>
							<Button
								className={classes.createTitle}
								color='primary'
								variant='contained'
								size='small'
								disableElevation
								onClick={toggleCategoryDialog}
							>
								Create New Category
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
				<Divider />
				<DialogActions className={classes.dialogFooter}>
					<Button variant='outlined' size={'medium'} onClick={handleClosePopup} color='primary' disableElevation>
						Cancel
					</Button>
					<Button variant='contained' size={'medium'} type='submit' onClick={formik?.handleSubmit} color='primary' disableElevation>
						Save
					</Button>
				</DialogActions>
			</Dialog>
			<NewCategoryDialog
				open={createClicked}
				handleClose={() => {
					setCreateClicked(!createClicked)
					setCategoryVal('')
				}}
				refreshFxn={() => getCategoriesList()}
				isEditMode={isEditMode}
				setIsEditMode={setIsEditMode}
				setCategoryVal={setCategoryVal}
				categoryVal={categoryVal}
			/>
		</>
	)
}

export default SaveResultPopup
