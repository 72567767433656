import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	searchSuggestions: (query) => {
		return Axios.post(API_ENDPOINTS.SEARCH_SUGGESTIONS_FOR_FILTER, query, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
	searchSuggestionsInProductsSearchApi: (query) => {
		return Axios.post(API_ENDPOINTS.PRODUCTS_SEARCH, query, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
}
