import { put, call, takeEvery } from 'redux-saga/effects'
import { ACTION_TYPES, SAGA_ACTIONS } from '../../../constants'
import modelxApi from '../api'
import _ from 'lodash'

function* watchworkGetModelxTable() {
	yield takeEvery(SAGA_ACTIONS.GET_MODELX_TABLE, workGetModelxTable)
}

function* workGetModelxTable(action) {
    const { successCallback, errCallback } = action
	yield put({ type: ACTION_TYPES.GET_MODELX_TABLE_PENDING })
	try {
		const products = yield call(modelxApi.getModelxTableData, action.query, action.search, action.redirect, action.redirectFrom, action.redirectFilter, action.redirectData, action.cancelExecutor)
		yield put({
			type: ACTION_TYPES.GET_MODELX_TABLE_FULFILLED,
			payload: products,
		})
        successCallback && (yield call(successCallback, products))
	} catch (err) {
		yield put({ type: ACTION_TYPES.GET_MODELX_TABLE_REJECTED, payload: err })
        errCallback && (yield call(errCallback, err))
		console.log(err)
	}
}

function* watchWorkGetModelxDetails() {
	yield takeEvery(SAGA_ACTIONS.GET_MODELX_DETAILS, workGetModelxDetails)
}

function* workGetModelxDetails(action) {
	yield put({ type: ACTION_TYPES.GET_MODELX_DETAILS_PENDING })

	try {
		const modelx = yield call(modelxApi.getModelxDataDetails, action.modelxId, action.cancelExecutor)
		yield put({
			type: ACTION_TYPES.GET_MODELX_DETAILS_FULFILLED,
			payload: modelx,
		})
	} catch (err) {
		yield put({ type: ACTION_TYPES.GET_MODELX_DETAILS_REJECTED, payload: err })
		console.log(err)
	}
}

function* watchworkGetModelxLogs() {
	yield takeEvery(SAGA_ACTIONS.GET_MODELX_LOGS, workGetModelxLogs)
}

function* workGetModelxLogs(action) {
	yield put({ type: ACTION_TYPES.GET_MODELX_LOGS_PENDING })

	try {
		const modelx_logs = yield call(modelxApi.getModelxLogs, action.modelxId, action.cancelExecutor)
		yield put({
			type: ACTION_TYPES.GET_MODELX_LOGS_FULFILLED,
			payload: modelx_logs,
		})
	} catch (err) {
		yield put({ type: ACTION_TYPES.GET_MODELX_LOGS_REJECTED, payload: err })
		console.log(err)
	}
}

function* watchModelxJobs() {
	yield takeEvery(SAGA_ACTIONS.GET_MODELX_JOBS, modelxJobs)
}

function* modelxJobs(action) {
	const { modelxId, data, cancelExecutor, successCallback, errCallback } = action
    yield put({ type: ACTION_TYPES.GET_MODELX_JOBS_PENDING })
	try {
		const resp = yield call(modelxApi.modelxJobs, modelxId, data, cancelExecutor)
		yield put({
			type: ACTION_TYPES.GET_MODELX_JOBS_FULFILLED,
			payload: _.get(resp, 'data.data'),
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
        yield put({ type: ACTION_TYPES.GET_MODELX_JOBS_REJECTED, payload: err })
		errCallback && (yield call(errCallback, err))
	}
}

export default [
    watchworkGetModelxTable(),
    watchWorkGetModelxDetails(),
    watchworkGetModelxLogs(),
	watchModelxJobs(),
]