import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {
	Dialog,
	makeStyles,
	Button,
	TextField,
	Grid,
	DialogContent,
	DialogActions,
	DialogTitle,
	Slide,
	Divider,
	Typography,
	FormHelperText,
	FormControlLabel,
	Checkbox,
	InputAdornment,
	IconButton,
	Icon,
	ClickAwayListener,
	Avatar,
	// LinearProgress,
} from '@material-ui/core'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { DateTimePicker } from '@material-ui/pickers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { formTheName, getErrMsg, getImgUrl, stringToHslColor, getSuccessMsg, convertLocalTimeToGmtStr } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import GlassApi from '../../../../services/glass/api'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { hideLoader, showLoader, showSuccessMessage } from 'services/loader/actions'
import { refreshStickyList } from 'services/glass/actions'
import classnames from 'classnames'
import Axios from 'axios'

const useStyles = makeStyles((theme) => ({
	disableOnUpdate: {
		opacity: (props) => (props.isLoading ? 0.8 : 1),
		pointerEvents: (props) => (props.isLoading ? 'none' : 'unset'),
	},
	dialog: {
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
		},
		maxWidth: '350px',
		maxHeight: 'calc(100% - 36px)',
	},
	textField: {
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
			'& .MuiAutocomplete-endAdornment': {
				display: 'none',
			},
		},
		'& .MuiAutocomplete-root': {
			'& .MuiFormControl-root': {
				'& .Mui-disabled': {
					color: '#ceced2',
				},
			},
		},
	},
	includesContainer: {
		'& .MuiGrid-item': {
			padding: '0 !important',
		},
		'& .MuiFormControlLabel-label': {
			fontSize: 'small',
			color: theme.palette.almostBlack[700],
		},
	},
	dialogContent: {
		'& .MuiFormControl-marginDense': {
			marginTop: '4px',
		},
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			background: `${theme.palette.primary.main}`,
		},
	},
	fieldContainer: {
		'& .MuiGrid-item': {
			paddingTop: '8px',
		},
	},
	dialogFooter: {
		padding: '12px 8px',
		borderTop: `1px solid ${theme.palette.grey[300]}`,
		'& .MuiButton-label': {
			textTransform: 'capitalize',
		},
	},
	mainGrid: {
		flexWrap: 'nowrap',
	},
	userPic: {
		width: 36,
		height: 36,
		marginRight: theme.spacing(1),
		fontSize: 17,
	},
	userInfo: { maxWidth: `calc(100% - (36px + 8px))` },
	optionTextEllipsis: {
		width: `100%`,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	assigneeDiv: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		marginTop: '4px',
	},
	text: {
		fontSize: 14,
		flex: 1,
		'&:hover': {
			color: theme.palette.primary.main,
		},
	},
	stickyCode: {
		marginBottom: theme.spacing(1),
	},
	includesList: {
		marginBottom: theme.spacing(1),
	},
	includesTitle: {
		marginBottom: theme.spacing(1),
	},
}))

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const FormSchema = Yup.object().shape({
	due_date: Yup.date().nullable().min(new Date(), 'Due date cannot be in the past.'),
	move_to: Yup.object().nullable().required('Please select the Board to copy'),
})

const CloneIncludes = ({ formik, isParent }) => {
	const classes = useStyles()

	return (
		<Grid container className={classes.includesContainer}>
			<Grid item xs={6}>
				<Typography className={classes.includesList} variant='subtitle2' component='li'>
					Attachments
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography variant='subtitle2' component='li'>
					Labels
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography variant='subtitle2' component='li'>
					Activity
				</Typography>
			</Grid>
			{isParent && (
				<Grid item xs={12}>
					{' '}
					<FormControlLabel
						control={<Checkbox checked={formik.values.is_subtasks} name='is_subtasks' onChange={formik.handleChange} />}
						label='Subtask'
					/>
				</Grid>
			)}
		</Grid>
	)
}
const MoveForm = ({ formik, stickyDetails, assigneeData, setAssigneeData, stickyCode, glassCode }) => {
	const classes = useStyles()
	const filterOptions = createFilterOptions({
		stringify: ({ first_name, last_name, email }) => `${first_name} ${last_name} ${email}`,
	})

	const token = useSelector((state) => _.get(state, 'session.authToken', ''))
	const [isAssigneeListOpen, setIsAssigneeListOpen] = useState(false)
	const [glassData, setGlasssData] = useState({
		glassLoading: false,
		glassList: [],
	})
	const { glassList, glassLoading } = glassData
	const { assigneeLoading, assigneeList } = assigneeData
	const cancelExecutor = React.useRef()
	const cancelExecutorAssignee = React.useRef()

	useEffect(() => {
		if (!_.isEmpty(stickyDetails?.assigned_to)) {
			setAssigneeData((prevState) => ({ ...prevState, assigneeList: [{ ...stickyDetails?.assigned_to }] }))
		}
	}, [stickyDetails])

	const toggleAssigneeList = () => {
		setIsAssigneeListOpen(!isAssigneeListOpen)
	}

	const onInputChange = _.debounce((e, value) => {
		fetchGlasses(value)
	}, 300)

	useEffect(() => {
		if (!_.isEqual(formik.values?.assigned_to, stickyDetails?.assigned_to)) {
			formik?.setFieldValue('assigned_to', null)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values?.move_to, stickyDetails])

	const fetchGlassDetails = React.useCallback((glassCode) => {
		if (glassCode) {
			setAssigneeData((prevState) => ({ ...prevState, assigneeLoading: true, assigneeList: [] }))
			GlassApi.fetchGlassDetails(glassCode, cancelExecutorAssignee)
				.then((res) => {
					const glassMemList = _.get(res, 'data.data.glass_members', [])
					let filteredAssignee = _.filter(glassMemList, (o) => !_.isEqual(o?.role, 'guest'))
					const foundAssignee = _.find(glassMemList, { user_id: formik?.values?.assigned_to?.user_id })
					//reset the assignee field if user has guest role
					if (_.isEqual(foundAssignee?.role, 'guest')) formik.setFieldValue('assigned_to', null)

					setAssigneeData((prevState) => ({
						...prevState,
						assigneeLoading: false,
						assigneeList: filteredAssignee,
					}))
				})
				.catch((err) => {
					setAssigneeData((prevState) => ({ ...prevState, assigneeLoading: false, assigneeList: [] }))
				})
		} else {
			formik.setFieldValue('assigned_to', null)
		}
	}, [])

	const fetchGlasses = React.useCallback((search) => {
		setGlasssData((prevState) => ({ ...prevState, glassLoading: true, glassList: [] }))
		GlassApi.fetchAllGlasses(
			search || '',
			0,
			10,
			'',
			cancelExecutor,
			(res) => {
				const list = _.get(res, 'data.data.glass', [])
				const filteredList = _.filter(list, (detail) => !_.isEqual(detail?.glass_code, glassCode))
				setGlasssData((prevState) => ({ ...prevState, glassLoading: false, glassList: filteredList || [] }))
			},
			(err) => {
				if (!Axios.isCancel(err)) {
					setGlasssData((prevState) => ({ ...prevState, glassLoading: false, glassList: [] }))
				}
			}
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		fetchGlasses()
	}, [fetchGlasses])

	return (
		<form onSubmit={formik.handleSubmit}>
			<Grid container className={classes.fieldContainer}>
				<Grid item xs={12}>
					<Typography variant='subtitle2' className={classes.stickyCode}>
						{stickyCode && `Task Code : ${stickyCode || ''}`}
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<Typography variant='subtitle2'>Move To Board*</Typography>
					<Autocomplete
						id='move_to'
						name='move_to'
						loading={glassLoading}
						fullWidth
						size='small'
						value={formik.values?.move_to}
						getOptionSelected={(option, value) => _.isEqual(option?.glass_code, value?.glass_code)}
						options={glassList}
						onInputChange={onInputChange}
						onChange={(event, newValue) => {
							formik.setFieldValue('move_to', newValue)
							fetchGlassDetails(newValue?.glass_code)
						}}
						getOptionLabel={(option) => option.glass_name}
						renderInput={(params) => (
							<TextField {...params} variant='outlined' name='move_to' margin='dense' size='small' placeholder='Select Board' />
						)}
					/>
					{formik.errors.move_to && formik.touched.move_to && <FormHelperText error>{formik.errors.move_to}</FormHelperText>}
				</Grid>
				<Grid item xs={12}>
					<Typography variant='subtitle2'>{'Due Date & Time'}</Typography>
					<DateTimePicker
						name='due_date'
						id='due_date'
						className={classes.textField}
						autoOk
						fullWidth
						size='small'
						margin='dense'
						variant='dialog'
						inputVariant='outlined'
						format='DD MMM YYYY, h:mm A'
						value={_.get(formik.values, 'due_date', null)}
						disablePast
						clearable
						error={formik.errors.due_date}
						placeholder={'Choose Due Date & Time'}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton size='small'>
										<Icon>date_range</Icon>{' '}
									</IconButton>
								</InputAdornment>
							),
						}}
						onChange={(newDate) => {
							if (newDate) {
								newDate.set({ seconds: 0, milliseconds: 0 })
							}

							formik.handleChange({
								target: {
									name: 'due_date',
									value: newDate,
								},
							})
						}}
					/>
					{formik.errors.due_date && <FormHelperText error>{formik.errors.due_date}</FormHelperText>}
				</Grid>
				<Grid item xs={12}>
					<Typography variant='subtitle2'>Assignee</Typography>
					{_.isEmpty(formik.values.assigned_to) || isAssigneeListOpen ? (
						<ClickAwayListener onClickAway={toggleAssigneeList}>
							<Autocomplete
								fullWidth
								size='small'
								id='assigned_to'
								loading={assigneeLoading}
								filterOptions={filterOptions}
								onChange={(event, newValue) => {
									formik.setFieldValue('assigned_to', newValue)
									toggleAssigneeList()
								}}
								openOnFocus
								value={formik.values.assigned_to}
								options={assigneeList}
								noOptionsText='Please select any Board to add a Assignee'
								getOptionSelected={(option, value) => _.isEqual(option?.user_id, value?.user_id)}
								// eslint-disable-next-line react/jsx-no-duplicate-props
								getOptionLabel={(option) => (option?.first_name && formTheName(option?.first_name, option?.last_name)) || ''}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										variant='outlined'
										size='small'
										id='assigned_to'
										name='assigned_to'
										margin='dense'
										placeholder='Select Assignee'
									/>
								)}
								renderOption={(option) => {
									const picUrl = _.get(option, 'profile_pic_url', '')
									const full_name = _.join([_.get(option, 'first_name', ''), _.get(option, 'last_name', '')], ' ')
									return (
										<Grid key={option.user_id} container alignItems='center' className={classes.mainGrid}>
											<Grid item>
												<Avatar
													alt={full_name}
													src={picUrl ? getImgUrl(token, picUrl) : null}
													size='small'
													className={classes.userPic}
													style={{ backgroundColor: stringToHslColor(full_name) }}
												>
													{full_name && full_name.substring(0, 1)}
												</Avatar>
											</Grid>
											<Grid item xs className={classes.userInfo}>
												<Typography variant='body2' className={classes.optionTextEllipsis} title={full_name}>
													{full_name}
												</Typography>
												<Typography variant='body2' color='textSecondary' className={classes.optionTextEllipsis} title={option.email}>
													{option.email}
												</Typography>
											</Grid>
										</Grid>
									)
								}}
							/>
						</ClickAwayListener>
					) : (
						<div
							role='button'
							onClick={toggleAssigneeList}
							style={{
								display: 'flex',
								flex: 1,
								alignItems: 'center',
							}}
						>
							<div className={classes.assigneeDiv} onClick={toggleAssigneeList}>
								<Avatar
									{...(_.isEmpty(formik.values?.assigned_to?.profile_pic_url)
										? {}
										: {
												src: getImgUrl(token, formik.values?.assigned_to?.profile_pic_url),
										  })}
									alt={formTheName(formik.values?.assigned_to?.first_name, formik.values?.assigned_to?.last_name)}
									className={classes.userPic}
									style={{
										backgroundColor: stringToHslColor(formTheName(formik.values?.assigned_to?.first_name, formik.values?.assigned_to?.last_name)),
									}}
								>
									{_.slice(_.words(formTheName(formik.values?.assigned_to?.first_name, formik.values?.assigned_to?.last_name)), 0, 1).reduce(
										(a, t) => (a += t[0]),
										''
									)}
								</Avatar>
								<Typography variant='body1' className={classes.text} style={{ marginLeft: 4 }}>
									{formTheName(formik.values?.assigned_to?.first_name, formik.values?.assigned_to?.last_name)}
								</Typography>
							</div>
						</div>
					)}
				</Grid>
				<Grid item xs={12}>
					<Typography variant='subtitle2' className={classes.includesTitle}>
						Includes
					</Typography>
					<CloneIncludes formik={formik} isParent={!!!stickyDetails?.parent_sticky_code} />
				</Grid>
			</Grid>
		</form>
	)
}
const FormDialog = ({ open, setForm, glassMembers, lanesData, stickyDetails, glassCode, stickyCode, history, sessionId, setSelectedRows }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [assigneeData, setAssigneeData] = useState({ assigneeLoading: false, assigneeList: [] })

	const formik = useFormik({
		initialValues: {
			move_to: null,
			assigned_to: null,
			due_date: null,
			is_attachments: false,
			is_labels: false,
			is_pinned_posts: false,
			is_comments: false,
			is_subtasks: false,
		},
		onSubmit: (e) => {
			submitForm(e)
		},
		validationSchema: FormSchema,
	})

	useEffect(() => {
		formik?.setFieldValue('assigned_to', stickyDetails?.assigned_to)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stickyDetails])

	const handleClone = () => {
		formik.submitForm()
	}

	const submitForm = (vals) => {
		let formVals = {
			to_glass_code: vals?.move_to?.glass_code,
			is_move_sub_stickies: vals?.is_subtasks,
			assignee_to: vals?.assigned_to?.user_id,
			due_date: _.isNil(vals.due_date) ? null : convertLocalTimeToGmtStr(vals.due_date),
			sticky_code: stickyCode,
			is_select_all: false
		}
		dispatch(showLoader('Loading please wait...'))
		formik.resetForm()
		setSelectedRows([])
		GlassApi.cutMultiSticky(glassCode, formVals, sessionId)
			.then((res) => {
				dispatch(
					showSuccessMessage(getSuccessMsg(res), 'Close', (e) => {
						dispatch(hideLoader())
						dispatch(refreshStickyList(glassCode, ''))
						handleClose()
						history.push(`/glassx/view/${glassCode}`)
					})
				)
			})
			.catch((err) => {
				dispatch(hideLoader())
				dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
			})
	}

	const handleClose = () => {
		setForm(false)
	}

	return (
		<>
			<Dialog
				open={open}
				classes={{ paper: classes.dialog }}
				TransitionComponent={Transition}
				keepMounted
				fullWidth
				onClose={handleClose}
				aria-labelledby='clone-sticky-form'
			>
				{/* <LinearProgress variant='indeterminate' /> */}
				<DialogTitle>Move Task</DialogTitle>
				<Divider />
				<DialogContent className={classnames(classes.dialogContent, classes.disableOnUpdate)}>
					<MoveForm
						glassMembers={glassMembers}
						lanesData={lanesData}
						stickyDetails={stickyDetails}
						assigneeData={assigneeData}
						setAssigneeData={setAssigneeData}
						formik={formik}
						stickyCode={stickyCode}
						glassCode={glassCode}
					/>
				</DialogContent>
				<DialogActions className={classnames(classes.dialogFooter, classes.disableOnUpdate)}>
					<Button
						variant='outlined'
						color='primary'
						size='medium'
						fullWidth
						disableElevation
						onClick={() => {
							handleClose()
							formik.handleReset()
						}}
					>
						Cancel
					</Button>
					<Button
						disabled={assigneeData?.assigneeLoading}
						size='medium'
						fullWidth
						variant='contained'
						color='primary'
						type='submit'
						disableElevation
						onClick={handleClone}
					>
						Move
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}
export default FormDialog
