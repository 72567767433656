import {
	Card,
	CardHeader,
	makeStyles,
	Typography,
	Avatar,
	CardContent,
	CardActions,
	ButtonBase,
	Icon,
	Grid,
	Button,
	Divider,
	Box,
	Snackbar,
	IconButton,
	FormControlLabel,
	Checkbox,
	TextField,
	Popover,
	MenuItem,
	Container,
	Paper,
} from '@material-ui/core'
import Attachments from 'components/Attachments'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { convertGmtToLocalTime, formTheName, getErrMsg, getImgUrl, stringToHslColor } from 'utils'
import _ from 'lodash'
import { Skeleton } from '@material-ui/lab'
import GlassApi from 'services/glass/api'
import { useDispatch, useSelector } from 'react-redux'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { hideLoader, showLoader, showErrorMessage } from 'services/loader/actions'
import useLazyPaginate from 'hooks/useLazyPaginate'
import CommentBox from 'components/CommentBox'
import update from 'immutability-helper'
import PropTypes from 'prop-types'
import { AccountCircle } from '@material-ui/icons'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined'
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined'
import Reply from '../Reply'

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(1, 0),
		paddingBottom: theme.spacing(2),
		'& .MuiCardContent-root': {
			padding: theme.spacing(0, 1, 0, 1),
		},
		'& .MuiCardHeader-root': {
			padding: theme.spacing(1),
		},
		'& .MuiCardHeader-action': {
			marginTop: 0,
			marginRight: 0,
		},
		[theme.breakpoints.down('sm')]: {
			'& .MuiCardHeader-root': {
				padding: theme.spacing(1),
				paddingBottom: 0,
			},
			'& .MuiCardContent-root': {
				padding: theme.spacing(0, 1, 1, 1),
			},
			'& .MuiCardHeader-avatar': {
				marginRight: theme.spacing(1),
			},
		},
		'& .wysiwyg-mention': {
			textDecoration: 'none',
			color: '#1236ff',
			backgroundColor: '#f0fbff',
			padding: '1px 2px',
			borderRadius: '2px',
		},
	},
	cardRoot: {
		backgroundColor: '#fcfcfc',
		margin: theme.spacing(1, 0),
	},
	avatar: {
		width: 32,
		height: 32,
		fontSize: 17,
		// marginRight: theme.spacing(1),
	},
	chatCard: {
		paddingTop: '8px',
	},
	mainChat: {
		paddingTop: '8px',
		paddingBottom: '8px',
	},
	subChat: {
		paddingTop: '8px',
		paddingBottom: '8px',
		// paddingLeft: '40px',
	},
	commentAvatar: {
		width: 24,
		height: 24,
		fontSize: 9,
	},
	comment: {
		paddingTop: '8px',
		paddingBottom: '8px',
		flexFlow: 'nowrap',
		display: 'block',
	},
	reply: {
		paddingTop: '8px',
		paddingBottom: '8px',
		flexFlow: 'nowrap',
	},
	subCommentBox: {
		'& .MuiOutlinedInput-multiline': {
			[theme.breakpoints.down('300')]: {
				width: '70px',
			},
			[theme.breakpoints.between('300', '350')]: {
				width: '127px',
			},
			[theme.breakpoints.between('350', '395')]: {
				width: '175px',
			},
			[theme.breakpoints.between('395', '500')]: {
				width: '200px',
			},
			[theme.breakpoints.between('960', '1134')]: {
				width: '200px',
			},
			width: '300px',
			borderRadius: '24px',
			padding: '8.5px 14px',
		},
	},
	commentBox: {
		'& .MuiOutlinedInput-multiline': {
			[theme.breakpoints.down('300')]: {
				width: '70px',
			},
			[theme.breakpoints.between('300', '350')]: {
				width: '130px',
			},
			[theme.breakpoints.between('350', '395')]: {
				width: '175px',
			},
			[theme.breakpoints.between('395', '420')]: {
				width: '220px',
			},
			[theme.breakpoints.between('420', '530')]: {
				width: '258px',
			},
			[theme.breakpoints.between('960', '1185')]: {
				width: '265px',
			},
			width: '370px',
			borderRadius: '24px',
			padding: '8.5px 14px',
		},
	},
	loadMore: {
		paddingTop: '8px',
	},
	subLoadMore: {
		textAlign: 'end',
	},
	chatBox: {
		display: 'flex',
		flexDirection: 'column',
		'& .MuiGrid-item': {
			maxWidth: '-webkit-fill-available',
		},
	},
	chatHeader: {
		'& .MuiCardHeader-title': {
			fontSize: '14px',
		},
		'& .MuiCardHeader-subheader': {
			fontSize: '14px',
		},
	},
	chatText: {
		backgroundColor: '#F2F2F2',
		borderRadius: '0px 14px 14px 14px',
		padding: '5px 5px',
		// display: 'inline-flex',
		// display: 'inline-table',
		fontSize: '14px',
		'& .MuiCardHeader-root': {
			paddingTop: '2px',
			paddingLeft: '2px',
		},
		'& .MuiCardContent-root': {
			paddingLeft: '2px',
		},
	},
	activityReactions: {
		marginLeft: '0px',
		display: 'flex',
		flexDirection: 'row',
		paddingTop: '2px',
	},
	reactions: {
		marginLeft: '7px',
		display: 'flex',
		flexDirection: 'row',
		paddingTop: '2px',
	},
	likeIcon: {
		fontSize: '14px',
		padding: '3px 1px',
		color: '#65b17f',
		transform: 'scaleX(-1)',
	},
	reactionCount: {
		fontSize: '13px',
		color: 'slategray',
	},
	dislikeIcon: {
		fontSize: '14px',
		padding: '3px 1px',
		color: 'rgb(244, 67, 54)',
	},
	dot: {
		color: 'slategray',
	},
	bar: {
		color: 'slategray',
	},
	replyBtn: {
		fontSize: '10px',
		padding: '0px 0px',
		minWidth: '42px',
	},
	// title: {
	// 	marginBottom: theme.spacing(1),
	// },
	description: {
		margin: theme.spacing(1, 0),
		fontSize: '14px',
	},
	labelsWrap: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
		width: '100%',
		gap: '5px',
	},
	labelBtnBase: {
		height: '28px',
		padding: '3px 9px',
		borderRadius: '2px',
		color: theme.palette.common.white,
	},
	pinnedIcon: {
		transform: 'rotate(45deg)',
	},
	attachmentsSection: {
		width: '100%',
	},
	sortButton: {
		textTransform: 'none',
		color: '#92929d',
		lineHeight: 'normal',
	},
	activitiesHead: { justifyContent: 'space-between' },
	loadingSkeleton: {
		display: 'flex',
		padding: '8px',
		margin: '8px 0px',
		'& .MuiSkeleton-circle': {
			marginRight: '10px',
		},
	},
	lineSkeleton: {
		width: '100%',
	},
	activityTypeItem: {
		textAlign: '-webkit-right',
	},
	mb2: {
		marginBottom: theme.spacing(2),
	},
}))

const ActivityList = (props) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [commentBoxShow, setCommentBoxShow] = useState(false)
	const [commentValue, setCommentValue] = useState('')
	const [activityId, setActivityId] = useState('')

	const {
		activity,
		token,
		userDetails,
		updateActivityReactions,
		glassLabels,
		glassCode,
		activityTypes,
		sessionId,
		commentLoading,
		suggestions,
		subReplyList,
		subReply_replyId,
		updateActivityItem,
		setSubReplyState,
		// updateReplyItem,
		stickyId,
		// setReplyState,
		// reply_activityId,
		// replyList,
		// updateActivityReplyReactions,
		subReply_reply_Id,
		updateActivitySubReplyReactions,
		subReply_sort_by,
		subReplyLimit,
		setSububReply_reply_Id,
		subReplyOffset,
		replyActivityId,
		isEditEnabled,
		// reply_sort_by,
		// replyOffset,
		// replyLimit,
		setReplyActivityId,
		updateActivity,
		history,
		isSocketConnected,
		socketRef,
		handleCustomLabel,
		newCustomLabel,
	} = props

	const ActivityRoomJoinListener = useRef()
	const ActivityUpdatesListener = useRef()
	const ReplyRoomJoinListener = useRef()

	const [replyState, setReplyState] = useState({
		replyList: [],
		replyLoading: true,
		replyLimit: 10,
		replyOffset: 0,
		reply_totalCount: 0,
		reply_sort_by: 'desc',
		reply_showRefreshSnackbar: false,
		reply_activityId: '',
	})

	const { replyList, replyLimit, replyOffset, reply_totalCount, reply_sort_by, replyLoading, reply_showRefreshSnackbar, reply_activityId } =
		replyState

	const replyListSynced = useRef(replyList)

	useEffect(() => {
		replyListSynced.current = replyList
	}, [replyList])

	const updateReplyItem = useCallback((replyItemToUpt) => {
		let replyIndex = _.findIndex(replyListSynced.current, { reply_id: replyItemToUpt?.reply_id })
		let opToPerform = null
		if (replyIndex > -1) {
			const updateActivity = { [replyIndex]: { $set: { ...replyItemToUpt } } }
			opToPerform = updateActivity
		} else {
			opToPerform = null
		}
		if (opToPerform === null) {
			setReplyState((prevState) => ({
				...prevState,
			}))
		} else {
			setReplyState((prevState) => ({
				...prevState,
				replyList: update(prevState?.replyList, opToPerform),
			}))
		}
	}, [])

	const updateActivityReplyReactions = (activity, replyId, value) => {
		let formData = {
			reactions: value,
		}
		const onSuccess = (res) => {
			updateReplyItem(res?.data?.data?.reply?.[0])
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		GlassApi.updateStickyActivitiesReplyReactions(glassCode, stickyId, activity?.activity_id, replyId, sessionId, formData).then(onSuccess, onFailure)
	}

	const likeCount = _.sum(
		_.map(
			_.map(activity?.activities_reactions, (reactions) => {
				return reactions
			}),
			(data) => {
				return data?.like
			}
		)
	)
	const dislikeCount = _.sum(
		_.map(
			_.map(activity?.activities_reactions, (reactions) => {
				return reactions
			}),
			(data) => {
				return data?.dislike
			}
		)
	)
	let name = formTheName(activity?.created_by?.first_name, activity?.created_by?.last_name)
	const reactionStatus = _.toString(
		_.map(_.filter(activity?.activities_reactions, { created_by: userDetails?.user_id }), (data) => {
			return data?.reactions
		})
	)

	const loadMoreCmt = (activityId) => {
		const replyOffset = _.isEqual(activityId, reply_activityId) ? replyList.length : 0
		// setReplyState((prevState) => ({ ...prevState, replyOffset: replyOffset, replyLoading: true }))
		setReplyState((prevState) => ({
			...prevState,
			replyOffset: replyOffset,
			replyLoading: true,
		}))
		loadMoreCmtFnx(activityId, replyOffset)
	}

	const loadMoreCmtFnx = useCallback(
		(activityId, replyOffset) => {
			GlassApi.getStickyActivitiesReplies(
				reply_sort_by,
				replyOffset,
				replyLimit,
				glassCode,
				stickyId,
				activityId,
				(res) => {
					let actions = _.get(res, 'data.data.reply', [])
					let replyTotalCount = _.get(res, 'data.data.reply_count')
					setReplyState((prevState) => ({
						...prevState,
						replyLoading: false,
						reply_totalCount: _.get(res, 'data.data.reply_count'),
						replyList: replyOffset === 0 ? actions : [...prevState.replyList, ...actions],
						reply_activityId: _.get(res, 'data.data.activity_id'),
					}))
					let replyLength = replyOffset !== 0 ? _.add(replyList.length, actions.length) : actions.length
					_.isEqual(replyLength, replyTotalCount) ? setReplyActivityId(activityId) : setReplyActivityId('')
				},
				(err) => {
					setReplyState((prevState) => ({ ...prevState, replyLoading: false }))
					dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
				}
			)
		},
		[replyOffset, activityId, replyList]
	)

	const handleCommentBoxOpen = (activity) => {
		setCommentBoxShow(!commentBoxShow)
		setCommentValue('')
		setActivityId(activity?.activity_id)
	}

	const updateActivityCommentLocal = (data, formik, activity) => {
		_.isEqual(activity?.activity_id, reply_activityId) &&
			setReplyState((prevState) => ({
				...prevState,
				replyList: [...data],
				// replyOffset: 0,
				// reply_activityId: activity?.activity_id,
			}))
	}

	const updateActivityComment = (data, formik, activity) => {
		dispatch(showLoader('Loading please wait...'))
		let formData = new FormData()
		formData.append('desc', data?.desc)
		formData.append('is_parent', true)
		formData.append('parent_id', '')
		data?.activity_type_id !== null && formData.append('activity_type_id', data?.activity_type_id?.activity_id)
		formData.append('labels', _.join(data?.labels, ','))
		data?.attachments.map((file) => formData.append('attachments', file))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			setCommentValue('')
			setCommentBoxShow(false)
			_.isEqual(activity?.activity_id, reply_activityId)
				? setReplyState((prevState) => ({
						...prevState,
						replyList: [res?.data?.data?.reply[0], ...prevState.replyList],
						replyOffset: 0,
						reply_activityId: res?.data?.data?.activity_id,
				  }))
				: setReplyState((prevState) => ({
						...prevState,
						replyList: [res?.data?.data?.reply[0]],
						replyOffset: 0,
						reply_activityId: res?.data?.data?.activity_id,
				  }))
			updateActivityItem(res?.data?.data)
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
			setCommentValue('')
			setCommentBoxShow(false)
		}
		GlassApi.updateStickyActivitiesReply(glassCode, stickyId, activity?.activity_id, sessionId, formData).then(onSuccess, onFailure)
	}

	// useEffect(() => {
	// 	if (isSocketConnected && activity?.activity_id) {
	// 		socketRef.current?.emit('join_activity', { id: activity?.activity_id, sticky_code: stickyId, glass_code: glassCode, access_token: token })
	// 		// ReplyRoomJoinListener.current = (resp) => {
	// 		// 	console.log('join_activity room joined resp', resp)
	// 		// }
	// 		// //On Authentication of the user authToken
	// 		// socketRef.current?.on('join_activity_status', ReplyRoomJoinListener.current)
	// 	}
	// 	return () => {
	// 		if (isSocketConnected && activity?.activity_id) {
	// 			// ReplyRoomJoinListener.current = (resp) => {
	// 			// 	console.log('join_activity room left_room resp', resp)
	// 			// }
	// 			// socketRef.current?.off('join_activity_status', ReplyRoomJoinListener.current)
	// 			// eslint-disable-next-line react-hooks/exhaustive-deps
	// 			socketRef.current?.emit('left_room', { id: activity?.activity_id })
	// 		}
	// 	}
	// }, [token, isSocketConnected, socketRef, stickyId, glassCode, activity])

	return (
		<Card key={activity?.activity_id} className={classes.cardRoot} raised={false} elevation={0}>
			<CardHeader
				avatar={
					<Avatar
						alt={name}
						src={getImgUrl(token, activity?.created_by?.profile_img) || null}
						size='small'
						className={classes.avatar}
						style={{ backgroundColor: stringToHslColor(name) }}
					>
						{_.slice(_.words(name), 0, 1).reduce((a, t) => (a += t[0]), '')}
					</Avatar>
				}
				action={
					<Typography variant='subtitle2' component='p'>
						{activity?.activity_type?.activity_name}
					</Typography>
				}
				title={name}
				subheader={convertGmtToLocalTime(_.get(activity, 'created_date', ''), 'DD MMM YYYY | hh:mm a')}
			/>
			<CardContent>
				{activity?.desc && <div className={classes.description} dangerouslySetInnerHTML={{ __html: activity?.desc }}></div>}
				{!_.isEmpty(_.get(activity, 'attachments', [])) && (
					<div className={classes.attachmentsSection}>
						<Attachments
							attachmentList={_.get(activity, 'attachments', [])}
							showDivider={false}
							isActivity={true}
							disableDelete={true}
							enableAddBtn={false}
						/>
					</div>
				)}
				{!activity?.is_log && (
					<div className={classes.activityReactions}>
						{_.isEqual(reactionStatus, 'like') ? (
							<Icon
								className={classes.likeIcon}
								onClick={() => {
									updateActivityReactions(activity, '')
								}}
							>
								thumb_up_icon
							</Icon>
						) : (
							<ThumbUpOutlinedIcon
								className={classes.likeIcon}
								onClick={() => {
									updateActivityReactions(activity, 'like')
								}}
							/>
						)}
						&nbsp;<div className={classes.dot}>.</div>&nbsp;
						<Typography className={classes.reactionCount}>{likeCount}</Typography>&nbsp;<div className={classes.bar}>|</div>&nbsp;
						{_.isEqual(reactionStatus, 'dislike') ? (
							<Icon
								className={classes.dislikeIcon}
								onClick={() => {
									updateActivityReactions(activity, '')
								}}
							>
								thumb_down_icon
							</Icon>
						) : (
							<ThumbDownOutlinedIcon
								className={classes.dislikeIcon}
								onClick={() => {
									updateActivityReactions(activity, 'dislike')
								}}
							/>
						)}
						&nbsp;<div className={classes.dot}>.</div>&nbsp;
						<Typography className={classes.reactionCount}>{dislikeCount}</Typography>&nbsp;<div className={classes.bar}>|</div>&nbsp;
						<Button
							className={classes.replyBtn}
							onClick={() => {
								handleCommentBoxOpen(activity)
							}}
						>
							Reply
						</Button>
						{_.gt(activity?.reply_count, 0) && (
							<>
								<div className={classes.dot}>&nbsp;.&nbsp;</div>
								<Typography className={classes.reactionCount}>{activity?.reply_count} Reply</Typography>
							</>
						)}
					</div>
				)}
			</CardContent>
			<CardContent style={{ paddingLeft: '8px' }} key={activity?.activity_id}>
				{commentBoxShow && (
					<Grid container spacing={1} alignItems='center' className={classes.comment}>
						<Grid item>
							<CommentBox
								glassLabels={glassLabels}
								glassCode={glassCode}
								activityTypes={activityTypes}
								sessionId={sessionId}
								placeholder={'Add a comment...'}
								onSubmit={(data, formik) => updateActivityComment(data, formik, activity)}
								loading={commentLoading}
								suggestions={suggestions}
								handleCustomLabel={handleCustomLabel}
								newCustomLabel={newCustomLabel}
							/>
						</Grid>
					</Grid>
				)}
			</CardContent>
			{_.isEqual(reply_activityId, activity?.activity_id) &&
				!_.isEmpty(replyList) &&
				_.map(replyList, (reply) => {
					return (
						<Reply
							reply={reply}
							activity={activity}
							userDetails={userDetails}
							token={token}
							updateActivityReplyReactions={updateActivityReplyReactions}
							updateActivityComment={updateActivityComment}
							updateActivityCommentLocal={updateActivityCommentLocal}
							setReplyState={setReplyState}
							activity={activity}
							ActivityUpdatesListener={ActivityUpdatesListener}
							// setReplyBoxShow={setReplyBoxShow}
							// setReplyValue={setReplyValue}
							// setReplyId={setReplyId}
							// replyId={replyId}
							// replyBoxShow={replyBoxShow}
							glassLabels={glassLabels}
							glassCode={glassCode}
							activityTypes={activityTypes}
							sessionId={sessionId}
							commentLoading={commentLoading}
							suggestions={suggestions}
							subReplyList={subReplyList}
							subReply_replyId={subReply_replyId}
							updateActivityItem={updateActivityItem}
							setSubReplyState={setSubReplyState}
							updateReplyItem={updateReplyItem}
							stickyId={stickyId}
							// updateActivityReply={updateActivityReply}
							subReply_reply_Id={subReply_reply_Id}
							// loadMoreReply={loadMoreReply}
							updateActivitySubReplyReactions={updateActivitySubReplyReactions}
							subReply_sort_by={subReply_sort_by}
							subReplyLimit={subReplyLimit}
							setSububReply_reply_Id={setSububReply_reply_Id}
							subReplyOffset={subReplyOffset}
							history={history}
							isSocketConnected={isSocketConnected}
							socketRef={socketRef}
							handleCustomLabel={handleCustomLabel}
							newCustomLabel={newCustomLabel}
						/>
					)
				})}
			{!_.isEqual(replyActivityId, activity?.activity_id) && _.gt(activity?.reply_count, 0) && (
				<CardContent>
					<div className={classes.loadMore}>
						<Button color='primary' size='small' style={{ textTransform: 'none' }} onClick={() => loadMoreCmt(activity?.activity_id)}>
							Load more comments...
						</Button>
					</div>
				</CardContent>
			)}
			<CardActions disableSpacing>
				<div className={classes.labelsWrap}>
					{!_.isEmpty(activity?.labels) &&
						_.map(activity?.labels, (label) => (
							<ButtonBase
								key={label?.label_id}
								onClick={null}
								disabled={true}
								style={{ background: label?.label_color }}
								className={classes.labelBtnBase}
							>
								<Typography variant='caption'>{label?.label_name}</Typography>
							</ButtonBase>
						))}
					{isEditEnabled && !activity?.is_log && (
						<>
							{activity?.is_pinned ? (
								<Button color='primary' size='small' onClick={() => updateActivity(activity)}>
									<Icon className={classes.pinnedIcon} fontSize='small'>
										push_pin
									</Icon>
									{'  '}
									&nbsp; unpin
								</Button>
							) : (
								<Button color='primary' size='small' onClick={() => updateActivity(activity)}>
									<Icon fontSize='small'>push_pin</Icon>
									{'  '}
									&nbsp; pin
								</Button>
							)}
						</>
					)}
				</div>
			</CardActions>
			<Divider variant='inset' />
		</Card>
	)
}

export default ActivityList
