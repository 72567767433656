import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from 'formik'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, Chip, Typography } from '@material-ui/core'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(2),
	},
	roleViewRoot: {
		textAlign: 'center',
	},
	chip: {
		margin: theme.spacing(0.5),
	},
}))

const Roles = ({ isCreateMode, formRef, isViewMode, isRoleListLoading, roleList }) => {
	const classes = useStyles()

	const roleIdMapper = useMemo(() => {
		return _.groupBy(roleList, 'role_id')
	}, [roleList])

	const setFieldValue = (formik, fieldName, value) => {
		formik.setFieldValue(fieldName, value)
	}

	//useEffect for create mode set default roles as prefilled
	useEffect(() => {
		if (!isRoleListLoading && isCreateMode && formRef.current) {
			const getDefaultRoles = () => {
				return _.filter(roleList, (role) => _.get(role, 'is_default'))
			}
			setFieldValue(
				formRef.current,
				'role_id',
				_.map(getDefaultRoles(), (role) => _.get(role, 'role_id'))
			)
		}
	}, [isCreateMode, isRoleListLoading, formRef, roleList])

	const getRolesSelectedValue = (fieldValue) => {
		return _.filter(
			_.map(fieldValue, (roleId) => _.get(roleIdMapper, `${roleId}[0]`, null)),
			(role) => !_.isNull(role)
		)
	}

	const getAssignedRoleChips = (fieldValue) => {
		const selectedRoles = getRolesSelectedValue(fieldValue)
		if (_.isEmpty(selectedRoles)) {
			return <Typography variant='caption'>No Roles Assigned.</Typography>
		}
		return _.map(selectedRoles, (role) => (
			<Chip key={_.get(role, 'role_id')} className={classes.chip} label={_.startCase(_.get(role, 'role_name'))} color='primary' />
		))
	}

	return (
		<div className={classes.root}>
			{isRoleListLoading ? (
				'Please wait Loading...'
			) : (
				<Field type='text' name='role_id'>
					{({ field, form }) =>
						isViewMode ? (
							<div className={classes.roleViewRoot}>{getAssignedRoleChips(field.value)}</div>
						) : (
							<Autocomplete
								multiple
								id='rolesoutlined'
								options={roleList || []}
								size={'small'}
								onChange={(e, value) => {
									field.onChange({
										target: {
											name: field.name,
											value: _.map(value, (role) => _.get(role, 'role_id')),
										},
									})
								}}
								onBlur={field.onBlur}
								value={getRolesSelectedValue(field.value)}
								disabled={isViewMode}
								classes={{
									root: classes.autocompleteRoot,
									inputRoot: classes.autoCompleteInputRoot,
								}}
								getOptionLabel={(option) => _.startCase(option.role_name)}
								defaultValue={[roleList[0]]}
								ChipProps={{ color: 'primary' }}
								filterSelectedOptions
								renderInput={(params) =>
									<TextField
										{...params}
										variant='outlined'
										label={isViewMode ? 'Assigned Roles' : 'Choose Roles'}
										error={Boolean(form.errors[field.name] && form.touched[field.name])}
										helperText={form.touched[field.name] && form.errors[field.name]}
									/>}
							/>
						)
					}
				</Field>
			)}
		</div>
	)
}

Roles.propTypes = {
	isCreateMode: PropTypes.bool.isRequired,
	isViewMode: PropTypes.bool.isRequired,
	isRoleListLoading: PropTypes.bool.isRequired,
	roleList: PropTypes.array,
}

export default Roles
