import { ACTION_TYPES } from '../../../../constants'
import _ from 'lodash'
import update from 'immutability-helper'

const activityTypes = (state, action) => {
	const activityIdx = _.findIndex(state, { activity_id: action?.payload?.actTypeId })
	switch (action.type) {
		case ACTION_TYPES.ADD_GLASS_ACTIVITY_TYPE:
			return update(state, { $push: [_.get(action, 'payload')] })
		case ACTION_TYPES.UPDATE_GLASS_ACTIVITY_TYPE:
			const activityToUpdate = _.get(action, 'payload.activity')
			return update(state, { [activityIdx]: { $set: { ...activityToUpdate } } })
		case ACTION_TYPES.REMOVE_GLASS_ACTIVITY_TYPE:
			return update(state, { $splice: [[activityIdx, 1]] })
		default:
			return state
	}
}

export default activityTypes
