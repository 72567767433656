import React, { useState, useRef, useEffect /* useMemo, useCallback */ } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Tabs, Tab, Typography } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { PAGE_MODES } from '../../../../constants'
import Validator from './validator'
import { Form } from 'formik'
import _ from 'lodash'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { useDispatch } from 'react-redux'
import { hideLoader } from 'services/loader/actions'
import { createRole, fetchRole, updateRole } from 'services/roles/actions'
// import { createNewUser, fetchUserViaSaga, updateUser } from 'services/users/actions';
import RoleDetails from './RoleDetails'
import Permissions from './Permissions'
import Slide from '@material-ui/core/Slide'
import classnames from 'classnames'
// import { ROLE_MODULE_CONFIG } from '../../../../constants/modules'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const getDialogTitle = (action) => {
	switch (action) {
		case PAGE_MODES.CREATE:
			return 'Add Role'
		case PAGE_MODES.EDIT:
			return 'Edit Role'
		case PAGE_MODES.VIEW:
			return 'View Role'
		default:
			return ''
	}
}

const useStyles = makeStyles((theme) => ({
	dialog: {
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
			top: 20,
		},
	},
	tab: {
		textTransform: 'none',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	customTabTitle: {
		fontSize: 14,
		fontWeight: '500',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	dialogTitle: {
		paddingBottom: 0,
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	dialogContent: {
		paddingTop: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			width: 840,
		},
		display: 'flex',
		'& form': {
			flex: '1',
			'& > div': {
				height: '100%',
			},
		},
	},
	dialogFooter: {
		padding: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
	badge: {
		marginLeft: theme.spacing(1),
	},
	warnIcon: {
		color: theme.palette.warning.main,
		fontSize: theme.typography.pxToRem(50),
	},
	actionBtn: {
		marginTop: theme.spacing(4),
		marginRight: theme.spacing(2),
	},
	subtitleTxt: {
		fontSize: theme.typography.pxToRem(13),
		marginTop: theme.spacing(1),
	},
	warnBtn: {
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.main,
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	container: {
		textAlign: 'center',
		padding: theme.spacing(2),
	},
	loaderTxt: {
		marginTop: theme.spacing(1.5),
	},
}))

const FormDialog = ({ open, roleId, action, handleClose, changeToEditMode, isEditAllowed }) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const dialogTitle = getDialogTitle(action)
	const classes = useStyles()
	const [activeTabIdx, setActiveTabIdx] = useState(0)
	const [extRoleState, setRoleState] = useState({
		isFetchingRole: false,
		isEditable: true,
		roleDetails: {},
	})
	const formRef = useRef()
	const dispatch = useDispatch()

	const { isFetchingRole, isEditable, roleDetails } = extRoleState

	const isEditMode = _.isEqual(action, PAGE_MODES.EDIT)
	const isCreateMode = _.isEqual(action, PAGE_MODES.CREATE)
	const isViewMode = _.isEqual(action, PAGE_MODES.VIEW)

	const onChangeTab = (e, tabIdx) => {
		setActiveTabIdx(tabIdx)
	}

	const onSubmitForm = (roleDetails) => {
		console.log('roleDetails', roleDetails?.role_name)
		const successCallback = () => {
			handleClose(null, true)
		}
		const closeErrDialog = () => {
			dispatch(hideLoader())
		}
		if (action === PAGE_MODES.CREATE) {
			dispatch(createRole({ ...roleDetails, role_name: _.trim(roleDetails?.role_name) }, successCallback, closeErrDialog))
		} else if (action === PAGE_MODES.EDIT) {
			dispatch(updateRole(roleId, { ...roleDetails, role_name: _.trim(roleDetails?.role_name) }, successCallback, closeErrDialog))
		}
	}

	const onClickEditBtn = () => {
		if (action === PAGE_MODES.VIEW) {
			changeToEditMode(roleId)
		}
	}

	const onFormSubmitErr = () => {
		if (!_.isEmpty(formRef.current, 'state.errors')) {
			if (activeTabIdx > 0) {
				setActiveTabIdx(0)
			}
			dispatch(showSnackbarWithTimeout('Please fix all the form errors', 2000))
		}
	}
	const [showPopup, setShowPopup] = useState(false)

	const performSubmit = () => {
		setShowPopup(false)
		formRef.current && formRef.current.submitForm()
	}

	const onClickActionBtn = (e) => {
		e.preventDefault()
		let hasAnyChecked = false
		if (formRef?.current?.values?.modules.length) {
			hasAnyChecked = formRef?.current?.values?.modules.some((item) => Object.keys(item.permissions).some((key) => item.permissions[key]))
		}
		if (!hasAnyChecked && !_.isEmpty(formRef?.current?.values?.role_name)) {
			setShowPopup(true)
		} else {
			performSubmit()
		}
	}

	useEffect(() => {
		if (!_.isEqual(action, PAGE_MODES.CREATE) && !_.isEmpty(action) && _.isEmpty(roleDetails)) {
			setRoleState((prevState) => ({ ...prevState, isFetchingRole: true }))
			dispatch(
				fetchRole(
					roleId,
					(resp) => {
						const { role_id, role_name, is_default, modules, is_editable } = _.get(resp, 'data.data')
						if (action === PAGE_MODES.EDIT && !is_editable) handleClose(null)
						else
							setRoleState({
								isFetchingRole: false,
								isEditable: is_editable,
								roleDetails: { role_id, role_name, is_default, modules },
							})
					},
					() => {
						setRoleState((prevState) => ({
							...prevState,
							isFetchingRole: false,
						}))
						dispatch(hideLoader())
						handleClose(null, true)
					}
				)
			)
		}
	}, [dispatch, action, roleDetails, roleId, handleClose])

	//reset exsting role details on form dialog close event
	useEffect(() => {
		if (!open) {
			setRoleState((prevState) => ({
				...prevState,
				isEditable: true,
				isFetchingRole: false,
				roleDetails: {},
			}))
			setActiveTabIdx(0)
		}
	}, [open])

	const openDialog = open && !isFetchingRole

	return (
		<>
			<Dialog
				classes={{
					paper: classes.dialog,
				}}
				TransitionComponent={Transition}
				fullScreen={fullScreen}
				maxWidth={'md'}
				open={openDialog}
				onClose={handleClose}
				aria-labelledby='role-form-title'
			>
				{!isFetchingRole ? (
					<Validator formMode={action} initialFormData={roleDetails} onSubmit={onSubmitForm} ref={formRef}>
						{(formik) => {
							return (
								<>
									<DialogTitle className={classes.dialogTitle} disableTypography={true} id='role-dialog-title'>
										<Typography variant='h6'>{dialogTitle}</Typography>
										<Tabs
											orientation='horizontal'
											value={activeTabIdx}
											indicatorColor='primary'
											onChange={onChangeTab}
											variant={fullScreen ? 'fullWidth' : 'standard'}
											aria-label='User form tabs'
										>
											<Tab className={classes.tab} label={'Role Name'}></Tab>
											<Tab className={classes.tab} label={'Permissions'}></Tab>
										</Tabs>
									</DialogTitle>
									<DialogContent className={classes.dialogContent}>
										<Form id='role-form' onSubmit={onClickActionBtn}>
											<Effect formik={formik} onSubmissionError={onFormSubmitErr} />
											<div hidden={activeTabIdx !== 0} className={classes.tabPanel}>
												{activeTabIdx === 0 && <RoleDetails isCreateMode={isCreateMode} isEditMode={isEditMode} isViewMode={isViewMode} />}
											</div>
											<div hidden={activeTabIdx !== 1} className={classes.tabPanel}>
												{activeTabIdx === 1 && (
													<Permissions
														isMobileView={fullScreen}
														isCreateMode={isCreateMode}
														isEditMode={isEditMode}
														isViewMode={isViewMode}
														// permissionChange={permissionChange}
													/>
												)}
											</div>
										</Form>
									</DialogContent>
									<DialogActions className={classes.dialogFooter}>
										<Button variant='outlined' size={fullScreen ? 'small' : 'medium'} onClick={handleClose} color='primary'>
											{isViewMode ? 'Close' : 'Cancel'}
										</Button>
										{isEditable && isEditAllowed && (
											<Button
												variant='contained'
												onClick={isViewMode ? onClickEditBtn : onClickActionBtn}
												size={fullScreen ? 'small' : 'medium'}
												color='primary'
												disableElevation
											>
												{isViewMode ? 'Edit' : 'Save'}
											</Button>
										)}
									</DialogActions>
								</>
							)
						}}
					</Validator>
				) : (
					<></>
				)}
			</Dialog>
			<Dialog fullWidth={true} maxWidth='xs' open={showPopup} aria-labelledby='Loader Popup'>
				<DialogContent>
					<div className={classes.container}>
						<i className={classnames('material-icons', classes.warnIcon)}>security</i>
						<Typography className={classes.loaderTxt} variant='body1'>
							Are you sure, you want to create a Role without any permission?
						</Typography>
						<>
							<Button
								variant='contained'
								color='primary'
								className={classnames(classes.actionBtn, classes.warnBtn)}
								onClick={performSubmit}
								disableElevation
							>
								Save
							</Button>
							<Button
								variant='contained'
								color='default'
								className={classnames(classes.actionBtn)}
								onClick={() => {
									setShowPopup(false)
								}}
								disableElevation
							>
								Cancel
							</Button>
						</>
					</div>
				</DialogContent>
			</Dialog>
		</>
	)
}

function Effect(props) {
	const effect = () => {
		if (props.formik.submitCount > 0 && !props.formik.isValid) {
			props.onSubmissionError()
		}
	}
	useEffect(effect, [props.formik.submitCount])
	return null
}

FormDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	changeToEditMode: PropTypes.func.isRequired,
	roleId: PropTypes.string,
	action: PropTypes.string,
	isEditAllowed: PropTypes.bool,
}

export default FormDialog
