import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Icon, List, ListItem, ListItemText, makeStyles, Typography, Link, TablePagination } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(1),
	},
	cbSection: {
		minWidth: 'unset',
		margin: 0,
	},
	title: {
		fontWeight: '500',
	},
	docType: {
		color: theme.palette.primary.main,
		marginLeft: theme.spacing(0.5),
		fontSize: '12px',
	},
	timeInfo: {
		marginTop: theme.spacing(0.7),
		lineHeight: '10px',
		'& span': {
			verticalAlign: 'middle',
			marginRight: theme.spacing(0.3),
		},
	},
	listItemRoot: {
		'& .MuiListItemText-multiline': {
			margin: 0,
		},
	},
	listRootWithoutCheckbox: {
		paddingTop: 0,
		paddingBottom: 0,
		'& .MuiListItemText-multiline': {
			margin: 8,
		},
	},
	desc: {
		display: '-webkit-box',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
	},
	checkbox: {
		paddingLeft: theme.spacing(0.5),
		paddingTop: theme.spacing(3),
	},
	infoTitle: {
		marginBottom: theme.spacing(1),
	},
}))

ResultsList.propTypes = {
	isMobileView: PropTypes.bool.isRequired,
	data: PropTypes.arrayOf(
		PropTypes.shape({
			data_source: PropTypes.string,
			date: PropTypes.string,
			description: PropTypes.string,
			doc_type: PropTypes.string,
			id: PropTypes.string,
			title: PropTypes.string,
			url: PropTypes.string,
		})
	),
	onDeleteResult: PropTypes.func,
}

function ResultsList({ isMobileView, data, onDeleteResult, selectAll }) {
	const classes = useStyles()

	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	useEffect(() => {
		setPage(0)
		setRowsPerPage(10)
	}, [data])

	const pageData = data.slice(page * rowsPerPage, (page + 1) * rowsPerPage)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	return (
		<div className={classes.root}>
			<List className={classes.root}>
				{_.map(pageData, (result, index) => (
					<ListItem
						onClick={(e) => {}}
						classes={{ root: classes.listRootWithoutCheckbox }}
						key={_.get(result, 'id')}
						disableGutters
						alignItems='flex-start'
						button
					>
						<ListItemText
							primary={
								<React.Fragment>
									<Typography className={classes.infoTitle} variant='caption' color='textSecondary'>
										{_.get(result, 'data_source')} &bull;
										<span className={classes.docType}>{_.get(result, 'doc_type')}</span>
									</Typography>
									<Typography
										variant={isMobileView ? 'body2' : 'body1'}
										fontWeight='bold'
										color='textPrimary'
										dangerouslySetInnerHTML={{ __html: _.get(result, 'title') }}
										className={classes.title}
									/>
								</React.Fragment>
							}
							secondary={
								<React.Fragment>
									<Typography
										dangerouslySetInnerHTML={{
											__html: _.get(result, 'description'),
										}}
										component='span'
										gutterBottom
										className={classes.desc}
										variant='body2'
										color='textSecondary'
									/>
									{!_.isEmpty(_.get(result, 'date')) ? (
										<Typography component='span' className={classes.timeInfo} variant='caption'>
											<Icon fontSize='inherit'>today</Icon>
											<span>
												{moment(_.get(result, 'date')).format('DD MMMM YYYY')} |{' '}
												<Link
													to='#'
													color='error'
													onClick={() => {
														onDeleteResult(index)
													}}
												>
													Delete
												</Link>
											</span>
										</Typography>
									) : _.isEqual(selectAll, false) ? (
										<Link
											to='#'
											color='error'
											onClick={() => {
												onDeleteResult(index)
											}}
										>
											Delete
										</Link>
									) : null}
								</React.Fragment>
							}
						/>
					</ListItem>
				))}
			</List>
			{!_.isEmpty(data) && _.isEqual(selectAll, false) && (
				<TablePagination
					rowsPerPageOptions={[10, 25, 50]}
					component='div'
					rowsPerPage={rowsPerPage}
					page={page}
					count={data.length}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
					classes={{
						toolbar: classes.paginationToolBar,
						spacer: classes.paginationSpacer,
						selectRoot: classes.paginationSelectRoot,
						actions: classes.paginationActions,
						caption: classes.paginationCaption,
					}}
				/>
			)}
		</div>
	)
}

export default ResultsList
