import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles, Icon, Typography } from '@material-ui/core'
import Projects from 'scences/Projects'

ProjectsWrapper.propTypes = {
	onClickNext: PropTypes.func.isRequired,
}

const useStyles = makeStyles((theme) => ({
	root: {},
	button: {
		marginTop: theme.spacing(2),
		float: 'right',
	},
	info: {},
}))

function ProjectsWrapper({ onClickNext }) {
	const classes = useStyles()
	const [state, setState] = useState({ id: null, action: '', isRefreshList: false, projectsLength: 0 })
	const { id, action, isRefreshList, projectsLength } = state

	const changeState = (newState) => {
		setState((prevState) => ({ ...prevState, ...newState }))
	}

	return (
		<div className={classes.root}>
			<Typography className={classes.info} variant='subtitle1' color='textSecondary' align='center' gutterBottom>
				Create the projects using the team units
			</Typography>
			<Projects isEmbedded={true} action={action} id={id} isRefreshList={isRefreshList} changeEmbededState={changeState} />
			<Button
				onClick={onClickNext}
				variant='contained'
				color='primary'
				disableElevation
				size='small'
				className={classes.button}
				endIcon={<Icon>east</Icon>}
				disabled={projectsLength === 0}
			>
				Next
			</Button>
		</div>
	)
}

export default ProjectsWrapper
