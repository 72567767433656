import React from 'react'
import { makeStyles, Grid, Icon, Typography } from '@material-ui/core'

const ListItemLabel = ({ text, icon, color, classes }) => {
	if (!!text) {
		if (!!color) {
			if (!!icon) {
				return (
					<div
						className={classes.optionListLabelContainer}
						style={{
							padding: `2px 8px 2px 4px`,
							background: `${color}25`,
							borderRadius: 4,
						}}
						onClick={(e) => e.preventDefault()}
					>
						<Icon style={{ color: color, fontSize: 18 }} onClick={(e) => e.preventDefault()}>
							{icon}
						</Icon>
						<Typography
							variant='caption'
							style={{
								color: `#000000DE`,
								lineHeight: '19px',
								letterSpacing: '0px',
								fontSize: 14,
							}}
							onClick={(e) => e.preventDefault()}
						>
							{text}
						</Typography>
					</div>
				)
			}
			return (
				<div className={classes.optionListLabelContainer} onClick={(e) => e.preventDefault()}>
					<div className={classes.optionListLabelColorDiv} style={{ background: color }} onClick={(e) => e.preventDefault()} />
					<Typography variant='body1' className={classes.optionItemLabel} onClick={(e) => e.preventDefault()}>
						{text}
					</Typography>
				</div>
			)
		}
		return (
			<Typography variant='body1' className={classes.optionItemLabel} onClick={(e) => e.preventDefault()}>
				{text}
			</Typography>
		)
	}
	return null
}
export default ListItemLabel
