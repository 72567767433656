import React, { useState, useImperativeHandle } from 'react'
import _ from 'lodash'
import { Typography, Icon, ButtonBase, IconButton } from '@material-ui/core'
import { GLASS_ROLES } from '../../constants'
import { convertGmtToLocalTime } from 'utils'
import moment from 'moment'

export const withCellRenderState = (CellRenderer) => {
	return React.forwardRef((props, ref) => {
		const [value, setValue] = useState(props.value)
		useImperativeHandle(ref, () => {
			return {
				refresh: (params) => {
					if (params.value !== value) {
						setValue(params.value)
					}
					return true
				},
			}
		})
		return <CellRenderer {...props}></CellRenderer>
	})
}

export const ActionCellRenderer = (props) => {
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const onClickMoreOptions = _.get(props, 'colDef.cellRendererParams.onClickMoreOptions')
	const isUpdating = _.get(props, 'data.isUpdating', false)

	const onClickAnchorEl = (event) => {
		onClickMoreOptions(event, _.get(props, 'data', {}))
	}
	return !isUpdating ? (
		<div className={containerClassName}>
			<a href='#no' onClick={onClickAnchorEl} title='Show Actions'>
				<i className='material-icons'>more_horiz</i>
			</a>
		</div>
	) : null
}

export const RoleCellRenderer = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const onClick = _.get(props, 'colDef.cellRendererParams.onClick')
	const disabled = _.get(props, 'colDef.cellRendererParams.disabled')
	const isUpdating = _.get(data, 'isUpdating', false)

	return !isUpdating ? (
		<ButtonBase disabled={disabled} onClick={(e) => onClick(e, data)} className={containerClassName} disableRipple>
			<Typography variant='body2' className='roleTxt' component='div'>
				{moment(convertGmtToLocalTime(data?.created_date)).format('DD-MM-YYYY')}
			</Typography>
		</ButtonBase>
	) : null
}

export const RemoveCellRenderer = (props) => {
	const { data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const onClick = _.get(props, 'colDef.cellRendererParams.onClick')
	const isUpdating = _.get(data, 'isUpdating', false)

	return (
		<div className={containerClassName}>
			{!isUpdating && !_.isEqual(_.get(data, 'role'), GLASS_ROLES.OWNER) && (
				<IconButton size='small' onClick={() => onClick(data)} title='Remove User'>
					<Icon fontSize='small'>close</Icon>
				</IconButton>
			)}
		</div>
	)
}

export const NameCellRenderer = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	return (
		<div className={containerClassName}>
			<Typography variant='body2' component='div' className='details-span'>
				{data?.name}
			</Typography>
		</div>
	)
}
