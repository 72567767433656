import React from 'react'
import PropTypes from 'prop-types'
import {
	Avatar,
	Divider,
	Icon,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
	Typography,
} from '@material-ui/core'
import _ from 'lodash'
import classnames from 'classnames'

ProductList.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			product_id: PropTypes.string,
			image_url: PropTypes.string,
		})
	),
	onClickDelProduct: PropTypes.func,
	errProdIds: PropTypes.arrayOf(PropTypes.string),
}

const useStyles = makeStyles((theme) => ({
	avatarCircle: {
		borderRadius: 0,
		marginLeft: theme.spacing(1),
	},
	productTitle: {
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
	},
	errItem: {
		background: `${theme.palette.error.main}26`,
	},
	errIco: {
		verticalAlign: 'middle',
		color: theme.palette.error.main,
	},
	errTxt: {
		marginTop: theme.spacing(1),
	},
}))

function ProductList({ data, errProdIds, onClickDelProduct }) {
	const classes = useStyles()
	const isShowErr = !_.isEmpty(errProdIds)

	return (
		<div>
			<Typography variant='overline' component='div'>
				Product List{' '}
				{isShowErr && (
					<Icon className={classes.errIco} fontSize='small'>
						error
					</Icon>
				)}
			</Typography>
			<List dense={true} className={classes.root}>
				<Divider variant='fullWidth' component='li' />
				{_.map(data, (product, idx) => (
					<div key={_.get(product, 'product_id')}>
						<ListItem
							className={classnames({
								[classes.errItem]: _.indexOf(errProdIds, _.get(product, 'product_id')) > -1,
							})}
							disableGutters={true}
						>
							<ListItemAvatar>
								<Avatar src={_.get(product, 'image_url')} classes={{ circle: classes.avatarCircle }}></Avatar>
							</ListItemAvatar>
							<ListItemText
								primary={
									<Typography className={classes.productTitle} variant='body2'>
										{_.get(product, 'product_name')}
									</Typography>
								}
								secondary={`${_.get(product, 'source')} - Price: ${_.get(product, 'market_price')} - Seller: ${_.get(
									product,
									'seller_name'
								)} - ${_.get(product, 'stock')}`}
							/>
							<ListItemSecondaryAction>
								<IconButton
									onClick={(e) => {
										onClickDelProduct(idx)
									}}
								>
									<Icon fontSize='small'>delete</Icon>
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
						<Divider variant='fullWidth' component='li' />
					</div>
				))}
				{isShowErr && (
					<Typography component='div' variant='caption' color='error' className={classes.errTxt}>
						Highlighted products are already assigned to the other card(s) in the selected glass. To fix this error, remove the highlighted product(s)
						manually.
					</Typography>
				)}
			</List>
		</div>
	)
}

export default ProductList
