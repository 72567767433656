import React, { useState, useImperativeHandle } from 'react'
import { IconButton, Icon, Avatar, ButtonBase, Typography, Grid, makeStyles} from '@material-ui/core'
import _ from 'lodash'
import { formTheName, getImgUrl, stringToHslColor, convertGmtToLocalTime } from 'utils'
import { Rating } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
	progressbarContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: 10,
		alignItems: 'center',
	},
	progressbarTotal: {
		backgroundColor: theme.palette.grey[300],
		height: 10,
		width: '100%',
		borderRadius: 10,
		display: 'flex',
		flexDirection: 'row',
		maxWidth: '100px'
	},
	inProgressbar: {
		backgroundColor: theme.palette.progress.inprogress,
		height: 10,
		borderTopRightRadius: 10,
		borderBottomRightRadius: 10,
	},
	completedBar: {
		backgroundColor: theme.palette.progress.completed,
		height: 10,
		borderTopLeftRadius: 10,
		borderBottomLeftRadius: 10,
	},
	progressContent: {
		'& .MuiTypography-body1': {},
	},
	completedText: {
		fontSize: 12,
		whiteSpace: 'nowrap',
	},
}))

export const withCellRenderState = (CellRenderer) => {
	return React.forwardRef((props, ref) => {
		const [value, setValue] = useState(props.value)

		useImperativeHandle(ref, () => {
			return {
				refresh: (params) => {
					if (params.value !== value) {
						setValue(params.value)
					}
					return true
				},
			}
		})
		return <CellRenderer {...props}></CellRenderer>
	})
}
export const CodeCellRenderer = (props) => {
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	return (
		<div className={containerClassName}>
			<span style={{ backgroundColor: value ? '#82c43c' : '#fc5a5a' }}></span>
			{data ? data.sticky_code : ''}
		</div>
	)
}
export const titleCellRenderer = (props) => {
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	return (
		<>
					{_.isEqual(data.sticky_for, 'products') && !_.isEmpty(data.seller_name) ? (
		                <Grid item>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="body2">
                                {data.sticky_title}
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Typography variant="body2" style={{color:'#65b17f', fontSize:'small'}}>
                                {data.seller_name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
					):(
						<div className={containerClassName}>
						{data ? data.sticky_title : ''} 
					    </div>

					)
					}
		</>
	)
}
export const NameCellRenderer = (props) => {
	const { value } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')

	return (
		<div className={containerClassName} title={value}>
			<span className='details-span'>{value}</span>
		</div>
	)
}

export const StickyGroupCellRenderer = (props) => {
	const { value, data } = props
	const stickyGroup = data?.sticky_group?.sticky_group
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')

	return (
		<div className={containerClassName} title={stickyGroup}>
			<span className='details-span'>{stickyGroup}</span>
		</div>
	)
}

export const AssignCellRenderer = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { value, data } = props

	const token = _.get(props, 'colDef.cellRendererParams.token', '')
	const avatarData = data?.assigned_to || data?.assignee
	return (
		<div>
			{!_.isEmpty(avatarData) ? (
				<Avatar
					title={`${formTheName(avatarData?.first_name || '', avatarData?.last_name || '')} - ${avatarData?.email || ''}`}
					style={{
						width: 30,
						height: 30,
						fontSize: 14,
						margin: '0 auto',
						marginTop: '8px',
						backgroundColor: stringToHslColor(formTheName(avatarData?.first_name || '', avatarData?.last_name || '')),
					}}
					src={avatarData?.profile_img ? getImgUrl(token, avatarData?.profile_img) : null}
				>
					{_.slice(avatarData?.first_name, 0, 1)}
				</Avatar>
			) : (
				<div style={{ textAlign: 'center' }}>-</div>
			)}
		</div>
	)
}

export const ReportingCellRenderer = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { value, data } = props

	const token = _.get(props, 'colDef.cellRendererParams.token', '')
	const avatarData = data?.reporting_to || {}
	return (
		<div>
			{!_.isEmpty(avatarData) ? (
				<Avatar
					title={`${formTheName(avatarData?.first_name || '', avatarData?.last_name || '')} - ${avatarData?.email || ''}`}
					style={{
						width: 30,
						height: 30,
						fontSize: 14,
						margin: '0 auto',
						marginTop: '8px',
						backgroundColor: stringToHslColor(formTheName(avatarData?.first_name || '', avatarData?.last_name || '')),
					}}
					src={avatarData?.profile_img ? getImgUrl(token, avatarData?.profile_img) : null}
				>
					{_.slice(avatarData?.first_name, 0, 1)}
				</Avatar>
			) : (
				<div>-</div>
			)}
		</div>
	)
}

export const labelCellRederer = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { value, data } = props
	return (
		<div style={{ textAlign: 'left' }}>
			{!_.isEmpty(value) ? (
				_.map(value, (label) => (
					<ButtonBase
						onClick={null}
						disabled={false}
						style={{ background: _.get(label, 'label_color'), color: '#fff', padding: '1px 4px', marginRight: '4px' }}
						// className={classes.labelBtnBase}
						key={_.get(label, 'label_id')}
					>
						<Typography variant='caption'>{_.get(label, 'label_name')}</Typography>
					</ButtonBase>
				))
			) : (
				<div style={{ textAlign: 'left' }}>-</div>
			)}
		</div>
	)
}

// dueDateCellRenderer

export const dueDateCellRenderer = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClassName')

	return (
		<div className={containerClassName}>
			{!_.isEmpty(value) ? (
				<Typography variant='caption'>{convertGmtToLocalTime(value, 'DD MMM YYYY hh:mm a')}</Typography>
			) : (
				<div style={{ textAlign: 'center' }}>-</div>
			)}
		</div>
	)
}

export const parentCodeCellRenderer = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { value } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClassName')

	return (
		<div className={containerClassName}>
			{!_.isEmpty(value) ? <div style={{ textAlign: 'center' }}>{value}</div> : <div style={{ textAlign: 'center' }}>-</div>}
		</div>
	)
}

export const StatusCellRenderer = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { value, data } = props

	return (
		<div>
			<span title={_.startCase(data?.sticky_status?.status)} className='details-span'>
				{_.startCase(data?.sticky_status?.status) || ''}
			</span>
		</div>
	)
}

export const RatingCellRenderer = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { value, data } = props

	return (
		<div>
			{!_.isEqual(data?.sticky_status?.ratings, 0) ? 
			<div style={{ justifyContent: 'center', display: 'flex', flexDirection: 'row', margin: '8px auto 0px'}}>
				<Typography variant='body1' style={{marginTop: '1px'}}>{data?.sticky_status?.ratings}&nbsp;</Typography>
				<Rating style={{alignItems: 'center'}} name="unique-rating" precision={0.1} value={data?.sticky_status?.ratings} readOnly size="small" />
			</div> 
			: <div style={{ textAlign: 'center' }}>-</div> }
		</div>
	)
}

export const PercentageCellRenderer = (props) => {
	const classes = useStyles()
	// eslint-disable-next-line no-unused-vars
	const { value, data } = props
	return (
		<div>
			{!_.isEmpty(data?.child_sticky_code) ? 
			<div className={classes.progressbarContainer}>
				<div className={classes.progressbarTotal}>
					<div
						title={`Completed - ${data?.completed_percentage}%`}
						style={{ width: `${data?.completed_percentage}%` }}
						className={classes.completedBar}
					></div>
					<div
						title={`In Progress - ${data?.inprogress_percentage}%`}
						style={{ width: `${data?.inprogress_percentage}%` }}
						className={classes.inProgressbar}
					></div>
				</div>
				<div className={classes.progressContent}>
					<Typography variant='caption' color='textSecondary' 
					className={classes.completedText}
					>
						{`${data?.completed_percentage}%`}
					</Typography>
				</div>
			</div> 
		   : <div style={{ textAlign: 'center' }}>-</div> }
		</div>
	)
}

export const ActionCellRenderer = (props) => {
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const onClickMoreOptions = _.get(props, 'colDef.cellRendererParams.onClickMoreOptions')

	const onClickAnchorEl = (event) => {
		onClickMoreOptions(event, _.get(props, 'data', {}))
	}

	return (
		<div className={containerClassName}>
			<IconButton size='small' onClick={onClickAnchorEl} title='Show Actions'>
				<i className='material-icons'>more_horiz</i>
			</IconButton>
		</div>
	)
}

export const PriorityCellRenderer = (props) => {
	const { data } = props
	return (
		<div style={{ textAlign: 'center' }}>
			{data?.priority ? (
				<Icon
					fontSize='small'
					title={data?.priority}
					style={{ color: _.isEqual(data?.priority, 'low') ? '#82C43C' : _.isEqual(data?.priority, 'medium') ? '#ffa500' : '#ff0000' }}
				>
					{_.isEqual(data?.priority, 'low') ? 'south' : 'north'}
				</Icon>
			) : (
				'-'
			)}
		</div>
	)
}
