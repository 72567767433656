import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Icon, makeStyles, TextField, Typography, Link, Breadcrumbs } from '@material-ui/core'
import PopoverMenus from 'components/PopoverMenus'
import { PERMISSION_TYPE } from 'constants/modules'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { getErrMsg, getSuccessMsg } from 'utils'
import { hideLoader, showConfirmMessage, showErrorMessage, showLoader } from 'services/loader/actions'
import { removeSticky, refreshStickyList } from 'services/glass/actions'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'

import { useDispatch } from 'react-redux'
const useStyles = makeStyles((theme) => ({
	headerRoot: {
		'& .MuiFormControl-fullWidth': {
			marginTop: 0,
		},
		'& .MuiOutlinedInput-inputMarginDense': {
			padding: theme.spacing(1),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1.2, 2, 0, 2),
		},
		padding: theme.spacing(1, '20px'),
	},
	closeIcon: {
		textAlign: 'center',
	},
	titleSec: {
		overflow: 'hidden',
		cursor: 'text',
		'& h5': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
	subTitleSec: {
		overflow: 'hidden',
		'& .MuiTypography-caption': {
			marginTop: theme.spacing(0.5),
			marginBottom: theme.spacing(1),
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(0),
				marginTop: theme.spacing(0),
			},
		},
	},
	headerIconSec: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& button': {
			margin: theme.spacing(0, 1),
		},
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'flex-end',
		},
	},
	parentSticky: {
		fontSize: '13px',
		cursor: 'pointer',
	},
}))

const Header = ({
	onClose,
	formik,
	isEditEnabled,
	isOwnerOrCreatedBy,
	isOwnerOrCreatedByOrReporter,
	stickyDetails,
	onHeaderClick,
	sessionId,
	glassCode,
	stickyCode,
	glassMembers,
	lanesData,
	history,
	slider,
	onClickDeleteMenu,
	onClickEdit,
	hideDeleteBtn,
	hideEditBtn,
}) => {
	const classes = useStyles()
	const [anchorEl, setAnchorEl] = useState(null)
	const dispatch = useDispatch()

	const actionMenus = [
		_.isEqual(hideEditBtn, false) ?
		{
			label: 'Edit',
			icon: 'edit',
			actionName: PERMISSION_TYPE.EDIT,
		} : null,
		_.isEqual(hideDeleteBtn, false) ?
		{
			label: 'Delete',
			icon: 'delete',
			actionName: PERMISSION_TYPE.DELETE,
		} : null,
	]

	const fomatedActionItems = _.compact(actionMenus)

	const handleClickMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	const performAction = (actionName) => {
		switch (actionName) {
			case PERMISSION_TYPE.DELETE:
				handleCloseMenu()
				onClickDeleteMenu(slider?.id)
				break
			case PERMISSION_TYPE.EDIT:
				// editProject()
				handleCloseMenu()
				onClickEdit(slider?.id)
				break
			default:
				break
		}
		handleCloseMenu()
	}

	const deleteProject = () => {
		const onSuccess = (resp) => {
			dispatch(hideLoader())
			onClose()
			dispatch(showSnackbarWithTimeout(getSuccessMsg(resp), 2500))
		}
		const onError = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		askDeleteConfirm(
			() => {
				// dispatch(showLoader('Deleting sticky...'))
				// dispatch(removeSticky(sessionId, glassCode, stickyCode, onSuccess, onError))
			},
			() => {
				dispatch(hideLoader())
			}
		)
	}
	const askDeleteConfirm = (onConfirm, onCancel) => {
		let askMesg = 'Are you sure?'
		dispatch(showConfirmMessage(askMesg, '', 'Confirm', onConfirm, 'Cancel', onCancel))
	}

	return (
		<>
			<Grid container justifyContent='space-between' alignItems='flex-start' className={classes.headerRoot}>
				<Grid item lg={10} sm={10} xs={10}>
					<React.Fragment>
						<Breadcrumbs aria-label='breadcrumb'>
							<Typography className={classes.subTitle} variant='caption' component='div' color='textSecondary'>
								{slider?.id}
							</Typography>
						</Breadcrumbs>
						<div className={classes.titleSec} role='button' >
							<Typography variant='h6' component='h6'>
								{slider?.title}
							</Typography>
						</div>
					</React.Fragment>
				</Grid>
				<Grid item lg={2} sma={2} xs={2} className={classes.closeIcon}>
					<div className={classes.headerIconSec}>
						{_.isEqual(hideEditBtn, false) || _.isEqual(hideDeleteBtn, false) ?
						<IconButton size='small' edge='end' onClick={handleClickMenu}>
							<Icon>more_horiz</Icon>
						</IconButton> : null}
						<IconButton size='small' edge='end' onClick={onClose}>
							<Icon>close</Icon>
						</IconButton>
					</div>
				</Grid>
			</Grid>
			<PopoverMenus
				open={Boolean(anchorEl)}
				id={'1'}
				menus={fomatedActionItems}
				anchorEl={anchorEl}
				onClickAction={performAction}
				onClose={handleCloseMenu}
			/>
		</>
	)
}

export default Header
