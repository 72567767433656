import React, { useCallback, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Popover, Box, TextField } from '@material-ui/core'
import _ from 'lodash'

HeaderRenameMenu.propTypes = {
	open: PropTypes.bool.isRequired,
	position: PropTypes.objectOf({ left: PropTypes.number, top: PropTypes.number }),
	handleClose: PropTypes.func.isRequired,
	column: PropTypes.objectOf(
		PropTypes.shape({
			field: PropTypes.string.isRequired,
			headerName: PropTypes.string.isRequired,
		})
	),
	onRenameHeader: PropTypes.func.isRequired,
}

const useStyles = makeStyles((theme) => ({
	textField: {
		fontSize: theme.typography.pxToRem(12),
	},
}))

function HeaderRenameMenu({ open, position, handleClose, column, onRenameHeader }) {
	const [columnLabel, setColumnLabel] = useState('')
	const classes = useStyles()

	const delayedUpdate = useCallback(
		_.debounce((q, callback) => {
			callback(q)
		}, 300),
		[]
	)

	useEffect(() => {
		setColumnLabel(column.headerName)
	}, [column])

	const onChangeLabel = (e) => {
		delayedUpdate(e.target.value, (val) => setColumnLabel(val))
	}

	const onClosePopover = (e) => {
		handleClose()
		if (!_.isEqual(columnLabel, column.headerName)) {
			onRenameHeader(column.field, columnLabel)
		}
	}

	return (
		<Popover
			open={open}
			anchorReference='anchorPosition'
			anchorPosition={position}
			onClose={onClosePopover}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			<Box paddingLeft={1} paddingRight={1} paddingTop={0.5}>
				<TextField className={classes.textField} size='small' defaultValue={columnLabel} onChange={onChangeLabel} />
			</Box>
		</Popover>
	)
}

export default HeaderRenameMenu
