import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import ImageList from '@material-ui/core/ImageList'
import ImageListItem from '@material-ui/core/ImageListItem'
import ImageListItemBar from '@material-ui/core/ImageListItemBar'
import ListSubheader from '@material-ui/core/ListSubheader'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import itemData from './itemData'
import { getImgUrl } from 'utils'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import defaultImg from '../../../../images/default_avatar.png'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		marginTop: '10px',
		backgroundColor: theme.palette.background.paper,
		'& ::-webkit-scrollbar, ::-webkit-scrollbar-track': {
			width: '8px',
			background: 'transparent',
		},
		'& ::-webkit-scrollbar-thumb': {
			background: theme.palette.almostBlack[400],
			height: '10px',
			borderRadius: '4px',
		},
	},
	imageList: {
		width: 500,
		[theme.breakpoints.down(1280)]: {
			width: 700,
		},
		height: (props) => props?.isViewMode ? 505 : 525,
	},
	centerList: {
		// justifyContent: 'center',
	},
	imageListItem: {
		position: 'relative',
		'& .MuiImageListItem-item': {
			borderRadius: 14,
			width: 105,
			height: 120,
		},
	},
	imageWrap: {
		// position: 'relative',
	},
	icon: {
		color: 'rgba(255, 255, 255, 0.54)',
	},
	delBtn: {
		position: 'absolute',
		right: 2,
		top: 2,
		zIndex: 100,
		// background: theme.palette.error.main,
		background: theme.palette.error.main,
		color: theme.palette.common.white,
		padding: theme.spacing(0.5),
		'& span': {
			fontSize: '0.75rem',
		},
		'&:hover': {
			background: theme.palette.error.dark,
		},
	},
	name: {
		fontSize: '14px',
		fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
		fontWeight: '400',
		lineHeight: '1.66',
		letterSpacing: '0.03333em',
		color: 'black',
		textAlign: 'center',
		marginTop: '0.35em',
	},
	designation: {
		fontSize: '14px',
		fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
		fontWeight: '400',
		lineHeight: '1.66',
		letterSpacing: '0.03333em',
		textAlign: 'center',
		marginBottom: '0.35em',
	},
}))

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const itemData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
const PeoplexCard = (props) => {

	const { width, projectMembers, handleRemovePeople, isViewMode, isCreateMode, isEditMode } = props
	const classes = useStyles(props)

	const token = useSelector((state) => _.get(state, 'session.authToken'))
    const getPicUrl = (profilePic) => getImgUrl(token, profilePic, 250)

	// const defaultImg = 'https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png'

	function getCols(screenWidth) {
		// if (isWidthUp('lg', screenWidth)) {
		// 	return 2
		// }
		// if (isWidthUp('md', screenWidth)) {
		// 	return 2
		// }
		if (isWidthUp('sm', screenWidth)) {
			return 3
		}

		return 1
	}

	const cols = getCols(width) // width is associated when using withWidth()

	return (
		<div className={classes.root}>
			<ImageList rowHeight={180} cols={cols} className={classnames(classes.imageList, (isEditMode || isCreateMode) && classes.centerList)}>
				{/* <ImageListItem key='Subheader' cols={2} style={{ height: 'auto' }}>
					<ListSubheader component='div'>December</ListSubheader>
				</ImageListItem> */}
				{projectMembers.map((item) => (
					<div style={{ width: 110 }}>
						<ImageListItem key={item?.id} className={classes.imageListItem}>
							{/* <div className={classes.imageWrap}> */}
							{(isCreateMode || isEditMode) &&
							<IconButton key='Delete' className={classes.delBtn} size='small' aria-label='Delete' onClick={() => {handleRemovePeople(item?.id)}}>
								<CloseIcon fontSize='inherit' />
							</IconButton>}
							{/* <img src={item.profile_url} alt={item.title} /> */}
							<img alt={item?.name} className={classes.prevImg} src={!_.isEmpty(item?.profile_url) ? getPicUrl(item?.profile_url) : defaultImg} style={{ maxHeight: 'none' }} />

							{/* </div> */}
							{/* <ImageListItemBar
							title={item.title}
							subtitle={<span>{item.author}</span>}
							actionIcon={
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									<IconButton aria-label={`info about ${item.title}`} className={classes.icon}>
										<InfoIcon />
									</IconButton>
									<IconButton key='Delete' className={classes.delBtn} aria-label='Delete'>
										<CloseIcon />
									</IconButton>
								</div>
							}
						/> */}
						</ImageListItem>

						<Typography component='h5' noWrap ariant='subtitle1' className={classes.name}>
							{item?.name}
						</Typography>
						<Typography variant='subtitle1' noWrap color='textSecondary' className={classes.designation}>
							{item?.talent_type}
						</Typography>
					</div>
				))}
			</ImageList>
		</div>
	)
}

export default withWidth()(PeoplexCard)
