import React, { useState, useEffect } from 'react'
import { makeStyles, ExpansionPanel, ExpansionPanelSummary, Icon, Grid, TextField, Typography, ExpansionPanelDetails, Badge } from '@material-ui/core'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	expansionPanelRoot: {
		borderBottom: 0,
		background: 'transparent',
		boxShadow: 'none',
		zIndex: '0',
		width: '100%',
		'&.Mui-expanded': {
			margin: theme.spacing(0.5, 0),
		},
		'&:not(:last-child)': {
			// borderBottom: '1px solid rgba(0, 0, 0, .125)',
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 0,
		},
		'& .MuiExpansionPanelSummary-expandIcon': {
			color: theme.palette.almostBlack[600],
		},
	},
	expanded: {},
	filterPanelTitleContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	filterTitleWithChip: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	resetSpan: {
		padding: theme.spacing(0.5),
		color: theme.palette.almostBlack[800],
		borderRadius: '5px',
		transition: 'background 0.3s',
		cursor: 'pointer',
		// fontFamily: '"Poppins"',
		fontSize: theme.typography.pxToRem(10),
		'&:hover': {
			background: `${theme.palette.primary.main}11`,
			transition: 'background 0.3s',
		},
	},
	root: {
		padding: theme.spacing(0),
		'&.Mui-expanded': {
			minHeight: 'unset',
			// margin: theme.spacing(0.5,0)
		},
		'& .MuiIconButton-colorPrimary:hover': {
			backgroundColor: 'transparent',
		},
		'& .MuiSvgIcon-root': {
			// color: `${theme.palette.primary.dark}`
		},
		'& .MuiTypography-body1': {
			// lineHeight: 0,
			fontWeight: 400,
		},
		'& .MuiFormGroup-row': {
			width: '100%',
			justifyContent: 'space-between',
		},
	},
	muiPanelSummaryRoot: {
		'&.Mui-expanded': {
			margin: theme.spacing(0.5, 0),
		},
	},
	iconRoot: {
		minWidth: 'auto',
	},
	listRoot: {},
	listItemRoot: {
		paddingTop: theme.spacing(0),
		paddingBottom: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		cursor: 'pointer',
		alignItems: 'center',
	},
	filterPanelHeading: {
		color: theme.palette.almostBlack[800],
		letterSpacing: theme.typography.pxToRem(0.8),
	},
	listText: {
		color: theme.palette.almostBlack[800],
		// textTransform: 'uppercase',
		'& .MuiTypography-body1': {
			// lineHeight: 0,
			fontWeight: 400,
			width: '100%',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
	},
	searchContainer: {
		padding: theme.spacing(1, 0),
	},
	capitaliseText: {
		textTransform: 'capitalize !important',
	},
	searchIcon: {
		color: theme.palette.almostBlack[400],
	},
	anchorOriginTopRightRectangle: {
		top: '50%',
		right: '-20px',
		'&.MuiBadge-dot': {
			top: '10%',
			right: '-4px',
			minWidth: '8px',
			width: '8px',
			height: '8px',
		},
		[theme.breakpoints.down('lg')]: {
			top: '2px',
			right: '-6px',
			fontWeight: '700',
			fontSize: theme.typography.pxToRem(10),
			width: 14,
			height: 14,
			minWidth: 14,
			borderRadius: 14,
		},
	},
	positionRelativeContainer: {
		position: 'relative',
	},
	positionAbsoluteContainer: {
		position: 'absolute',
		top: '45px',
		backgroundColor: 'white',
		width: 'calc(100% - 2px)',
		left: '1px',
	},
}))

const NumberRangeFilter = (props) => {
	const { title, handleClearThisFilter, value, /* optionKey, textKey, */ handleCheckBoxClick, filterDesc } = props

	// console.log("props from filterPanel", props)

	const classes = useStyles()
	const [isExpanded, setIsExpanded] = useState(false)

	// let optionKeyHere = optionKey
	// let textKeyHere = textKey

	const [tempValue, setTempValue] = useState(value)

	// const [radioValue, setRadioValue] = useState(selectedOptions);

	// const handleRadioChange = (e) => {
	//     setRadioValue(e.target.value);
	//     handleCheckBoxClick(e.target.value);
	// }

	// useEffect(()=>setRadioValue(selectedOptions), [selectedOptions])

	// const [searchQuery, setSearchQuery] = useState('');

	// const isDataChecked = (optionName) => {
	//     return !_.isEmpty(selectedOptions) && selectedOptions.indexOf(optionName) > -1;
	// }

	// const handleSearchInput = (keyValue) => {
	//     setSearchQuery(keyValue);
	//     handleSearch(keyValue.replace(/[^\w\s]/gi, ''));
	// }

	const handleResetClick = (e) => {
		e.stopPropagation()
		setTempValue([])
	}

	const handleValueChange = (v, idx) => {
		if (idx === 0) {
			setTempValue([`${v}`, tempValue[1]])
		} else if (idx === 1) {
			setTempValue([tempValue[0], `${v}`])
		}
	}

	// const handleValueChange = (v, idx) => {
	//     if(idx === 0){
	//         if(_.isEmpty(tempValue[1]) && !_.isEmpty(v)){
	//             setTempValue([`${v}`, `${v}`])
	//         }
	//         else if(!_.isEmpty(tempValue[1]) && !_.isEmpty(v)){
	//             if(_.toNumber(tempValue[1]) <= v){
	//                 setTempValue([`${v}`, `${v}`])
	//             }
	//             else{
	//                 setTempValue([`${v}`, tempValue[1]])
	//             }
	//         }
	//         else if(!_.isEmpty(tempValue[1]) && _.isEmpty(v)){
	//             setTempValue([undefined, tempValue[1]])
	//         }
	//         else if(_.isEmpty(tempValue[1]) && _.isEmpty(v)){
	//             setTempValue([])
	//         }
	//     }else if(idx === 1){
	//         if(_.isEmpty(tempValue[0]) && !_.isEmpty(v)){
	//             setTempValue([`${v}`, `${v}`])
	//         }
	//         else if(!_.isEmpty(tempValue[0]) && !_.isEmpty(v)){
	//             if(_.toNumber(tempValue[0]) >= v){
	//                 setTempValue([`${v}`, `${v}`])
	//             }
	//             else{
	//                 setTempValue([tempValue[0], `${v}`])
	//             }
	//         }
	//         else if(!_.isEmpty(tempValue[0]) && _.isEmpty(v)){
	//             setTempValue([tempValue[0], undefined])
	//         }
	//         else if(_.isEmpty(tempValue[0]) && _.isEmpty(v)){
	//             setTempValue([])
	//         }
	//     }
	// }

	const handleBlur = (v, idx) => {
		if (idx === 0) {
			if (_.isEmpty(tempValue[1]) && !_.isEmpty(v)) {
				setTempValue([`${v}`, `${v}`])
			} else if (!_.isEmpty(tempValue[1]) && !_.isEmpty(v)) {
				if (_.toNumber(tempValue[1]) <= v) {
					setTempValue([`${v}`, `${v}`])
				} else {
					setTempValue([`${v}`, tempValue[1]])
				}
			} else if (!_.isEmpty(tempValue[1]) && _.isEmpty(v)) {
				// setTempValue([undefined, tempValue[1]])
				setTempValue([])
			} else if (_.isEmpty(tempValue[1]) && _.isEmpty(v)) {
				setTempValue([])
			}
		} else if (idx === 1) {
			if (_.isEmpty(tempValue[0]) && !_.isEmpty(v)) {
				setTempValue([`${v}`, `${v}`])
			} else if (!_.isEmpty(tempValue[0]) && !_.isEmpty(v)) {
				if (_.toNumber(tempValue[0]) >= v) {
					setTempValue([`${v}`, `${v}`])
				} else {
					setTempValue([tempValue[0], `${v}`])
				}
			} else if (!_.isEmpty(tempValue[0]) && _.isEmpty(v)) {
				// setTempValue([tempValue[0], undefined])
				setTempValue([])
			} else if (_.isEmpty(tempValue[0]) && _.isEmpty(v)) {
				setTempValue([])
			}
		}
	}

	useEffect(() => {
		handleCheckBoxClick(tempValue)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tempValue])

	return (
		<>
			<ExpansionPanel
				expanded={isExpanded}
				classes={{ root: classes.expansionPanelRoot, expanded: classes.expanded }}
				onChange={() => setIsExpanded(!isExpanded)}
				elevation={0}
			>
				<ExpansionPanelSummary classes={{ root: classes.root, content: classes.muiPanelSummaryRoot }} expandIcon={<Icon>keyboard_arrow_down</Icon>}>
					<div className={classes.filterPanelTitleContainer}>
						<div className={classes.filterTitleWithChip}>
							<Badge
								badgeContent={_.isArray(tempValue) ? tempValue.length : 0}
								max={9}
								variant='dot'
								color='error'
								classes={{ anchorOriginTopRightRectangle: classes.anchorOriginTopRightRectangle }}
							>
								<Typography className={classes.filterPanelHeading} variant='subtitle2' title={filterDesc}>
									{title}
								</Typography>
							</Badge>
						</div>
						{handleClearThisFilter && _.isArray(tempValue) && tempValue.length > 0 ? (
							<Typography variant='caption' className={classes.resetSpan} component='span' onClick={handleResetClick}>
								RESET
							</Typography>
						) : null}
					</div>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails classes={{ root: classes.root }}>
					<Grid container className={classes.positionRelativeContainer} spacing={1}>
						<Grid item xs={6} className={classes.searchContainer}>
							<TextField
								type='number'
								size='small'
								margin='dense'
								inputProps={{ max: _.get(tempValue, '[1]', '') }}
								variant='outlined'
								value={_.isEmpty(value[0]) ? '' : value[0]}
								label='From'
								onChange={(e) => handleValueChange(e.target.value, 0)}
								onBlur={(e) => handleBlur(e.target.value, 0)}
							/>
						</Grid>
						<Grid item xs={6} className={classes.searchContainer}>
							<TextField
								type='number'
								size='small'
								margin='dense'
								inputProps={{ min: _.get(tempValue, '[0]', '') }}
								variant='outlined'
								value={_.isEmpty(value[1]) ? '' : value[1]}
								label='To'
								onChange={(e) => handleValueChange(e.target.value, 1)}
								onBlur={(e) => handleBlur(e.target.value, 1)}
							/>
						</Grid>
					</Grid>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</>
	)
}

export default NumberRangeFilter
