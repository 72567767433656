import React, { useState } from 'react'
import DashboardModal from 'components/DashboardModal'

const withDashboardModal = (Component) => (props) => {
	const [dialogState, setDialogState] = useState({
		openDbDialog: false,
		openTaskDialog: null,
		isPopup: false,
		dashboardId: null,
		dashboardDetails: {},
		handleClose: null,
		dashStackIdx: 0,
	})

	const { openDbDialog, dashboardId, dashStackIdx, handleClose, openTaskDialog, isPopup, dashboardDetails } = dialogState

	const openDialog = (dashboardId, dashboardDetails, openTaskDialog = null, isPopup = false, handleClose = null, dashStackIdx = 1) => {
		setDialogState({
			openDbDialog: true,
			dashboardId,
			openTaskDialog,
			handleClose,
			isPopup,
			dashboardDetails,
			dashStackIdx,
		})
	}

	const closeDialog = () => {
		setDialogState({
			openDbDialog: false,
			dashboardId: null,
			openTaskDialog: null,
			dashStackIdx: 0,
			isPopup: false,
			dashboardDetails: {},
		})
	}

	return (
		<>
			<Component openDashboard={openDialog} closeDashboard={closeDialog} isDashDialogOpened={openDbDialog} {...props} />
			<DashboardModal
				open={openDbDialog}
				isPopup={isPopup}
				dashboardId={dashboardId}
				dashStackIdx={dashStackIdx}
				dashboardDetails={dashboardDetails}
				handleClose={handleClose || closeDialog}
				openTaskDialog={openTaskDialog}
			/>
		</>
	)
}

export default withDashboardModal
