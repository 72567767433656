import React, { useRef, useState, useEffect, useCallback } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import PropTypes from 'prop-types'
import { Typography, Grid, TextField, FormControlLabel, Collapse, Switch, Icon, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import SelectBox from '../../../../components/SelectBox'
import DashboardsApi from '../../../../services/dashboards/api'
import _ from 'lodash'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	titleContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingRight: theme.spacing(2),
		paddingBottom: theme.spacing(1.5),
	},
	textFieldIcon: {
		paddingRight: theme.spacing(1),
	},
	selectFieldIcon: {
		paddingLeft: '5px',
		paddingRight: theme.spacing(1),
	},
	textField: {
		marginTop: theme.spacing(2),
	},
	selectBox: {
		marginTop: theme.spacing(1),
	},
	title: {
		letterSpacing: '2px',
		flex: '1',
	},
	closeBtn: {
		position: 'absolute',
		top: theme.spacing(1),
		right: theme.spacing(1),
	},
	caption: {
		lineHeight: 'unset',
		marginTop: theme.spacing(1),
	},
}))

const buildDashOptions = (dashboardList) => {
	return (
		_.map(dashboardList, (dashboard) => ({
			id: _.get(dashboard, 'dashboard_id'),
			name: _.get(dashboard, 'dashboard_name'),
		})) || []
	)
}

const TableFilterConfDialog = (props) => {
	const { open, onClose, columnName, colFilterDef, changeChartInfo, cellValue } = props
	const { isFilterDashboard, filterDashboardId, filterColName, filterDashboardTitle, isEnableWebLink } = colFilterDef
	const [dashboardState, setDashboardState] = useState({
		isFetching: true,
		dashboardList: [],
	})
	const [errorState, setErrorState] = useState({
		errFilterDashboardId: false,
		errFilterColName: false,
		errDashboardTitle: false,
	})
	const { isFetching, dashboardList } = dashboardState

	const classes = useStyles()
	const textTimeout = useRef(null)
	const dispatch = useDispatch()

	const loadDashboardList = useCallback(() => {
		setDashboardState({ isFetching: true, dashboardList: [] })
		DashboardsApi.fetchDashboards().then((resp) => {
			setDashboardState({
				isFetching: false,
				dashboardList: buildDashOptions(_.get(resp, 'data.data', [])),
			})
		})
	}, [])

	useEffect(() => {
		if (open) {
			setErrorState({
				errFilterDashboardId: false,
				errFilterColName: false,
				errDashboardTitle: false,
			})
			loadDashboardList()
		}
	}, [open])

	const onChangeSwitch = (e) => {
		changeChartInfo(`settings.tblOptions.clickToFilters.${columnName}.isFilterDashboard`, e.target.checked)
	}

	const onEnableWebSwitch = (e) => {
		changeChartInfo(`settings.tblOptions.clickToFilters.${columnName}.isEnableWebLink`, e.target.checked)
	}

	const selectBoxOnChange = useCallback(
		(value) => {
			changeChartInfo(`settings.tblOptions.clickToFilters.${columnName}.filterDashboardId`, value)
			setErrorState({ ...errorState, errFilterDashboardId: false })
		},
		[columnName, errorState]
	)

	const onTextChange = (e) => {
		const textFieldName = e.target.name
		const textFieldVal = e.target.value
		textTimeout.current && clearTimeout(textTimeout.current)
		textTimeout.current = setTimeout(() => {
			changeChartInfo(textFieldName, textFieldVal)
			setErrorState({
				...errorState,
				[textFieldName.includes('Title') ? 'errDashboardTitle' : 'errFilterColName']: false,
			})
		}, 300)
	}

	const onCloseBeforeValidate = () => {
		const errFilterDashboardId = _.isEmpty(filterDashboardId)
		const errFilterColName = _.isEmpty(_.trim(filterColName))
		const errDashboardTitle = _.isEmpty(_.trim(filterDashboardTitle))
		const isError = isFilterDashboard && (errFilterDashboardId || errFilterColName || errDashboardTitle)
		if (isError) {
			setErrorState({
				errFilterDashboardId,
				errFilterColName,
				errDashboardTitle,
			})
			dispatch(showSnackbarWithTimeout('Please fix the errors (or) uncheck the click to filter option to close the dialog', 3000))
		} else onClose()
	}

	const { errFilterDashboardId, errFilterColName, errDashboardTitle } = errorState

	const isValidUrl = (cellValue) => {
		if (_.isString(cellValue)) {
			var res = cellValue.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)
			if (res == null) return false
			else return true
		}
	}

	return (
		<div>
			<Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={onCloseBeforeValidate} aria-labelledby={`Table Filter Config for ${columnName}`}>
				<DialogContent>
					<div>
						<IconButton size='medium' className={classes.closeBtn} onClick={onCloseBeforeValidate} aria-label='close'>
							<Icon fontSize='small'>close</Icon>
						</IconButton>
						<div className={classes.titleContainer}>
							<Typography className={classes.title} variant='h6'>
								{_.startCase(columnName)}
							</Typography>
						</div>
						<Grid container>
							<Grid sm={12} item>
								<FormControlLabel
									control={<Switch checked={isFilterDashboard} onChange={onChangeSwitch} disabled={isEnableWebLink} />}
									label='Enable click to filter for this column'
								/>
							</Grid>
							<Grid sm={12} item>
								<Collapse in={isFilterDashboard}>
									<Grid sm={12} item>
										<TextField
											name={`settings.tblOptions.clickToFilters.${columnName}.filterDashboardTitle`}
											onChange={onTextChange}
											className={classes.textField}
											defaultValue={filterDashboardTitle}
											InputProps={{
												startAdornment: <Icon className={classes.textFieldIcon}>title</Icon>,
											}}
											helperText={errDashboardTitle && 'Please enter the dashboard title'}
											error={errDashboardTitle}
											variant='outlined'
											fullWidth={true}
											label='Dashboard Title*'
										></TextField>
									</Grid>
									<Grid sm={12} item>
										<SelectBox
											isLoading={isFetching}
											label={'Choose Dashboard To Show*'}
											inputClass={classnames(classes.selectBox)}
											size='medium'
											startAdornment={<Icon className={classes.selectFieldIcon}>dashboard</Icon>}
											//   name={field.name}
											value={filterDashboardId}
											onChange={selectBoxOnChange}
											isError={errFilterDashboardId}
											errTxt={errFilterDashboardId && 'Please choose the dashboard to show'}
											//   errTxt={
											//   form.errors[field.name] &&
											//   form.touched[field.name] &&
											//   String(form.errors[field.name])
											//   }
											options={dashboardList}
										/>
									</Grid>
									<Grid sm={12} item>
										<TextField
											name={`settings.tblOptions.clickToFilters.${columnName}.filterColName`}
											onChange={onTextChange}
											className={classes.textField}
											defaultValue={filterColName}
											InputProps={{
												startAdornment: <Icon className={classes.textFieldIcon}>filter_alt</Icon>,
											}}
											helperText={errFilterColName && 'Please enter the filter column name'}
											error={errFilterColName}
											variant='outlined'
											fullWidth={true}
											label='Filter Column Name*'
										></TextField>
										<Typography component='div' className={classes.caption} variant='caption' gutterBottom>
											&#9888; Note : Filter will apply default as equals condition with selected cell value from the table against specified filter
											column and filter column should matches with one or more available datasources in the dashboard otherwise filter won't work.
										</Typography>
									</Grid>
								</Collapse>
							</Grid>
						</Grid>
						{isValidUrl(cellValue) && (
							<Grid container>
								<Grid sm={12} item>
									<FormControlLabel
										control={<Switch checked={isEnableWebLink} onChange={onEnableWebSwitch} disabled={isFilterDashboard} />}
										label='Enable weblinks'
									/>
								</Grid>
							</Grid>
						)}
					</div>
				</DialogContent>
				<DialogActions></DialogActions>
			</Dialog>
		</div>
	)
}

export default TableFilterConfDialog

TableFilterConfDialog.defaultProps = {
	open: false,
	columnName: '',
	colFilterDef: {
		isFilterDashboard: false,
		filterDashboardId: '',
		filterColName: '',
		isEnableWebLink: false,
	},
}

TableFilterConfDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	columnName: PropTypes.string.isRequired,
	changeChartInfo: PropTypes.func.isRequired,
	colFilterDef: PropTypes.shape({
		isFilterDashboard: PropTypes.bool,
		filterDashboardId: PropTypes.string,
		filterColName: PropTypes.string,
		isEnableWebLink: PropTypes.bool,
	}),
}
