import React from 'react'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	Slide,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import forgotPasswordApi from '../../../services/forgotPassword/api'
import { useDispatch } from 'react-redux'
import { hideLoader, showLoader, showErrorMessage, showSuccessMessage } from 'services/loader/actions'
import { getErrMsg, getSuccessMsg } from 'utils'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
	dialog: {
		minWidth: 400,
		[theme.breakpoints.down('sm')]: {
			minWidth: 'unset',
		},
	},
	tab: {
		textTransform: 'none',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	customTabTitle: {
		fontSize: 14,
		fontWeight: '500',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	dialogTitle: {
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	dialogContent: {
		paddingTop: theme.spacing(2),
	},
	dialogFooter: {
		padding: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
	textField: {
		paddingBottom: theme.spacing(2),
		'& .Mui-disabled': {
			color: theme.palette.almostBlack[700],
		},
	},
	container: {
		textAlign: 'center',
		padding: theme.spacing(2),
	},
	loaderTxt: {
		marginTop: theme.spacing(1.5),
	},
	alertIcon: {
		color: theme.palette.warning.main,
		fontSize: theme.typography.pxToRem(50),
	},
	actionBtn: {
		marginTop: theme.spacing(4),
		marginRight: theme.spacing(2),
	},
	subtitleTxt: {
		marginTop: theme.spacing(1.5),
	},
}))

const passwordSchema = Yup.object().shape({
	email_id: Yup.string().email('Email Address is incorrect').required('Please	enter your Email Address'),
	// tenant_name: Yup.string().trim().required('Please Enter a Tenant Name'),
})

function ForgotPasswordPopup({ open, handleClose, tenantName }) {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
	const dispatch = useDispatch()

	const onSubmit = (e) => {
		dispatch(showLoader('loading please wait...'))
		const onSuccess = (res) => {
			dispatch(
				showSuccessMessage(getSuccessMsg(res), 'Close', (e) => {
					formik?.resetForm()
					handleClose()
					dispatch(hideLoader())
				})
			)
		}

		const onFailure = (err) => {
			dispatch(
				showErrorMessage(getErrMsg(err), 'Close', (e) => {
					dispatch(hideLoader())
				})
			)
		}

		forgotPasswordApi.forgotPassword(e).then(onSuccess, onFailure)
	}

	const formik = useFormik({
		initialValues: {
			email_id: '',
			tenant_name: '',
		},
		onSubmit: onSubmit,
		validationSchema: passwordSchema,
	})

	const onClickCancel = () => {
		handleClose()
		formik?.resetForm()
	}

	return (
		<>
			<Dialog
				classes={{
					paper: classes.dialog,
				}}
				TransitionComponent={Transition}
				fullScreen={isMobile}
				maxWidth={'sm'}
				open={open}
				onClose={onClickCancel}
				aria-labelledby='create-glass'
			>
				<DialogTitle className={classes.dialogTitle} disableTypography={true} id='create-glass-title'>
					<Typography variant='h6'>Forgot Password?</Typography>
					<Typography component='p' variant='caption' className={classes.subTitle}>
						Enter your username and we will send you a mail to reset password
					</Typography>
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					<form onSubmit={formik?.handleSubmit}>
						<TextField
							variant='outlined'
							margin='normal'
							size='small'
							value={formik?.values?.email_id}
							error={Boolean(formik?.errors?.email_id) && formik?.touched?.email_id}
							helperText={Boolean(formik?.errors?.email_id) && formik?.touched?.email_id && formik?.errors?.email_id}
							onChange={(e) => {
								formik?.setFieldValue('email_id', e.target.value)
								tenantName !== '' && tenantName === 'app'
									? formik?.setFieldValue('tenant_name', 'datinfi')
									: formik?.setFieldValue('tenant_name', tenantName)
							}}
							fullWidth
							label='Enter Email Address'
						/>
						{tenantName === '' && (
							<TextField
								variant='outlined'
								margin='normal'
								size='small'
								value={formik?.values?.tenant_name}
								error={Boolean(formik?.errors?.tenant_name) && formik?.touched?.tenant_name}
								helperText={Boolean(formik?.errors?.tenant_name) && formik?.touched?.tenant_name && formik?.errors?.tenant_name}
								onChange={(e) => {
									formik?.setFieldValue('tenant_name', e.target.value)
								}}
								fullWidth
								label='Enter Tenant Name'
							/>
						)}
					</form>
				</DialogContent>
				<DialogActions className={classes.dialogFooter}>
					<Button variant='outlined' size={isMobile ? 'small' : 'medium'} onClick={onClickCancel} color='primary' disableElevation>
						Cancel
					</Button>
					<Button variant='contained' size={isMobile ? 'small' : 'medium'} onClick={formik?.handleSubmit} color='primary' disableElevation>
						Send Link
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default ForgotPasswordPopup
