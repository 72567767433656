import { Icon, makeStyles, Typography, Grid, Divider, Link } from '@material-ui/core'
import GroupedAvatars from 'components/GroupedAvatars'
import InputLabels from 'components/InputLabels'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { Box } from '@material-ui/core'
import SwiperCarousel from './SwiperCarousel'
import DiscoverApi from '../../../../services/discoverx/api'
import { useDispatch } from 'react-redux'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { getErrMsg } from 'utils'
import Axios from 'axios'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
	detailsRoot: {
		padding: theme.spacing(0, 3, 3, 3),
	},
	timeInfo: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		lineHeight: '10px',
		'& span': {
			verticalAlign: 'middle',
			marginRight: theme.spacing(0.3),
		},
	},
	titleText: {
		marginBottom: theme.spacing(1),
	},
	moreInfoWrapper: {
		marginTop: theme.spacing(4),
	},
	moreLessIcon: {
		cursor: 'pointer',
	},
}))

const SliderDetails = ({ data }) => {
	const classes = useStyles()
	const [labelState, setLabelState] = useState({ selectedLbls: data?.labels || [], labelList: [], isLoading: false })
	const cancelExecutor = useRef()
	const dispatch = useDispatch()
	const [showMore, setShowMore] = useState(false)

	useEffect(() => {
		let labelIds = _.map(data?.labels, (label) => label?.label_id)
		setLabelState((prevState) => ({ ...prevState, selectedLbls: labelIds }))
	}, [data])
	const { selectedLbls, labelList, isLoading } = labelState

	const onUpdateLabelList = (newLblList) => {
		setLabelState((prevState) => ({ ...prevState, labelList: newLblList }))
	}

	const handleAddLabel = (values, onSuccess, onError) => {
		const data = {
			label_name: values?.label_name,
			label_color: values?.label_color,
		}
		DiscoverApi.createLbel(data).then(onSuccess, onError)
	}

	const handleUpdateLabel = (values, onSuccess, onError) => {
		const labelId = values?.label_id
		const data = {
			label_name: values?.label_name,
			label_color: values?.label_color,
		}
		DiscoverApi.updateLabel(data, labelId).then(onSuccess, onError)
	}

	const handleDeleteLabel = (labelId, onSuccess, onError) => {
		DiscoverApi.deleteLabel(labelId).then(onSuccess, onError)
	}

	useEffect(() => {
		setLabelState((prevState) => ({ ...prevState, isLoading: true }))
		const onSuccess = (res) => {
			setLabelState((prevState) => ({ ...prevState, isLoading: false, labelList: res?.data?.data?.labels || [] }))
		}
		const onFailure = (err) => {
			setLabelState((prevState) => ({ ...prevState, labelList: [], isLoading: false }))
		}
		DiscoverApi.getAllLabels().then(onSuccess, onFailure)
		//TODO: fetch discoverX label list
	}, [data])

	const delayedLabelUpdate = useCallback(
		_.debounce((q, callback) => {
			callback(q)
		}, 300),
		[]
	)

	const onLabelValueChange = (e) => {
		const prevSelectedLbls = selectedLbls
		const onFailure = (err) => {
			if (!Axios.isCancel(err)) {
				setLabelState((prevState) => ({ ...prevState, selectedLbls: prevSelectedLbls }))
				dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
			}
		}
		setLabelState((prevState) => ({ ...prevState, selectedLbls: e.target.value }))
		delayedLabelUpdate(e.target.value, (value) => {
			const formData = {
				label_ids: value,
			}
			DiscoverApi.onChangeLabel(data?.ds_id, data?.id, formData, cancelExecutor).then(() => {}, onFailure)
		})
	}
	const toggleShowMore = () => {
		setShowMore(!showMore)
	}

	return (
		<div className={classes.detailsRoot}>
			{data?.data_source && (
				<Typography className={classes.datasourceName} color='textSecondary' variant='caption'>
					{data?.data_source}
				</Typography>
			)}
			{data?.title && (
				<Typography variant='h6' component='h6' style={{ wordBreak: 'break-word' }}>
					{data?.title}
				</Typography>
			)}
			{data?.description && (
				<Typography color='textPrimary' component='div' variant='body2' gutterBottom style={{ wordBreak: 'break-word' }}>
					{data?.description.length > 100 && !showMore ? `${data?.description.slice(0, 200)}...` : data?.description}
					{data?.description.length > 100 && (
						<Link onClick={toggleShowMore} className={classes.moreLessIcon}>
							{showMore ? ' less' : ' more'}
						</Link>
					)}
				</Typography>
			)}
			{data?.slider_details && !_.isEmpty(data?.slider_details) && (
				<Typography className={classes.datasourceName} color='textSecondary' variant='caption'>
					Additional Details:
				</Typography>
			)}
			{data?.slider_details &&
				!_.isEmpty(data?.slider_details) &&
				Object.entries(data.slider_details[0]).map(([key, val]) => (
					// <Typography key={key} variant='body2' component='h6'>
					// 	{_.startCase(key)}: {val}
					// </Typography>
					<Typography key={key} variant='body2' component='h6'>
						<span style={{ fontWeight: 'bold' }}>{_.startCase(key)}</span>: {val}
					</Typography>
				))}
			{data?.date && (
				<Typography component='div' className={classes.timeInfo} variant='caption' color='textSecondary' gutterBottom>
					<Icon fontSize='inherit'>query_builder</Icon>
					<span>{moment(_.get(data, 'date')).format('DD MMMM YYYY')}</span>
				</Typography>
			)}
			{!_.isEmpty(data?.img_url) || !_.isEmpty(data?.video_url) ? <SwiperCarousel imgUrl={data?.img_url} videoUrl={data?.video_url} /> : null}
			<Grid container className={classes.moreInfoWrapper}>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item sm={6} xs={12}>
					<Box padding={2}>
						<Typography variant='caption' component='div' color='textSecondary' gutterBottom>
							Connected Users
						</Typography>
						<GroupedAvatars list={data?.sticky_assigned_users} isLarge={true} handleChange={(v) => {}} />
					</Box>
				</Grid>
				<Grid item sm={6} xs={12}>
					<Box padding={2}>
						<Typography variant='caption' component='div' color='textSecondary' gutterBottom>
							Labels
						</Typography>
						<InputLabels
							name='label_ids'
							glassId={''}
							labels={labelList}
							disabled={false}
							onChange={onLabelValueChange}
							onUpdtLblListChanges={onUpdateLabelList}
							handleAddLabel={handleAddLabel}
							handleUpdateLabel={handleUpdateLabel}
							handleDeleteLabel={handleDeleteLabel}
							isCustom={true}
							loading={isLoading}
							value={selectedLbls}
						/>
					</Box>
				</Grid>
			</Grid>
		</div>
	)
}

export default SliderDetails
