import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Typography, TextField, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import Slide from '@material-ui/core/Slide'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import TemplateApi from '../../../../services/emails/api'
import { hideLoader, showErrorMessage, showLoader } from 'services/loader/actions'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { useDispatch } from 'react-redux'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
	dialogTitle: {
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	dialogContent: {
		padding: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			width: 400,
		},
	},
	dialogFooter: {
		padding: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
	textField: {
		marginBottom: '8px',
	},
}))

const validationSchema = Yup.object().shape({
	category_name: Yup.string().trim().required('Please enter Module name'),
})

const CategoryDialog = ({ open, handleClose, newCategoryCreated, setCategories }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

	const handleCloseDialog = () => {
		handleClose()
		formik.resetForm()
	}

	const onSubmitForm = (values) => {
		dispatch(showLoader('Creating Module...'))
		TemplateApi.createCategory(values)
			.then((res) => {
				dispatch(hideLoader())
				handleCloseDialog()
				setCategories((prevState) => ({
					...prevState,
					newCategoryCreated: !newCategoryCreated,
				}))
				dispatch(showSnackbarWithTimeout(_.get(res, 'data.more_info', 'Successfully created module.'), 2000))
			})
			.catch((err) => {
				dispatch(hideLoader())
				dispatch(
					showErrorMessage(_.get(err, 'response.data.more_info', 'Could not create Module. Please try again!'), 'Close', () => {
						dispatch(hideLoader())
					})
				)
			})
	}

	const formik = useFormik({
		initialValues: {
			category_name: '',
		},
		onSubmit: (values) => {
			onSubmitForm(values)
		},
		validationSchema: validationSchema,
	})

	return (
		<form onSubmit={formik.handleSubmit}>
			<Dialog
				classes={{
					paper: classes.dialog,
				}}
				TransitionComponent={Transition}
				maxWidth={'sm'}
				fullWidth={isMobile}
				open={open}
				onClose={handleCloseDialog}
				aria-labelledby='role-form-title'
			>
				<DialogTitle className={classes.dialogTitle} disableTypography={true} id='role-dialog-title'>
					<Typography variant='h6'>Create New Module</Typography>
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					<TextField
						name='category_name'
						label='Module Name'
						placeholder='Enter Module name'
						margin='dense'
						variant='outlined'
						fullWidth
						className={classes.textField}
						value={_.get(formik, 'values.category_name')}
						error={formik.errors.category_name && formik.touched.category_name}
						helperText={formik.errors.category_name && formik.touched.category_name && formik.errors.category_name}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>
				</DialogContent>
				<DialogActions className={classes.dialogFooter}>
					<Button variant='outlined' onClick={handleCloseDialog} color='primary'>
						Cancel
					</Button>
					<Button variant='contained' onClick={formik.handleSubmit} color='primary' disableElevation>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</form>
	)
}

export default CategoryDialog
