import { Divider, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import AgGridCustom from 'components/AgGridCustom'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import {
	withCellRenderState,
	CodeCellRenderer,
	NameCellRenderer,
	AssignCellRenderer,
	StatusCellRenderer,
	ActionCellRenderer,
	PriorityCellRenderer,
	CreateDateCellRenderer,
} from './customCells'
import Progressbar from './Progressbar'
import { STICKY_STATUS_ORDER } from 'constants/index.js'

const useStyles = makeStyles((theme) => ({
	root: {
		'& .ag-cell, .ag-header-cell': {
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
		},
	},
	divider: {
		marginTop: theme.spacing(1),
	},
	avatarContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		width: 34,
	},
	verticalSpace: {
		margin: theme.spacing(4, 0),
	},
}))

const SubTaskList = ({ glassMembers, onClickDeleteMenu, onRowClicked, loading, data = [], totalList }) => {
	const classes = useStyles()
	const token = useSelector((state) => _.get(state, 'session.authToken'))
	const [fetchedData, setFetchedData] = useState([])
	const theme = useTheme()
	const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'))
	useEffect(() => {
		if (_.isArray(data)) {
			setFetchedData(
				_.map(data, (item) => ({
					...item,
					first_name: item?.assigned_to?.first_name,
					status: _.indexOf(STICKY_STATUS_ORDER, item?.sticky_status?.status),
				}))
			)
		} else {
			setFetchedData([])
		}
	}, [data])

	const gridHeaders = useMemo(() => {
		return [
			{
				headerName: 'CODE',
				field: 'sticky_code',
				cellRenderer: 'codeCellRenderer',
				hide: isMobileDevice,
				sortable: true,
				maxWidth: '120',
			},
			{
				headerName: 'TITLE',
				field: 'sticky_title',
				cellRenderer: 'nameCellRenderer',
				maxWidth: '130',
				sortable: true,
			},
			{
				headerName: 'CREATE DATE',
				field: 'created_date',
				cellRenderer: 'createdateCellRenderer',
				maxWidth: '190',
				sortable: true,
			},
			{
				headerName: 'ASSIGNEE',
				field: 'first_name',
				cellRenderer: 'assignCellRenderer',
				maxWidth: '135',
				cellRendererParams: {
					containerClassName: classes.avatarContainer,
					glassMembers,
					token,
				},
				hide: isMobileDevice,
				sortable: true,
			},
			{
				headerName: 'PRIORITY',
				field: 'priority',
				cellRenderer: 'priorityCellRenderer',
				maxWidth: '120',
				hide: isMobileDevice,
				sortable: true,
			},
			{
				headerName: 'STATUS',
				field: 'status',
				cellRenderer: 'statusCellRenderer',
				maxWidth: '150',
				sortable: true,
			},
			{
				headerName: '',
				field: 'action',
				maxWidth: '80',
				cellRenderer: 'actionCellRenderer',
				cellRendererParams: {
					onClickDeleteMenu,
				},
			},
		]
	}, [classes.avatarContainer, glassMembers, isMobileDevice, onClickDeleteMenu, token])

	return (
		<div className={classes.root}>
			<Typography variant='subtitle2' component='p'>
				Subtasks
			</Typography>
			<Divider component='div' className={classes.divider} />
			<div className={classes.verticalSpace}>{!_.isEmpty(data) && <Progressbar progressData={data} totalList={totalList} />}</div>
			<div>
				<AgGridCustom
					columnDefs={gridHeaders}
					rowData={fetchedData}
					loading={loading}
					onRowClicked={onRowClicked}
					frameworkComponents={{
						codeCellRenderer: withCellRenderState(CodeCellRenderer),
						nameCellRenderer: withCellRenderState(NameCellRenderer),
						createdateCellRenderer: withCellRenderState(CreateDateCellRenderer),
						assignCellRenderer: withCellRenderState(AssignCellRenderer),
						statusCellRenderer: withCellRenderState(StatusCellRenderer),
						actionCellRenderer: withCellRenderState(ActionCellRenderer),
						priorityCellRenderer: withCellRenderState(PriorityCellRenderer),
					}}
					disableClickSelectionRenderers={['actionCellRenderer']}
					noDataTxt='No Subtasks Found'
				/>
			</div>
		</div>
	)
}

export default SubTaskList
