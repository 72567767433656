import { Grid, makeStyles, TextField, CircularProgress, Link } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import projectApi from 'services/projects/api'
import Axios from 'axios'
import _ from 'lodash'
import { usePermission } from 'hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import TeamUnitFormDialog from 'scences/UserManagement/TeamUnits/TeamUnitFormDialog'
import update from 'immutability-helper'
import React, { useEffect, useState } from 'react'
import { convertGmtToLocalTime } from 'utils'

const useStyles = makeStyles((theme) => ({
	textField: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
			'& .MuiAutocomplete-endAdornment': {
				display: 'none',
			},
		},
		'& .MuiAutocomplete-root': {
			'& .MuiFormControl-root': {
				'& .Mui-disabled': {
					color: '#ceced2',
				},
			},
		},
	},

	textArea: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
			'& .MuiAutocomplete-endAdornment': {
				display: 'none',
			},
		},
	},
	formContentView: {
		padding: theme.spacing(2, 4, 0),
		'& .MuiInputBase-root.Mui-disabled': {
			marginTop: '5px',
		},
		'& .MuiFormLabel-root': {
			fontWeight: 'bold',
		},
	},
	formContent: {
		padding: theme.spacing(2, 3, 0),
	},
	linkContainer: {
		textAlign: 'right',
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(1),
		},
	},
	link: {
		textAlign: 'right',
		cursor: 'pointer',
	},
}))
const TeamSelect = ({ onChangeSelectBox, error, touched, handleBlur, values, disabled, tuCode, teamUnit, isLoading }) => {
	return (
		<Autocomplete
			name='tu_code'
			value={tuCode ? tuCode : null}
			noOptionsText='No Teams Found'
			onChange={onChangeSelectBox}
			getOptionLabel={(option) => (option.tu_name ? option.tu_name : '')}
			getOptionSelected={(option, value) => option?.tu_code === value?.tu_code}
			options={teamUnit}
			disabled={Boolean(disabled)}
			filterSelectedOptions
			renderInput={(params) => (
				<TextField
					{...params}
					size='small'
					variant='outlined'
					label='Team Unit*'
					onBlur={handleBlur}
					name='tu_code'
					error={error.tu_code && touched.tu_code}
					helperText={error.tu_code && touched.tu_code && error.tu_code}
					InputProps={{
						...params.InputProps,
						disabled: isLoading,
						endAdornment: (
							<React.Fragment>
								{isLoading ? <CircularProgress color='inherit' size={10} /> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
					fullWidth
				/>
			)}
		/>
	)
}
const ProjectForm = (props) => {
	const classes = useStyles()
	const { values, errors, touched, handleBlur, resetForm, setFieldValue, resetErr, handleChange, isViewMode, isEditMode } = props
	const teamPerms = usePermission(ROLE_MODULES.TEAM_MANAGEMENT)
	const isTeamCreateAllowed = React.useMemo(() => _.get(teamPerms, PERMISSION_TYPE.CREATE, false), [teamPerms])
	const [openTeamUnit, setOpenTeamUnit] = useState(false)
	const [teamUnit, setTeamUnit] = useState([])
	const [isLoading, setLoading] = useState(false)

	const getTeamUnit = () => {
		setLoading(true)
		projectApi.fetchTeamUnit(
			(res) => {
				setLoading(false)
				setTeamUnit(_.get(res, 'data.data.team_units', []))
			},
			(err) => {
				if (!Axios.isCancel(err)) setLoading(false)
			}
		)
	}

	useEffect(() => {
		getTeamUnit()
		return () => {
			setTeamUnit([])
		}
	}, [])

	const [tuCode, setTuCode] = useState(null)

	useEffect(() => {
		let selectedTuCode = !_.isEmpty(values?.tu_code)
			? _.find(teamUnit, (obj) => {
					return _.isEqual(obj.tu_code, values.tu_code)
			  })
			: null
		setTuCode(selectedTuCode || null)
	}, [teamUnit, values])

	const onChangeSelectBox = (e, value) => {
		if (!_.isEmpty(value)) setFieldValue('tu_code', value?.tu_code)
		else setFieldValue('tu_code', null)
	}
	useEffect(() => {
		if (resetErr) {
			resetForm()
		}
	}, [resetErr, resetForm])

	const toggleTeamUnit = () => {
		setOpenTeamUnit(true)
	}

	const closeTeamUnitUpdate = (e, isRefreshList, pageMode, teamUnitObj) => {
		const tempobj = {
			parent_tu_code: teamUnitObj?.parent_tu_code,
			tu_code: teamUnitObj?.tu_code,
			tu_name: teamUnitObj?.tu_name,
		}
		if (teamUnitObj?.tu_code) {
			setTeamUnit((prevState) => update(prevState, { $push: [tempobj] }))
			setTuCode(teamUnitObj?.tu_code || null)
		}
		setOpenTeamUnit(false)
	}
	return (
		<>
			<TeamUnitFormDialog open={openTeamUnit} handleClose={closeTeamUnitUpdate} action={PERMISSION_TYPE.CREATE} addIntitialUser={true} />
			<div className={isViewMode ? classes.formContentView : classes.formContent}>
				<Grid container>
					{isViewMode ? (
						<Grid item lg={4}>
							<TextField
								fullWidth
								size='small'
								type='text'
								margin='dense'
								value={values.project_code}
								disabled={true}
								label='Project Code'
								variant='outlined'
								name='project_code'
								className={classes.textField}
							/>
						</Grid>
					) : (
						<></>
					)}
					<Grid item xs={12} lg={isViewMode ? 4 : 12}>
						<TextField
							fullWidth
							size='small'
							type='text'
							margin='dense'
							onBlur={handleBlur}
							onChange={(e) => {
								handleChange(e)
								e.target.value === ' ' && setFieldValue('project_name', '')
							}}
							value={values.project_name}
							autoComplete='off'
							disabled={isViewMode}
							label={isViewMode ? 'Project Name' : 'Project Name*'}
							variant='outlined'
							error={errors.project_name && touched.project_name}
							helperText={errors.project_name && touched.project_name && errors.project_name}
							name='project_name'
							className={classes.textField}
							inputProps={{
								maxLength: 64,
							}}
						/>
					</Grid>
					{isViewMode ? (
						<Grid item lg={4}>
							<TextField
								fullWidth
								size='small'
								type='text'
								value={values.tu_name}
								disabled={true}
								label='Team Unit'
								variant='outlined'
								margin='dense'
								name='tu_name'
								className={classes.textField}
							/>
						</Grid>
					) : (
						<Grid item className={classes.textField} xs={12}>
							{isTeamCreateAllowed ? (
								<Grid container alignItems='center' justifyContent='space-between'>
									<Grid item lg={9} xs={12}>
										<TeamSelect
											onChangeSelectBox={onChangeSelectBox}
											values={values}
											error={errors}
											touched={touched}
											handleBlur={handleBlur}
											disabled={isEditMode}
											isLoading={isLoading}
											tuCode={tuCode}
											teamUnit={teamUnit}
										/>
									</Grid>
									<Grid item lg={3} xs={12} className={classes.linkContainer}>
										<Link className={classes.link} onClick={toggleTeamUnit}>
											+ New Team Unit
										</Link>
									</Grid>
								</Grid>
							) : (
								<TeamSelect
									onChangeSelectBox={onChangeSelectBox}
									values={values}
									error={errors}
									touched={touched}
									handleBlur={handleBlur}
									disabled={isEditMode}
									isLoading={isLoading}
									tuCode={tuCode}
									teamUnit={teamUnit}
								/>
							)}
						</Grid>
					)}
					<Grid item xs={12} lg={isViewMode ? 12 : 12}>
						<TextField
							fullWidth
							size='small'
							type='text'
							onChange={(e) => {
								handleChange(e)
								e.target.value === ' ' && setFieldValue('project_desc', '')
							}}
							value={values.project_desc}
							disabled={isViewMode}
							onBlur={handleBlur}
							label={isViewMode ? 'Project Objectives and Key Results' : 'Project Objectives and Key Results*'}
							variant='outlined'
							margin='dense'
							error={errors.project_desc && touched.project_desc}
							helperText={errors.project_desc && touched.project_desc && errors.project_desc}
							name='project_desc'
							multiline
							rows={isViewMode ? 0 : 4}
							className={classes.textField}
							inputProps={{
								maxLength: 255,
							}}
						/>
					</Grid>
					{isViewMode && (
						<>
							<Grid item xs={12} lg={6}>
								<TextField
									fullWidth
									size='small'
									type='text'
									value={values.created_by.user_name}
									disabled={true}
									label='Created By'
									variant='outlined'
									margin='dense'
									name='created_By'
									className={classes.textField}
								/>
							</Grid>
							<Grid item xs={12} lg={6}>
								<TextField
									fullWidth
									type='text'
									size='small'
									value={convertGmtToLocalTime(values.created_date, 'DD MMM YYYY hh:mm a')}
									disabled={true}
									label='Created Date'
									variant='outlined'
									margin='dense'
									name='created_date'
									className={classes.textField}
								/>
							</Grid>
							<Grid item xs={12} lg={6}>
								<TextField
									fullWidth
									type='text'
									size='small'
									value={values.modified_by.user_name}
									disabled={true}
									label='Modified By'
									variant='outlined'
									margin='dense'
									name='modified_by'
									className={classes.textField}
								/>
							</Grid>
							<Grid item xs={12} lg={6}>
								<TextField
									fullWidth
									type='text'
									size='small'
									value={convertGmtToLocalTime(values.modified_date, 'DD MMM YYYY hh:mm a')}
									disabled={true}
									label='Modified Date'
									variant='outlined'
									margin='dense'
									name='modified_date'
									className={classes.textField}
								/>
							</Grid>
						</>
					)}
				</Grid>
			</div>
		</>
	)
}

export default ProjectForm
