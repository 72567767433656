import { ACTION_TYPES } from '../../../constants'
import _ from 'lodash'

const initialState = {
	styleCode: {
		isLoading: false,
		isAllLoaded: false,
		suggestions: [],
	},
	retailer: {
		isLoading: false,
		isAllLoaded: false,
		suggestions: [],
	},
	product: {
		isLoading: false,
		isAllLoaded: false,
		suggestions: [],
	},
	reseller: {
		isLoading: false,
		isAllLoaded: false,
		suggestions: [],
	},
}

const searchSuggestions = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.GET_STYLE_CODE_SUGGESTIONS_PENDING: {
			return {
				...state,
				styleCode: {
					isLoading: true,
					suggestions: [...state.styleCode.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_STYLE_CODE_SUGGESTIONS_FULFILLED: {
			return {
				...state,
				styleCode: {
					isLoading: false,
					suggestions: _.uniqBy([...state.styleCode.suggestions, ...action.payload.search_result], _.get(action, 'key', 'objectID')),
					isAllLoaded:
						state.styleCode.suggestions.length === action.payload.total_count || action.payload.search_result.length === action.payload.total_count,
				},
			}
		}

		case ACTION_TYPES.GET_STYLE_CODE_SUGGESTIONS_REJECTED: {
			return {
				...state,
				styleCode: {
					isLoading: false,
					suggestions: [...state.styleCode.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_RETAILER_SUGGESTIONS_PENDING: {
			return {
				...state,
				retailer: {
					isLoading: true,
					suggestions: [...state.retailer.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_RETAILER_SUGGESTIONS_FULFILLED: {
			return {
				...state,
				retailer: {
					isLoading: false,
					isAllLoaded: state.retailer.suggestions.length === action.payload.total_count || action.payload.length === action.payload.total_count,
					suggestions: [...state.retailer.suggestions, ...action.payload.items],
				},
			}
		}

		case ACTION_TYPES.GET_RETAILER_SUGGESTIONS_REJECTED: {
			return {
				...state,
				retailer: {
					isLoading: false,
					suggestions: [...state.retailer.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_PRODUCT_SUGGESSIONS_PENDING: {
			return {
				...state,
				product: {
					isLoading: true,
					suggestions: [...state.product.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_PRODUCT_SUGGESSIONS_FULFILLED: {
			return {
				...state,
				product: {
					isLoading: false,
					isAllLoaded: state.retailer.suggestions.length === action.payload.total_count || action.payload.length === action.payload.total_count,
					suggestions: [...state.product.suggestions, ...action.payload.items],
				},
			}
		}

		case ACTION_TYPES.GET_PRODUCT_SUGGESSIONS_REJECTED: {
			return {
				...state,
				product: {
					isLoading: false,
					suggestions: [...state.product.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_RESELLER_SUGGESSIONS_PENDING: {
			return {
				...state,
				reseller: {
					isLoading: true,
					suggestions: [...state.product.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_RESELLER_SUGGESSIONS_FULFILLED: {
			return {
				...state,
				reseller: {
					isLoading: false,
					isAllLoaded: state.retailer.suggestions.length === action.payload.total_count || action.payload.length === action.payload.total_count,
					suggestions: [...state.reseller.suggestions, ...action.payload.items],
				},
			}
		}

		case ACTION_TYPES.GET_RESELLER_SUGGESSIONS_REJECTED: {
			return {
				...state,
				reseller: {
					isLoading: false,
					suggestions: [...state.reseller.suggestions],
				},
			}
		}

		case ACTION_TYPES.CLEAR_SUGGESTIONS: {
			return { ...initialState }
		}

		default: {
			return state
		}
	}
}

export default searchSuggestions
