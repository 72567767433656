import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	filter: (filter_param) => {
		return Axios.post(API_ENDPOINTS.PROJECT_FILTERS, filter_param, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
	updateFilter: (filter_obj) => {
		let data = { filter_obj }
		return Axios.post(API_ENDPOINTS.PROJECT_FILTER_UPDATE, data, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
}
