import React from 'react'
import { Formik } from 'formik'
import _ from 'lodash'
import { DATA_CONNECTION_TYPES } from '../../../../constants'

const Validator = React.forwardRef((props, ref) => {
	const initialValues = _.isEmpty(props.initialFormData)
		? {
				db_name: '',
				dc_type: DATA_CONNECTION_TYPES.HIVE,
				dc_description: '',
				dc_config: {
					authenticationType: 'STORAGE',
					schedule_time: '',
					hostname: '',
					port: '',
					enableSasl: true,
					kerberosPrincipal: '',
					propertyList: [],
				},
		  }
		: props.initialFormData

	return (
		<Formik
			innerRef={ref}
			initialValues={initialValues}
			validate={(values) => {
				console.log(values)
				let errors = {}

				const dbName = _.get(values, 'db_name')
				if (_.isEmpty(dbName) || _.isEmpty(_.trim(dbName))) {
					_.set(errors, 'db_name', 'Enter a database name')
				}

				const host = _.get(values, 'dc_config.hostname')
				if (_.isEmpty(host) || _.isEmpty(_.trim(host))) {
					_.set(errors, 'dc_config.hostname', 'Enter a host')
				}

				const port = _.get(values, 'dc_config.port')
				if (_.isEmpty(port) || _.isEmpty(_.trim(port))) {
					_.set(errors, 'dc_config.port', 'Enter a port')
				} else {
					if (!/^\d+$/.test(port)) _.set(errors, 'dc_config.port', 'Invalid Port (Numbers only)')
				}

				var numbers = /^[0-9]+$/
				const scheduledTime = _.get(values, 'dc_config.schedule_time')
				if (_.isEmpty(scheduledTime)) {
					_.set(errors, 'dc_config.schedule_time', 'Please specify scheduled time')
				} else if (!scheduledTime.match(numbers)) {
					_.set(errors, 'dc_config.schedule_time', 'Please Enter valid time')
				}

				const authType = _.get(values, 'dc_config.enableSasl')

				if (authType === true) {
					const kerberosprincipal = _.get(values, 'dc_config.kerberosPrincipal')
					if (_.isEmpty(kerberosprincipal) || _.isEmpty(_.trim(kerberosprincipal))) {
						_.set(errors, 'dc_config.kerberosPrincipal', 'Enter a kerberos principal')
					}
				}

				return errors
			}}
			onSubmit={props.onSubmit || null}
		>
			{props.children}
		</Formik>
	)
})

export default Validator
