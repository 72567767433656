import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { Grid, makeStyles, TextField, Typography, IconButton, InputAdornment } from '@material-ui/core'
import { CirclePicker } from 'react-color'
import * as Yup from 'yup'
import BackupIcon from '@material-ui/icons/Backup'
import classNames from 'classnames'
import File from 'components/File'
import { useDispatch } from 'react-redux'
import { hideAdvaceSnackbar, showAdvanceSnackbar } from 'services/snackbarAdvance/actions'
import _ from 'lodash'
import { Field } from 'formik'
import Validator from './validator'

const LabelSchema = Yup.object().shape({
	// label_name: Yup.string()
	// 	//   .min(2, 'Too Short!')
	// 	//   .max(50, 'Too Long!')
	// 	.required('Please enter the label name'),
	// label_color: Yup.string().required('Please choose the label color'),
})

const useStyles = makeStyles((theme) => ({
	colorRoot: {
		justifyContent: 'center',
		marginBottom: '0!important',
		gap: '10px',
	},
	fieldset: {
		top: '-5px',
		overflow: 'hidden',
		borderStyle: 'solid',
		borderColor: 'rgba(0, 0, 0, 0.23)',
		padding: theme.spacing(1, 1, 2),
		borderWidth: '1px',
		borderRadius: '4px',
		'&.error': {
			borderColor: theme.palette.error.main,
			'& span': {
				color: theme.palette.error.main,
			},
		},
	},
	errTxt: {
		marginLeft: '14px',
		marginRight: '14px',
		marginTop: '4px',
		color: theme.palette.error.main,
		display: 'inline-block',
	},
    fileInput: {
		display: 'none',
	},
	profileURL: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
		'& .MuiInputBase-input': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		marginTop: '10px',
	},
}))

const StickyGroupForm = React.forwardRef(({ onSubmit, initialValues, action }, ref) => {
	const classes = useStyles()
    const reduxDispatch = useDispatch()
	// const formRef = useRef()
    const hiddenFileInput = React.useRef(null)

	// const formik = useFormik({
	// 	initialValues: initialValues || {
	// 		sticky_group_name: '',
	// 		sticky_group_url: [],
	// 		sticky_group_id: '',
	// 	},
	// 	onSubmit: onSubmit,
	// 	validationSchema: LabelSchema,
	// })

	// useEffect(() => {
	// 	ref.current = formik
	// 	return () => {
	// 		ref.current = null
	// 	}
	// }, [ref, formik])

    const handleClick = (event) => {
		hiddenFileInput.current.click()
	}

    const handleChange = (e, formik) => {
		let imgArray = Array.from(e.target.files)
		let result = _.find(imgArray, function (file) {
			if (_.get(file, 'size', 0) >= 1073741824) return true
		})
		let isFileExceeded = result ? true : false
		if (isFileExceeded) {
			reduxDispatch(
				showAdvanceSnackbar({
					msg: 'File size is too large. maximum file size is 1 gb.',
					severity: 'error',
				})
			)
			setTimeout(() => {
				reduxDispatch(hideAdvaceSnackbar())
			}, 2000)
			e.target.value = ''
		} else {
            formik.setFieldValue("sticky_group_url", [e.target.files[0]])
			// setFileUploaded(e.target.files[0])
			e.target.value = ''
		}
	}

	return (
		<Validator 
		formMode={action} 
		initialFormData={initialValues} 
		onSubmit={onSubmit} 
		ref={ref}>
					{(formik) => {
						return (
							<>
		{/* <form onSubmit={formik.handleSubmit}> */}
			<Grid container spacing={1}>
				<Grid item xs={12}>
				<Field type='text' name='sticky_group'>
						{({ field, form }) => (
							<TextField
								name={field.name}
								value={field.value || ''}
								helperText={form.touched[field.name] && form.errors[field.name]}
								error={Boolean(form.errors[field.name] && form.touched[field.name])}
								onChange={field.onChange}
								label='Task Group Name*'
								// id='sticky_group_name'
								variant='outlined'
								size='small'
								fullWidth
							/>
							)}
				</Field>
				</Grid>
				<Grid item xs={12}>
                    <Field type='text' name='sticky_group_url'>
						{({ field, form }) => (
							<File
								// disabled={isViewMode}
								value={form.values.sticky_group_url}
								label='Active'
								field={field}
								form={form}
								acceptsExts={['jpg', 'png', 'jpeg']}
								resolution={[240, 240]}
								placeholder={'upload icon'}
								imgRatioClass={classes.profilePic}
								maxHeight='200px'
							/>
						)}
					</Field>
				</Grid>
			</Grid>
		 {/* </form> */}
		</>
						)
					}}
				</Validator>
	)
})


export default StickyGroupForm
