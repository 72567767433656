import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Grid, TextField, Typography, Switch, FormControlLabel } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from 'formik'
// import { REASON_TO_SKIP } from '../../../../../../constants'
import classnames from 'classnames'
import SelectBox from '../../../../../../components/SelectBox'
import MultiSelect from '../../../../../../components/MultiSelect'

const useStyles = makeStyles((theme) => ({
	profilePic: {
		width: 170,
		height: 170,
		[theme.breakpoints.down('xs')]: {
			width: 140,
			height: 140,
			margin: '0 auto',
		},
	},
	flexRootResponsive: {
		display: 'flex',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
	},
	basicHeaderRight: {
		flex: 1,
		// paddingLeft: theme.spacing(2),
		marginTop: theme.spacing(0.8),
		[theme.breakpoints.down('xs')]: {
			flex: 'unset',
			paddingLeft: 0,
		},
	},
	basicHeaderLeft: {
		maxWidth: 170,
		[theme.breakpoints.down('xs')]: {
			flex: 1,
			maxWidth: '100%',
			paddingBottom: theme.spacing(2),
			textAlign: 'center',
		},
	},
	textField: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
	},
	parallelInputRoot: {
		width: 'calc(50% - 8px)',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	parallelInputEnd: {
		marginLeft: 16,
		[theme.breakpoints.down('xs')]: {
			marginLeft: 0,
		},
	},
	basicHeader: {
		marginBottom: theme.spacing(0.5),
		[theme.breakpoints.down('xs')]: {
			marginBottom: 0,
		},
	},
	pwdTitle: {
		textTransform: 'none',
	},
	statusIndicator: {
		marginLeft: theme.spacing(2),
		borderRadius: '50%',
		width: '10px',
		height: '10px',
		display: 'inline-block',
		marginRight: theme.spacing(1),
	},
}))

const BasicDetails = ({ isEditMode, isCreateMode, isViewMode, formik, streams }) => {
	const classes = useStyles()

	const [searchkey, setSearchkey] = useState('')
	const searchBoxTimeout = useRef(undefined)

	const showFieldValue = (value) => {
		return isViewMode ? (_.isEmpty(_.trim(value)) ? '-' : value) : value
	}

	const marketPlace = [
		{
			id: 'Rohit',
			name: 'Rohit - Development :: 5.5* :: $90',
			label: 'Rohit',
			img: 'https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png',
			rating: '5.5',
			budget: '$90',
			skill: 'Development',
			location: 'US',
		},
		{
			id: 'Virat',
			name: 'Virat - Development :: 5.5* :: $90',
			label: 'Virat',
			img: 'https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png',
			rating: '5.5',
			budget: '$90',
			skill: 'Development',
			location: 'US',
		},
		{
			id: 'SKY',
			name: 'SKY - Development :: 5.5* :: $90',
			label: 'SKY',
			img: 'https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png',
			rating: '5.5',
			budget: '$90',
			skill: 'Development',
			location: 'US',
		},
		{
			id: 'Ishan',
			name: 'Ishan - Development :: 5.5* :: $90',
			label: 'Ishan',
			img: 'https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png',
			rating: '5.5',
			budget: '$90',
			skill: 'Development',
			location: 'US',
		},
	]

	const onInputSRChange = (e) => {
		if (e !== null) {
			if (e.target.value !== 0) {
				if (searchBoxTimeout.current) clearTimeout(searchBoxTimeout.current)
				searchBoxTimeout.current = setTimeout(() => {
					// let product_id = e.target.value
					// setProdSearch(e.target.value)
					setSearchkey(e.target.value)
				}, 1500)
			}
		}
	}

	return (
		<Grid container>
			<Grid item xs={12} className={classnames(classes.flexRootResponsive, classes.basicHeader)}>
				<div className={classes.basicHeaderRight}>
					<Field type='text' name='stream'>
						{({ field, form }) => (
							// <SelectBox
							// 	label='Datastream'
							// 	value={field.value || ''}
							// 	onChange={(e) => {
							// 		e === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e)
							// 	}}
							// 	onInputChange={(e) => onInputSRChange(e)}
							// 	isLoading={false}
							// 	inputClass={classes.textField}
							// 	name={field.name}
							// 	onBlur={field.onBlur}
							// 	// disabled={disabled}
							// 	helperText={form.touched[field.name] && form.errors[field.name]}
							// 	isError={Boolean(form.errors[field.name] && form.touched[field.name])}
							// 	options={marketPlace}
							// ></SelectBox>
							<MultiSelect
								items={marketPlace || []}
								// getOptionDisabled={getOptionDisabled}
								label='People'
								// placeholder='Placeholder for textbox'
								name='brand'
								selectAllLabel=''
								onChange={(e) => {
									e === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e)
								}}
								value={streams || field.value}
								// onBlur={handleBlur}
								// error={errors?.brand && touched.brand}
								// helperText={errors?.brand && touched.brand && errors?.brand}
								// disabled={disabled}
								// onChange={(e) => {
								// 	e === []
								// 		? form.setFieldValue(field.name, '')
								// 		: form.setFieldValue(field.name, _.toString(_.map(e, (list) => list.id)))
								// }}
								inputClass={classes.selectField}
								onBlur={field.onBlur}
								// disabled={disabled}
								helperText={form.touched[field.name] && form.errors[field.name]}
								error={Boolean(form.errors[field.name] && form.touched[field.name])}
							/>
						)}
					</Field>
				</div>
			</Grid>
		</Grid>
	)
}

BasicDetails.propTypes = {
	isCreateMode: PropTypes.bool.isRequired,
	isEditMode: PropTypes.bool.isRequired,
	isViewMode: PropTypes.bool.isRequired,
	formik: PropTypes.object.isRequired,
}

export default BasicDetails
