/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { Checkbox } from '@material-ui/core'
import _ from 'lodash'

const MultiSelect = ({
	items,
	label,
	value,
	name,
	disabled,
	placeholder,
	onBlur,
	helperText,
	error,
	selectAllLabel,
	noOptionsText,
	limitTags,
	onChange,
	disableClearable,
	onInputChange = () => null,
	isLoading = false,
}) => {
	const [selectedOptions, setSelectedOptions] = useState(value || [])
	const allSelected = items?.length === selectedOptions?.length
	const handleToggleOption = (selectedOptions) => setSelectedOptions(selectedOptions)
	const handleClearOptions = () => setSelectedOptions([])
	const getOptionLabel = (option) => `${option.name}`
	const handleSelectAll = (isSelected) => {
		if (isSelected) {
			setSelectedOptions(items)
		} else {
			handleClearOptions()
		}
	}

	useEffect(() => {
		setSelectedOptions(value)
	}, [value])

	const handleToggleSelectAll = () => {
		handleSelectAll && handleSelectAll(!allSelected)
	}

	const handleChange = (event, selectedOptions, reason) => {
		if (reason === 'select-option' || reason === 'remove-option') {
			if (selectedOptions.find((option) => option.id === 'all')) {
				handleToggleSelectAll()
				let result = []
				result = items.filter((el) => el.value !== 'all')
				return onChange(result)
			} else {
				handleToggleOption && handleToggleOption(selectedOptions)
				return onChange(selectedOptions)
			}
		} else if (reason === 'clear') {
			handleClearOptions && handleClearOptions()
			let result = []
			// result = items.filter((el) => el.value !== 'all')
			return onChange(result)
		}
	}
	const optionRenderer = (option, { selected }) => {
		const selectAllProps =
			option.id === 'all' // To control the state of 'select-all' checkbox
				? { checked: allSelected }
				: {}
		return (
			<>
				<Checkbox
					color='primary'
					icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
					checkedIcon={<CheckBoxIcon fontSize='small' />}
					style={{ marginRight: 8 }}
					checked={selected}
					{...selectAllProps}
				/>
				{getOptionLabel(option)}
			</>
		)
	}
	const inputRenderer = (params) => (
		<TextField
			{...params}
			name={name}
			helperText={helperText}
			error={error}
			variant='outlined'
			label={label}
			InputProps={{
				...params.InputProps,
				disabled: isLoading || disabled,
				readOnly: disabled,
				endAdornment: (
					<React.Fragment>
						{isLoading ? <CircularProgress color='inherit' size={20} /> : null}
						{params.InputProps.endAdornment}
					</React.Fragment>
				),
			}}
		/>
	)
	const getOptionSelected = (option, anotherOption) => option.id === anotherOption.id
	const filter = createFilterOptions()
	return (
		<Autocomplete
			multiple
			size='small'
			name={name}
			loading={isLoading}
			limitTags={limitTags}
			options={items}
			onBlur={onBlur}
			placeholder={placeholder}
			value={selectedOptions || []}
			disabled={isLoading || disabled}
			disableClearable={disableClearable}
			disableCloseOnSelect
			getOptionLabel={getOptionLabel}
			getOptionSelected={getOptionSelected}
			noOptionsText={noOptionsText}
			filterOptions={(options, params) => {
				const filtered = filter(options, params)
				return !_.isEmpty(selectAllLabel) ? [{ name: selectAllLabel, id: 'all' }, ...filtered] : [...filtered]
			}}
			onChange={handleChange}
			onInputChange={(e) => {
				onInputChange(e)
			}}
			renderOption={optionRenderer}
			renderInput={inputRenderer}
		/>
	)
}

MultiSelect.defaultProps = {
	limitTags: 3,
	items: [],
	selectedValues: [],
	getOptionLabel: (value) => value,
}

export default MultiSelect
