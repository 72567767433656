import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import { Box, Fade, Icon, IconButton, Typography } from '@material-ui/core'
import SwiperCarousel from './SwiperCarousel'
import { getFileFormatIconAndColor } from 'utils'
import _ from 'lodash'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Fade ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiBackdrop-root, & .MuiPaper-root': {
			backgroundColor: '#202124',
		},
	},
	header: {
		background: '#000',
		color: theme.palette.common.white,
		position: 'relative',
	},
	closeBtn: {
		position: 'absolute',
		right: 6,
		top: 6,
		background: '#312f2e',
		padding: '2px',
		marginLeft: '16px',
		textTransform: 'initial',
		color: theme.palette.common.white,
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		flex: '1',
	},
	fileIcon: {
		display: 'inline-block',
		verticalAlign: 'middle',
		marginRight: '4px',
	},
	fileName: {
		display: 'inline-block',
		verticalAlign: 'middle',
		[theme.breakpoints.down('sm')]: {
			width: '80%',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
	headerContent: {
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
		},
	},
}))

function FilePreviewDialog(props) {
	const classes = useStyles()
	const { onClose, open, fileList, accessToken, openFileIdx } = props
	const [activeFileIdx, setActiveFileIdx] = useState(0)

	useEffect(() => {
		if (open) {
			setActiveFileIdx(fileList.length > openFileIdx ? openFileIdx : 0)
		}
	}, [fileList, openFileIdx, open])

	const handleClose = () => {
		onClose()
	}

	const activeFile = useMemo(() => fileList[activeFileIdx], [fileList, activeFileIdx])
	const fileAvatarDetails = useMemo(() => {
		let fileExt = _.split(activeFile?.file_name, '.').pop()
		return getFileFormatIconAndColor(fileExt)
	}, [activeFile])

	return (
		<Dialog
			className={classes.root}
			fullScreen
			TransitionComponent={Transition}
			transitionDuration={{ enter: 1000, exit: 500 }}
			onClose={handleClose}
			aria-labelledby='simple-dialog-title'
			open={open}
			classes={{ paper: classes.paper }}
		>
			<Box className={classes.header} padding={1}>
				<Typography className={classes.headerContent} variant='body2' align='center'>
					<Icon fontSize='small' style={{ color: fileAvatarDetails?.background }} className={classes.fileIcon}>
						{fileAvatarDetails?.fileIcon}
					</Icon>
					<span className={classes.fileName}>{activeFile?.file_name}</span>
				</Typography>
				<IconButton size='small' onClick={handleClose} className={classes.closeBtn}>
					<Icon fontSize='small'>close</Icon>
				</IconButton>
			</Box>
			<Box className={classes.content}>
				<SwiperCarousel activeIdx={activeFileIdx} setActiveIdx={setActiveFileIdx} fileList={fileList} accessToken={accessToken} />
			</Box>
		</Dialog>
	)
}

FilePreviewDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	fileList: PropTypes.array.isRequired,
	accessToken: PropTypes.string.isRequired,
}

export default FilePreviewDialog
