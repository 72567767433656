import { ACTION_TYPES, SAGA_ACTIONS, FILTER_CONSTANTS } from '../../../constants'
import _ from 'lodash'

// old ds_id = 5ea932ef2e2241589d0b66d1

export const getProjectSuggestions = (filterKey, offset = 0, keyword = '', dataSourceId) => {
	const productFilters = [
		FILTER_CONSTANTS.CREATED_DATE,
		FILTER_CONSTANTS.MODIFIED_DATE,
		FILTER_CONSTANTS.CREATED_BY,
		FILTER_CONSTANTS.MODIFIED_BY,
		FILTER_CONSTANTS.START_DATE,
		FILTER_CONSTANTS.END_DATE,
		FILTER_CONSTANTS.STATUS,
		FILTER_CONSTANTS.RATING,
		FILTER_CONSTANTS.LABELS,
	]
	if (_.includes(productFilters, filterKey)) {
		return {
			isProduct: true,
			type: SAGA_ACTIONS.GET_PROJECT_SUGGESTIONS,
			filterKey: filterKey,
			query: {
				column_name: filterKey,
				value: keyword,
				offset: offset,
				limit: 10,
				// ds_id: '6290d1cda24e9fc352ac6c1b',
			},
		}
	} else {
		return {
			type: SAGA_ACTIONS.GET_PROJECT_SUGGESTIONS,
			filterKey: filterKey,
			query: {
				column_name: filterKey,
				column_value: keyword,
				offset: offset,
				limit: 10,
			},
		}
	}
}

// export const updateJobStatusFilterData = (jobStatusList) => {
// 	return {
// 		type: ACTION_TYPES.UPDATE_JOB_FILTER_DATA,
// 		data: jobStatusList,
// 	}
// }

// export const getStyleCodeSuggestions = (offset, keyValue, productType = 'old') => ({
// 	type: SAGA_ACTIONS.GET_STYLE_CODE_SUGGESTIONS,
// 	payload: {
// 		search_key: productType === 'old' ? 'objectID' : 'stylecode',
// 		search_value: keyValue ? keyValue : '',
// 		offset: offset,
// 		limit: 10,
// 		product_type: productType,
// 	},
// })

// export const getRetailerSuggestions = (offset, keyValue, limit) => ({
// 	type: SAGA_ACTIONS.GET_RETAILER_SUGGESTIONS,
// 	payload: {
// 		column_name: 'source',
// 		value: keyValue ? keyValue : '',
// 		offset: offset,
// 		limit: limit,
// 		ds_id: '5ea932ef2e2241589d0b66d1',
// 	},
// })

// export const getProductSuggessions = (offset, keyValue, limit) => ({
// 	type: SAGA_ACTIONS.GET_PRODUCT_SUGGESSIONS,
// 	payload: {
// 		column_name: 'product_name',
// 		value: keyValue ? keyValue : '',
// 		offset: offset,
// 		limit: limit,
// 		ds_id: '5ea932ef2e2241589d0b66d1',
// 	},
// })

// export const getResellerSuggessions = (offset, keyValue, limit) => ({
// 	type: SAGA_ACTIONS.GET_RESELLER_SUGGESSIONS,
// 	payload: {
// 		column_name: 'seller_name',
// 		value: keyValue ? keyValue : '',
// 		offset: offset,
// 		limit: limit,
// 		// ds_id: '5ea932ef2e2241589d0b66d1',
// 	},
// })

export const clearSuggestions = () => ({
	type: ACTION_TYPES.CLEAR_SUGGESTIONS,
})
