import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import classnames from 'classnames'
import { showConfirmMessage, hideLoader } from 'services/loader/actions'
import { makeStyles, Button, MenuItem, Select, Grid, Typography, TextField, FormHelperText, FormControl, CircularProgress } from '@material-ui/core'
import { Alert, AlertTitle, Rating } from '@material-ui/lab'
import RejectIcon from '@material-ui/icons/HighlightOff'
import AcceptIcon from '@material-ui/icons/CheckCircleOutline'
import { STICKY_STATUS_LIST } from 'constants/index'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { convertGmtToLocalTime } from 'utils'

const useStyles = makeStyles((theme) => ({
	rootContainer: {
		padding: theme.spacing(3, 0, 1, 0),
		'& .MuiFormHelperText-root': {
			color: theme.palette.almostBlack[700],
		},
	},
	commentContainer: {
		// padding: theme.spacing(0, 1, 0, 2),
	},
	rating: {
		marginTop: '3px',
	},
	commentFiled: {
		// minWidth: 280,
	},
	alertBox: {
		maxWidth: '80%',
		marginTop: theme.spacing(1),
		'& .MuiAlert-message': {
			fontSize: 'small',
			'& .MuiAlertTitle-root': {
				fontWeight: 400,
				fontSize: 'small',
				margin: 0,
			},
		},
	},
	inputField: {
		color: theme.palette.almostBlack[700],
		fontSize: '14px',
	},
	selectBox: {
		padding: theme.spacing(0.5, 2, 0.5, 1),
		fontSize: 'small',
		minWidth: 100,
		color: theme.palette.almostBlack[700],
		'& .Mui-disabled': {
			paddingRight: theme.spacing(1),
		},
	},
	approveText: {
		color: theme.palette.success.main,
	},
	RejectText: {
		color: theme.palette.error.main,
	},
	rejectStatus: {
		backgroundColor: theme.palette.error.light,
		color: theme.palette.primary.contrastText,
	},
	StatusLabel: {
		padding: theme.spacing(0, 1, 2, 0),
		fontWeight: 500,
		color: theme.palette.almostBlack[700],
	},
	taskStatusLabel: {
		padding: '3px 8px 16px 0px',
		fontWeight: 500,
		color: theme.palette.almostBlack[700],
	},
	StatusValue: {
		padding: theme.spacing(0, 1, 2, 0),
		color: theme.palette.almostBlack[900],
		fontSize: '14px',
	},
	actionBtn: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(2),
	},
	approveButton: {
		color: theme.palette.success.main,
	},
	rejectButton: {
		color: theme.palette.error.main,
	},
	loadingIcon: {
		padding: '6px',
	},
}))

const CommentBox = ({ onChangeStatus, onStatusChange, taskDetails }) => {
	const classes = useStyles()
	const [error, setError] = useState(false)
	const [ratingError, setRatingError] = useState(false)
	const [comments, setComments] = useState('')
	const [rating, setRating] = useState(0)
	const handleTextChange = (e) => {
		setComments(e.target.value)
	}

	const onClickApprove = () => {
		if (handleVallidate()) {
			onChangeStatus('approved', comments, rating)
		}
	}

	const onClickReject = () => {
		if (handleVallidate()) {
			onChangeStatus('rejected', comments, rating)
		}
	}

	const handleVallidate = () => {
		// if (!_.isEmpty(comments)) {
		// 	setError(false)
		// 	return true
		// } else {
		// 	setError(true)
		// 	return false
		// }
		if (_.isEmpty(comments) && (_.isEqual(rating, 0) || _.isEqual(rating, null))) {
			setError(true)
			setRatingError(true)
			return false
		} else if (!_.isEmpty(comments) && (_.isEqual(rating, 0) || _.isEqual(rating, null))) {
			setError(false)
			setRatingError(true)
			return false
		} else if (_.isEmpty(comments) && !_.isEqual(rating, 0) && !_.isEqual(rating, null)) {
			setError(true)
			setRatingError(false)
			return false
		} else {
			setError(false)
			setRatingError(false)
			return true
		}
	}

	return (
		<Grid container className={classes.rootContainer}>
			<Grid item xs={5} lg={4}>
				<Typography className={classes.StatusLabel} variant='body2'>
					Task Status
				</Typography>
			</Grid>
			<Grid item xs={7} lg={8}>
				<Typography className={classes.StatusValue}>{_.capitalize(taskDetails?.status)}</Typography>
			</Grid>
			<Grid item xs={5} lg={4}>
				<Typography className={classes.StatusLabel} variant='body2'>
					Requested By
				</Typography>
			</Grid>
			<Grid item xs={7} lg={8}>
				<Typography className={classes.StatusValue}>{taskDetails.created_by}</Typography>
			</Grid>
			<Grid item xs={5} lg={4}>
				<Typography className={classes.StatusLabel} variant='body2'>
					Date &#38; Time
				</Typography>
			</Grid>
			<Grid item xs={7} lg={8}>
				<Typography className={classes.StatusValue}>{convertGmtToLocalTime(taskDetails?.created_date, 'DD MMM YYYY hh:mm a')}</Typography>
			</Grid>
			<Grid item xs={5} lg={4}>
				<Typography className={classes.StatusLabel} variant='body2'>
					Task Approval
				</Typography>
			</Grid>
			<Grid conatainer item alignItems='center' xs={12} lg={8}>
				<div className={classes.commentContainer}>
					<TextField
						variant='outlined'
						className={classes.commentFiled}
						rows={4}
						fullWidth
						value={comments}
						error={error}
						onChange={handleTextChange}
						multiline
						InputProps={{
							classes: {
								input: classes.inputField,
							},
						}}
						size='small'
						placeholder='Enter Comments...'
						error={error}
						helperText={error && (<p style={{margin: '0px', color: 'red'}}>Please enter the Comments</p>)}
					/>
					<div className={classes.rating}>
					    <Rating 
						   name="unique-rating" 
						   precision={0.1} 
						   value={rating} 
						   onChange={(event, newValue)=> { setRating(newValue) }}
						   size="small" 
						/>
						{ratingError && <FormHelperText style={{color: 'red'}}>Please select stars and provide Rating</FormHelperText>}
					</div>
					<div>
						<Button
							className={classnames(classes.actionBtn, classes.approveButton)}
							disableElevation
							variant='outlined'
							size='small'
							onClick={onClickApprove}
						>
							Approve
						</Button>
						<Button
							className={classnames(classes.actionBtn, classes.rejectButton)}
							disableElevation
							variant='outlined'
							size='small'
							onClick={onClickReject}
						>
							Reject
						</Button>
					</div>
				</div>
			</Grid>
		</Grid>
	)
}
const SelectContainer = ({
	taskDetails,
	onChangeStatus,
	isReportingTo,
	isOwner,
	isApproval,
	stickyDetails,
	isApprovedStatus,
	isEditEnabled,
	isCompleted,
	isRejectStatus,
	loading,
}) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const filteredOptions = _.filter(STICKY_STATUS_LIST[taskDetails.status], (status) => {
		if ((!isReportingTo && isApprovedStatus) || (!isOwner && isApprovedStatus)) {
			if (isOwner || isReportingTo) {
				return status
			} else {
				return
			}
		} else {
			return status
		}
	})
	const handleChange = (e) => {
		if (isApprovedStatus) {
			askConfirm(
				() => {
					onChangeStatus(e.target.value, '')
					dispatch(hideLoader())
				},
				() => {
					dispatch(hideLoader())
				}
			)
		} else {
			onChangeStatus(e.target.value, '')
		}
	}
	const askConfirm = (onConfirm, onCancel) => {
		dispatch(showConfirmMessage(`Are you sure, you want to Re-open this task?`, '', 'Confirm', onConfirm, 'Cancel', onCancel))
	}
	return (
		<Grid container className={classes.rootContainer}>
			<Grid item xs={4} lg={4}>
				<Typography className={classes.taskStatusLabel} variant='body2'>
					Task Status :
				</Typography>
			</Grid>
			<Grid conatainer item xs={8} md={8}>
				<FormControl disabled={loading || !isEditEnabled}>
					<Select
						name='task_status'
						id='task_status'
						classes={{ root: classnames(classes.selectBox, { [classes.rejectStatus]: isRejectStatus }) }}
						value={taskDetails?.status}
						displayEmpty
						fullWidth
						variant='outlined'
						size='small'
						onChange={handleChange}
					>
						<MenuItem dense value={taskDetails.status} disabled>
							{_.capitalize(taskDetails.status)}
						</MenuItem>
						{_.map(_.values(filteredOptions), (status, index) => (
							<MenuItem dense value={status} key={index}>
								{_.capitalize(status)}
							</MenuItem>
						))}
					</Select>
					{isApproval && isCompleted && <FormHelperText>Approval Pending...</FormHelperText>}
				</FormControl>
				{loading && <CircularProgress size={15} className={classes.loadingIcon} />}
				{!_.isEmpty(taskDetails?.status_comments) && !loading && (
					<Alert
						severity={isApprovedStatus ? 'success' : 'error'}
						icon={isApprovedStatus ? <AcceptIcon fontSize='inherit' /> : <RejectIcon fontSize='inherit' />}
						className={classes.alertBox}
					>
						<div style={{display: 'flex', flexDirection: 'column'}}>
						<AlertTitle>{!_.isEmpty(taskDetails?.status_comments) && taskDetails?.status_comments}</AlertTitle>
						<Rating name="unique-rating" precision={0.1} value={taskDetails?.ratings} readOnly size="small" />
						<strong>{taskDetails.created_by}</strong>
						<AlertTitle>{convertGmtToLocalTime(taskDetails?.created_date, 'DD MMM YYYY hh:mm a')}</AlertTitle>
						</div>
					</Alert>
				)}
			</Grid>
		</Grid>
	)
}

const TaskStatus = ({ isApproval, isOwner, stickyDetails, taskDetails, onStatusChange, loading }) => {
	const currentUserId = useSelector((state) => _.get(state, 'session.user.userDetails.user_id'))

	const onChangeStatus = (status, comment, rating) => {
		onStatusChange(status, comment, rating)
	}
	const isCompleted = useMemo(() => {
		return taskDetails?.status === 'completed'
	}, [taskDetails])

	const isApprovedStatus = useMemo(() => {
		return taskDetails?.status === 'approved'
	}, [taskDetails])

	const isRejectStatus = useMemo(() => {
		return taskDetails?.status === 'rejected'
	}, [taskDetails])

	const isAssigne = useMemo(() => _.isEqual(_.get(stickyDetails, 'assigned_to.user_id'), currentUserId), [currentUserId, stickyDetails])
	const isReportingTo = useMemo(() => _.isEqual(_.get(stickyDetails, 'reporting_to.user_id'), currentUserId), [currentUserId, stickyDetails])
	const isEditEnabled = isReportingTo || isAssigne || isOwner

	return (
		<>
			{isCompleted && isApproval && (isOwner || isReportingTo) ? (
				<CommentBox onChangeStatus={onChangeStatus} isApprovedStatus={isApprovedStatus} loading={loading} taskDetails={taskDetails} />
			) : (
				<SelectContainer
					onChangeStatus={onChangeStatus}
					taskDetails={taskDetails}
					isOwner={isOwner}
					isApproval={isApproval}
					isReportingTo={isReportingTo}
					isApprovedStatus={isApprovedStatus}
					isRejectStatus={isRejectStatus}
					isCompleted={isCompleted}
					stickyDetails={stickyDetails}
					loading={loading}
					isEditEnabled={isEditEnabled}
				/>
			)}
		</>
	)
}
TaskStatus.propTypes = {
	isOwner: PropTypes.bool,
	glassCode: PropTypes.string.isRequired,
	glassLabels: PropTypes.array,
	sessionId: PropTypes.string,
	isApproval: PropTypes.bool,
	loading: PropTypes.bool,
}
export default TaskStatus
