import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { Dialog, Divider, makeStyles } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'

const useStyles = makeStyles((theme) => ({
	dialog: {
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
			top: 20,
		},
		'& .MuiDialog-paperScrollPaper': {
			width: '100%',
		},
	},
	dialogTitle: {
		paddingBottom: 10,
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	dialogContent: {
		padding: theme.spacing(3),
	},
	dialogFooter: {
		padding: '10px 15px 10px 15px',
	},
}))

function GBQDialog({ triggerClose, dialogProps, submitForm, isEditMode, formData }) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [state, setState] = React.useState({ allChecked: false, list: [] })
	const [selectedTables, setSelectedTables] = useState([])

	useEffect(() => {
		if (dialogProps?.data?.data) {
			let tempState = { ...state }
			tempState.list = dialogProps?.data?.data?.tables
				? dialogProps.data.data.tables.map((item) => {
						let checkedData = _.findIndex(formData?.dc_config?.tables, (file) => file === item)
						return {
							name: item,
							isChecked: isEditMode ? !_.isEqual(checkedData, -1) : false,
							key: item,
						}
				  })
				: []
			tempState.allChecked =
				isEditMode &&
				!!tempState?.list.reduce(function (a, b) {
					return a.isChecked === b.isChecked ? a : NaN
				})

			setState(tempState)
		}
		if (!dialogProps?.open) {
			setState({ allChecked: false, list: [] })
		}
	}, [dialogProps])

	useEffect(() => {
		if (!_.isEmpty(state?.list)) {
			let tempArr = []
			_.map(state?.list, (tableType) => {
				if (tableType.isChecked) {
					tempArr.push(tableType.name)
				}
			})
			setSelectedTables(tempArr)
		} else {
			setSelectedTables([])
		}
	}, [state])

	const onClickCreate = () => {
		if (_.isEmpty(selectedTables)) {
			dispatch(showSnackbarWithTimeout('Please select atleast one table', 1000))
		} else {
			submitForm(selectedTables)
		}
	}

	const handleChange = (e) => {
		let itemName = e.target.name
		let checked = e.target.checked

		setState((prevState) => {
			let { list, allChecked } = prevState
			if (itemName === 'checkAll') {
				allChecked = checked
				list = list.map((item) => ({ ...item, isChecked: checked }))
			} else {
				list = list.map((item) => (item.key === itemName ? { ...item, isChecked: checked } : item))
				allChecked = list.every((item) => item.isChecked)
			}
			return { list, allChecked }
		})
	}

	return (
		<div className={classes.dialogContainer}>
			<Dialog open={dialogProps?.open} onClose={triggerClose} className={classes.dialog}>
				<DialogTitle className={classes.dialogTitle} id='alert-dialog-title'>
					{'Choose tables to add to this section'}
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					<FormGroup column>
						<FormControlLabel
							control={<Checkbox onChange={handleChange} name='checkAll' checked={state.allChecked} color='primary' />}
							label='All Tables'
						/>
						{state.list.map((item) => (
							<FormControlLabel
								control={
									<Checkbox key={item.id} name={item.key} value={item.name} checked={item.isChecked} onChange={handleChange} color='primary' />
								}
								label={item.name}
							/>
						))}
					</FormGroup>
				</DialogContent>
				<Divider />
				<DialogActions className={classes.dialogFooter}>
					<Button onClick={triggerClose} color='primary' variant='outlined' autoFocus>
						Close
					</Button>
					<Button onClick={onClickCreate} color='primary' variant='contained' autoFocus>
						{isEditMode ? 'Save' : 'Create'}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default GBQDialog
