import React, { useMemo, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Button, Typography, ButtonBase, makeStyles } from '@material-ui/core'
import LabelPopover from './LabelPopover'
import { reducer, initialState } from './reducer'
import _ from 'lodash'
import update from 'immutability-helper'
import { useDispatch } from 'react-redux'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { getErrMsg, getSuccessMsg } from 'utils'
import { hideLoader, showConfirmMessage, showErrorMessage, showLoader } from 'services/loader/actions'
import { addGlassLabel, removeGlassLabel, updateGlassLabel } from 'services/glass/actions'
import Settings from 'scences/GlassX/Settings'

InputLabels.propTypes = {
	loading: PropTypes.bool,
	glassCode: PropTypes.string,
	name: PropTypes.string,
	sessionId: PropTypes.string,
	value: PropTypes.arrayOf(PropTypes.string),
	labels: PropTypes.arrayOf(
		PropTypes.shape({
			label_id: PropTypes.string,
			label_name: PropTypes.string,
			label_color: PropTypes.string,
		})
	),
	onChange: PropTypes.func,
	onUpdtLblListChanges: PropTypes.func,
	disabled: PropTypes.bool,
	hideAddLabel: PropTypes.bool,
	hideEditLabel: PropTypes.bool,
	hideDeleteLabel: PropTypes.bool,
	isCustom: PropTypes.bool,
	handleAddLabel: PropTypes.func,
	handleUpdateLabel: PropTypes.func,
	handleDeleteLabel: PropTypes.func,
}

const useStyles = makeStyles((theme) => ({
	labelsWrap: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: '5px',
	},
	labelBtnBase: {
		height: '28px',
		padding: '3px 9px',
		borderRadius: '2px',
		color: theme.palette.common.white,
	},
	labelElipsesCount: {
		height: '28px',
		padding: '3px 9px',
		borderRadius: '2px',
		backgroundColor: theme.palette.grey[300],
		color: theme.palette.text.primary,
	},
	title: {
		marginBottom: theme.spacing(1),
	},
	addLabelButton: {
		background: theme.palette.almostBlack[300],
		color: theme.palette.almostBlack[700],
		textTransform: 'capitalize',
		'&:hover': {
			background: theme.palette.almostBlack[200],
		},
	},
	addLabelDescButton: {
		minWidth: '85px',
		background: theme.palette.almostBlack[300],
		color: theme.palette.almostBlack[700],
		textTransform: 'capitalize',
		'&:hover': {
			background: theme.palette.almostBlack[200],
		},
	},
}))

function InputLabels({
	sessionId,
	glassCode,
	name,
	labels,
	value,
	loading,
	disabled,
	onChange,
	onUpdtLblListChanges,
	isDescription,
	showElpises,
	elipsesFrom,
	hideAddLabel,
	hideEditLabel,
	hideDeleteLabel,
	isCustom = false,
	handleAddLabel,
	handleUpdateLabel,
	handleDeleteLabel,
	newCustomLabel = false,
	handleCustomLabel,
}) {
	const [state, dispatch] = useReducer(reducer, initialState)
	const reduxDispatch = useDispatch()

	const { openPopover, searchText, openLblForm, formLabelId, formLabelName, formLabelColor, anchorEl } = state

	const classes = useStyles()

	const selectedLabels = useMemo(() => {
		return _.filter(labels, (label) => _.indexOf(value, _.get(label, 'label_id')) > -1)
	}, [labels, value])

	const openPopver = (e) => {
		dispatch({ type: 'openLabelsPopover', element: e.currentTarget })
	}

	const closePopover = () => {
		dispatch({ type: 'closeLabelsPopover' })
	}

	const onClickAddLblBtn = () => {
		dispatch({ type: 'openLabelForm' })
	}

	const onClickBackBtn = () => {
		dispatch({ type: 'closeLabelForm' })
	}

	const onChngSearchBox = (e) => {
		dispatch({ type: 'searchLabels', query: e.target.value })
	}

	const onClickLabel = (labelObj) => {
		const labelId = _.get(labelObj, 'label_id')
		const existIdx = _.indexOf(value, labelId)
		const opToPerform = existIdx === -1 ? { $push: [labelId] } : { $splice: [[existIdx, 1]] }
		onChange({ target: { name, value: update(value, opToPerform) } })
		if (existIdx !== -1) {
			closePopover()
		}
	}

	const onEditLabel = (labelObj) => {
		dispatch({
			type: 'openLabelForm',
			formData: {
				formLabelName: _.get(labelObj, 'label_name'),
				formLabelColor: _.get(labelObj, 'label_color'),
				formLabelId: _.get(labelObj, 'label_id'),
			},
		})
	}

	const hideAnyInfoDialog = () => {
		reduxDispatch(hideLoader())
	}

	const createOrUpdateLbl = (values) => {
		const isAddingLbl = _.isEmpty(values?.label_id)
		const onSuccess = (resp) => {
			hideAnyInfoDialog()
			reduxDispatch(showSnackbarWithTimeout(getSuccessMsg(resp), 2500))
			onClickBackBtn()
			const lblToUpdate = _.get(resp, 'data.data')
			if (isAddingLbl) {
				onUpdtLblListChanges && onUpdtLblListChanges(update(labels, { $push: [lblToUpdate] }))
				onClickLabel(lblToUpdate)
			} else if (onUpdtLblListChanges) {
				const extValIdx = _.findIndex(labels, {
					label_id: _.get(lblToUpdate, 'label_id'),
				})
				onUpdtLblListChanges(update(labels, { [extValIdx]: { $set: lblToUpdate } }))
			}

			if (newCustomLabel) {
				isAddingLbl ? handleCustomLabel(lblToUpdate, 'ADD_GLASS_LABEL') : handleCustomLabel(lblToUpdate, 'UPDATE_GLASS_LABEL')
			}
		}

		const onError = (err) => {
			reduxDispatch(showErrorMessage(getErrMsg(err), 'Close', () => hideAnyInfoDialog()))
		}

		reduxDispatch(showLoader(isAddingLbl ? 'Creating Label...' : 'Updating Label...'))

		if (!isCustom)
			reduxDispatch(
				isAddingLbl
					? addGlassLabel(sessionId, glassCode, values?.label_name, values?.label_color, onSuccess, onError)
					: updateGlassLabel(sessionId, glassCode, values?.label_id, values?.label_name, values?.label_color, onSuccess, onError)
			)
		else isAddingLbl ? handleAddLabel(values, onSuccess, onError) : handleUpdateLabel(values, onSuccess, onError)
	}

	const onSubmitLblForm = (values) => {
		createOrUpdateLbl(values)
	}

	const askDeleteConfirm = (onConfirm, onCancel) => {
		reduxDispatch(showConfirmMessage(`Are you sure, you want to delete this Label?`, '', 'Confirm', onConfirm, 'Cancel', onCancel))
	}

	const onDeleteLabel = (labelId) => {
		const onSuccess = (resp) => {
			hideAnyInfoDialog()
			reduxDispatch(showSnackbarWithTimeout(getSuccessMsg(resp), 2500))
			onClickBackBtn()
			const extValIdx = _.findIndex(labels, { label_id: labelId })
			onUpdtLblListChanges && onUpdtLblListChanges(update(labels, { $splice: [[extValIdx, 1]] }))
			const lblToUpdate = { label_id: labelId }
			if (newCustomLabel) {
				handleCustomLabel(lblToUpdate, 'REMOVE_GLASS_LABEL')
			}
		}
		const onError = (err) => {
			reduxDispatch(showErrorMessage(getErrMsg(err), 'Close', () => hideAnyInfoDialog()))
		}
		askDeleteConfirm(
			() => {
				reduxDispatch(showLoader('Deleting Label...'))
				if (!isCustom) reduxDispatch(removeGlassLabel(sessionId, glassCode, labelId, onSuccess, onError))
				else handleDeleteLabel(labelId, onSuccess, onError)
			},
			() => {
				hideAnyInfoDialog()
			}
		)
	}

	return (
		<div>
			{isDescription && (
				<Typography variant='subtitle2' component='p' className={classes.title}>
					Labels
				</Typography>
			)}
			<div className={classes.labelsWrap}>
				{showElpises ? (
					<>
						{_.map(selectedLabels.slice(0, elipsesFrom), (label) => (
							<ButtonBase
								onClick={openPopver}
								disabled={disabled}
								style={{ background: _.get(label, 'label_color') }}
								className={classes.labelBtnBase}
								key={_.get(label, 'label_id')}
							>
								<Typography variant='caption'>{_.get(label, 'label_name')}</Typography>
							</ButtonBase>
						))}
						{selectedLabels.length > elipsesFrom && (
							<ButtonBase onClick={openPopver} disabled={disabled} className={classes.labelElipsesCount}>
								<Typography variant='caption'>{`+ ${selectedLabels.slice(elipsesFrom).length}`}</Typography>
							</ButtonBase>
						)}
					</>
				) : (
					_.map(selectedLabels, (label) => (
						<ButtonBase
							onClick={openPopver}
							disabled={disabled}
							style={{ background: _.get(label, 'label_color') }}
							className={classes.labelBtnBase}
							key={_.get(label, 'label_id')}
						>
							<Typography variant='caption'>{_.get(label, 'label_name')}</Typography>
						</ButtonBase>
					))
				)}

				<Button
					size='small'
					component='label'
					disabled={disabled}
					className={isDescription ? classes.addLabelDescButton : classes.addLabelButton}
					onClick={openPopver}
				>
					+ Labels
				</Button>
			</div>
			<LabelPopover
				loading={loading}
				open={openPopover}
				anchorEl={anchorEl}
				openLblForm={openLblForm}
				onClickAddLblBtn={onClickAddLblBtn}
				onClickBackBtn={onClickBackBtn}
				labels={labels}
				value={value}
				formLabelId={formLabelId}
				formLabelName={formLabelName}
				formLabelColor={formLabelColor}
				searchText={searchText}
				onEditLabel={onEditLabel}
				onClickLabel={onClickLabel}
				onDeleteLabel={onDeleteLabel}
				onChangeSearchBox={onChngSearchBox}
				onSubmitLblForm={onSubmitLblForm}
				onClose={closePopover}
				hideAddLabel={hideAddLabel}
				hideEditLabel={hideEditLabel}
				hideDeleteLabel={hideDeleteLabel}
			/>
		</div>
	)
}

export default InputLabels
