import React from 'react'
import _ from 'lodash'
import { makeStyles, Avatar, Grid, Typography } from '@material-ui/core'
import classnames from 'classnames'
import { formTheName, getImgUrl, stringToHslColor } from 'utils'
import { useSelector } from 'react-redux'

const MAIN_HEADER_HEIGHT = 64
const BOARD_HEADER_HEIGHT = 48

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		marginLeft: 8,
		marginRight: 8,
	},
	avatar: {
		width: `100%`,
		height: `100%`,
		fontSize: 14,
		lineHeight: 'unset',
		boxSizing: 'border-box',
	},
	avatarBorderDiv: {
		width: `100%`,
		height: `100%`,
		position: 'absolute',
		top: 0,
		left: 0,
		boxSizing: 'border-box',
		transition: 'top 150ms',
		borderRadius: '50%',
		border: `0px solid blue`,
		'&:hover:not($otherAvatar)': {
			top: `-4px`,
		},
	},
	otherAvatar: {},
	otherAvatarOpened: {
		zIndex: 9999,
	},
	selectedAvatar: {
		border: `2px solid #0052cc`,
	},
	avatarContainer: (props) => ({
		width: 24,
		height: 24,
		position: 'relative',
		marginLeft: -8,
		cursor: 'pointer',
		'&:hover': {
			zIndex: `${props?.maxZ || 9999} !important`,
			transition: `z-index 150ms`,
		},
	}),
	largeAvatarContainer: (props) => ({
		width: 40,
		height: 40,
		position: 'relative',
		marginLeft: -8,
		cursor: 'pointer',
		'&:hover': {
			zIndex: `${props?.maxZ || 9999} !important`,
			transition: `z-index 150ms`,
		},
	}),
	popoverRoot: {
		marginTop: theme.spacing(1),
		padding: theme.spacing(2),
	},
	content: {
		width: '260px',
		border: `1px solid ${theme.palette.grey[300]}`,
		padding: theme.spacing(0),
		height: 'auto',
	},
	menuHeader: {
		width: '100%',
		height: 40,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: `1px solid ${theme.palette.almostBlack[200]}`,
		boxShadow: `0px 1px 8px 0px ${theme.palette.almostBlack[200]}`,
	},
	menuTitle: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 500,
	},
	menuHeaderIconDiv: {
		width: 32,
		height: 32,
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& .MuiIconButton-root': {
			padding: theme.spacing(0.75),
			fontSize: theme.typography.pxToRem(20),
			'& .MuiIcon-root': {
				fontSize: theme.typography.pxToRem(20),
			},
		},
	},
	listContainer: {
		overflow: 'auto',
		marginTop: 1,
		padding: theme.spacing(0, 1, 1),
		maxHeight: `calc(100vh - (${MAIN_HEADER_HEIGHT}px + (3 * ${BOARD_HEADER_HEIGHT}px)))`,
		'&::-webkit-scrollbar,::-webkit-scrollbar-track': {
			width: '8px',
			height: '8px',
			'-webkit-appearance': 'none',
			backgroundColor: 'transparent',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.almostBlack[400],
			height: '20px',
			borderRadius: '4px',
		},
	},
	listItemContainer: {
		padding: theme.spacing(0.5),
		margin: theme.spacing(0.5, 0, 0.5, 0),
		borderRadius: 4,
		border: `1px solid transparent`,
		cursor: 'pointer',
	},
	selectedListItem: {
		background: `${theme.palette.success.light}25`,
		borderColor: `${theme.palette.success.light}`,
	},
	userPic: {
		width: 36,
		height: 36,
		marginRight: theme.spacing(1),
		fontSize: 17,
	},
	optionTextEllipsis: {
		width: `100%`,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	otherUsers: {
		minWidth: 65,
		marginLeft: '2px !important',
		marginTop: 2,
	},
}))

const GroupedAvatars = ({
	max = 5,
	handleChange = () => null,
	list = [],
	firstId = '',
	selectedIds = [],
	isLarge = false,
	keys = {
		firstName: 'first_name',
		lastName: 'last_name',
		emailKey: 'email',
		id: 'user_id',
		imgKey: 'profile_img',
	},
}) => {
	const classes = useStyles({ maxZ: list?.length })
	const sortedList = _.sortBy(list, keys?.firstName)
	const firstObj = _.remove(sortedList, (o) => _.get(o, keys?.id) === firstId)
	const finalList = !_.isEmpty(firstObj) ? [firstObj[0], ...sortedList] : sortedList

	return (
		<div className={classes.container}>
			<RenderAvatars max={max} handleChange={handleChange} isLarge={isLarge} keys={keys} list={finalList} selectedIds={selectedIds} />
		</div>
	)
}

const RenderAvatars = ({ max, handleChange, keys, list, selectedIds, isLarge }) => {
	const anchorEl = React.useRef()
	let renderedItems = []
	let hiddenItems = []
	const classes = useStyles()
	const token = useSelector((state) => _.get(state, 'session.authToken', ''))
	let shownIds = []

	_.map(list, (o, i) => {
		let isSelected = _.findIndex(selectedIds, (o) => o === _.get(list[i], keys?.id)) > -1
		if (i < max) {
			shownIds.push(_.get(list[i], keys?.id))
			let imgProps = !_.isEmpty(_.get(list[i], keys?.imgKey)) ? { src: getImgUrl(token, _.get(list[i], keys?.imgKey)) } : { src: 'fake_path' }
			renderedItems.push(
				<div
					key={`shown_items_${i}`}
					className={isLarge ? classes.largeAvatarContainer : classes.avatarContainer}
					style={{
						zIndex: isSelected ? list?.length - i + 1 : list?.length - i,
					}}
				>
					<div
						className={classnames(classes.avatarBorderDiv, {
							[classes.selectedAvatar]: isSelected,
						})}
					>
						<Avatar
							onClick={() => handleChange(_.get(o, keys?.id, ''))}
							key={'grouped_avatar' + i}
							src={getImgUrl(token, _.get(o, 'profile_pic_url', _.get(o, 'profile_img', null)))}
							alt={formTheName(_.get(list, `[${i}].${keys?.firstName}`), _.get(list, `[${i}].${keys?.lastName}`))}
							title={`${formTheName(_.get(list, `[${i}].${keys?.firstName}`), _.get(list, `[${i}].${keys?.lastName}`))} \n${_.get(
								list,
								`[${i}].${keys?.emailKey}`
							)}`}
							className={classes.avatar}
							style={{
								border: `2px solid white`,
								background: stringToHslColor(formTheName(_.get(list, `[${i}].${keys?.firstName}`), _.get(list, `[${i}].${keys?.lastName}`))),
							}}
						/>
					</div>
				</div>
			)
			hiddenItems.push(
				<ListItem
					key={`hidden_item_${i}`}
					onClick={() => handleChange(_.get(o, keys?.id, ''))}
					name={formTheName(_.get(list, `[${i}].${keys?.firstName}`), _.get(list, `[${i}].${keys?.lastName}`))}
					email={_.get(list[i], keys?.emailKey, '')}
					isSelected={isSelected}
					{...imgProps}
				/>
			)
		} else {
			let imgProps = !_.isEmpty(_.get(list[i], keys?.imgKey)) ? { src: getImgUrl(token, _.get(list[i], keys?.imgKey)) } : { src: 'fake_path' }
			hiddenItems.push(
				<ListItem
					key={`hidden_item_${i}`}
					onClick={() => handleChange(_.get(o, keys?.id, ''))}
					name={formTheName(_.get(list, `[${i}].${keys?.firstName}`), _.get(list, `[${i}].${keys?.lastName}`))}
					email={_.get(list[i], keys?.emailKey, '')}
					isSelected={isSelected}
					{...imgProps}
				/>
			)
		}
	})

	let isSelectedInHidden = !_.isEmpty(_.difference(selectedIds, shownIds))

	return (
		<>
			{renderedItems}
			{hiddenItems?.length > renderedItems?.length && (
				<>
					<div ref={anchorEl} className={classnames(classes.avatarContainer, classes.otherUsers)} style={{ zIndex: list?.length - max - 1 }}>
						<div className={classnames(classes.avatarBorderDiv, classes.otherAvatar, { [classes.selectedAvatar]: isSelectedInHidden })}>
							{`& ${list?.length - max} others`}
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default GroupedAvatars

const ListItem = ({ name = '', email = '', isSelected = false, onClick = () => null, src = 'fake_path', ...others }) => {
	const classes = useStyles()
	return (
		<Grid
			container
			alignItems='center'
			style={{ flexWrap: 'nowrap' }}
			onClick={onClick}
			className={classnames(classes.listItemContainer, {
				[classes.selectedListItem]: isSelected,
			})}
		>
			<Avatar alt={name} src={src} size='small' className={classes.userPic} style={{ backgroundColor: stringToHslColor(name) }} />
			<Grid item xs style={{ maxWidth: `calc(100% - (36px + 8px))` }}>
				<Typography variant='body2' className={classes.optionTextEllipsis} title={name}>
					{name}
				</Typography>
				<Typography variant='body2' color='textSecondary' title={email} className={classes.optionTextEllipsis}>
					{email}
				</Typography>
			</Grid>
		</Grid>
	)
}
