import React, { useRef, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import _ from 'lodash'
import LoadingIndicator from 'components/LoadingIndicator'
import ReactResizeDetector from 'react-resize-detector'

const TABLE_HEADER_HEIGHT = 30

const useStyles = makeStyles((theme) => ({
	table: (props) => ({
		position: 'relative',
		'&.ag-theme-material': {
			'& ::-webkit-scrollbar, ::-webkit-scrollbar-track': {
				width: '10px',
				height: '10px',
				'-webkit-appearance': 'none',
				backgroundColor: _.get(props, 'scrollBar.trackColor', 'rgba(255, 255, 255, 0)'), //, //'transparent'
			},
			'& ::-webkit-scrollbar-thumb': {
				backgroundColor: _.get(props, 'scrollBar.thumbColor', 'rgba(213, 213, 220, 1)'), //theme.palette.almostBlack[400],
				height: '80px',
				borderRadius: '5px',
			},
		},
		'& .ag-header': {
			textTransform: 'uppercase',
			letterSpacing: '2px',
			fontSize: theme.typography.pxToRem(11),
			background: _.get(props, 'tableStyles.headerBgColor'),
			border: 0,
		},
		'&.ag-theme-material .ag-header-cell': {
			color: _.get(props, 'tableStyles.headerTxtColor'),
		},
		'&.ag-theme-material .ag-cell': {
			fontSize: theme.typography.pxToRem(14),
			'& .ag-react-container': {
				height: '100%',
				'& div': {
					'white-space': 'nowrap',
					'text-overflow': 'ellipsis',
					overflow: 'hidden',
				},
			},
		},
		'&.ag-theme-material .ag-row': {
			background: _.get(props, 'tableStyles.rowBgColor'),
			color: _.get(props, 'tableStyles.rowTxtColor'),
			borderColor: _.get(props, 'tableStyles.rowSeparatorColor'),
			cursor: 'pointer',
		},
		'&.ag-theme-material .ag-row:hover': {
			backgroundColor: 'rgba(250,250,251,1)',
		},
		'&.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after': {
			color: '#65b17f !important',
		},
	}),
	loaderContainer: {
		position: 'absolute',
		top: TABLE_HEADER_HEIGHT,
		paddingTop: theme.spacing(2),
		width: '100%',
		height: '100%',
		textAlign: 'center',
		background: theme.palette.common.white,
	},
}))

const AgGridCustom = (props) => {
	const {
		className,
		onReady,
		columnDefs,
		defaultColDef,
		rowData,
		onRowClicked,
		loading,
		rowHeight,
		frameworkComponents,
		disableClickSelectionRenderers,
		noDataTxt,
		sizeColumnsToFit = true,
		...otherProps
		// onChangeSelection,
	} = props

	const gridOptions = useRef()

	const setOrUnsetResizeColsToFit = useCallback((sizeColumnsToFit, gridApi, columnApi) => {
		if (!sizeColumnsToFit) columnApi.autoSizeAllColumns(false)
		else gridApi.sizeColumnsToFit()
	}, [])

	const updateRowData = useCallback(
		(rowData, gridApi, columnApi) => {
			gridApi?.setRowData(rowData)
			setOrUnsetResizeColsToFit(sizeColumnsToFit, gridApi, columnApi)
		},
		[sizeColumnsToFit, setOrUnsetResizeColsToFit]
	)

	useEffect(() => {
		if (_.get(gridOptions, 'current.api')) {
			updateRowData(rowData, gridOptions.current.api, gridOptions.current.columnApi)
		}
	}, [rowData, updateRowData])
	const [currentWidth, setCurrentWidth] = useState(null)

	useEffect(() => {
		if (_.get(gridOptions, 'current.api')) {
			const gridApi = gridOptions.current.api
			const gridColApi = gridOptions.current.columnApi
			gridApi.setColumnDefs(columnDefs)
			gridApi.resetRowHeights()
			// gridApi.rowRenderer()
			setOrUnsetResizeColsToFit(sizeColumnsToFit, gridApi, gridColApi)
		}
	}, [columnDefs, sizeColumnsToFit, setOrUnsetResizeColsToFit, currentWidth])

	const onResizeLayout = (width) => {
		setCurrentWidth(width)
	}
	const onGridReady = useCallback(
		(params) => {
			gridOptions.current = params
			onReady && onReady(params)
			setOrUnsetResizeColsToFit(sizeColumnsToFit, params.api, params.columnApi)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setOrUnsetResizeColsToFit, sizeColumnsToFit]
	)

	const classes = useStyles({
		tableStyles: {
			headerBgColor: 'rgba(158,156,156,0.08)',
			headerTxtColor: 'rgba(68,68,79,1)',
			rowBgColor: 'rgba(255,255,255,1)',
			rowTxtColor: 'rgba(0,0,0,0.87)',
			rowSeparatorColor: 'rgba(226,226,226,1)',
		},
		scrollBar: {
			trackColor: 'rgba(255, 255, 255, 0)',
			thumbColor: 'rgba(213, 213, 220, 1)',
		},
	})

	const onCellClicked = (e) => {
		if (e.column && _.indexOf(disableClickSelectionRenderers, e.column.colDef.cellRenderer) > -1) {
			e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = true
		} else {
			e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = false
		}
	}

	const onSelectionChanged = () => {
		// const gridApi = gridOptions.current.api
		// var selectedRows = gridApi.getSelectedRows()
		// onChangeSelection(selectedRows)
	}

	return (
		<div className={classnames('ag-theme-material', classes.table, className)}>
			<AgGridReact
				defaultColDef={defaultColDef ? defaultColDef : {}}
				rowSelection={'multiple'}
				onSelectionChanged={onSelectionChanged}
				domLayout='autoHeight'
				columnDefs={columnDefs}
				sizeColumnsToFit={sizeColumnsToFit}
				rowData={rowData}
				frameworkComponents={frameworkComponents}
				animateRows
				onGridReady={onGridReady}
				headerHeight={30}
				suppressCellSelection
				onRowClicked={onRowClicked && onRowClicked}
				onCellClicked={onCellClicked}
				rowHeight={rowHeight}
				overlayNoRowsTemplate={noDataTxt ? noDataTxt : null}
				suppressDragLeaveHidesColumns
				{...otherProps}
			/>
			{loading && (
				<div className={classes.loaderContainer}>
					<LoadingIndicator></LoadingIndicator>
				</div>
			)}
			<ReactResizeDetector handleWidth onResize={onResizeLayout} />
		</div>
	)
}

AgGridCustom.propTypes = {
	columnDefs: PropTypes.array.isRequired,
	rowData: PropTypes.array.isRequired,
	loading: PropTypes.bool,
	onRowClicked: PropTypes.func,
	rowHeight: PropTypes.number,
	frameworkComponents: PropTypes.object,
	disableClickSelectionRenderers: PropTypes.arrayOf(PropTypes.string),
	onReady: PropTypes.func,
}

export default AgGridCustom
