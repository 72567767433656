import React, { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { Grid, Icon, IconButton, LinearProgress, makeStyles, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import _ from 'lodash'
import DashboardViewer from 'scences/Dashboard/DashboardViewer'
import { useCapture } from 'hooks/useCapture/index.js'
import { useDispatch } from 'react-redux'
import { hideLoader, showLoader as showLoaderDialog } from 'services/loader/actions/index.js'
import { ROLE_MODULES } from 'constants/modules.js'
import { TASK_BUTTON_TXT } from '../../constants'

const useStyles = makeStyles((theme) => ({
	titleWrap: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	title: {
		lineHeight: 'unset',
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		paddingRight: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			fontSize: '14px',
		},
	},
	tag: {
		color: theme.palette.primary.main,
		fontSize: '12px',
	},
	subtitle: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	toolbar: {
		width: '100%',
		boxSizing: 'border-box',
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
	},
	dialogFooter: {
		padding: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
	textField: {
		//    marginBottom:theme.spacing(2.2)
	},
	dialogContent: {
		paddingTop: theme.spacing(1.5),
		paddingBottom: theme.spacing(1.5),
	},
	priorityItem: {
		justifyContent: 'center',
		gap: '5px',
	},
	userPic: {
		width: 37,
		height: 37,
		marginRight: theme.spacing(1),
	},
	disableOnLoading: {
		padding: 0,
		opacity: (props) => (props.showLoader ? 0.6 : 1),
		pointerEvents: (props) => (props.showLoader ? 'none' : 'unset'),
	},
	infoWrapper: {
		textAlign: 'center',
		padding: theme.spacing(6, 2, 2, 2),
	},
	dashIco: {
		fontSize: '60px',
		paddingBottom: theme.spacing(0.5),
		color: theme.palette.text.secondary,
	},
}))

DashboardModal.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	dashboardId: PropTypes.string,
	dashboardDetails: PropTypes.shape({
		title: PropTypes.string,
		filterColumn: PropTypes.string,
		filterValue: PropTypes.any,
		subtitle: PropTypes.string,
		tag: PropTypes.string,
		viewLink: PropTypes.string,
	}),
	isPopup: PropTypes.bool,
	dashStackIdx: PropTypes.number,
	openTaskDialog: PropTypes.func, //add stick popup function
}

export default function DashboardModal({ open, dashboardId, dashStackIdx, openTaskDialog, isPopup, dashboardDetails, handleClose }) {
	const showLoader = false
	const classes = useStyles({ showLoader: false })
	const theme = useTheme()
	const dispatch = useDispatch()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

	const { title, subtitle, filterColumn, history, filterValue, dsId, tag, viewLink } = dashboardDetails

	const onClickViewBtn = () => {
		window.open(viewLink, '_blank')
	}

	const tempFilters = useMemo(() => {
		if (filterColumn) {
			return { [filterColumn]: filterValue }
		}
	}, [filterColumn, filterValue])

	const openDatasourcePage = () => {
		history && history.push(`/datasource/edit/${dsId}`)
	}

	const dashImgRef = useRef(null)
	const takeScreenshot = useCapture(dashImgRef)

	const onClickAddTaskBtn = () => {
		dispatch(showLoaderDialog('Please wait...'))
		takeScreenshot({
			scrollX: 0,
			scrollY: 0,
			windowWidth: document.documentElement.offsetWidth,
			windowHeight: document.documentElement.offsetHeight,
		}).then((file) => {
			dispatch(hideLoader())
			openTaskDialog([file], ROLE_MODULES.DASHBOARDS, {})
		})
	}

	return (
		<Dialog
			open={open}
			fullScreen={true}
			onClose={handleClose}
			disableBackdropClick={showLoader}
			aria-labelledby='task-add-dialog'
			scroll={fullScreen ? 'paper' : 'body'}
			maxWidth={'md'}
		>
			<Toolbar className={classes.toolbar}>
				<IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
					<Icon>close</Icon>
				</IconButton>
				<div className={classes.titleWrap}>
					<Typography className={classes.title} component='div' variant='h6'>
						{title}
					</Typography>
					{tag || subtitle ? (
						<Typography variant='caption' component='div' color='textSecondary'>
							<span className={classes.tag}>{tag}&nbsp;&bull;&nbsp;</span>
							<span className={classes.subtitle}>{subtitle}</span>
						</Typography>
					) : null}
				</div>
				{isPopup && (
					<Button variant='outlined' size='small' onClick={onClickAddTaskBtn} startIcon={<Icon fontSize='small'>sticky_note_2</Icon>}>
						{TASK_BUTTON_TXT}
					</Button>
				)}
				{!_.isEmpty(viewLink) && (
					<Button disableElevation autoFocus variant='contained' color='primary' size='small' onClick={onClickViewBtn}>
						View
					</Button>
				)}
			</Toolbar>
			{showLoader && <LinearProgress variant='indeterminate' />}
			<DialogContent className={classes.disableOnLoading}>
				<Grid container>
					<Grid item xs={12}>
						{_.isEmpty(dashboardId) ? (
							<div className={classes.infoWrapper}>
								<Icon className={classes.dashIco} fontSize='large' component='div'>
									dashboard
								</Icon>
								<Typography component='div' variant='body1' color='textSecondary'>
									{isPopup ? 'No Dashboard Found' : 'No Dashboard Configured'}
								</Typography>
								{!isPopup && (
									<>
										<Typography component='div' variant='caption' color='textSecondary' gutterBottom>
											Configure the Dashboard for this result item.
										</Typography>
										<Button onClick={openDatasourcePage} color='primary'>
											Configure
										</Button>
									</>
								)}
							</div>
						) : open ? (
							<DashboardViewer
								dashboardTitle={title}
								viewerRef={dashImgRef}
								tempFilters={tempFilters}
								isPopup={isPopup}
								forceRefreshNo={0}
								isEditMode={false}
								isEmbedded={true}
								dashStackIdx={dashStackIdx}
								dashboardId={dashboardId}
							/>
						) : null}
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}
