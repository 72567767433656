import { Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Header from './Header'
import ResultList from './ResultList'
import DiscoverxApi from '../../../services/discoverx/api'
import _ from 'lodash'
import { Pagination } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		top: 0,
		boxSizing: 'border-box',
		backgroundColor: '#fff',
		zIndex: 99,
		overflowY: 'auto',
		paddingTop: theme.spacing(8),
	},
	contentWrapper: {
		maxWidth: '1200px',
		margin: '0 auto',
		padding: theme.spacing(3, 4),
	},
	nodataTxt: {
		textAlign: 'center',
		padding: theme.spacing(1, 0),
	},
}))
const rowPerPage = 10
const WishList = ({ onBackBtnClick }) => {
	const classes = useStyles()
	const cancelExecutor = React.useRef()
	const [params, setParams] = React.useState({
		limit: rowPerPage,
		offset: 0,
		query: '',
	})
	const { offset } = params
	const [wishListData, setWishlistData] = React.useState({
		loading: false,
		wishList: [],
		total: 0,
	})
	const { wishList, total, loading } = wishListData

	const [pageNo, setPageNo] = useState(offset + 1)

	const onSearch = _.debounce(
		(e) => {
			setParams((prevState) => ({ ...prevState, query: e.target.value }))
		},
		[300]
	)

	const onChangePageNo = (e, pageNo) => {
		setParams((prevState) => ({ ...prevState, limit: pageNo * rowPerPage, offset: (pageNo - 1) * rowPerPage }))
		setPageNo(pageNo)
	}

	useEffect(() => {
		const onSuccess = (res) => {
			setWishlistData((prevState) => ({ ...prevState, loading: false, wishList: res?.data?.data?.discoverx, total: res?.data?.data?.total_records }))
		}
		const onFailure = (err) => {
			setWishlistData((prevState) => ({ ...prevState, loading: false, wishList: [], total: 0 }))
		}
		setWishlistData((prevState) => ({ ...prevState, loading: true, wishList: [], total: 0 }))
		DiscoverxApi.getMyWishlist(params, cancelExecutor).then(onSuccess, onFailure)
	}, [params])

	return (
		<div className={classes.root}>
			<div className={classes.contentWrapper}>
				<Header onBackBtnClick={onBackBtnClick} onChangeSearchBox={onSearch} searchPlaceholder={'Search by name or Query'} />
				{loading ? (
					<Grid item xs={12} md={8} container justifyContent='center'>
						<Typography variant='subtitle2' className={classes.nodataTxt}>
							Loading wishlist...
						</Typography>
					</Grid>
				) : _.isEmpty(wishList) ? (
					<Grid item xs={12} md={8} container justifyContent='center'>
						<Typography variant='subtitle2' className={classes.nodataTxt}>
							No wishlist found
						</Typography>
					</Grid>
				) : (
					<>
						<ResultList data={wishList} total={total} />
						{!loading && !_.isEmpty(wishList) && (
							<Grid item xs={12} md={8} container justifyContent='center'>
								<div className={classes.paginationWrapper}>
									<Pagination page={pageNo} onChange={onChangePageNo} count={Math.ceil(total / rowPerPage)} color='primary' />
								</div>
							</Grid>
						)}
					</>
				)}
			</div>
		</div>
	)
}

export default WishList
