import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import SearchBox from 'components/SearchBox'
import { makeStyles, TablePagination } from '@material-ui/core'
import AgGridCustom from 'components/AgGridCustom'
import { useSelector } from 'react-redux'
import { UserCellRenderer, DeleteCellRenderer } from './CustomCells'
import { withCellRenderState } from '../../../Users/CustomCells'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { removeTeamMember, fetchAllTeamMembers } from 'services/teams/actions'
import { isAxiosCancelError } from 'utils'
import { hideLoader, showConfirmMessage } from 'services/loader/actions'

MembersList.propTypes = {
	teamUnitCode: PropTypes.string,
	teamName: PropTypes.string,
	showDelOption: PropTypes.bool,
}

const useStyles = makeStyles((theme) => ({
	searchBar: {
		padding: 0,
		'& .searchbar': {
			maxWidth: '100%',
		},
		marginBottom: theme.spacing(1),
	},
	nameCellContent: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'flex-start',
		'& .avatar-span': {
			width: theme.spacing(5),
			height: theme.spacing(5),
			fontSize: '14px',
			marginRight: theme.spacing(1),
		},
		'& .m-details': {
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
		},
		'& .details-span': {
			lineHeight: 'unset',
			'& span': {
				lineHeight: 'inherit',
			},
		},
	},
	deleteCellContainer: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'center',
		'& a': {
			display: 'block',
			lineHeight: 'initial',
			color: theme.palette.primary.main,
		},
	},
}))

function MembersList({ teamCode, teamName, showDelOption }) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const isMounted = useRef(true)
	const authToken = useSelector((state) => _.get(state, 'session.authToken', ''))

	const [usersTblState, setUsersTblState] = useState({
		loading: true,
		searchText: '',
		offset: 0,
		limit: 5,
		totalCount: 0,
		teamUnitCode: '',
		userList: [],
	})
	const { teamUnitCode, searchText, loading, offset, totalCount, limit, userList } = usersTblState

	const addFilterAndRefreshTbl = (filterParams) => {
		setUsersTblState((prevState) => ({
			...prevState,
			loading: true,
			userList: [],
			...filterParams,
		}))
	}

	const onChangePage = (e, pageNo) => {
		addFilterAndRefreshTbl({ offset: pageNo * limit })
	}

	const onChangeRowsPerPage = (e) => {
		addFilterAndRefreshTbl({ limit: e.target.value, offset: 0 })
	}

	const removeUserFromTeam = useCallback(
		(userId) => {
			const onSuccess = () => {
				addFilterAndRefreshTbl({ offset: 0 })
			}
			const onErrorClose = () => {
				dispatch(hideLoader())
			}
			dispatch(removeTeamMember(teamCode, userId, onSuccess, onErrorClose))
		},
		[dispatch, teamCode]
	)

	const onClickDelete = useCallback(
		(e, data) => {
			dispatch(
				showConfirmMessage(
					`Are you sure to remove user "${_.get(data, 'full_name')}" from team unit ${teamName}?`,
					'',
					'Remove',
					(e) => {
						removeUserFromTeam(_.get(data, 'user_id'))
					},
					'Cancel',
					(e) => {
						dispatch(hideLoader())
					}
				)
			)
		},
		[teamName, removeUserFromTeam, dispatch]
	)

	const onChangeSearchBox = _.debounce((e) => {
		addFilterAndRefreshTbl({ searchText: e.target.value })
	}, 300)

	const colDefs = useMemo(
		() =>
			[
				{
					headerName: 'Name',
					width: 100,
					field: 'first_name',
					cellRenderer: 'userCellRenderer',
					cellRendererParams: {
						containerClass: classes.nameCellContent,
						authToken: authToken,
					},
				},
				showDelOption && {
					headerName: '',
					field: '',
					width: 30,
					cellRenderer: 'deleteCellRenderer',
					cellRendererParams: {
						containerClass: classes.deleteCellContainer,
						onClickDelete,
					},
				},
			].filter(Boolean),
		[showDelOption, classes, onClickDelete, authToken]
	)

	const fetchUsersList = useCallback(
		({ limit, offset, searchText, teamUnitCode }) => {
			dispatch(
				fetchAllTeamMembers(
					{ limit, offset, search_text: searchText, tu_code: teamUnitCode, tu_action: 'list' },
					(resp) => {
						const totalCount = _.get(resp, 'data.data.total_count', 0)
						const userList = _.get(resp, 'data.data.team_members', [])
						if (isMounted.current) {
							setUsersTblState((prevState) => ({
								...prevState,
								loading: false,
								totalCount,
								userList,
							}))
						}
					},
					(err) => {
						if (!isAxiosCancelError(err) && isMounted.current) {
							setUsersTblState((prevState) => ({
								...prevState,
								loading: false,
								totalCount: 0,
								userList: [],
							}))
						}
					}
				)
			)
		},
		[dispatch]
	)

	useEffect(() => {
		if (!_.isEmpty(teamUnitCode) && loading) fetchUsersList({ limit, offset, searchText, teamUnitCode })
	}, [limit, offset, searchText, loading, teamUnitCode, fetchUsersList])

	useEffect(() => {
		addFilterAndRefreshTbl({ teamUnitCode: teamCode })
	}, [teamCode])

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	return (
		<div>
			<SearchBox containerClassName={classes.searchBar} onChange={onChangeSearchBox} placeholder='Search Team Members...' />
			<div>
				<AgGridCustom
					columnDefs={colDefs}
					rowData={userList}
					loading={loading}
					frameworkComponents={{
						userCellRenderer: withCellRenderState(UserCellRenderer),
						deleteCellRenderer: withCellRenderState(DeleteCellRenderer),
					}}
					disableClickSelectionRenderers={['deleteCellRenderer']}
					overlayNoRowsTemplate='No Users Added'
					rowHeight={60}
					noDataTxt={'No Users Found'}
				></AgGridCustom>
				{!loading && (
					<TablePagination
						component='div'
						count={totalCount}
						page={offset / limit}
						onPageChange={onChangePage}
						rowsPerPage={limit}
						rowsPerPageOptions={[5, 10, 20, 100]}
						onRowsPerPageChange={onChangeRowsPerPage}
					/>
				)}
			</div>
		</div>
	)
}

export default MembersList
