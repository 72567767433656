import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Slide, Typography, withWidth } from '@material-ui/core'
import _ from 'lodash'

DialogForm.propTypes = {
	dialogTitle: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	onClickOkBtn: PropTypes.func.isRequired,
	onClickCancelBtn: PropTypes.func.isRequired,
}

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
	dialogTitle: {
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	dialogContent: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			width: 400,
		},
	},
	dialogFooter: {
		padding: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
	textField: {
		paddingBottom: theme.spacing(1),
	},
}))

function DialogForm({ width, dialogTitle, open, onClose, children, onClickOkBtn, onClickCancelBtn }) {
	const classes = useStyles()
	return (
		<Dialog
			classes={{
				paper: classes.dialog,
			}}
			TransitionComponent={Transition}
			// maxWidth={'md'}
			open={open}
			onClose={onClose}
			fullScreen={_.isEqual(width, 'xs')}
			aria-labelledby='role-form-title'
		>
			<DialogTitle className={classes.dialogTitle} disableTypography={true} id='role-dialog-title'>
				<Typography variant='h6'>{dialogTitle}</Typography>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>{children}</DialogContent>
			<DialogActions className={classes.dialogFooter}>
				<Button variant='outlined' onClick={onClickCancelBtn} color='primary'>
					Cancel
				</Button>
				<Button variant='contained' onClick={onClickOkBtn} color='primary' disableElevation>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default withWidth()(DialogForm)
