import React from 'react'
import { 
	Icon, 
	Typography, 
	Switch, 
	FormControlLabel, 
	makeStyles, 
	withWidth, 
	Slide,
	Button, 
    Dialog, 
    DialogContent, 
    DialogActions, 
    DialogTitle, 
    LinearProgress,
	Divider,
	useMediaQuery,
	useTheme,
	Grow,
	Fab,
	Grid,
	IconButton,
	TextField,
	Select,
	MenuItem,
} from '@material-ui/core'
import _ from 'lodash'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
	root: {
		// width: 42,
		// height: 26,
		// padding: 0,
		// margin: theme.spacing(1),

		width: '100%',
		height: 'calc(100vh - 0px)',
		position: 'relative',
		boxSizing: 'border-box',
		'& textarea': {
			font: 'inherit',
		},
		'& a.sc-iemWCZ.ivkEkN': {
			textAlign: 'center',
			textDecoration: 'none',
		},
	},
	muiIconButtonRoot: {
		// border: `1px solid ${theme.palette.almostBlack[300]}`,
		// borderRadius: `8px`,
		padding: `1px`,
		margin: '0px 8px',
		'& .MuiIcon-root': {
			color: theme.palette.almostBlack[600],
			fontSize: `1.75rem`,
		},
	},
	muiIconButtonDisabled: {
		// border: `1px solid ${theme.palette.almostBlack[200]}`,
		'& .MuiIcon-root': {
			color: theme.palette.almostBlack[400],
			fontSize: `1.75rem`,
		},
	},
	muiButtonRoot: {
		fontSize: theme.typography.pxToRem(14),
		lineHeight: theme.typography.pxToRem(14),
		borderRadius: '50%',
		color: theme.palette.almostBlack[600],
		fontFamily: ['"Roboto"', '"sans-serif"'].join(','),
		letterSpacing: theme.typography.pxToRem(0.1),
		fontWeight: '500',
		margin: '0px 8px',
		minWidth: 32,
		width: 32,
		height: 32,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'&.active': {
			color: theme.palette.almostBlack[0],
			background: theme.palette.primary.main,
		},
	},
	threeDotsDiv: {
		fontSize: theme.typography.pxToRem(14),
		color: theme.palette.almostBlack[600],
		fontFamily: ['"Roboto"', '"sans-serif"'].join(','),
		letterSpacing: theme.typography.pxToRem(0.1),
		minWidth: 32,
		width: 32,
		height: 32,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	muiSelectOutlined: {
		fontSize: theme.typography.pxToRem(14),
		color: theme.palette.almostBlack[600],
		fontFamily: ['"Roboto"', '"sans-serif"'].join(','),
		letterSpacing: theme.typography.pxToRem(0.1),
		padding: `7px`,
		paddingRight: `${theme.spacing(4.5)}px !important`,
		// border: `1px solid ${theme.palette.almostBlack[300]}`,
		'&.MuiSelect-select:focus': {
			borderRadius: '4px',
		},
		borderRadius: `4px`,
		'&::after': {
			content: '""',
			position: 'absolute',
			height: '100%',
			width: 1,
			background: theme.palette.almostBlack[300],
			top: '0px',
			right: '26px',
		},
	},
	muiSelectIconOutlined: {
		right: `3px`,
	},
	pageLinksContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		paddingRight: theme.spacing(2),
		[theme.breakpoints.down(600)]: {
			paddingRight: '0px',
			maxWidth: '100%',
		},
		[theme.breakpoints.down(470)]: {
			display: 'flex',
			flexDirection: 'column',
		},
	},
	viewSummaryContainer: {
		display: 'flex',
		// justifyContent: 'flex-start',
		alignItems: 'center',
		paddingLeft: theme.spacing(2),
		letterSpacing: theme.typography.pxToRem(0.1),
		lineHeight: theme.typography.pxToRem(14),
		color: theme.palette.almostBlack[800],
		[theme.breakpoints.down(600)]: {
			justifyContent: 'center',
			padding: '4px 0px 16px 0px',
			maxWidth: '100%',
		},
	},
	paginationContainer: {
		borderTop: `1px solid ${theme.palette.almostBlack[200]}`,
		padding: `${theme.spacing(4)}px 8px`,
		[theme.breakpoints.down(600)]: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	pagination: {
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down(470)]: {
			paddingBottom: '16px',
		},
		'& .MuiFormControl-root': {
			width: '40px',
			alignSelf: 'center',
			'& .MuiInputBase-inputMarginDense': {
				fontSize: '0.875rem',
				fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
				fonWeight: '400',
				lineHeight: '1.43',
				letterSpacing: '0.01071em',
			},
		},
		'& .MuiTypography-body1': {
			fontSize: '0.875rem',
			fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
			fonWeight: '400',
			lineHeight: '1.43',
			letterSpacing: '0.01071em',
			alignSelf: 'center',
		},
	},
}))

const CustomPagination = (props) => {

    const {
        data,
        paginationFooterDetails,
        page,
        limit,
        smallScreen,
        goToPage,
        setPageLimit,
    } = props

    const classes = useStyles()

    const navigateToNextPage = () => {
		goToPage(parseInt(page) + 1)
	}

	const navigateToPrevPage = () => {
		goToPage(parseInt(page) - 1)
	}

	const navigateToLastPage = () => {
		goToPage(parseInt(_.last(_.get(paginationFooterDetails, 'paginationNumbers'))))
	}

	const navigateToFirstPage = () => {
		goToPage(parseInt(_.first(_.get(paginationFooterDetails, 'paginationNumbers'))))
	}

	const OnTextChange = (e) => {
		let value = parseInt(e.target.value)
		let compare =
			parseInt(e.target.value) > _.last(_.get(paginationFooterDetails, 'paginationNumbers')) ||
			parseInt(e.target.value) < _.first(_.get(paginationFooterDetails, 'paginationNumbers'))
		setTimeout(() => {
			if (_.isNaN(value) || compare) {
				goToPage(1)
			} else {
				goToPage(value)
			}
		}, 1500)
	}


    return (
        <div>
            {!_.isEmpty(data !== '-' && data) && !_.isEmpty(_.get(paginationFooterDetails, 'paginationNumbers')) && (
            <Grid container className={classes.paginationContainer}>
                <Grid item xs={4} sm={2} container className={classes.viewSummaryContainer} justifyContent='flex-start'>
                    <Typography variant='body2'>{_.get(paginationFooterDetails, 'viewSummary', '')}</Typography>
                </Grid>
                <Grid item xs={4} sm={10} className={classes.pageLinksContainer}>
                    <div className={classes.pagination}>
                    <IconButton
                        classes={{
                            root: classes.muiIconButtonRoot,
                            disabled: classes.muiIconButtonDisabled,
                        }}
                        disableRipple
                        onClick={navigateToFirstPage}
                        disabled={!_.get(paginationFooterDetails, 'isPrevAvail')}
                    >
                        <Icon>keyboard_double_arrow_left</Icon>
                    </IconButton>
                    <IconButton
                        classes={{
                            root: classes.muiIconButtonRoot,
                            disabled: classes.muiIconButtonDisabled,
                        }}
                        disableRipple
                        onClick={navigateToPrevPage}
                        disabled={!_.get(paginationFooterDetails, 'isPrevAvail')}
                    >
                        <Icon>chevron_left</Icon>
                    </IconButton>
                    {smallScreen ?
                    <Button
                        key={page}
                        classes={{ root: classes.muiButtonRoot }}
                        onClick={() => {
                            goToPage(page)
                        }}
                        className={classnames({
                            active: page === parseInt(page),
                        })}
                        disableRipple
                    >
                        {page}
                    </Button> : 
                    _.get(paginationFooterDetails, 'paginationNumbers').map((element, idx) => {
                        if (typeof element === 'number')
                            return (
                                <Button
                                    key={element}
                                    classes={{ root: classes.muiButtonRoot }}
                                    onClick={() => {
                                        goToPage(element)
                                    }}
                                    className={classnames({
                                        active: element === parseInt(page),
                                    })}
                                    disableRipple
                                >
                                    {element}
                                </Button>
                            )
                        else
                            return (
                                <div key={element + idx} className={classes.threeDotsDiv}>
                                    <span key={`${idx}-dot`}>{element}</span>
                                </div>
                            )
                    })}
                    <IconButton
                        classes={{
                            root: classes.muiIconButtonRoot,
                            disabled: classes.muiIconButtonDisabled,
                        }}
                        disableRipple
                        onClick={navigateToNextPage}
                        disabled={!_.get(paginationFooterDetails, 'isNextAvail')}
                    >
                        <Icon>chevron_right</Icon>
                    </IconButton>
                    <IconButton
                        classes={{
                            root: classes.muiIconButtonRoot,
                            disabled: classes.muiIconButtonDisabled,
                        }}
                        disableRipple
                        onClick={navigateToLastPage}
                        disabled={!_.get(paginationFooterDetails, 'isNextAvail')}
                    >
                        <Icon>keyboard_double_arrow_right</Icon>
                    </IconButton>
                    </div>
                    <div className={classes.pagination}>
                    <TextField size='small' className={classes.pageInput} defaultValue={parseInt(page)} onChange={(e) => OnTextChange(e)} />
                    <Typography variant='body1' style={{ paddingRight: 10 }}>
                        of {_.get(paginationFooterDetails, 'endPageNo', '')}
                    </Typography>
                    <Select
                        value={limit}
                        classes={{
                            outlined: classes.muiSelectOutlined,
                            iconOutlined: classes.muiSelectIconOutlined,
                        }}
                        variant='outlined'
                        onChange={(e) => setPageLimit(e.target.value)}
                    >
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={48}>48</MenuItem>
                    </Select>
                    </div>
                </Grid>
            </Grid>
        )}
        </div>
    )
}

export default CustomPagination
