import { SAGA_ACTIONS } from '../../../constants'

export const getProductsTable = (query, search, cancelExecutor) => {
	return {
		type: SAGA_ACTIONS.GET_PRODUCTS_TABLE,
		query,
		search,
		cancelExecutor,
	}
}
