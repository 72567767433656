import { Field } from 'formik'

const FormikFieldWrapper = (props) => {
	return (
		<Field type={props?.type} name={props?.name}>
			{props?.render || props?.children}
		</Field>
	)
}

export default FormikFieldWrapper
