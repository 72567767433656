import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, Dialog, IconButton, Typography, Box, Icon, Grid, useMediaQuery, useTheme } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import GlassApi from 'services/glass/api'

import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import _ from 'lodash'
import { Swiper, SwiperSlide } from 'swiper/react'
import ReactPlayer from 'react-player'
import { getImgUrl, getErrMsg } from 'utils'
import { hideLoader, showErrorMessage } from 'services/loader/actions'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'
import VideocamIcon from '@material-ui/icons/Videocam'
import YouTubeIcon from '@material-ui/icons/YouTube'

const images = [
	'https://placekitten.com/600/400',
	'https://placekitten.com/601/400',
	'https://placekitten.com/602/400',
	// Add more image URLs as needed
]

const styles = makeStyles((theme) => ({
	dialogContainer: {
		'& .MuiDialog-paperWidthSm': {
			maxWidth: 'fit-content',
		},
	},
	dialogRoot: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},

	swiperRoot: {
		height: '100%',
		[theme.breakpoints.up(1250)]: {
			height: '720px',
		},
		[theme.breakpoints.between(1100, 1250)]: {
			height: '620px',
		},
		[theme.breakpoints.between(550, 1100)]: {
			height: '550px',
		},
		[theme.breakpoints.between(600, 550)]: {
			height: '450px',
		},
		backgroundColor: '#414243',
		'& .MuiIcon-fontSizeSmall': {
			fontSize: '2.25rem',
		},
	},
	swiperContainer: {
		'& .swiper-slide-active': {
			width: `-webkit-fill-available !important`,
		},
		'& .swiper-wrapper': {
			alignItems: 'center !important',
		},
		'& .swiper-container-autoheight': {
			height: '400px !important',
			display: 'flex !important',
			alignItems: 'center !important',
		},
	},
	slideContent: {
		// display: 'flex',
		justifyContent: 'center',
		// alignItems: 'center',
		width: '100%',
		// height: '100%',
		padding: '5px',
		boxSizing: 'border-box',

		height: '400px !important',
		display: 'flex !important',
		alignItems: 'center !important',
	},
	slideImage: {
		maxHeight: '90vh',
		maxWidth: '100%',
	},
	mobileStepper: {
		width: '100%',
		borderRadius: '10px',
	},
	navBtn: {
		color: '#FFFFFF',
		'& .MuiIconButton-root.Mui-disabled': {
			color: 'rgba(0, 0, 0, 0.54)',
		},
	},
	noPrevRoot: {
		backgroundColor: '#4c494c',
		color: theme.palette.common.white,
		borderRadius: '10px',
		textAlign: 'center',
	},
	downloadBtn: {
		marginTop: theme.spacing(2),
	},
	image: {
		height: '-webkit-fill-available',
	},
	thumbnailImage: {
		height: 'calc(100% - 2px)',
		width: 'calc(100% - 2px)',
	},
	noThumbnails: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		top: '1px',
		left: '1px',
		height: 'calc(100% - 2px)',
		width: 'calc(100% - 2px)',
		backgroundImage: `linear-gradient(rgb(49 46 46), rgb(12 12 12))`,
	},
	videoContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		top: '1px',
		left: '1px',
		height: 'calc(100% - 2px)',
		width: 'calc(100% - 2px)',
		backgroundImage: `linear-gradient(rgb(255 255 255 / 0%), rgb(255 249 249 / 0%))`,
	},
	emptyThumbnail: {
		backgroundImage: `linear-gradient(rgb(53 47 47), rgb(47 32 32))`,
	},
	videocamIcon: {
		fontSize: '40px',
		color: '#fafafb',
		backgroundColor: '#ff5722',
		borderRadius: '8px',
	},
	youtubeIcon: {
		color: '#ff0000',
		fontSize: '65px',
	},
}))

const isImage = (mediaType) => {
	return ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'].indexOf(mediaType) > -1
}

const isPlayerSupport = (mediaType) => {
	return (
		[
			'video/mp4',
			'video/3gpp',
			'video/wmv',
			'video/mkv',
			'video/webm',
			'video/avi',
			'video/avchd',
			'video/flv',
			'video/swf',
			'video/f4v',
			'video/mpeg-2',
			'video/x-ms-wmv',
			'application/octet-stream',
			'audio/mpeg',
			'audio/mp3',
			'audio/3gp',
			'audio/flac',
			'audio/cda',
			'audio/wav',
		].indexOf(mediaType) > -1
	)
}

const isYoutubeVideo = (mediaType) => {
	return ['url'].indexOf(mediaType) > -1
}

// const DialogTitle = withStyles(styles)((props) => {
// 	const { children, classes, onClose, ...other } = props
// 	return (
// 		<MuiDialogTitle disableTypography className={classes.root} {...other}>
// 			<Typography variant='h6'>{children}</Typography>
// 			{onClose ? (
// 				<IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
// 					<CloseIcon />
// 				</IconButton>
// 			) : null}
// 		</MuiDialogTitle>
// 	)
// })

// const DialogContent = withStyles((theme) => ({
// 	root: {
// 		padding: theme.spacing(2),
// 	},
// }))(MuiDialogContent)

// const DialogActions = withStyles((theme) => ({
// 	root: {
// 		margin: 0,
// 		padding: theme.spacing(1),
// 	},
// }))(MuiDialogActions)

const SliderContent = (props) => {
	const { file, filesToShow, onClickDownload, accessToken, index, activeIdx } = props
	const classes = styles()

	const [showVideo, setShowVideo] = useState(false)

	const onClickCapture = () => {
		setShowVideo(true)
	}

	return (
		<>
			{isImage(file?.file_type) ? (
				<>
					<img className={classes.image} alt={'Image Loading...'} src={file?.url} />
				</>
			) : isPlayerSupport(file?.file_type) ? (
				!showVideo ? (
					<div onClick={() => onClickCapture()}>
						{!_.isEmpty(file?.thumbnail_path) ? (
							<>
								<img className={classes.thumbnailImage} alt={'Video Loading...'} src={file?.src} />
								<div className={classnames(_.isEmpty(file?.thumbnail_path) ? classes.noThumbnails : classes.videoContainer)}>
									<VideocamIcon className={classes.videocamIcon} />
								</div>
							</>
						) : (
							<>
								<div className={classes.emptyThumbnail}></div>
								<div className={classnames(_.isEmpty(file?.thumbnail_path) ? classes.noThumbnails : classes.videoContainer)}>
									<VideocamIcon className={classes.videocamIcon} />
								</div>
							</>
						)}
					</div>
				) : (
					<ReactPlayer
						className={classes.reactPlayer}
						url={file?.url}
						width={'100%'}
						height={'auto'}
						playing={showVideo && _.isEqual(index, activeIdx)}
						controls={true}
						preload={true}
						config={{
							youtube: {
								playerVars: { showinfo: 1 },
							},
						}}
						fallback={<img src={file?.url} alt='Fallback Thumbnail' />}
					/>
				)
			) : isYoutubeVideo(file?.file_type) ? (
				!showVideo ? (
					<div onClick={() => onClickCapture()}>
						{!_.isEmpty(file?.thumbnail_path) ? (
							<img className={classes.thumbnailImage} alt={'Video Loading...'} src={file?.src} />
						) : (
							<div className={classes.emptyThumbnail}></div>
						)}
						<div className={classnames(_.isEmpty(file?.thumbnail_path) ? classes.noThumbnails : classes.videoContainer)}>
							<YouTubeIcon className={classes.youtubeIcon} />
						</div>
					</div>
				) : (
					<ReactPlayer
						className={classes.reactPlayer}
						url={file?.url}
						width={'100%'}
						height={'400px'}
						playing={showVideo && _.isEqual(index, activeIdx)}
						controls={true}
						preload={true}
						config={{
							youtube: {
								playerVars: { showinfo: 1 },
							},
						}}
						fallback={<img src={file?.url} alt='Fallback Thumbnail' />}
					/>
				)
			) : (
				<>
					<Box paddingTop={2} paddingLeft={3} paddingBottom={2} paddingRight={3} className={classes.noPrevRoot}>
						<Typography variant='body1'>{`${file?.file_name}` || `No preview available`}</Typography>
						<Button
							startIcon={<Icon>get_app</Icon>}
							size='small'
							variant='contained'
							disableElevation
							color='primary'
							className={classes.downloadBtn}
							onClick={() => {
								onClickDownload(getImgUrl(accessToken, file?.file_path))
							}}
						>
							Download
						</Button>
					</Box>
				</>
			)}
		</>
	)
}

export default function Popup(props) {
	const {
		setOpen,
		open,
		handleClickOpen,
		handleClose,
		filesToShow,
		attachments_count,
		accessToken,
		fileList,
		sticky_title,
		activeIdx,
		setActiveIdx,
		sticky,
	} = props

	const classes = styles()
	const theme = useTheme()
	const dispatch = useDispatch()
	const isSmartDevice = useMediaQuery(theme.breakpoints.down(600))
	const [attachments, setAttachments] = useState(filesToShow)
	const [swiper, setSwiper] = useState(null)

	useEffect(() => {
		if (open === true) {
			const onSuccess = (res) => {
				const attachments = res?.data?.data?.attachments
				const customAttachments = _.map(attachments, (file) => ({
					...file,
					url: _.isEqual(file?.file_type, 'url') ? file?.file_path : getImgUrl(accessToken, file?.file_path),
					src: isImage(file?.file_type) ? getImgUrl(accessToken, file?.file_path) : getImgUrl(accessToken, file?.thumbnail_path),
				}))
				setAttachments(customAttachments)
			}
			const onFailure = (err) => {
				dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
			}
			GlassApi.getStickyAttachments(sticky?.glass_code, sticky?.sticky_code).then(onSuccess, onFailure)
		}
	}, [sticky, open === true])

	useEffect(() => {
		if (swiper && swiper.activeIndex !== activeIdx) {
			swiper.slideTo(activeIdx, 0)
		}
	}, [activeIdx, swiper])

	const params = {
		onSwiper: setSwiper,
		autoHeight: true,
		slidesPerView: 1,
	}

	const handlePrev = () => {
		// setActiveIdx((prev) => (prev === 0 ? images.length - 1 : prev - 1))
		if (!_.isEmpty(swiper)) {
			swiper.slidePrev()
			setActiveIdx(activeIdx - 1)
		}
	}

	const handleNext = () => {
		// setActiveIdx((prev) => (prev === images.length - 1 ? 0 : prev + 1))
		if (!_.isEmpty(swiper)) {
			swiper.slideNext()
			setActiveIdx(activeIdx + 1)
		}
	}

	const onClickDownload = (filePath) => {
		window.open(filePath, '_blank')
	}

	return (
		<div>
			<Dialog
				onClose={handleClose}
				aria-labelledby='customized-dialog-title'
				open={open}
				className={classes.dialogContainer}
				fullScreen={isSmartDevice ? true : false}
			>
				<MuiDialogTitle disableTypography className={classes.dialogRoot} id='customized-dialog-title'>
					<Grid container>
						<Grid item lg={11} xs={11}>
							<Typography variant='h6'>{sticky_title}</Typography>
						</Grid>
						<Grid item lg={1} xs={1}>
							<IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
				</MuiDialogTitle>
				{/* <DialogContent dividers>
					<Typography gutterBottom>
						Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
						consectetur ac, vestibulum at eros.
					</Typography>
					<Typography gutterBottom>
						Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
					</Typography>
					<Typography gutterBottom>
						Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui.
						Donec ullamcorper nulla non metus auctor fringilla.
					</Typography>
				</DialogContent> */}
				<Grid className={classes.swiperRoot} container alignItems='center' justifyContent='center'>
					<Grid item lg={1} xs={1} container justifyContent='center'>
						<IconButton className={classes.navBtn} disabled={activeIdx === 0} onClick={handlePrev} size='small'>
							<Icon fontSize='small'>chevron_left</Icon>
						</IconButton>
					</Grid>
					<Grid item lg={10} xs={10}>
						<Swiper className={classes.swiperContainer} {...params}>
							{_.map(attachments, (file, index) => (
								<SwiperSlide key={file?.attachment_id} className={classes.slideContent}>
									<SliderContent file={file} onClickDownload={onClickDownload} accessToken={accessToken} index={index} activeIdx={activeIdx} />
								</SwiperSlide>

								// <SwiperSlide key={file?.attachment_id} className={classes.slideContent}>
								// 	{isImage(file?.file_type) ? (
								// 		<img className={classes.slideImage} alt={'Image Loading...'} src={file?.url} />
								// 	) : isPlayerSupport(file?.file_type) ? (
								// 		<ReactPlayer
								// 			className={classes.reactPlayer}
								// 			url={file?.url}
								// 			width={'100%'}
								// 			height={'auto'}
								// 			playing={false}
								// 			controls={true}
								// 			preload={true}
								// 			config={{
								// 				youtube: {
								// 					playerVars: { showinfo: 1 },
								// 				},
								// 			}}
								// 			fallback={<img src={file?.url} alt='Fallback Thumbnail' />}
								// 		/>
								// 	) : isYoutubeVideo(file?.file_type) ? (
								// 		<ReactPlayer
								// 			className={classes.reactPlayer}
								// 			url={file?.url}
								// 			width={'100%'}
								// 			height={'400px'}
								// 			playing={false}
								// 			controls={true}
								// 			preload={true}
								// 			config={{
								// 				youtube: {
								// 					playerVars: { showinfo: 1 },
								// 				},
								// 			}}
								// 			fallback={<img src={file?.url} alt='Fallback Thumbnail' />}
								// 		/>
								// 	) : (
								// 		<Box paddingTop={2} paddingLeft={3} paddingBottom={2} paddingRight={3} className={classes.noPrevRoot}>
								// 			<Typography variant='body1'>{`${file?.file_name}` || `No preview available`}</Typography>
								// 			<Button
								// 				startIcon={<Icon>get_app</Icon>}
								// 				size='small'
								// 				variant='contained'
								// 				disableElevation
								// 				color='primary'
								// 				className={classes.downloadBtn}
								// 				onClick={() => {
								// 					onClickDownload(getImgUrl(accessToken, file?.file_path))
								// 				}}
								// 			>
								// 				Download
								// 			</Button>
								// 		</Box>
								// 	)}
								// </SwiperSlide>
							))}
						</Swiper>
					</Grid>
					<Grid item lg={1} xs={1} container justifyContent='center'>
						<IconButton className={classes.navBtn} disabled={_.isEqual(activeIdx, attachments?.length - 1)} onClick={handleNext} size='small'>
							<Icon fontSize='small'>chevron_right</Icon>
						</IconButton>
					</Grid>
				</Grid>
			</Dialog>
		</div>
	)
}
