import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'
import _ from 'lodash'
import { format } from 'date-fns'

const CancelToken = Axios.CancelToken

const params = (param, search, redirect, redirectFrom, redirectFilter, redirectData) => {

	// let startDate = format(
	// 	_.isString(param?.date_range?.startDate) ? new Date(param?.date_range?.startDate) : param?.date_range?.startDate,
	// 	'yyyy-MM-dd'
	// )
	// let endDate = format(_.isString(param?.date_range?.endDate) ? new Date(param?.date_range?.endDate) : param?.date_range?.endDate, 'yyyy-MM-dd')

	let query = {
		page_number: parseInt(param.page),
		page_row_count: parseInt(param.limit),
		// date: {
		// 	from: `${new Date(startDate).toUTCString()}`,
		// 	to: `${new Date(endDate).toUTCString()}`,
		// },
		// counterfeit: _.get(param, 'isOnlyCounterfeits', false),
		search_text: search ? search : '',
		redirect_url: redirect,
		redirect: !_.isEmpty(redirectFrom) ? redirectFrom : null,
		sticky_code: !_.isEmpty(redirectData?.sticky_code) ? redirectData?.sticky_code : null,
		is_parent: !_.isEmpty(redirectData?.sticky_code) ? redirectData?.is_parent : false,
		filter_obj: !_.isEmpty(redirectFrom) ? redirectFilter : null,
	}
	return query
}

export default {
	getModelxTableData: (query, search, redirect, redirectFrom, redirectFilter, redirectData, cancelExecutor) => {
		if (cancelExecutor.current) cancelExecutor.current()
		return Axios.post(API_ENDPOINTS.MODELX_LIST, params(query, search, redirect, redirectFrom, redirectFilter, redirectData), {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			// cancelToken: new CancelToken(function executor(c) {
			// 	cancelExecutor.current = c
			// }),
		})
	},
	modelxJobs: (modelxId, data, cancelExecutor) => {
		if (cancelExecutor.current) cancelExecutor.current()
		return Axios.post(
			API_ENDPOINTS.MODELX_JOBS,
			{
				...data,
			},
			{
				headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { modelxId } },
				cancelToken: new CancelToken(function executor(c) {
					cancelExecutor.current = c
				}),
			}
		)
	},
	getModelxDataDetails: (modelxId, cancelExecutor) => {
		if (cancelExecutor.current) cancelExecutor.current()
		return Axios.get(API_ENDPOINTS.MODELX_DETAILS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { modelxId }, },
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
		})
	},
	getModelxLogs: (modelxId, cancelExecutor) => {
		if (cancelExecutor.current) cancelExecutor.current()
		return Axios.get(API_ENDPOINTS.MODELX_LOGS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { modelxId }, },
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
		})
	},
	deleteModelx: (modelxId, successCallback, errorCallback) => {
		Axios.delete(`${API_ENDPOINTS.MODELX_DETAILS}`, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { modelxId },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	getExportView: (cancelExecutor) => {
		if (cancelExecutor.current) cancelExecutor.current()
		return Axios.get(API_ENDPOINTS.MODELX_EXPORT_VIEW, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
		})
	},
	changeExportView: function (searchParams, cancelExecutor) {
		if (cancelExecutor.current) cancelExecutor.current()
		return Axios.put(
			API_ENDPOINTS.MODELX_EXPORT_VIEW,
			{
				...searchParams,
			},
			{
				headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
				cancelToken: new CancelToken(function executor(c) {
					cancelExecutor.current = c
				}),
			}
		)
	},
	getExportResults: function (searchParams, cancelExecutor) {
		if (cancelExecutor.current) cancelExecutor.current()
		return Axios.post(
			API_ENDPOINTS.MODELX_EXPORT_RESULTS,
			{
				...searchParams,
			},
			{
				headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
				cancelToken: new CancelToken(function executor(c) {
					cancelExecutor.current = c
				}),
			}
		)
	},
	getExport: function (searchParams, cancelExecutor) {
		if (cancelExecutor.current) cancelExecutor.current()
		return Axios.post(
			API_ENDPOINTS.MODELX_EXPORT,
			{
				...searchParams,
			},
			{
				headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
				cancelToken: new CancelToken(function executor(c) {
					cancelExecutor.current = c
				}),
			}
		)
	},
	fetchModelxDashboard: (search, offset, limit, cancelExecutor, successCallback, errorCallback) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		Axios.get(`${API_ENDPOINTS.MODELX_DASHBOARD}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { search, offset, limit },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchModelxDataSource: (dashboardId, search, offset, limit, cancelExecutor, successCallback, errorCallback) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		Axios.get(`${API_ENDPOINTS.MODELX_DATASOURCE}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { dashboardId, search, offset, limit },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchModelxFields: (dsId, search, offset, limit, cancelExecutor, successCallback, errorCallback) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		Axios.get(`${API_ENDPOINTS.MODELX_FIELDS}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { dsId, search, offset, limit },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchModelxSettings: (cancelExecutor, successCallback, errorCallback) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		Axios.get(`${API_ENDPOINTS.MODELX_SETTINGS}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	changeModelxSettings: (data, successCallback, errorCallback) => {
		return Axios.put(`${API_ENDPOINTS.MODELX_SETTINGS}`,
			{ ...data },
			{
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
			}}
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
}

