import {
	Card,
	CardHeader,
	makeStyles,
	Typography,
	Avatar,
	CardContent,
	CardActions,
	ButtonBase,
	Icon,
	Grid,
	Button,
	Divider,
	Box,
	Snackbar,
	IconButton,
	FormControlLabel,
	Checkbox,
	Container,
} from '@material-ui/core'
import Attachments from 'components/Attachments'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { convertGmtToLocalTime, formTheName, getErrMsg, getImgUrl, stringToHslColor } from 'utils'
import _ from 'lodash'
import { Skeleton } from '@material-ui/lab'
import GlassApi from 'services/glass/api'
import MytaskApi from 'services/myTask/api'
import { useDispatch, useSelector } from 'react-redux'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { hideLoader, showLoader, showErrorMessage } from 'services/loader/actions'
import useLazyPaginate from 'hooks/useLazyPaginate'
import CommentBox from 'components/CommentBox'
import update from 'immutability-helper'
import PropTypes from 'prop-types'
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined'
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ACTION_TYPES } from '../../../constants'

const useStyles = makeStyles((theme) => ({
	root: {
		// margin: theme.spacing(1, 0),
		// paddingBottom: theme.spacing(2),
		padding: '16px',
		'& .MuiCardContent-root': {
			padding: theme.spacing(0, 1, 0, 7),
		},
		'& .MuiCardHeader-root': {
			padding: theme.spacing(1),
		},
		'& .MuiCardHeader-action': {
			marginTop: 0,
			marginRight: 0,
		},
		[theme.breakpoints.down('sm')]: {
			'& .MuiCardHeader-root': {
				padding: theme.spacing(1),
				paddingBottom: 0,
			},
			'& .MuiCardContent-root': {
				padding: theme.spacing(0, 1, 1, 1),
			},
			'& .MuiCardHeader-avatar': {
				marginRight: theme.spacing(1),
			},
		},
		'& .wysiwyg-mention': {
			textDecoration: 'none',
			color: '#1236ff',
			backgroundColor: '#f0fbff',
			padding: '1px 2px',
			borderRadius: '2px',
		},
	},
	cardRoot: {
		backgroundColor: '#fcfcfc',
		margin: theme.spacing(1, 0),
	},
	avatar: {
		width: 32,
		height: 32,
		fontSize: 17,
		// marginRight: theme.spacing(1),
	},
	chatCard: {
		paddingTop: '8px',
	},
	mainChat: {
		paddingTop: '8px',
		paddingBottom: '8px',
	},
	subChat: {
		paddingTop: '8px',
		paddingBottom: '8px',
		// paddingLeft: '40px',
	},
	commentAvatar: {
		width: 24,
		height: 24,
		fontSize: 9,
	},
	comment: {
		paddingTop: '8px',
		paddingBottom: '8px',
		flexFlow: 'nowrap',
		display: 'block',
	},
	reply: {
		paddingTop: '8px',
		paddingBottom: '8px',
		flexFlow: 'nowrap',
	},
	subCommentBox: {
		'& .MuiOutlinedInput-multiline': {
			[theme.breakpoints.down('300')]: {
				width: '70px',
			},
			[theme.breakpoints.between('300', '350')]: {
				width: '127px',
			},
			[theme.breakpoints.between('350', '395')]: {
				width: '175px',
			},
			[theme.breakpoints.between('395', '500')]: {
				width: '200px',
			},
			[theme.breakpoints.between('960', '1134')]: {
				width: '200px',
			},
			width: '300px',
			borderRadius: '24px',
			padding: '8.5px 14px',
		},
	},
	commentBox: {
		'& .MuiOutlinedInput-multiline': {
			[theme.breakpoints.down('300')]: {
				width: '70px',
			},
			[theme.breakpoints.between('300', '350')]: {
				width: '130px',
			},
			[theme.breakpoints.between('350', '395')]: {
				width: '175px',
			},
			[theme.breakpoints.between('395', '420')]: {
				width: '220px',
			},
			[theme.breakpoints.between('420', '530')]: {
				width: '258px',
			},
			[theme.breakpoints.between('960', '1185')]: {
				width: '265px',
			},
			width: '370px',
			borderRadius: '24px',
			padding: '8.5px 14px',
		},
	},
	loadMore: {
		paddingTop: '8px',
	},
	subLoadMore: {
		textAlign: 'end',
	},
	chatBox: {
		display: 'flex',
		flexDirection: 'column',
		'& .MuiGrid-item': {
			maxWidth: '-webkit-fill-available',
		},
	},
	chatHeader: {
		'& .MuiCardHeader-title': {
			fontSize: '14px',
		},
		'& .MuiCardHeader-subheader': {
			fontSize: '14px',
		},
	},
	chatText: {
		backgroundColor: '#F2F2F2',
		borderRadius: '0px 14px 14px 14px',
		padding: '5px 5px',
		// display: 'inline-flex',
		// display: 'inline-table',
		fontSize: '14px',
		'& .MuiCardHeader-root': {
			paddingTop: '2px',
			paddingLeft: '2px',
		},
		'& .MuiCardContent-root': {
			paddingLeft: '16px',
		},
	},
	activityReactions: {
		marginLeft: '0px',
		display: 'flex',
		flexDirection: 'row',
		paddingTop: '2px',
	},
	reactions: {
		marginLeft: '7px',
		display: 'flex',
		flexDirection: 'row',
		paddingTop: '2px',
	},
	likeIcon: {
		fontSize: '14px',
		padding: '3px 1px',
		color: '#65b17f',
		transform: 'scaleX(-1)',
	},
	loadingIcon: {
		color: '#65b17f',
		cursor: 'pointer',
		display: 'inline-flex',
		alignItems: 'center',
		verticalAlign: 'middle',
		marginRight: '11px',
	},
	refreshIcon: {
		fontSize: '21px',
		padding: '3px 1px',
		color: '#65b17f',
		cursor: 'pointer',
		display: 'inline-flex',
		alignItems: 'center',
		marginRight: '11px',
		verticalAlign: 'middle',
	},
	activityForm: {
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: '#65b17f',
		},
	},
	reactionCount: {
		fontSize: '13px',
		color: 'slategray',
	},
	dislikeIcon: {
		fontSize: '14px',
		padding: '3px 1px',
		color: 'rgb(244, 67, 54)',
	},
	dot: {
		color: 'slategray',
	},
	bar: {
		color: 'slategray',
	},
	replyBtn: {
		fontSize: '10px',
		padding: '0px 0px',
		minWidth: '42px',
	},
	// title: {
	// 	marginBottom: theme.spacing(1),
	// },
	description: {
		margin: theme.spacing(1, 0),
		fontSize: '14px',
	},
	labelsWrap: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
		width: '100%',
		gap: '5px',
	},
	labelBtnBase: {
		height: '28px',
		padding: '3px 9px',
		borderRadius: '2px',
		color: theme.palette.common.white,
	},
	pinnedIcon: {
		transform: 'rotate(45deg)',
	},
	attachmentsSection: {
		width: '100%',
	},
	sortButton: {
		textTransform: 'none',
		color: '#92929d',
		lineHeight: 'normal',
	},
	activitiesHead: { justifyContent: 'space-between' },
	loadingSkeleton: {
		display: 'flex',
		padding: '8px',
		margin: '8px 0px',
		'& .MuiSkeleton-circle': {
			marginRight: '10px',
		},
	},
	lineSkeleton: {
		width: '100%',
	},
	activityTypeItem: {
		textAlign: '-webkit-right',
	},
	mb2: {
		marginBottom: theme.spacing(2),
	},
}))

const Activities = ({
	title,
	stickyId,
	glassCode,
	is_pinned,
	sessionId,
	isEditEnabled,
	showCommentBox,
	showActivity,
	setShowActivity,
	activityTypes,
	glassLabels,
	placeholder,
	socketRef,
	isSocketConnected,
	suggestions,
	showExcludeSystemMessage = false,
	glass_code,
	setGlassDetails,
	glassDetails,
	callSocketFunction,
	openActivity,
	socketIO,
	history,
}) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [buttonUI, setButtonUI] = useState({ btnText: 'Newest first', btnIcon: 'arrow_upward' })
	const [activityState, setActivityState] = useState({
		activityList: [],
		loading: true,
		limit: 5,
		offset: 0,
		totalCount: 0,
		sort_by: 'desc',
		showRefreshSnackbar: false,
		excludeSysLog: true,
	})
	const [replyState, setReplyState] = useState({
		replyList: [],
		replyLoading: true,
		replyLimit: 5,
		replyOffset: 0,
		reply_totalCount: 0,
		reply_sort_by: 'desc',
		reply_showRefreshSnackbar: false,
		reply_activityId: '',
	})
	const [subReplyState, setSubReplyState] = useState({
		subReplyList: [],
		subReplyLoading: true,
		subReplyLimit: 5,
		subReplyOffset: 0,
		subReply_totalCount: 0,
		subReply_sort_by: 'desc',
		subReply_showRefreshSnackbar: false,
		subReply_activityId: '',
		subReply_replyId: '',
	})
	const [commentBoxShow, setCommentBoxShow] = useState(false)
	const [replyBoxShow, setReplyBoxShow] = useState(false)
	const [subReplyBoxShow, setSubReplyBoxShow] = useState(false)
	const [commentValue, setCommentValue] = useState('')
	const [replyValue, setReplyValue] = useState('')
	const [subReplyValue, setSubReplyValue] = useState('')
	const [activityId, setActivityId] = useState('')
	const [replyId, setReplyId] = useState('')
	const [subReplyId, setSubReplyId] = useState('')
	const [replyActivityId, setReplyActivityId] = useState('')
	const [subReply_reply_Id, setSububReply_reply_Id] = useState('')
	const [refresh, setRefresh] = useState(false)

	const createActivityListener = useRef()

	const handleCommentBoxOpen = (activity) => {
		setCommentBoxShow(true)
		setCommentValue('')
		setActivityId(activity?.activity_id)
	}

	const handleReplyBoxOpen = (replyId) => {
		setReplyBoxShow(true)
		setReplyValue('')
		setReplyId(replyId)
	}

	const handleSubReplyBoxOpen = (replyId) => {
		setSubReplyBoxShow(true)
		setSubReplyValue('')
		setSubReplyId(replyId)
	}

	const { activityList, limit, offset, totalCount, sort_by, loading, showRefreshSnackbar, excludeSysLog } = activityState
	const { replyList, replyLimit, replyOffset, reply_totalCount, reply_sort_by, replyLoading, reply_showRefreshSnackbar, reply_activityId } =
		replyState
	const {
		subReplyList,
		subReplyLimit,
		subReplyOffset,
		subReply_totalCount,
		subReply_sort_by,
		subReplyLoading,
		subReply_showRefreshSnackbar,
		subReply_activityId,
		subReply_replyId,
	} = subReplyState
	const activityListSynced = useRef(activityList)
	const replyListSynced = useRef(replyList)
	const subReplyListSynced = useRef(subReplyList)

	useEffect(() => {
		activityListSynced.current = activityList
	}, [activityList])

	useEffect(() => {
		replyListSynced.current = replyList
	}, [replyList])

	useEffect(() => {
		subReplyListSynced.current = subReplyList
	}, [subReplyList])

	const activityAddListener = useRef()
	const activityUpdateListener = useRef()
	const token = useSelector((state) => _.get(state, 'session.authToken'))
	const userDetails = useSelector((state) => _.get(state, 'session.user.userDetails', {}))

	useEffect(() => {
		setRefresh(false)
		if (showActivity) {
			GlassApi.getStickyActivities(
				sort_by,
				offset,
				limit,
				is_pinned ? true : null,
				glassCode,
				stickyId,
				excludeSysLog,
				(res) => {
					let actions = _.get(res, 'data.data.activities', [])
					setActivityState((prevState) => ({
						...prevState,
						loading: false,
						totalCount: _.get(res, 'data.data.total_count'),
						activityList: offset === 0 ? actions : [...prevState.activityList, ...actions],
					}))
					setGlassDetails((prevState) => ({
						...prevState,
						activityCount: _.get(res, 'data.data.total_count'),
					}))
				},
				(err) => {
					setActivityState((prevState) => ({ ...prevState, loading: false }))
					dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
				}
			)
		}
	}, [dispatch, glassCode, is_pinned, limit, offset, sort_by, stickyId, excludeSysLog, showActivity, refresh === true])

	const refreshActivity = () => {
		setActivityState((prevState) => ({
			...prevState,
			loading: true,
			activityList: [],
			limit: 5,
			offset: 0,
			totalCount: 0,
			sort_by: 'desc',
			showRefreshSnackbar: false,
		}))
		setRefresh(true)
	}

	const getGlassDetails = () => {
		const onSuccess = (res) => {
			let data = res?.data?.data
			setGlassDetails((prevState) => ({
				...prevState,
				activityTypes: data?.activity_type,
				glassLabels: data?.labels,
				glassMembers: data?.glass_members,
				glassCode: data?.glass_code,
			}))
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
			setGlassDetails((prevState) => ({
				...prevState,
				activityTypes: [],
				glassLabels: [],
				glassMembers: [],
				glassCode: '',
			}))
		}
		if (_.isEmpty(glassDetails.glassCode)) {
			MytaskApi.getMytaskGlassDetails(glass_code).then(onSuccess, onFailure)
		}
	}

	useEffect(() => {
		getGlassDetails()
	}, [showActivity, showCommentBox])

	const loadMoreActivities = () => {
		setActivityState((prevState) => ({ ...prevState, offset: activityList.length, loading: true }))
	}

	const updateActivityItem = useCallback(
		(activityItemToUpt) => {
			let opToPerform = null
			let subtractTotal = 0
			if (sort_by === 'desc') {
				let activityIndex = _.findIndex(activityListSynced.current, { activity_id: activityItemToUpt?.activity_id })
				if (activityIndex > -1) {
					const updateActivity = { [activityIndex]: { $set: { ...activityItemToUpt } } }
					opToPerform = !is_pinned ? updateActivity : !activityItemToUpt?.is_pinned ? { $splice: [[activityIndex, 1]] } : updateActivity
					subtractTotal = opToPerform === updateActivity ? 0 : -1
				} else {
					opToPerform = is_pinned ? { $unshift: [activityItemToUpt] } : null
					subtractTotal = is_pinned ? 1 : 0
				}
			}
			if (opToPerform === null) {
				setActivityState((prevState) => ({
					...prevState,
					showRefreshSnackbar: true,
				}))
			} else {
				setActivityState((prevState) => ({
					...prevState,
					activityList: update(prevState?.activityList, opToPerform),
					totalCount: prevState.totalCount + subtractTotal,
				}))
				setGlassDetails((prevState) => ({
					...prevState,
					activityCount: prevState.activityCount + subtractTotal,
				}))
			}
		},
		[sort_by, is_pinned]
	)

	const updateReplyItem = useCallback((replyItemToUpt) => {
		let replyIndex = _.findIndex(replyListSynced.current, { reply_id: replyItemToUpt?.reply_id })
		let opToPerform = null
		if (replyIndex > -1) {
			const updateActivity = { [replyIndex]: { $set: { ...replyItemToUpt } } }
			opToPerform = updateActivity
		} else {
			opToPerform = null
		}
		if (opToPerform === null) {
			setReplyState((prevState) => ({
				...prevState,
			}))
		} else {
			setReplyState((prevState) => ({
				...prevState,
				replyList: update(prevState?.replyList, opToPerform),
			}))
		}
	}, [])

	const updateSubReplyItem = useCallback((replyItemToUpt) => {
		let replyIndex = _.findIndex(subReplyListSynced.current, { reply_id: replyItemToUpt?.reply_id })
		let opToPerform = null
		if (replyIndex > -1) {
			const updateActivity = { [replyIndex]: { $set: { ...replyItemToUpt } } }
			opToPerform = updateActivity
		} else {
			opToPerform = null
		}
		if (opToPerform === null) {
			setSubReplyState((prevState) => ({
				...prevState,
			}))
		} else {
			setSubReplyState((prevState) => ({
				...prevState,
				subReplyList: update(prevState?.subReplyList, opToPerform),
			}))
		}
	}, [])

	const updateActivity = (activity) => {
		dispatch(showLoader('Loading please wait...'))
		let formData = new FormData()
		formData.append('is_pinned', _.toString(!_.get(activity, 'is_pinned', false)))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			updateActivityItem(res?.data?.data)
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		GlassApi.updateStickyActivities(glassCode, stickyId, activity?.activity_id, sessionId, formData).then(onSuccess, onFailure)
	}

	const updateActivityComment = (data, formik, activity) => {
		dispatch(showLoader('Loading please wait...'))
		let formData = new FormData()
		formData.append('desc', data?.desc)
		formData.append('is_parent', true)
		formData.append('parent_id', '')
		data?.activity_type_id !== null && formData.append('activity_type_id', data?.activity_type_id?.activity_id)
		formData.append('labels', _.join(data?.labels, ','))
		data?.attachments.map((file) => formData.append('attachments', file))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			setCommentValue('')
			setCommentBoxShow(false)
			_.isEqual(activity?.activity_id, reply_activityId)
				? setReplyState((prevState) => ({
						...prevState,
						replyList: [res?.data?.data?.reply[0], ...prevState.replyList],
						replyOffset: 0,
						reply_activityId: res?.data?.data?.activity_id,
				  }))
				: setReplyState((prevState) => ({
						...prevState,
						replyList: [res?.data?.data?.reply[0]],
						replyOffset: 0,
						reply_activityId: res?.data?.data?.activity_id,
				  }))
			updateActivityItem(res?.data?.data)
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
			setCommentValue('')
			setCommentBoxShow(false)
		}
		GlassApi.updateStickyActivitiesReply(glassCode, stickyId, activity?.activity_id, sessionId, formData).then(onSuccess, onFailure)
	}

	const updateActivityReply = (data, formik, activity, replyId) => {
		dispatch(showLoader('Loading please wait...'))
		let formData = new FormData()
		formData.append('desc', data?.desc)
		data?.activity_type_id !== null && formData.append('activity_type_id', data?.activity_type_id?.activity_id)
		formData.append('labels', _.join(data?.labels, ','))
		data?.attachments.map((file) => formData.append('attachments', file))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			setReplyValue('')
			setReplyBoxShow(false)
			setSubReplyBoxShow(false)
			_.isEqual(replyId, subReply_replyId)
				? setSubReplyState((prevState) => ({
						...prevState,
						subReplyList: [res?.data?.data?.sub_reply[0], ...prevState.subReplyList],
						subReplyOffset: 0,
						subReply_activityId: res?.data?.data?.activity_id,
						subReply_replyId: res?.data?.data?.reply_id,
				  }))
				: setSubReplyState((prevState) => ({
						...prevState,
						subReplyList: [res?.data?.data?.sub_reply[0]],
						subReplyOffset: 0,
						subReply_activityId: res?.data?.data?.activity_id,
						subReply_replyId: res?.data?.data?.reply_id,
				  }))
			updateReplyItem(res?.data?.data)
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
			setReplyValue('')
			setReplyBoxShow(false)
			setSubReplyBoxShow(false)
		}
		GlassApi.updateStickyActivitiesSubReply(glassCode, stickyId, activity?.activity_id, replyId, sessionId, formData).then(onSuccess, onFailure)
	}

	const updateActivitySubReply = (activity, replyId) => {
		dispatch(showLoader('Loading please wait...'))
		let formData = {
			desc: subReplyValue,
			is_parent: false,
			parent_id: replyId,
		}
		const onSuccess = (res) => {
			dispatch(hideLoader())
			updateActivityItem(res?.data?.data)
			setSubReplyValue('')
			setSubReplyBoxShow(false)
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
			setSubReplyValue('')
			setSubReplyBoxShow(false)
		}
		GlassApi.updateStickyActivitiesReply(glassCode, stickyId, activity?.activity_id, sessionId, formData).then(onSuccess, onFailure)
	}

	const updateActivitySubReplyReactions = (activity, replyId, subReplyId, value) => {
		let formData = {
			reactions: value,
		}
		const onSuccess = (res) => {
			updateSubReplyItem(res?.data?.data?.sub_reply?.[0])
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		GlassApi.updateStickyActivitiesSubReplyReactions(glassCode, stickyId, activity?.activity_id, replyId, subReplyId, sessionId, formData).then(
			onSuccess,
			onFailure
		)
	}

	const updateActivityReplyReactions = (activity, replyId, value) => {
		let formData = {
			reactions: value,
		}
		const onSuccess = (res) => {
			updateReplyItem(res?.data?.data?.reply?.[0])
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		GlassApi.updateStickyActivitiesReplyReactions(glassCode, stickyId, activity?.activity_id, replyId, sessionId, formData).then(onSuccess, onFailure)
	}

	const updateActivityReactions = (activity, value) => {
		let formData = {
			reactions: value,
		}
		const onSuccess = (res) => {
			updateActivityItem(res?.data?.data)
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		GlassApi.updateStickyActivitiesReactions(glassCode, stickyId, activity?.activity_id, sessionId, formData).then(onSuccess, onFailure)
	}

	const handleSort = () => {
		let buttonText = buttonUI.btnText === 'Newest first' ? 'Oldest first' : 'Newest first'
		let buttonIcon = buttonUI.btnIcon === 'arrow_upward' ? 'arrow_downward' : 'arrow_upward'
		setButtonUI({ btnText: buttonText, btnIcon: buttonIcon })
		setActivityState((prevState) => ({
			...prevState,
			sort_by: buttonText === 'Newest first' ? 'desc' : 'asc',
			offset: 0,
			activityList: [],
			showRefreshSnackbar: false,
			loading: true,
		}))
	}
	const [commentLoading, setCommentLoading] = useState(false)

	const onCommentBoxSubmit = (data, formik) => {
		setCommentLoading(true)
		let formData = new FormData()
		formData.append('desc', data?.desc)
		data?.activity_type_id !== null && formData.append('activity_type_id', data?.activity_type_id?.activity_id)
		formData.append('labels', _.join(data?.labels, ','))
		data?.attachments.map((file) => formData.append('attachments', file))

		const onSuccess = (res, formik) => {
			formik.resetForm()
			setCommentLoading(false)
			setActivityState((prevState) => ({
				...prevState,
				activityList: [res?.data?.data, ...prevState.activityList],
			}))
			setShowActivity(true)
			callSocketFunction()
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
			setCommentLoading(false)
		}
		GlassApi.postStickyActivities(glassCode, stickyId, sessionId, formData).then((e) => onSuccess(e, formik), onFailure)
	}

	const bottomDividerRef = useRef()

	useLazyPaginate(bottomDividerRef, () => {
		if (!loading && totalCount > activityList.length) {
			setActivityState((prevState) => ({ ...prevState, offset: activityList.length, loading: true }))
		}
	})

	const closeRefreshSnackBar = () => {
		setActivityState((prevState) => ({ ...prevState, showRefreshSnackbar: false }))
	}

	const onChangeSysExecCb = () => {
		setActivityState((prevState) => ({
			...prevState,
			offset: 0,
			activityList: [],
			showRefreshSnackbar: false,
			loading: true,
			excludeSysLog: !excludeSysLog,
		}))
	}

	const loadMoreCmt = (activityId) => {
		const replyoffset = _.isEqual(activityId, reply_activityId) ? replyList.length : 0
		setReplyState((prevState) => ({ ...prevState, replyOffset: replyoffset, replyLoading: true }))
		loadMoreCmtFnx(activityId, replyoffset)
	}

	const loadMoreCmtFnx = useCallback(
		(activityId, replyoffset) => {
			GlassApi.getStickyActivitiesReplies(
				reply_sort_by,
				replyoffset,
				replyLimit,
				glassCode,
				stickyId,
				activityId,
				(res) => {
					let actions = _.get(res, 'data.data.reply', [])
					let replyTotalCount = _.get(res, 'data.data.reply_count')
					setReplyState((prevState) => ({
						...prevState,
						replyLoading: false,
						reply_totalCount: _.get(res, 'data.data.reply_count'),
						replyList: replyoffset === 0 ? actions : [...prevState.replyList, ...actions],
						reply_activityId: _.get(res, 'data.data.activity_id'),
					}))
					let replyLength = replyoffset !== 0 ? _.add(replyList.length, actions.length) : actions.length
					_.isEqual(replyLength, replyTotalCount) ? setReplyActivityId(activityId) : setReplyActivityId('')
				},
				(err) => {
					setReplyState((prevState) => ({ ...prevState, replyLoading: false }))
					dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
				}
			)
		},
		[replyOffset, activityId, replyList]
	)

	const loadMoreReply = (activityId, replyId) => {
		const replyoffset = _.isEqual(replyId, subReply_replyId) ? subReplyList.length : 0
		setSubReplyState((prevState) => ({ ...prevState, subReplyOffset: replyoffset, subReplyLoading: true }))
		loadMoreReplyFnx(activityId, replyId, replyoffset)
	}

	const loadMoreReplyFnx = useCallback(
		(activityId, replyId, replyoffset) => {
			GlassApi.getStickyActivitiesSubReplies(
				subReply_sort_by,
				replyoffset,
				subReplyLimit,
				glassCode,
				stickyId,
				activityId,
				replyId,
				(res) => {
					let actions = _.get(res, 'data.data.sub_reply', [])
					let replyTotalCount = _.get(res, 'data.data.sub_reply_count')
					setSubReplyState((prevState) => ({
						...prevState,
						subReplyLoading: false,
						subReply_totalCount: _.get(res, 'data.data.sub_reply_count'),
						subReplyList: replyoffset === 0 ? actions : [...prevState.subReplyList, ...actions],
						subReply_activityId: _.get(res, 'data.data.activity_id'),
						subReply_replyId: _.get(res, 'data.data.reply_id'),
					}))
					let replyLength = replyoffset !== 0 ? _.add(subReplyList.length, actions.length) : actions.length
					_.isEqual(replyLength, replyTotalCount) ? setSububReply_reply_Id(replyId) : setSububReply_reply_Id('')
				},
				(err) => {
					setSubReplyState((prevState) => ({ ...prevState, replyLoading: false }))
					dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
				}
			)
		},
		[subReplyOffset, replyId, subReplyList]
	)

	const labelsReducer = (state, action) => {
		const labelIdx = _.findIndex(state, { label_id: action?.payload?.label_id })
		switch (action.type) {
			case ACTION_TYPES.ADD_GLASS_LABEL:
				return update(state, { $push: [_.get(action, 'payload')] })
			case ACTION_TYPES.UPDATE_GLASS_LABEL:
				const labelToUpdate = _.get(action, 'payload')
				return update(state, { [labelIdx]: { $set: { ...labelToUpdate } } })
			case ACTION_TYPES.REMOVE_GLASS_LABEL:
				return update(state, { $splice: [[labelIdx, 1]] })
			default:
				return state
		}
	}

	const handleCustomLabel = (lblToUpdate, action) => {
		setGlassDetails((prevState) => ({ ...prevState, glassLabels: labelsReducer(glassDetails?.glassLabels, { type: action, payload: lblToUpdate }) }))
	}

	return (
		<div className={classes.root}>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				open={showRefreshSnackbar}
				autoHideDuration={6000}
				onClose={closeRefreshSnackBar}
				message={'Activities Updates Found!'}
				action={
					<React.Fragment>
						<Button color='secondary' size='small' onClick={handleSort}>
							Refresh
						</Button>
						<IconButton aria-label='close' color='inherit' className={classes.close} onClick={closeRefreshSnackBar}>
							<Icon>close</Icon>
						</IconButton>
					</React.Fragment>
				}
			/>

			{showCommentBox && (
				<div className={classes.mb2}>
					<CommentBox
						glassLabels={glassLabels}
						glassCode={glassCode}
						activityTypes={activityTypes}
						sessionId={sessionId}
						placeholder={placeholder}
						onSubmit={onCommentBoxSubmit}
						loading={commentLoading}
						suggestions={suggestions}
						handleCustomLabel={handleCustomLabel}
						newCustomLabel={true}
					/>
				</div>
			)}
			{showActivity ? (
				<>
					<Grid container direction='row' alignItems='center' className={classes.activitiesHead}>
						<Grid item>
							<Typography variant='subtitle2' component='p'>
								{title ? title : 'Activities'}
							</Typography>
						</Grid>
						<Grid item>
							{loading ? (
								<CircularProgress className={classes.loadingIcon} size={16} thickness={5} />
							) : (
								<Icon className={classes.refreshIcon} onClick={() => refreshActivity()}>
									refresh_icon
								</Icon>
							)}
							{showExcludeSystemMessage && (
								<FormControlLabel
									className={classes.activityForm}
									control={<Checkbox size='small' checked={excludeSysLog} onChange={onChangeSysExecCb} name='SysExCb' />}
									label={
										<Typography color='textSecondary' variant='body2'>
											Exclude System Messages
										</Typography>
									}
								/>
							)}
							<Button className={classes.sortButton} endIcon={<Icon>{buttonUI.btnIcon}</Icon>} onClick={handleSort}>
								{buttonUI.btnText}
							</Button>
						</Grid>
					</Grid>

					{!_.isEmpty(activityList) ? (
						_.map(activityList, (activity) => {
							const likeCount = _.sum(
								_.map(
									_.map(activity?.activities_reactions, (reactions) => {
										return reactions
									}),
									(data) => {
										return data?.like
									}
								)
							)
							const dislikeCount = _.sum(
								_.map(
									_.map(activity?.activities_reactions, (reactions) => {
										return reactions
									}),
									(data) => {
										return data?.dislike
									}
								)
							)
							let name = formTheName(activity?.created_by?.first_name, activity?.created_by?.last_name)
							const reactionStatus = _.toString(
								_.map(_.filter(activity?.activities_reactions, { created_by: userDetails?.user_id }), (data) => {
									return data?.reactions
								})
							)
							return (
								<Card key={activity?.activity_id} className={classes.cardRoot} raised={false} elevation={0}>
									<CardHeader
										avatar={
											<Avatar
												alt={name}
												src={getImgUrl(token, activity?.created_by?.profile_img) || null}
												size='small'
												className={classes.avatar}
												style={{ backgroundColor: stringToHslColor(name) }}
											>
												{_.slice(_.words(name), 0, 1).reduce((a, t) => (a += t[0]), '')}
											</Avatar>
										}
										action={
											<Typography variant='subtitle2' component='p'>
												{activity?.activity_type?.activity_name}
											</Typography>
										}
										title={name}
										subheader={convertGmtToLocalTime(_.get(activity, 'created_date', ''), 'DD MMM YYYY | hh:mm a')}
									/>
									<CardContent>
										{activity?.desc && <div className={classes.description} dangerouslySetInnerHTML={{ __html: activity?.desc }}></div>}
										{!_.isEmpty(_.get(activity, 'attachments', [])) && (
											<div className={classes.attachmentsSection}>
												<Attachments
													attachmentList={_.get(activity, 'attachments', [])}
													showDivider={false}
													isActivity={true}
													disableDelete={true}
													enableAddBtn={false}
												/>
											</div>
										)}
										{!activity?.is_log && (
											<div className={classes.activityReactions}>
												{_.isEqual(reactionStatus, 'like') ? (
													<Icon
														className={classes.likeIcon}
														onClick={() => {
															updateActivityReactions(activity, '')
														}}
													>
														thumb_up_icon
													</Icon>
												) : (
													<ThumbUpOutlinedIcon
														className={classes.likeIcon}
														onClick={() => {
															updateActivityReactions(activity, 'like')
														}}
													/>
												)}
												&nbsp;<div className={classes.dot}>.</div>&nbsp;
												<Typography className={classes.reactionCount}>{likeCount}</Typography>&nbsp;<div className={classes.bar}>|</div>&nbsp;
												{_.isEqual(reactionStatus, 'dislike') ? (
													<Icon
														className={classes.dislikeIcon}
														onClick={() => {
															updateActivityReactions(activity, '')
														}}
													>
														thumb_down_icon
													</Icon>
												) : (
													<ThumbDownOutlinedIcon
														className={classes.dislikeIcon}
														onClick={() => {
															updateActivityReactions(activity, 'dislike')
														}}
													/>
												)}
												&nbsp;<div className={classes.dot}>.</div>&nbsp;
												<Typography className={classes.reactionCount}>{dislikeCount}</Typography>&nbsp;<div className={classes.bar}>|</div>&nbsp;
												<Button
													className={classes.replyBtn}
													onClick={() => {
														handleCommentBoxOpen(activity)
													}}
												>
													Reply
												</Button>
												{_.gt(activity?.reply_count, 0) && (
													<>
														<div className={classes.dot}>&nbsp;.&nbsp;</div>
														<Typography className={classes.reactionCount}>{activity?.reply_count} Reply</Typography>
													</>
												)}
											</div>
										)}
									</CardContent>
									<CardContent style={{ paddingLeft: '8px' }} key={activity?.activity_id}>
										{_.isEqual(activityId, activity?.activity_id) && commentBoxShow && (
											<Grid container spacing={1} alignItems='center' className={classes.comment}>
												<Grid item>
													<CommentBox
														glassLabels={glassLabels}
														glassCode={glassCode}
														activityTypes={activityTypes}
														sessionId={sessionId}
														placeholder={'Add a comment...'}
														onSubmit={(data, formik) => updateActivityComment(data, formik, activity)}
														loading={commentLoading}
														suggestions={suggestions}
														handleCustomLabel={handleCustomLabel}
														newCustomLabel={true}
													/>
												</Grid>
											</Grid>
										)}
									</CardContent>
									{_.isEqual(reply_activityId, activity?.activity_id) &&
										!_.isEmpty(replyList) &&
										_.map(replyList, (reply) => {
											const replyLikeCount = _.sum(
												_.map(
													_.map(reply?.reply_reactions, (reactions) => {
														return reactions
													}),
													(data) => {
														return data?.like
													}
												)
											)
											const replyDislikeCount = _.sum(
												_.map(
													_.map(reply?.reply_reactions, (reactions) => {
														return reactions
													}),
													(data) => {
														return data?.dislike
													}
												)
											)
											const replyReactionStatus = _.toString(
												_.map(_.filter(reply?.reply_reactions, { created_by: userDetails?.user_id }), (data) => {
													return data?.reactions
												})
											)
											let name = formTheName(reply?.created_by?.first_name, reply?.created_by?.last_name)
											return (
												<CardContent style={{ paddingLeft: '8px' }} key={reply?.reply_id}>
													<div className={classes.chatCard}>
														<Grid container alignItems='flex-start' className={classes.mainChat}>
															<Grid item xs={1} lg={1}>
																<Avatar
																	size='small'
																	className={classes.commentAvatar}
																	src={getImgUrl(token, reply?.created_by?.profile_img) || null}
																	style={{
																		backgroundColor: stringToHslColor(formTheName(reply?.created_by?.first_name, reply?.created_by?.last_name)),
																	}}
																>
																	{_.slice(_.words(formTheName(reply?.created_by?.first_name, reply?.created_by?.last_name)), 0, 1).reduce(
																		(a, t) => (a += t[0]),
																		''
																	)}
																</Avatar>
															</Grid>
															<Grid container item xs={11} lg={11} className={classes.chatBox}>
																<Grid item>
																	<Card elevation={0} className={classes.chatText}>
																		<CardHeader
																			className={classes.chatHeader}
																			title={name}
																			subheader={convertGmtToLocalTime(_.get(reply, 'created_date', ''), 'DD MMM YYYY | hh:mm a')}
																			action={
																				<Typography variant='subtitle2' component='p'>
																					{reply?.activity_type?.activity_name}
																				</Typography>
																			}
																		/>
																		<CardContent>
																			{reply?.desc && <div className={classes.description} dangerouslySetInnerHTML={{ __html: reply?.desc }}></div>}
																			{!_.isEmpty(_.get(reply, 'attachments', [])) && (
																				<div className={classes.attachmentsSection}>
																					<Attachments
																						attachmentList={_.get(reply, 'attachments', [])}
																						showDivider={false}
																						isActivity={true}
																						disableDelete={true}
																						enableAddBtn={false}
																					/>
																				</div>
																			)}
																			<div className={classes.labelsWrap}>
																				{!_.isEmpty(reply?.labels) &&
																					_.map(reply?.labels, (label) => (
																						<ButtonBase
																							key={label?.label_id}
																							onClick={null}
																							disabled={true}
																							style={{ background: label?.label_color }}
																							className={classes.labelBtnBase}
																						>
																							<Typography variant='caption'>{label?.label_name}</Typography>
																						</ButtonBase>
																					))}
																			</div>
																		</CardContent>
																	</Card>
																</Grid>
																<Grid item className={classes.reactions}>
																	{_.isEqual(replyReactionStatus, 'like') ? (
																		<Icon
																			className={classes.likeIcon}
																			onClick={() => {
																				updateActivityReplyReactions(activity, reply?.reply_id, '')
																			}}
																		>
																			thumb_up_icon
																		</Icon>
																	) : (
																		<ThumbUpOutlinedIcon
																			className={classes.likeIcon}
																			onClick={() => {
																				updateActivityReplyReactions(activity, reply?.reply_id, 'like')
																			}}
																		/>
																	)}
																	&nbsp;<div className={classes.dot}>.</div>&nbsp;
																	<Typography className={classes.reactionCount}>{replyLikeCount}</Typography>&nbsp;
																	<div className={classes.bar}>|</div>&nbsp;
																	{_.isEqual(replyReactionStatus, 'dislike') ? (
																		<Icon
																			className={classes.dislikeIcon}
																			onClick={() => {
																				updateActivityReplyReactions(activity, reply?.reply_id, '')
																			}}
																		>
																			thumb_down_icon
																		</Icon>
																	) : (
																		<ThumbDownOutlinedIcon
																			className={classes.dislikeIcon}
																			onClick={() => {
																				updateActivityReplyReactions(activity, reply?.reply_id, 'dislike')
																			}}
																		/>
																	)}
																	&nbsp;<div className={classes.dot}>.</div>&nbsp;
																	<Typography className={classes.reactionCount}>{replyDislikeCount}</Typography>&nbsp;
																	<div className={classes.bar}>|</div>&nbsp;
																	<Button
																		className={classes.replyBtn}
																		onClick={() => {
																			handleReplyBoxOpen(reply?.reply_id)
																		}}
																	>
																		Reply
																	</Button>
																	{_.gt(reply?.sub_reply_count, 0) && (
																		<>
																			<div className={classes.dot}>&nbsp;.&nbsp;</div>
																			<Typography className={classes.reactionCount}>{reply?.sub_reply_count} Reply</Typography>
																		</>
																	)}
																</Grid>
															</Grid>
														</Grid>
														{_.isEqual(replyId, reply?.reply_id) && replyBoxShow && (
															<Grid container alignItems='center'>
																<Grid item xs={1} lg={1}></Grid>
																<Grid item xs={11} lg={11}>
																	<div>
																		<Grid item>
																			<CommentBox
																				glassLabels={glassLabels}
																				glassCode={glassCode}
																				activityTypes={activityTypes}
																				sessionId={sessionId}
																				placeholder={'Add a comment...'}
																				onSubmit={(data, formik) => updateActivityReply(data, formik, activity, reply?.reply_id)}
																				loading={commentLoading}
																				suggestions={suggestions}
																				handleCustomLabel={handleCustomLabel}
																				newCustomLabel={true}
																			/>
																		</Grid>
																	</div>
																</Grid>
															</Grid>
														)}
														{_.isEqual(subReply_replyId, reply?.reply_id) &&
															!_.isEmpty(subReplyList) &&
															_.map(subReplyList, (subReply) => {
																const subReplyLikeCount = _.sum(
																	_.map(
																		_.map(subReply?.sub_reply_reactions, (reactions) => {
																			return reactions
																		}),
																		(data) => {
																			return data?.like
																		}
																	)
																)
																const subReplyDislikeCount = _.sum(
																	_.map(
																		_.map(subReply?.sub_reply_reactions, (reactions) => {
																			return reactions
																		}),
																		(data) => {
																			return data?.dislike
																		}
																	)
																)
																const subReplyReactionStatus = _.toString(
																	_.map(_.filter(subReply?.sub_reply_reactions, { created_by: userDetails?.user_id }), (data) => {
																		return data?.reactions
																	})
																)
																let name = formTheName(subReply?.created_by?.first_name, subReply?.created_by?.last_name)
																return (
																	<>
																		<Grid container alignItems='flex-start' className={classes.subChat} key={subReply?.reply_id}>
																			<Grid item xs={1} lg={1}></Grid>
																			<Grid item xs={1} lg={1}>
																				<Avatar
																					size='small'
																					className={classes.commentAvatar}
																					src={getImgUrl(token, subReply?.created_by?.profile_img) || null}
																					style={{
																						backgroundColor: stringToHslColor(
																							formTheName(subReply?.created_by?.first_name, subReply?.created_by?.last_name)
																						),
																					}}
																				>
																					{_.slice(
																						_.words(formTheName(subReply?.created_by?.first_name, subReply?.created_by?.last_name)),
																						0,
																						1
																					).reduce((a, t) => (a += t[0]), '')}
																				</Avatar>
																			</Grid>
																			<Grid container item xs={10} lg={10} className={classes.chatBox}>
																				<Grid item>
																					<Card elevation={0} className={classes.chatText}>
																						<CardHeader
																							className={classes.chatHeader}
																							title={name}
																							subheader={convertGmtToLocalTime(_.get(subReply, 'created_date', ''), 'DD MMM YYYY | hh:mm a')}
																						/>
																						<CardContent>
																							{subReply?.desc && (
																								<div className={classes.description} dangerouslySetInnerHTML={{ __html: subReply?.desc }}></div>
																							)}
																							{!_.isEmpty(_.get(subReply, 'attachments', [])) && (
																								<div className={classes.attachmentsSection}>
																									<Attachments
																										attachmentList={_.get(subReply, 'attachments', [])}
																										showDivider={false}
																										isActivity={true}
																										disableDelete={true}
																										enableAddBtn={false}
																									/>
																								</div>
																							)}
																							<div className={classes.labelsWrap}>
																								{!_.isEmpty(subReply?.labels) &&
																									_.map(subReply?.labels, (label) => (
																										<ButtonBase
																											key={label?.label_id}
																											onClick={null}
																											disabled={true}
																											style={{ background: label?.label_color }}
																											className={classes.labelBtnBase}
																										>
																											<Typography variant='caption'>{label?.label_name}</Typography>
																										</ButtonBase>
																									))}
																							</div>
																						</CardContent>
																					</Card>
																				</Grid>
																				<Grid item className={classes.reactions}>
																					{_.isEqual(subReplyReactionStatus, 'like') ? (
																						<Icon
																							className={classes.likeIcon}
																							onClick={() => {
																								updateActivitySubReplyReactions(activity, reply?.reply_id, subReply?.reply_id, '')
																							}}
																						>
																							thumb_up_icon
																						</Icon>
																					) : (
																						<ThumbUpOutlinedIcon
																							className={classes.likeIcon}
																							onClick={() => {
																								updateActivitySubReplyReactions(activity, reply?.reply_id, subReply?.reply_id, 'like')
																							}}
																						/>
																					)}
																					&nbsp;<div className={classes.dot}>.</div>&nbsp;
																					<Typography className={classes.reactionCount}>{subReplyLikeCount}</Typography>&nbsp;
																					<div className={classes.bar}>|</div>&nbsp;
																					{_.isEqual(subReplyReactionStatus, 'dislike') ? (
																						<Icon
																							className={classes.dislikeIcon}
																							onClick={() => {
																								updateActivitySubReplyReactions(activity, reply?.reply_id, subReply?.reply_id, '')
																							}}
																						>
																							thumb_down_icon
																						</Icon>
																					) : (
																						<ThumbDownOutlinedIcon
																							className={classes.dislikeIcon}
																							onClick={() => {
																								updateActivitySubReplyReactions(activity, reply?.reply_id, subReply?.reply_id, 'dislike')
																							}}
																						/>
																					)}
																					&nbsp;<div className={classes.dot}>.</div>&nbsp;
																					<Typography className={classes.reactionCount}>{subReplyDislikeCount}</Typography>&nbsp;
																					<div className={classes.bar}>|</div>&nbsp;
																					<Button
																						className={classes.replyBtn}
																						onClick={() => {
																							handleSubReplyBoxOpen(subReply?.reply_id)
																						}}
																					>
																						Reply
																					</Button>
																				</Grid>
																			</Grid>
																		</Grid>
																		{_.isEqual(subReplyId, subReply?.reply_id) && subReplyBoxShow && (
																			<Grid container alignItems='center'>
																				<Grid item xs={1} lg={1}></Grid>
																				<Grid item xs={11} lg={11}>
																					<div>
																						<Grid item>
																							<CommentBox
																								glassLabels={glassLabels}
																								glassCode={glassCode}
																								activityTypes={activityTypes}
																								sessionId={sessionId}
																								placeholder={'Add a comment...'}
																								onSubmit={(data, formik) => updateActivityReply(data, formik, activity, reply?.reply_id)}
																								loading={commentLoading}
																								suggestions={suggestions}
																								handleCustomLabel={handleCustomLabel}
																								newCustomLabel={true}
																							/>
																						</Grid>
																					</div>
																				</Grid>
																			</Grid>
																		)}
																	</>
																)
															})}
													</div>
													{!_.isEqual(subReply_reply_Id, reply?.reply_id) && _.gt(reply?.sub_reply_count, 0) && (
														<Grid container alignItems='center'>
															<Grid item xs={2} lg={2}></Grid>
															<Grid item xs={10} lg={10}>
																<div className={classes.subLoadMore}>
																	<Button
																		color='primary'
																		size='small'
																		style={{ textTransform: 'none' }}
																		onClick={() => loadMoreReply(activity?.activity_id, reply?.reply_id)}
																	>
																		Load more comments...
																	</Button>
																</div>
															</Grid>
														</Grid>
													)}
												</CardContent>
											)
										})}
									{!_.isEqual(replyActivityId, activity?.activity_id) && _.gt(activity?.reply_count, 0) && (
										<CardContent>
											<div className={classes.loadMore}>
												<Button color='primary' size='small' style={{ textTransform: 'none' }} onClick={() => loadMoreCmt(activity?.activity_id)}>
													Load more comments...
												</Button>
											</div>
										</CardContent>
									)}
									<CardActions disableSpacing>
										<div className={classes.labelsWrap}>
											{!_.isEmpty(activity?.labels) &&
												_.map(activity?.labels, (label) => (
													<ButtonBase
														key={label?.label_id}
														onClick={null}
														disabled={true}
														style={{ background: label?.label_color }}
														className={classes.labelBtnBase}
													>
														<Typography variant='caption'>{label?.label_name}</Typography>
													</ButtonBase>
												))}
											{isEditEnabled && !activity?.is_log && (
												<>
													{activity?.is_pinned ? (
														<Button color='primary' size='small' onClick={() => updateActivity(activity)}>
															<Icon className={classes.pinnedIcon} fontSize='small'>
																push_pin
															</Icon>
															{'  '}
															&nbsp; unpin
														</Button>
													) : (
														<Button color='primary' size='small' onClick={() => updateActivity(activity)}>
															<Icon fontSize='small'>push_pin</Icon>
															{'  '}
															&nbsp; pin
														</Button>
													)}
												</>
											)}
										</div>
									</CardActions>
									<Divider />
								</Card>
							)
						})
					) : (
						<>
							{!loading && (
								<Box padding={2}>
									<Typography component='div' align='center' variant='caption'>
										No Activites Found!
									</Typography>
								</Box>
							)}
						</>
					)}

					{loading && (
						<div className={classes.loadingSkeleton}>
							<Skeleton animation='wave' variant='circle' width={39} height={36} />
							<div className={classes.lineSkeleton}>
								<Grid container direction='row'>
									<Grid item xs={6}>
										<Skeleton animation='wave' height={18} width='90%' />
									</Grid>
									<Grid item xs={6} className={classes.activityTypeItem}>
										<Skeleton animation='wave' height={18} width='50%' />
									</Grid>
								</Grid>
								<Skeleton animation='wave' height={18} width='40%' />
								<Skeleton animation='wave' height={45} width='100%' />
							</div>
						</div>
					)}

					{!_.isEmpty(activityList) && !loading && totalCount > activityList.length && (
						<div className={classes.loadMore}>
							<Button color='primary' size='small' style={{ textTransform: 'none' }} onClick={() => loadMoreActivities()}>
								Load more comments...
							</Button>
						</div>
					)}
					{/* <div ref={bottomDividerRef}></div> */}
				</>
			) : (
				<></>
			)}
		</div>
	)
}

Activities.propTypes = {
	title: PropTypes.string,
	stickyId: PropTypes.string,
	glassCode: PropTypes.string,
	is_pinned: PropTypes.bool,
	sessionId: PropTypes.string,
	isEditEnabled: PropTypes.bool,
	showCommentBox: PropTypes.bool,
	activityTypes: PropTypes.array,
	glassLabels: PropTypes.array,
	placeholder: PropTypes.string,
	socketRef: PropTypes.object,
	isSocketConnected: PropTypes.bool,
	suggestions: PropTypes.array,
	showExcludeSystemMessage: PropTypes.bool,
}

export default Activities
