import React, { useState, useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Tabs, Tab, Typography, Badge } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import {
	PAGE_MODES,
	DATA_CONNECTION_DISPLAY_IMAGE,
	DATA_CONNECTION_DISPLAY_NAME,
	ACTIVE_DATA_CONNECTIONS,
	API_ENDPOINTS,
	CONNECTION_TABS,
	STREAM_CONNECTION_TABS,
	DATA_CONNECTION_BACKGROUND_IMAGE,
	ACTIVE_STREAM_CONNECTIONS,
} from '../../../../../constants'
import Validator from './validator'
import { Form } from 'formik'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { hideLoader } from '../../../../../services/loader/actions'
import { createNewUser, fetchUserViaSaga, updateUser } from '../../../../../services/users/actions'
import ThirdPartyStream from './ThirdPartyStream'
import Apps from './Apps'
import DataBase from './DataBase'
import Slide from '@material-ui/core/Slide'
import classnames from 'classnames'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const getDialogTitle = (action) => {
	switch (action) {
		case PAGE_MODES.CREATE:
			return 'Add User'
		case PAGE_MODES.EDIT:
			return 'Edit User'
		case PAGE_MODES.VIEW:
			return 'View User'
		default:
			return ''
	}
}

const useStyles = makeStyles((theme) => ({
	dialog: {
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
			top: 20,
		},
	},
	tab: {
		textTransform: 'none',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	customTabTitle: {
		fontSize: 14,
		fontWeight: '500',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	dialogTitle: {
		paddingBottom: 0,
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	dialogContent: {
		// paddingTop: theme.spacing(2),
		width: '549px',
		[theme.breakpoints.down('sm')]: {
			width: 'auto',
		},
	},
	dialogFooter: {
		padding: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
	badge: {
		marginLeft: theme.spacing(1),
	},
	hideBtn: {
		display: 'none',
	},
}))

const FormDialog = ({
	open,
	userId,
	action,
	handleClose,
	distributors,
	products,
	isRoleListLoading,
	roleList,
	isEditAllowed,
	onSkipClick,
	changeToEditMode,
	streams,
}) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const dialogTitle = getDialogTitle(action)
	const classes = useStyles()
	const [activeTabIdx, setActiveTabIdx] = useState(0)

	const [stream, setStream] = useState(streams)

	const formRef = useRef()
	const dispatch = useDispatch()


	const isEditMode = _.isEqual(action, PAGE_MODES.EDIT)
	const isCreateMode = _.isEqual(action, PAGE_MODES.CREATE)
	const isViewMode = _.isEqual(action, PAGE_MODES.VIEW)

	const cardImageStyle = { width: 100, margin: '0 auto', display: 'block' }

	const connectionTypes = useMemo(() => {
		if (activeTabIdx !== 2) {
			return _.values(STREAM_CONNECTION_TABS)[activeTabIdx].map((connectionType) => {
				// if (_.includes(stream[activeTabIdx], connectionType)) {
				// 	return {
				// 		isSelected: true,
				// 		id: connectionType,
				// 		isDisabled: _.indexOf(ACTIVE_DATA_CONNECTIONS, connectionType) === -1,
				// 		image: DATA_CONNECTION_DISPLAY_IMAGE[connectionType],
				// 	}
				// } else {
					return {
						isSelected: false,
						id: connectionType,
						name: connectionType,
						isBackground: !_.isEmpty(DATA_CONNECTION_BACKGROUND_IMAGE[connectionType]) ? true : false,
						backgroundImage: DATA_CONNECTION_BACKGROUND_IMAGE[connectionType],
						isDisabled: _.indexOf(ACTIVE_STREAM_CONNECTIONS, connectionType) === -1,
						image: DATA_CONNECTION_DISPLAY_IMAGE[connectionType],
					}
				// }
			})
		}
	}, [activeTabIdx, stream])

	const onClickcard = (record, key, formik) => {
		// const result = _.some(stream[key], (obj) => _.isEqual(obj.id, record?.id))
		const result = _.findIndex(stream[key], { id: record?.id })
		if (result !== -1) {
			let tempArray = [...stream[key]]
			// _.remove(tempArray, (key) => key === record)
			_.remove(tempArray, { id: record?.id })
			setStream((prevState) => {
				return { ...prevState, [key]: tempArray }
			})
			formik?.setFieldValue(key, tempArray)
		} else {
			setStream((prevState) => ({
				...prevState, [key]: [...stream[key], record] 
			}))
			formik?.setFieldValue(key, [...stream[key], record])
		}
	}

	const onChangeTab = (e, tabIdx) => {
		setActiveTabIdx(tabIdx)
	}

	const onSubmitForm = (values) => {
		onSkipClick(values)
	}

	const onClickEditBtn = () => {
		if (action === PAGE_MODES.VIEW) {
			changeToEditMode(userId)
		}
	}

	const onFormSubmitErr = () => {
		if (!_.isEmpty(formRef.current, 'state.errors')) {
			if (activeTabIdx > 0) {
				setActiveTabIdx(0)
			}
		}
	}

	const onClickActionBtn = () => {
		formRef.current && formRef.current.submitForm()
	}

	//reset exsting user details on form dialog close event
	useEffect(() => {
		if (!open) {
			setStream(streams)
			setActiveTabIdx(0)
		}
	}, [open, streams])

	// const openDialog = open && !isFetchingUsr
	return (
		<Dialog
			classes={{
				paper: classes.dialog,
			}}
			TransitionComponent={Transition}
			fullScreen={fullScreen}
			maxWidth={'sm'}
			open={open}
			onClose={handleClose}
			aria-labelledby='user-form-title'
		>
			{/* {!isFetchingUsr ? ( */}
				<Validator formMode={action} initialFormData={stream} onSubmit={onSubmitForm} ref={formRef}>
					{(formik) => {
						return (
							<>
								<DialogTitle className={classes.dialogTitle} disableTypography={true} id='user-dialog-title'>
									{/* <Typography variant='h6'>Add data stream</Typography> */}
									<Tabs
										orientation='horizontal'
										value={activeTabIdx}
										indicatorColor='primary'
										onChange={onChangeTab}
										variant='fullWidth'
										aria-label='User form tabs'
									>
										<Tab className={classes.tab} label={'Database'}></Tab>
										<Tab className={classes.tab} label={'Apps'}></Tab>
										<Tab className={classes.tab} label={'Third Party Stream'}></Tab>
									</Tabs>
								</DialogTitle>
								<DialogContent className={classes.dialogContent}>
									<Form id='user-form' autoComplete='new-password'>
										<Effect formik={formik} onSubmissionError={onFormSubmitErr} />
										<div hidden={activeTabIdx !== 0} className={classes.tabPanel}>
											<DataBase
												data={connectionTypes}
												cardImageStyle={cardImageStyle}
												cardBreakups={{ xs: 12, sm: 6, md: 4, lg: 4 }}
												onClickcard={(id) => onClickcard(id, 'data_base', formik)}
												stream={stream?.data_base}
											/>
										</div>
										<div hidden={activeTabIdx !== 1} className={classes.tabPanel}>
											<Apps
												data={connectionTypes}
												cardImageStyle={cardImageStyle}
												cardBreakups={{ xs: 12, sm: 6, md: 4, lg: 4 }}
												onClickcard={(id) => onClickcard(id, 'apps', formik)}
												stream={stream?.apps}
											/>
										</div>
										<div hidden={activeTabIdx !== 2} className={classes.tabPanel}>
											<ThirdPartyStream
												isViewMode={isViewMode}
												distributors={distributors}
												products={products}
												isEditMode={isEditMode}
												isCreateMode={isCreateMode}
												formik={formik}
												streams={streams?.third_party_stream}
											/>
										</div>
									</Form>
								</DialogContent>
								<DialogActions className={classes.dialogFooter}>
									<Button
										style={{ fontWeight: 'bold' }}
										variant='outlined'
										size={fullScreen ? 'small' : 'medium'}
										onClick={handleClose}
										color='primary'
									>
										{isViewMode ? 'Close' : 'Cancel'}
									</Button>

									<Button
										variant='contained'
										onClick={isViewMode ? onClickEditBtn : onClickActionBtn}
										size={fullScreen ? 'small' : 'medium'}
										className={classnames({
											[classes.hideBtn]: isViewMode && !isEditAllowed,
										})}
										color='primary'
										style={{ fontWeight: 'bold' }}
										disableElevation
									>
										{isViewMode ? 'Edit' : 'Add'}
									</Button>
								</DialogActions>
							</>
						)
					}}
				</Validator>
			{/* ) : (
				<></>
			)} */}
		</Dialog>
	)
}

function Effect(props) {
	const effect = () => {
		if (props.formik.submitCount > 0 && !props.formik.isValid) {
			props.onSubmissionError()
		}
	}
	useEffect(effect, [props.formik.submitCount])
	return null
}

FormDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	changeToEditMode: PropTypes.func.isRequired,
	isRoleListLoading: PropTypes.bool.isRequired,
	roleList: PropTypes.array,
	userId: PropTypes.string,
	action: PropTypes.string,
	isEditAllowed: PropTypes.bool,
}

export default FormDialog
