import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import { Box, Fade, Icon, IconButton, Typography, useMediaQuery, useTheme, Backdrop, CircularProgress } from '@material-ui/core'
import SwiperCarousel from './SwiperCarousel'
import { getFileFormatIconAndColor } from 'utils'
import _ from 'lodash'
import GlassApi from 'services/glass/api'
import { getImgUrl, getErrMsg } from 'utils'
import { hideLoader, showErrorMessage } from 'services/loader/actions'
import { useDispatch } from 'react-redux'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Fade ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.down(600)]: {
			'& .MuiBackdrop-root, & .MuiPaper-root': {
				backgroundColor: '#202124',
			},
		},
	},
	header: {
		background: '#FFFFFF',
		color: '#171725',
		position: 'relative',
	},
	closeBtn: {
		position: 'absolute',
		right: 6,
		top: 6,
		background: '#ffffff',
		padding: '2px',
		marginLeft: '16px',
		textTransform: 'initial',
		color: '#9e9e9e',
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 'fit-content',
		// popup maximum height
		// height: 'inherit',
		[theme.breakpoints.down(600)]: {
			maxWidth: '-webkit-fill-available',
		},
	},
	content: {
		flex: '1',
		backgroundColor: '#414243',
	},
	fileIcon: {
		display: 'inline-block',
		verticalAlign: 'middle',
		marginRight: '4px',
	},
	fileName: {
		display: 'inline-block',
		verticalAlign: 'middle',
		[theme.breakpoints.down('sm')]: {
			width: '80%',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
	headerContent: {
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
		},
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}))

const isImage = (mediaType) => {
	return ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'].indexOf(mediaType) > -1
}

function FilePreviewDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { onClose, open, fileList, accessToken, openFileIdx, sticky } = props
	const [activeFileIdx, setActiveFileIdx] = useState(0)
	const theme = useTheme()

	const isSmartDevice = useMediaQuery(theme.breakpoints.down(600))
	const isSmallHeight = useMediaQuery('(max-height:680px)')

	const [showFile, setShowFile] = useState(fileList)
	const [apiSuccess, setApiSuccess] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (open === true) {
			setApiSuccess(false)
			setIsLoading(true)
			const onSuccess = (res) => {
				const attachments = res?.data?.data?.attachments
				const customAttachments = _.map(attachments, (file) => ({
					...file,
					url: _.isEqual(file?.file_type, 'url') ? file?.file_path : getImgUrl(accessToken, file?.file_path),
					src: isImage(file?.file_type) ? getImgUrl(accessToken, file?.file_path) : getImgUrl(accessToken, file?.thumbnail_path),
				}))
				setShowFile(customAttachments)
				setApiSuccess(true)
				setIsLoading(false)
			}
			const onFailure = (err) => {
				dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
				setApiSuccess(false)
				setIsLoading(false)
				onClose()
			}
			GlassApi.getStickyAttachments(sticky?.glass_code, sticky?.sticky_code).then(onSuccess, onFailure)
		}
	}, [sticky, open === true])

	useEffect(() => {
		if (open) {
			setActiveFileIdx(showFile.length > openFileIdx ? openFileIdx : 0)
		}
	}, [showFile, openFileIdx, open])

	const handleClose = () => {
		onClose()
	}

	const activeFile = useMemo(() => showFile[activeFileIdx], [showFile, activeFileIdx])
	const fileAvatarDetails = useMemo(() => {
		let fileExt = _.split(activeFile?.file_name, '.').pop()
		return getFileFormatIconAndColor(fileExt)
	}, [activeFile])

	return (
		<>
			{isLoading ? (
				<Backdrop className={classes.backdrop} open={!apiSuccess} invisible={apiSuccess}>
					<CircularProgress color='inherit' />
				</Backdrop>
			) : !isLoading && apiSuccess ? (
				<Dialog
					className={classes.root}
					fullScreen={isSmartDevice ? true : false}
					TransitionComponent={Transition}
					transitionDuration={{ enter: 1000, exit: 500 }}
					onClose={handleClose}
					aria-labelledby='simple-dialog-title'
					open={open}
					classes={{ paper: classes.paper }}
				>
					<Box className={classes.header} padding={1}>
						<Typography className={classes.headerContent} variant='body2' align='center'>
							<Icon fontSize='small' style={{ color: fileAvatarDetails?.background }} className={classes.fileIcon}>
								{fileAvatarDetails?.fileIcon}
							</Icon>
							<span className={classes.fileName}>{activeFile?.file_name}</span>
						</Typography>
						<IconButton size='small' onClick={handleClose} className={classes.closeBtn}>
							<Icon fontSize='small'>close</Icon>
						</IconButton>
					</Box>
					<Box className={classes.content}>
						<SwiperCarousel activeIdx={activeFileIdx} setActiveIdx={setActiveFileIdx} fileList={showFile} accessToken={accessToken} />
					</Box>
				</Dialog>
			) : null}
		</>
	)
}

FilePreviewDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	fileList: PropTypes.array.isRequired,
	accessToken: PropTypes.string.isRequired,
}

export default FilePreviewDialog
