import { put, call, takeLatest } from 'redux-saga/effects'
import { ACTION_TYPES, SAGA_ACTIONS } from '../../../constants'
import fetchJobsApi from '../api'
import _ from 'lodash'

// const delay = ms => new Promise(res => setTimeout(res, ms));

function* fetchJobs(action) {
	// yield delay(10);

	yield put({ type: ACTION_TYPES.FETCH_JOBS_PENDING })
	try {
		const jobs = yield call(fetchJobsApi.fetchJobs, action.query)
		yield put({
			type: ACTION_TYPES.FETCH_JOBS_FULFILLED,
			payload: _.get(jobs, 'data.data'),
		})
	} catch (err) {
		yield put({ type: ACTION_TYPES.FETCH_JOBS_REJECTED, payload: err })
	}
}

function* fetchJobByJobId(action) {
	// yield put({type: ACTION_TYPES.FETCH_JOB_BY_JOB_ID_PENDING})
	try {
		const job = yield call(fetchJobsApi.fetchJobs, {
			filters: { job_id: [`${action.jobId}`] },
			offset: 0,
			limit: 1,
		})
		yield put({
			type: ACTION_TYPES.FETCH_JOB_BY_JOB_ID_FULFILLED,
			payload: job.data,
			recIdx: action.recIdx,
			jobId: action.jobId,
		})
	} catch (err) {
		// yield put({type: ACTION_TYPES.FETCH_JOB_BY_JOB_ID_REJECTED, payload: err})
		console.log('on fetching job by job id... error happened', err.message, err)
	}
}

export function* watchFetchJobs() {
	yield takeLatest(SAGA_ACTIONS.FETCH_JOBS, fetchJobs)
}

export function* watchFetchJobByJobId() {
	yield takeLatest(SAGA_ACTIONS.FETCH_JOB_BY_JOB_ID, fetchJobByJobId)
}
